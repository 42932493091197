import React from 'react';

export default function Dashboard() {
  return (
    <div class="bg-gray-200 p-8 mt-0.5">
        <div class="bg-gray-200 p-4 ">
            <div class="flex justify-between items-center">
                <div class="text-2xl font-bold text-gray-900">Dashboard</div>
            </div>
        </div>

        <div class="bg-gray-200 p-8 ">
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                <div class="bg-white p-4 rounded-lg">
                    <div class="flex justify-between items-center">
                        <div class="flex flex-col">
                            <div class="text-xl font-bold">Lorem Ipsum</div>
                            <div class="text-3xl font-normal mt-2">0</div>
                        </div>
                    </div>
                    <div class="flex justify-between items-center mt-2 text-gray-500">
                        <div>0%</div>
                        <div>Lorem Ipsum</div>
                    </div>
                </div>
                <div class="bg-white p-4 rounded-lg">
                    <div class="flex justify-between items-center">
                        <div class="flex flex-col">
                            <div class="text-xl font-bold">Lorem Ipsum</div>
                            <div class="text-3xl font-normal mt-2">0</div>
                        </div>
                    </div>
                    <div class="flex justify-between items-center mt-2 text-gray-500">
                        <div>0%</div>
                        <div>Lorem Ipsum</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
