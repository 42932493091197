import React, { useEffect, useState } from 'react';
import config from '../../config/config';
import { fetchGlobalVariablesList } from '../../services/apis/globalRecommendationVariable';
import { Tooltip } from 'react-tooltip';

export default function FormulaVariablesTable() {
    const headings = config.formulaVariablesListHeaders;
    const [data, setData] = useState([]);

    useEffect(() => {
        const getFormulaVariablesList = async () => {
            const payload = {
                isMap: "true",
                mapData: "true"
            };
            const response = await fetchGlobalVariablesList(payload);
            const variablesList = response?.data?.data?.results;
            setData(variablesList);
        };
        getFormulaVariablesList();
    }, []);

    const displayType = (type) => {
        switch (type) {
            case 'regulatoryCharge':
                return 'Regulatory Charge';
            case 'intermediateVariable':
                return 'Intermediate Variable';
            case 'formula':
                return 'Formula';
            case 'constant':
                return 'Constant';
            default:
                return type;
        }
    };

    const filterMapData = (mapData) => {
        const { createdAt, updatedAt, __v, _id, globalRecVariableId, ...filteredData } = mapData;
        return filteredData;
    };

    const capitalize = (str) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    };

    const formatValue = (value) => {
        if (Array.isArray(value)) {
            return value.map((item, index) => `<div key=${index}>${formatValue(item)}</div>`).join('');
        } else if (typeof value === 'object') {
            return `<table class="min-w-full divide-y divide-gray-200 border border-black">${Object.entries(value).map(([key, val]) => `
                <tr>
                    <td class="px-2 py-1 text-xs font-medium border border-black text-black">${capitalize(key)}</td>
                    <td class="px-2 py-1 text-xs border border-black text-black">${formatValue(val)}</td>
                </tr>
            `).join('')}</table>`;
        }
        return value;
    };

    const generateTooltipContent = (mapData) => {
        const filteredData = filterMapData(mapData);
        return `
            <table class="min-w-full divide-y divide-gray-200 border border-black">
                ${Object.entries(filteredData).map(([key, value]) => `
                    <tr>
                        <td class="px-2 py-1 text-xs font-medium border border-black text-black">${capitalize(key)}</td>
                        <td class="px-2 py-1 text-xs border border-black text-black">${formatValue(value)}</td>
                    </tr>
                `).join('')}
            </table>
        `;
    };

    return (
        <div className="bg-white shadow-sm rounded-xl ">
            <div className="p-2 min-h-[350px] overflow-hidden">
                <div className="overflow-y-auto min-h-[350px] relative max-h-[400px] no-scrollbar">
                    <table className="min-w-full ">
                        <thead>
                            <tr>
                                {Object.entries(headings).map(([key, heading], index, array) => (
                                    <th
                                        key={heading}
                                        className={`px-6 py-2 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider ${heading !== 'Actions' && heading !== 'Add Role' && heading !== 'Roles' && heading !== 'Status' ? 'cursor-pointer' : ''}`}
                                        style={{
                                            borderBottomLeftRadius: index === 0 ? '0.5rem' : '0',
                                            borderBottomRightRadius: index === array.length - 1 ? '0.5rem' : '0',
                                            borderTopLeftRadius: index === 0 ? '0.5rem' : '0',
                                            borderTopRightRadius: index === array.length - 1 ? '0.5rem' : '0',
                                        }}
                                    >
                                        {heading}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-300">
                            {data?.length > 0 ? (
                                data?.map((row, index) => (
                                    <tr key={index}>
                                        {Object.entries(headings).map(([key, value]) => (
                                            <td
                                                key={key}
                                                className="px-6 py-2 whitespace-no-wrap text-sm leading-5 text-gray-500 cursor-pointer"
                                                data-tooltip-id={`tooltip-${index}`}
                                                data-tooltip-html={row.mapData ? generateTooltipContent(row.mapData) : 'No mapData available'}
                                            >
                                                {key === 'type' ? displayType(row[key]) : row[key]}
                                            </td>
                                        ))}
                                        <Tooltip id={`tooltip-${index}`} place="top" effect="solid" style={{ backgroundColor: "#E2F5EC", color: "white", opacity: "100", border: "solid 1px black",  }}/>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={Object.keys(headings).length} className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 text-center">
                                        No Records Available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
