import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import { getRegulationById } from '../../services/apis/regulationsList';
import '../common.css';

export default function EditRegulation() {

    const { id } = useParams();

    const [formData, setFormData] = useState({
        regulationName: "",
        slug: "",
        status: "status",
        description: "",
        shortDescription: "",
        metaTitle: "",
        metaDataDescription: "",
        metaKeyword: ""
    });
    
    const [errors, setErrors] = useState({});
    const [formReset, setFormReset] = useState(false);

    useEffect(() => {
        const fetchRegulationData = async () => {
            try {
                const regulation = getRegulationById(id);
                
                if (regulation) {
                    setFormData({
                      regulationName: regulation.title || "", 
                      slug: regulation.slug || "",
                      status: regulation.status || "",
                      shortDescription: regulation.shortDescription || "",
                      content: regulation.description || "", 
                      metaTitle: regulation.metaTitle || "",
                      metaTags: regulation.metaTags || "",
                      metaDataDescription: regulation.metaDataDescription || "",
                    });
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchRegulationData();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        let slugValue = name === 'regulationName' ? 
                      value
                      .toLowerCase() 
                      .replace(/\s+/g, '-') 
                      .replace(/[^a-zA-Z0-9-]/g, '') : formData.slug; 
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
          slug: slugValue
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: ""
        }));
    };

    const handleCancel = () => {
        setFormData({
            regulationName: "",
            slug: "",
            status: "status",
            description: "",
            metaTitle: "",
            metaDataDescription: "",
            metaKeyword: ""
        });
        setErrors({});
    };
  
    const handleSubmit = (e) => {
        e.preventDefault();
        let formErrors = {};
    
        if (!formData.regulationName.trim()) {
            formErrors.regulationName = "Regulation name is required";
        }
    
        if (!formData.slug.trim()) {
            formErrors.slug = "Slug URL is required";
        }
    
        if (formData.status == "status") {
            formErrors.status = "Status is required";
        }
    
        if (!formData.description) {
            formErrors.description = "Description is required";
        }

        if (!formData.shortDescription) {
            formErrors.shortDescription = "Short Description is required";
        }
    
        if (!formData.metaDataDescription) {
            formErrors.metaDataDescription = "Meta Data Description is required";
        }
    
        if (!formData.metaKeyword) {
            formErrors.metaKeyword = "Meta Keyword is required";
        }
    
        if (!formData.metaTitle) {
            formErrors.metaTitle = "Meta Title is required";
        }
    
        if (Object.keys(formErrors).length === 0) {
        console.log(formData);
        toast.success('Regulation Updated Successfully!', {
            position: "top-center"
        });
        setFormReset(true);
        } else {
        setErrors(formErrors);
        }
    };


  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-grow p-4">
        <div className="max-w-8xl">
            {/* Edit Regulation Title Card */}
            <div className="bg-white shadow-md overflow-hidden">
              <div className="p-4 bg-white text-gray">
                  <h2 className="text-lg font-semibold">Edit Regulation</h2>
              </div>
            </div>
            <div >
              <form onSubmit={handleSubmit}>
                <div className="max-w-8xl">
                  <div className="mt-8 shadow-md p-4">
                  <div className="mb-6 max-w-5xl relative">
                    <label htmlFor="regulationName" className="mt-4 mb-2 block text-gray-700">Name :</label>
                    <input
                        type="text"
                        placeholder="Enter Regulation Name"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400"
                        value={formData.regulationName}
                        onChange={handleChange}
                        name="regulationName"
                    />
                    {errors.regulationName && (
                        <p className="text-red-500 text-sm absolute">{errors.regulationName}</p>
                    )}
                  </div>
                  <div className="mb-6 max-w-5xl relative">
                      <label htmlFor="slug" className="mt-4 mb-2 block text-gray-700">Slug :</label>
                      <input
                          type="text"
                          id="slug"
                          name="slug"
                          value={formData.slug}
                          onChange={handleChange}
                          placeholder="Enter Slug URL"
                          className={`w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                              errors.slug ? "border-red-500" : ""
                          }`}
                      />
                      {errors.slug && (
                          <p className="text-red-500 text-sm absolute">{errors.slug}</p>
                      )}
                  </div>
                  <div className="mb-6 max-w-xl relative">
                      <label htmlFor="status" className="mt-4 mb-2 block text-gray-700">Status :</label>
                      <select
                          id="status"
                          name="status"
                          value={formData.status}
                          onChange={handleChange}
                          className={`w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                              errors.status ? "border-red-500" : ""
                          }`}
                      >
                          <option value="status" defaultValue disabled className='text-gray'>Status</option>
                          <option value="draft">Draft</option>
                          <option value="publish">Publish</option>
                          <option value="deactivate">Deactivate</option>
                      </select>
                      {errors.status && (
                          <p className="text-red-500 text-sm absolute">{errors.status}</p>
                      )}
                  </div>
                  <div className="mb-10 max-w-5xl relative">
                      <label htmlFor="description" className="block text-gray-700">Description :</label>
                      <textarea
                          id="description"
                          name="description"
                          value={formData.description}
                          onChange={handleChange}
                          placeholder="Enter Regulation Description"
                          className={`w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                              errors.description ? "border-red-500" : ""
                          }`}
                          rows="4"
                      />
                      {errors.description && (
                          <p className="text-red-500 text-sm absolute">{errors.description}</p>
                      )}
                  </div>
                  <div className="mb-10 max-w-5xl relative">
                      <label htmlFor="shortDescription" className="block text-gray-700">Short Description :</label>
                      <textarea
                          id="shortDescription"
                          name="shortDescription"
                          value={formData.shortDescription}
                          onChange={handleChange}
                          placeholder="Enter Regulation Short Description"
                          className={`w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                              errors.shortDescription ? "border-red-500" : ""
                          }`}
                          rows="4"
                      />
                      {errors.shortDescription && (
                          <p className="text-red-500 text-sm absolute">{errors.shortDescription}</p>
                      )}
                  </div>
                  <div className="mb-4 max-w-5xl relative">
                    <label htmlFor="metaTitle" className="mt-2 mb-2 block text-gray-700">Meta Title :</label>
                    <input
                        type="text"
                        placeholder="Enter Meta Tags"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400"
                        value={formData.metaTitle}
                        onChange={handleChange}
                        name="metaTitle"
                    />
                    {errors.metaTitle && (
                      <p className="text-red-500 text-sm absolute">{errors.metaTitle}</p>
                    )}
                  </div>
                  <div className="mb-6 max-w-5xl relative">
                    <label htmlFor="metaDataDescription" className="mt-6 mb-2 block text-gray-700">Meta Data Description :</label>
                    <input
                        type="text"
                        placeholder="Enter Meta Data Description"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400"
                        value={formData.metaDataDescription}
                        onChange={handleChange}
                        name="metaDataDescription"
                    />
                    {errors.metaDataDescription && (
                      <p className="text-red-500 text-sm absolute">{errors.metaDataDescription}</p>
                    )}
                  </div>
                  <div className="mb-4 max-w-5xl relative">
                    <label htmlFor="metaKeyword" className="mt-4 mb-2 block text-gray-700">Meta Keyword :</label>
                    <input
                        type="text"
                        placeholder="Enter Meta Tags"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400"
                        value={formData.metaKeyword}
                        onChange={handleChange}
                        name="metaKeyword"
                    />
                    {errors.metaKeyword && (
                      <p className="text-red-500 text-sm absolute">{errors.metaKeyword}</p>
                    )}
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={handleCancel}
                      className="bg-gray-500 mt-10 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-4"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="bg-blue-500 mt-10 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Save
                    </button>
                  </div>
                  </div>
                </div>
                <ToastContainer position="top-center" autoClose={3000} />
              </form>
            </div>
        </div>
      </div>
    </div>
  )
}
