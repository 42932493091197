import RegulationsList from "../dummyData/regulationsList";
// const api = process.env.REACT_APP_API_URL;

export const getRegulationsListData = () => {
    try{
        return RegulationsList;
    } catch (e) {
        console.log(e);
    }
}

export const getRegulationById = (regulationId) => {
    try {
        const regulationsList = getRegulationsListData();
        const regulation = regulationsList.find(regulation => regulation._id == regulationId);
        return regulation;
    } catch (error) {
        console.error(error);
        return null;
    }
}