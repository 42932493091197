import React, { useState, useEffect } from 'react';
import config from '../../config/config';
import PageTitleCard from '../../components/pageTitleCard/PageTitleCard';
import Sidebar from '../../components/sidebar/Sidebar';
import Table from '../../components/table/Table';
import { deleteUserById, getUserList } from '../../services/apis/user';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import AddRolePopUp from '../../components/addRolePopUp/AddRolePopUp';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import DeleteConfirmation from '../../components/deleteConfirmation/DeleteConfirmation';

export default function UserList() {
    const navigate = useNavigate();

    const { userListTableHeadings } = config;

    const [userData, setUserData] = useState([]);
    const [totalUserData, setTotalUserData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: '_id', direction: 'asc' });
    const [count, setCount] = useState(1);
    const [showPopup, setShowPopup] = useState(false);
    const [userId, setUserId] = useState('')
    const [limit, setLimit] = useState(() => {
        const storedLimit = parseInt(localStorage.getItem('recordsPerPage'), 10);
        return storedLimit || 50;
    });
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false); 
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [deleteItemName, setDeleteItemName] = useState("");
    const [loader,setLoader] = useState(true)


    useEffect(() => {
        const fetchData = async () => {
            setLoader(true)
            const payload = {
                page: currentPage,
                pageSize: limit,
                sortBy: sortConfig.key,
                sortOrder: sortConfig.direction,
            };
            const userList = await getUserList(payload);
            const data = userList?.data?.results;
            setUserData(data);
            setTotalCount(userList?.data?.total)
            setTotalUserData(data);
            setLoader(false)
        }
        fetchData();
    },[count, sortConfig, currentPage])

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        setCurrentPage(1);
    };

    const handleAddRole = (id) => {
        setUserId(id);
        setShowPopup(true);
    };

    const handleDelete = (id, name) => {
        setDeleteModalOpen(true);
        setDeleteItemId(id);
        setDeleteItemName(name);
    };

    const confirmDelete = async () => {
        const response = await deleteUserById(deleteItemId);

        if (response.success) {
            toast.success(`User deleted successfully!`, {
                position: "top-center",
                autoClose: 1500,
            });
            updateCount();
        } else {
            toast.error(`Failed to delete the User. Please try again later.`, {
                position: "top-center",
                autoClose: 1500,
            });
        }
        setDeleteModalOpen(false);
    };

    const updateCount = () => {
        setCount(prevCount => prevCount + 1);
    };

    const handleEdit = (id) => {
        navigate(`/edit-user/${id}`)
    }

    const updateUserList = (updatedList) => {
        setUserData(updatedList);
    };

    return (
        <div className="flex">
            <Sidebar />
            <div className="flex-grow p-4">
                <div className="max-w-8xl">
                    <PageTitleCard title="User List" addLink="/add-user-role" />
                    <div className='bg-white rounded-xl'>
                        <div className="bg-white overflow-hidden mt-4 rounded-xl ">
                            <div className='bg-white p-2 rounded-xl min-h-[350px]'>
                                <Table loader={loader} headings={userListTableHeadings} data={userData} handleAddRole={(id) => handleAddRole(id)} handleDelete={(id) => handleDelete(id)} handleEdit={(id) => handleEdit(id)} handleSort={handleSort} sortConfig={sortConfig} />
                            </div>
                        </div>
                        {totalCount > limit && (
                            <div className="pagination-controls bg-white rounded-b-xl border-[#abaaaa] mb-2 pb-2 flex flex-row items-center justify-end pt-8 -mt-2">
                                <button className={`bg-[#e1e0e0] ${currentPage === 1 ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl`} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                <span className="mx-4">{Math.min(currentPage * limit, totalCount) ? Math.min(currentPage * limit, totalCount) : '0'} off {totalCount ? totalCount : '0'}</span>
                                <button className={`bg-[#e1e0e0] ${currentPage * limit >= totalCount ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl mr-2`} disabled={currentPage * limit >= totalCount} onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
                            </div>
                        )}
                    </div>
                </div>
                <ToastContainer position="top-center" autoClose={3000} />
                <DeleteConfirmation
                    isOpen={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    onConfirm={confirmDelete}
                    itemName={deleteItemName}
                />
                {showPopup && 
                    <AddRolePopUp userId={userId} onClose={() => setShowPopup(false)} onUpdateUserList={updateUserList}/>
                }
            </div>
        </div>
    )
}
