import axios from 'axios';

const user = JSON.parse(localStorage.getItem('user'));
const token = user?.result?.token

const API_URL = process.env.REACT_APP_API_URL; 

export const createRegulatoryCharge = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/regulatoryCharge`, payload);
        // console.log(response,'response')
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const fetchRegulatoryChargesList = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/regulatoryCharge/list`, payload);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const fetchRegulatoryChargeById = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/regulatoryCharge/${id}`);
        // console.log(response,'by-id');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const updateRegulatoryChargeById = async (id, payload) => {
    try {
        const response = await axios.put(`${API_URL}/regulatoryCharge/${id}`, payload);
        // console.log(response,'update-by-id');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const deleteRegulatoryChargeById = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/regulatoryCharge/${id}`);
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}


export const createIntermediateVariables = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/intermediateVariable`, payload);
        // console.log(response,'response')
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const fetchIntermediateVariables = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/intermediateVariable/list`, payload);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const fetchIntermediateVariablesById = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/intermediateVariable/${id}`);
        // console.log(response,'by-id');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const updateIntermediateVariablesById = async (id, payload) => {
    try {
        const response = await axios.put(`${API_URL}/intermediateVariable/${id}`, payload);
        // console.log(response,'update-by-id');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}


export const deleteIntermediateVariablesById = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/intermediateVariable/${id}`);
        // console.log(response,'update-by-id');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}
