import React,{useEffect,useState} from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import PageTitleCard from "../../components/pageTitleCard/PageTitleCard";
import FormulaTable from "../../components/table/FormulaTable";
import { deleteFormulaApi, fetchFormulaList } from "../../services/apis/formulaList";
import { useNavigate } from "react-router-dom";
import DeleteConfirmation from "../../components/deleteConfirmation/DeleteConfirmation";
import { ToastContainer, toast } from "react-toastify";
import SearchBar from "../../components/searchBar/SearchBar";
import SearchById from "../../components/searchById/SearchById";
import FilterDropdown from "../../components/filterDropdown/FilterDropdown";
import RecordsPerPageDropdown from "../../components/recordsPerPageDropdown/RecordsPerPageDropdown";
import ResetButton from "../../components/resetButton/ResetButton";
import SelectDropdown from "../../components/selectDropdown/SelectDropdown";
import CloneStatepop from "./CloneStatepop";
import { getAllStates } from "../../services/apis/listings";
import { makeFinancialYear, makeYearFormate } from "../recommendations/constantVariable/getFY";

const ListFormula = () => {

    const types = ["formula", "arithmetic", "conditional"]; 

    const [formulaData, setFormulaData] = useState([])
    const [searchQuery, setSearchQuery] = useState('');
    const [searchUniqueQuery, setSearchUniqueQuery] = useState('');
    const [isTablePopupOpen, setIsTablePopupOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false); 
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [deleteItemName, setDeleteItemName] = useState("");
    const [limit, setLimit] = useState(() => {
      const storedLimit = parseInt(localStorage.getItem('recordsPerPage'), 10);
      return storedLimit || 50;
  });
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [typeFilter, setTypeFilter] = useState("formula")
    const [loader, setLoader] = useState(true)
    const [cloneFormOpen,setCloneFormOpen] = useState(false);
    const [allStates,setAllStates] =useState([])
    const [financialYears, setFinancialYears] = useState("2024-2025");
    const [stateCode,setStateCode] = useState("IN-MH");
    const [refresh,setRefresh] = useState(0)
    
    const navigate= useNavigate();

    useEffect(() => {
      const getData = async () => {
        const response = await getAllStates();
        setAllStates(response?.data?.results)
      }
      getData()
      
    }, []);

    const fetchData = async () => {
      setLoader(true)
      const payload = {
          type: typeFilter,
          page: currentPage,
          pageSize: limit,
          name: searchQuery,
          uniqueName: searchUniqueQuery,
          financialYear: financialYears?.split("-")[0],
          stateCode: stateCode
          // sortBy: sortConfig.key,
          // sortOrder: sortConfig.direction,
      };
      const formulaList = await fetchFormulaList(payload);
      const data = formulaList?.data?.data;
      setFormulaData(data);
      setTotalCount(data?.total);
      setLoader(false)
    };

    const toggleTablePopup = () => setIsTablePopupOpen(!isTablePopupOpen);

    useEffect(() => {
      fetchData()
    }, [currentPage, searchQuery, searchUniqueQuery, limit, typeFilter, stateCode, financialYears,refresh]);

    const handleSearch = (event) =>{
      const query = event.target.value;
      setSearchQuery(query);
      setCurrentPage(1);
    }

    const handleUniqueSearch = (event) =>{
      const query = event.target.value;
      setSearchUniqueQuery(query);
      setCurrentPage(1);
    }

    const handleTypeChange = (selectedType) => {
      if (selectedType) {
        setTypeFilter(selectedType);
      }
    };

    const handleStateChange = (selectedType) => {
      if (selectedType) {
        setStateCode(selectedType);
      }
    };
    
    const handleFYChange = (selectedType) => {
      if (selectedType) {
        setFinancialYears(selectedType);
      }
    };

    const handleAddButtonClick=()=>{
      navigate("/recommendations/formula/create")
    }

    const deleteFormula = (id, name) => {
      setDeleteModalOpen(true);
      setDeleteItemId(id);
      setDeleteItemName(name);
    };

    const confirmDelete = async () => {
      const response = await deleteFormulaApi(deleteItemId);

      if (response?.data?.statusCode === 200) {
        toast.success('Billing Unit deleted successfully!', {
          position: "top-center",
          autoClose: 1500,
        });
        fetchData();
        // setUpdateCount((prevCount) => prevCount + 1);
      } else {
        toast.error('Failed to delete the Billing Unit. Please try again later.', {
          position: "top-center",
          autoClose: 1500,
        });
      }
      setDeleteModalOpen(false);
  };

  const handleResetFilters = () => {
    setStateCode("")
    setFinancialYears("2024-2025")
    setTypeFilter('formula');
    setSearchQuery('');
    setSearchUniqueQuery('');
  };

  const handleRecordsPerPageChange = (newLimit) => {
      setLimit(newLimit);
      setCurrentPage(1); 
  };
  const openCloneForm=()=>{
    setCloneFormOpen(true)
  }
  const closeCloneForm=()=>{
    setCloneFormOpen(false)
  }

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-grow p-4">
        <div className="max-w-[1920px] w-full  ">
          <div className="w-full mt-1">
          <PageTitleCard title="Formula List" onAddButtonClick={handleAddButtonClick} addLinkText="Add New Formula" clone={true} cloneFromOpen={openCloneForm}/>
          </div>
          <div className="overflow-hidden mt-2 rounded-xl ">
            <div className="flex">
              <div className="p-3 flex flex-wrap items-center justify-start gap-4 flex-row">
                  <SearchBar searchQuery={searchQuery} handleSearch={handleSearch} title="Formula Name"/>
                  <SearchBar searchQuery={searchUniqueQuery} handleSearch={handleUniqueSearch} title="Unique Name"/>
                  <SelectDropdown
                      label="State"
                      options={allStates?.map((ele) => {
                          return { label: ele.name, value: ele.code};
                      })}
                      selectedValue={stateCode}
                      onChange={(e) => handleStateChange(e.target.value)}
                  />
                  <SelectDropdown
                      label="FY"
                      options={makeFinancialYear().map((ele) => {
                          return { label: ele, value: ele };
                      })}
                      selectedValue={financialYears}
                      onChange={(e) => handleFYChange(e.target.value)}
                  />
                  <SelectDropdown
                      label="Type"
                      options={types?.map((type) => {
                          return { label: type.charAt(0).toUpperCase() + type.slice(1), value: type };
                      })}
                      selectedValue={typeFilter}
                      onChange={(e) => handleTypeChange(e.target.value)}
                  />
                  <RecordsPerPageDropdown onRecordsPerPageChange={handleRecordsPerPageChange} />
              </div>
              <div className="p-3 mt-[5px] ml-[50px] reset-button-container">
                  <ResetButton onClick={handleResetFilters} />
              </div>
            </div>
            <div className="">
              <FormulaTable loader={loader} isTablePopupOpen={isTablePopupOpen} deleteFormula={(id, name) => deleteFormula(id, name)} data={formulaData} toggleTablePopup={toggleTablePopup} />
            </div>
            {totalCount > limit && (
                <div className="pagination-controls bg-white rounded-b-xl border-[#abaaaa] mb-2 pb-2 flex flex-row items-center justify-end pt-8 -mt-2">
                    <button className={`bg-[#e1e0e0] ${currentPage === 1 ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl`} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                    <span className="mx-4">{Math.min(currentPage * limit, totalCount) ? Math.min(currentPage * limit, totalCount) : '0'} off {totalCount ? totalCount : '0'}</span>
                    <button className={`bg-[#e1e0e0] ${currentPage * limit >= totalCount ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl mr-2`} disabled={currentPage * limit >= totalCount} onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
                </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={3000} />
      {cloneFormOpen && <CloneStatepop onClose={closeCloneForm} setRefresh={()=>setRefresh(prev=>prev+1)}/>}
      <DeleteConfirmation
          isOpen={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={confirmDelete}
          itemName={deleteItemName}
      />
    </div>
  );
};

export default ListFormula;
