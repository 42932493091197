import axios from 'axios';

const user = JSON.parse(localStorage.getItem('user'));
const token = user?.result?.token

const API_URL = process.env.REACT_APP_API_URL; 


export const fetchPowerPlantDataList = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/powerPlantData/list`, payload);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const addPowerPlantData = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/powerPlantData/multiple`, payload);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}


export const deleteExistingPowerPlantDataOfParticularDate = async (date,id) => {
    try {
        const response = await axios.post(`${API_URL}/powerPlantData/delete-many/${date}`,{powerPlantUnitId:id});
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}



export const deleteExistingPowerPlantDataOfParticularRecord = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/powerPlantData/${id}`);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}