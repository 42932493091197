import React, { useEffect, useState } from 'react'
import { fetchPowerPlantList } from '../../../services/apis/powerPlant'
import { fetchPowerPlantsListNearToUnit, fetchUnitById, updateUnitById } from '../../../services/apis/listings';
import { toast } from 'react-toastify';
import Loading from '../../../components/loader/Loader';

export default function MapPowerPlant({ id, unitName, onClose, setMapPowerPlantOpen, setUpdateCount }) {

    const [powerPlantList, setPowerPlantList] = useState([]);
    const [selectedPowerPlants, setSelectedPowerPlants] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getUnitById = async () => {
            const response = await fetchUnitById(id);
            const data = response?.data?.result;
            if(data && data?.powerPlantIds?.length > 0){
                const powerPlantIds = data.powerPlantIds.map(powerPlant => powerPlant);
                setSelectedPowerPlants(powerPlantIds);
            }
        }
        getUnitById();
    },[id])


    useEffect(() => {
        const getPowerPlantList = async () => {
            setLoading(true)
            const payload = {
                    radius: 100
            }
            const response = await fetchPowerPlantsListNearToUnit(id, payload);
            const data = response?.data;
            const powerPlants = data?.map(powerPlant => ({
                _id: powerPlant._id,
                name: powerPlant.name,
                distance: powerPlant.distanceFromUnit,
                type:powerPlant.type
            }));
            setPowerPlantList(powerPlants);
            setLoading(false)

        }
        getPowerPlantList();
    },[id])


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await updateUnitById(id, { powerPlantIds: selectedPowerPlants });
            if(response?.data?.statusCode===200){
                setMapPowerPlantOpen(false);
                toast.success(`Power Plant mapping updated successfully`);
            }
            
        } catch (error) {
            console.error('Error mapping Power Plant:', error);
        }
    }

    const handlePowerPlantChange = (event) => {
        const powerPlantId = event.target.value;
        
        if (event.target.checked) {
            setSelectedPowerPlants([...selectedPowerPlants, powerPlantId]);
        } else {
            setSelectedPowerPlants(selectedPowerPlants.filter(powerPlant => powerPlant !== powerPlantId));
        }
    };


    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
            <div className="bg-white p-4 shadow-lg w-full max-w-3xl max-h-[95vh] overflow-y-auto rounded-lg">
                <h2 className="text-lg px-2 font-semibold">Map Power Plant to Unit : {unitName}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="flex-grow overflow-auto p-2">
                        <div className="max-w-8xl">
                            <div className="bg-white overflow-hidden p-5 mt-2 border border-gray-300 rounded-xl">
                            <ul>
                                <div className='flex flex-row justify-between'>
                                    <h3 className='mb-3 font-bold'>Power Plants</h3>
                                    <h3 className='mb-3 font-bold'>Types</h3>
                                </div>
                                {
                                    powerPlantList?.length > 0 && !loading ? (
                                        powerPlantList.map((powerPlant) => (
                                        <li key={powerPlant._id} className=''>
                                            <label className="flex flex-row justify-between">
                                            <div>
                                                <input
                                                type="checkbox"
                                                value={powerPlant._id}
                                                checked={selectedPowerPlants.includes(powerPlant._id)}
                                                onChange={handlePowerPlantChange}
                                                className='mb-2 mr-2'
                                                />
                                                <span>{powerPlant.name}</span> 
                                                <span className='text-[11px] text-gray-900'>
                                                {`(Distance :- ${powerPlant.distance ? powerPlant.distance : "-"} kms)`}
                                                </span>
                                            </div>
                                            <div className='capitalize'>{powerPlant.type}</div>
                                            </label> 
                                        </li>
                                        ))
                                    ) : (
                                        loading ? <Loading width={50} height={50}/> : <p>No power plants found</p>
                                    )
                                }
                            </ul>
                            </div>
                        </div>
                    </div>
                    <div className="sticky bottom-0 bg-white p-4 flex justify-center gap-8">
                        <button
                            type="button"
                            onClick={onClose}
                            className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
