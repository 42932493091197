import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';



export default function ViewActionButton({ handleView, title, handleDelete }) {
    
    return (
        <>  
            {title && 
                <button className="mx-1" onClick={handleView} title={title}>
                    <FontAwesomeIcon icon={faEye} />
                </button>
            }
            <button className="mx-1" onClick={handleDelete}>
                <FontAwesomeIcon icon={faTrash} />
            </button>
        </>
    );
}
