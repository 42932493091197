import React, { useState, useEffect } from 'react';
import { fetchImages, uploadImage } from '../../services/apis/media'; 
import "../../pages/common.css";

export default function AddImagePopUp({ onAddImage, onClose, onAddProfileImage, popupContext }) {

  var objectType = "blog";

  const [images, setImages] = useState([]);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [activeTab, setActiveTab] = useState('gallery');
  const [imageId, setImageId] = useState("");
  const [fileType, setFileType] = useState("image");

  const [selectedImageUrl, setSelectedImageUrl] = useState('');

  // Effect to fetch images from API on component mount
  useEffect(() => {
    async function fetchData() {
      const imagesData = await fetchImages(); 
      setImages(imagesData);
    }
    fetchData();
  }, []);


  // Function to handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    // console.log(file,'file');
    setUploadedImage(file);
  };

  // Function to handle saving the image with details
  const handleSaveImage = async () => {
    try {
      if (!uploadedImage) {
        throw new Error('No file selected');
      }
      
      const response = await uploadImage(uploadedImage, objectType, fileType, );
      
      const imagesData = await fetchImages(); 
      setImages(imagesData);
      setActiveTab('gallery')
      
    } catch (error) {
      console.error('Error saving image:', error);
      // Handle error (e.g., display an error message)
    }
  };

  const handleImageSelect = (imageUrl, imageId) => {
    setSelectedImageUrl(imageUrl);
    setImageId(imageId);
  };

  const handleAddImage = () => {
    if (popupContext === 'profile') {
      // Add image near Add Profile Image button
      onAddProfileImage(selectedImageUrl, imageId);
    } else if (popupContext === 'content') {
      // Add image in rich text area
      onAddImage(selectedImageUrl);
    }
    setSelectedImageUrl(''); 
    onClose(); 
  };

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains('popup-overlay')) {
      onClose(); 
    }
  };

  return (
    <div className='popup'>
      {/* Tab Switch */}
      <div className="tabs">
        <button className={activeTab === 'gallery' ? 'active' : ''} onClick={() => setActiveTab('gallery')}>Gallery</button>
        <button className={activeTab === 'upload' ? 'active' : ''} onClick={() => setActiveTab('upload')}>Upload</button>
      </div>
      <div>
        {/* Gallery Tab */}
        {activeTab === 'gallery' && (
          <div className='mt-2 px-4 py-2 border border-gray-300 rounded-md'>
            <div className="image-grid">
              {images?.map((image, index) => (
                <div key={index} className={`image-item ${selectedImageUrl === image.url ? 'selected' : ''}`}>
                  <img src={image.url} alt={image.description} onClick={() => handleImageSelect(image.url, image._id)} />
                </div>
              ))}
            </div>
            {/* Button for adding the selected image */}
              <button className="add-image-button" onClick={handleAddImage} >
                Add Image
              </button>
              {/* Close button in the bottom right corner */}
              <button className="close-button" onClick={onClose}>Close</button>
          </div>
        )}
        {/* Upload Tab */}
        {activeTab === 'upload' && (
          <div className='mt-2 px-4 py-4 border border-gray-300 rounded-md'>
            <input type="file" onChange={handleImageUpload} />
            {/* Additional input fields for description, caption, and URL */}
            <button className="upload-button" onClick={handleSaveImage}>Save</button>
          </div>
        )}
      </div>
    </div>
  )
}

