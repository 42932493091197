import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

export default function AddRoleButton({handleAddRole}) {
  return (
    <>
        <button className="mx-1" onClick={handleAddRole}>
            <FontAwesomeIcon icon={faUserPlus} />
        </button>
    </>
  )
}
