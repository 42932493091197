import React from 'react';

class About extends React.Component {
    render() {
        return (
            <div className='flex flex-col items-center justify-center  p-10'>
                <h1 className='text-4xl font-bold mb-4'>About Us</h1>
                <p className='text-lg text-gray-700 max-w-prose'>We are a team of passionate individuals dedicated to providing the best service in the market.</p>
            </div>
        );
    }
}

export default About;