import React from 'react'

export default function InputBox({type, width, placeholder, value, onChange, disabled }) {
  return (
    <div className={`relative ${width}`}>
      <input
        type={type || "text"}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={`w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 
          ${disabled ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : ''}`}
        disabled={disabled}
      />
    </div>
  )
}
