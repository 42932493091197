import React from 'react';
import InputBox from '../inputBox/InputBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import SearchInputBox from '../inputBox/SearchInputBox';

export default function SearchBar({ searchQuery, handleSearch, width, title }) {
  return (
    <div className={`relative ${width}`}>
        {/* <div className="absolute inset-y-0 left-0 flex items-center pl-3">
            <FontAwesomeIcon icon={faSearch} className="text-gray-400" />
        </div> */}
        <SearchInputBox
            width="w-60"
            placeholder={`Search by ${title}...`}
            value={searchQuery}
            onChange={handleSearch}
            className="pl-5"
        />
        <FontAwesomeIcon icon={faSearch} className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
    </div>
  );
}
