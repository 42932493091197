import React from 'react'

export default function SearchInputBox({type, width, placeholder, value, onChange, disabled, className }) {
    return (
        <div className={`relative ${width}`}>
        <input
                type={type || "text"}
                className={`border border-gray-200 focus:border-gray-500 placeholder-gray-400 focus:placeholder-gray-600 rounded-md py-2 px-3 ${width} ${className}`}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                disabled={disabled}
            />
        </div>
    )
}
