import React, { useEffect, useState } from 'react';
import InputBox from "../../components/inputBox/InputBox";
import config from "../../config/config";
import { ToastContainer, toast } from 'react-toastify';
import { createContent, editContentById, fetchCategories, getContentById } from '../../services/apis/content';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';


export default function EditFAQs() {
    const { id } = useParams();
    const isEditMode = !!id;

    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [formReset, setFormReset] = useState(false);
    const [categoryNames, setCategoryNames] = useState([]);
    const [contentData, setContentData] = useState({});
    const [formData, setFormData] = useState({
        type: "faqs",
        contentCategory: "",
        title: "",
        slug: "",
        description: "",
        status: "",
    });

    useEffect(() => {
        const fetchContentData = async () => {
            try {
                const response = await getContentById(id);
                const content = response?.result;
                
                if (content) {
                    setFormData({
                        type: content.type || "",
                        contentCategory: content.contentCategory?._id || "",
                        title: content.title || "",
                        slug: content.slug || "",
                        description: content.description || "",
                        status: content.status || "",
                    })
                  
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchContentData();
      }, [id]);


    useEffect(() => {
        const fetchCategoriesList = async () => {
            const response = await fetchCategories();
            const categoriesList = response?.data?.results;
            
            if (categoriesList) {
                const categories = categoriesList.map(category => ({
                    _id: category._id,
                    name: category.name
                }));
                setCategoryNames(categories);
            }
        }

        fetchCategoriesList();
    },[])

    const renderFormFields = () => {
        const fields = config.cmsSettings.faqs.fields;
    
        return Object.keys(fields).map((fieldName, index) => {
            
            const field = fields[fieldName];
            const { label, type, required, options } = field;

                switch (type) {
                    case 'text':
                        return (
                            <div key={index} className="mb-6 max-w-5xl relative">
                                <label htmlFor={fieldName} className="mb-2 block text-gray-700">
                                    {label}{required && <span className="text-red-500">*</span>} :
                                </label>
                                <InputBox
                                    width="max-w-5xl"
                                    placeholder={`Enter ${label}`}
                                    value={formData[fieldName]}
                                    onChange={(e) => handleChange(fieldName, e.target.value)}
                                />
                                {errors[fieldName] && (
                                    <p className="text-red-500 text-sm absolute">
                                        {errors[fieldName]}
                                    </p>
                                )}
                            </div>
                        );
                    case 'textarea':
                        return (
                            <div key={index} className="mb-6 max-w-5xl relative">
                                <label htmlFor={fieldName} className="mb-2 block text-gray-700">
                                    {label}{required && <span className="text-red-500">*</span>} :
                                </label>
                                <textarea
                                    id={fieldName}
                                    value={formData[fieldName]}
                                    onChange={(e) => handleChange(fieldName, e.target.value)}
                                    placeholder={`Enter ${label}`}
                                    className={`w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                                        errors[fieldName] ? "border-red-500" : ""
                                    }`}
                                    rows={fieldName === 'shortDescription' ? 6 : undefined }
                                />
                                {errors[fieldName] && (
                                    <p className="text-red-500 text-sm absolute">
                                        {errors[fieldName]}
                                    </p>
                                )}
                            </div>
                        );
                    case 'select':
                        if (label == 'Category'){
                            return (
                                <div key={index} className="mb-6 max-w-sm relative">
                                    <label htmlFor={fieldName} className="mb-2 block text-gray-700">
                                        {label}{required && <span className="text-red-500">*</span>} :
                                    </label>
                                    <select
                                        id={fieldName}
                                        value={formData.contentCategory}
                                        onChange={(e) => handleChange(fieldName, e.target.value)}
                                        className={`w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                                            errors[fieldName] ? "border-red-500" : ""
                                        }`}
                                        disabled
                                    >
                                        <option value="" >Select {label}</option>
                                        {categoryNames?.map((category, index) => (
                                            <option key={index} value={category._id}>
                                                {category.name}
                                            </option>
                                        ))}
                                    </select>
                                    {errors[fieldName] && (
                                        <p className="text-red-500 text-sm absolute">
                                            {errors[fieldName]}
                                        </p>
                                    )}
                                </div>
                            );
                        } else {
                            return (
                                <div key={index} className="mb-6 max-w-sm relative">
                                    <label htmlFor={fieldName} className="mb-2 block text-gray-700">
                                        {label}{required && <span className="text-red-500">*</span>} :
                                    </label>
                                    <select
                                        id={fieldName}
                                        value={formData[fieldName]}
                                        onChange={(e) => handleChange(fieldName, e.target.value)}
                                        className={`w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                                            errors[fieldName] ? "border-red-500" : ""
                                        }`}
                                    >
                                        <option value="" >Select {label}</option>
                                        {options?.map((option, index) => (
                                            <option key={index} value={option}>{option.charAt(0).toUpperCase() + option.slice(1)}</option>
                                        ))}
                                    </select>
                                    {errors[fieldName] && (
                                        <p className="text-red-500 text-sm absolute">
                                            {errors[fieldName]}
                                        </p>
                                    )}
                                </div>
                            );
                        }
                    default:
                        return null;
                }
            }
        );
    };

    const handleChange = (name, value) => {
        let slugValue =
        name === "title" ? value.toLowerCase().replace(/\s+/g, "-").replace(/[^a-zA-Z0-9-]/g, "") : formData.slug;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
          slug: slugValue,
        }));
        if (name === "title" && errors.slug && value.trim() !== "") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            slug: "",
          }));
        }
        let error = "";
        if(name === 'title' ||  name === 'slug' || name === 'shortDescription' || name === 'description' || name === 'status'){
          if (!value.trim()) {
            error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
          }
        }
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: error,
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        const fields = config.cmsSettings.faqs.fields;

        Object.keys(fields).forEach(fieldName => {
            const field = fields[fieldName];
            const { required } = field;

            if (required && !formData[fieldName]) {
                newErrors[fieldName] = `${field.label} is required`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            try {
                
                const response = await editContentById(id,formData);

                if (response.success == true){
                    setFormData({
                        type: "faqs",
                        contentCategory: "",
                        title: "",
                        slug: "",
                        description: "",
                        status: "",
                    })

                    setErrors({});
    
                    toast.success("FAQ Updated Successfully!", {
                        position: "top-center",
                    });
                    setFormReset(true);
                    setTimeout(() => {
                        navigate("/content-list");
                    }, 3000);
                } else {
                    
                    toast.error(`${response?.error?.message}`, {
                        position: "top-center",
                    });
                } 
                // toast.success("Form submitted successfully");
            } catch (error) {
                console.error("Error:", error);
                
                toast.error("Failed to add blog. Please try again later.", {
                position: "top-center",
                });
            }
        } else {
            toast.error("Please Fill All Data!");
        }
    };
    
    const handleCancel = () => {
        setFormData({
            type: "faqs",
            contentCategory: "",
            title: "",
            slug: "",
            description: "",
            status: "",
        });
        setErrors({});
        navigate('/content-list')
    };

    return (
        <div className="flex">
            <div className="flex-grow">
                <div className="max-w-10xl">
                    <div>
                    <form onSubmit={handleSubmit}>
                        <div className="max-w-8xl">
                        <div className="shadow-md p-4">
                        {renderFormFields()}
                            <div>
                            <button
                                type="button"
                                onClick={handleCancel}
                                className=" bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] mt-10 hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className=" bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] mt-10 hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
                            >
                                Save
                            </button>
                            </div>
                        </div>
                        </div>
                        <ToastContainer position="top-center" autoClose={3000} />
                    </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
