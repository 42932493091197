const NewsList = [
    {
        _id: "1",
        title: "Expect Pricing Parity in Electricity",
        shortDescription: "",
        slug: "",
        sourceName: "",
        sourceWebsite: "",
        webSiteLink: "",
        metaTitle: "",
        metaDataDescription: "",
        metaKeyword: "",
        publishDate: "2022-01-01",
        status: ""
    },
    {
        _id: "2",
        title: "Renewable Rhythms",
        shortDescription: "",
        slug: "",
        sourceName: "",
        sourceWebsite: "",
        webSiteLink: "",
        metaTitle: "",
        metaDataDescription: "",
        metaKeyword: "",
        publishDate: "2022-02-01",
        status: ""
    }
]

export default NewsList;