import React, { useEffect, useState } from 'react'
import InputBox from '../../../components/inputBox/InputBox';
import config from '../../../config/config';
import { ToastContainer, toast } from 'react-toastify';
import GooglePlacesMap from '../../../components/googlePlaceMap/GooglePlacesMap';
import { fetchPowerPlantById, updatePowerPlantById } from '../../../services/apis/powerPlant';
import { fetchStateList } from '../../../services/apis/listings';
import { fetchPowerPlantsTypesList } from '../../../services/apis/powerPlantType';

export default function EditPowerPlantDetails({onClose, editId, setUpdateCount, setEditFormOpen}) {

    const [errors, setErrors] = useState({});
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [states, setStates] = useState([]);
    const [powerPlantTypes, setPowerPlantTypes] = useState([]);

    const [formData, setFormData] = useState({
        name: "",
        address: "",
        city: "",
        district: "",
        stateId: "",
        location: {
            type: "Point",
            coordinates: [latitude, longitude],
        }
    });
    const [isMapOpen, setIsMapOpen] = useState(false);
    const [clickedCoords, setClickedCoords] = useState(null);

    useEffect(() => {
        const getPowerPlantById = async () => {
            const response = await fetchPowerPlantById(editId);
            const data = response?.data?.data;
            setFormData({
                name: data?.name || "",
                address: data?.address || "",
                city: data?.city || "",
                district: data?.district || "",
                type: data?.type || "",
                stateId: data?.stateId || "",
                location: {
                    coordinates: [data?.location?.coordinates?.[0], data?.location?.coordinates?.[1]],
                }
            });
            setLatitude(data?.location?.coordinates?.[0])
            setLongitude(data?.location?.coordinates?.[1])
        }
        getPowerPlantById();
    },[])

    useEffect(() => {
        const getStateList = async () => {
            const response = await fetchStateList();
            const stateList = response?.data?.results;

            if (stateList) {
                // Sort the state list alphabetically by state name
                const sortedStateList = stateList.sort((a, b) => a.name.localeCompare(b.name));
                setStates(sortedStateList);
            }
        };
        getStateList();
    },[])

    useEffect(() => {
        const getPowerPlantTypesList = async () => {
            const payload = {
                status : 'active'
            }
            const response = await fetchPowerPlantsTypesList(payload);
            const powerPlantTypesList = response?.data?.data?.results;
            if (powerPlantTypesList){
                setPowerPlantTypes(powerPlantTypesList);
            }
        }
        getPowerPlantTypesList();
    },[])

    const renderFormFields = () => {
        const fields = config.powerPlantSettings.fields;

        return Object.keys(fields)?.map((fieldName, index) => {
            const field = fields[fieldName];
            const { label, type, required, options } = field;

            switch (type) {
                case 'text':
                    if (label === "Latitude" || label === "Longitude"){
                        return (
                            <div key={index} className="mb-5">
                                <div className="flex items-center">
                                    <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                                        {label}{required && <span className="text-red-500">*</span>} :
                                    </label>
                                    <InputBox
                                        width="w-3/4"
                                        placeholder={`Enter ${label}`}
                                        value={label === "Latitude" ? latitude : longitude}
                                        onChange={(e) => handleChange(fieldName, e.target.value)}
                                        className="ml-4"
                                    />
                                </div>
                                {errors[fieldName] && (
                                    <p className="absolute text-red-500 text-sm ml-44">{errors[fieldName]}</p>
                                )}
                            </div>
                        ); 
                    } else {
                        return (
                            <div key={index} className="mb-5">
                                <div className="flex items-center">
                                    <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                                        {label}{required && <span className="text-red-500">*</span>} :
                                    </label>
                                    <InputBox
                                        width="w-3/4"
                                        placeholder={`Enter ${label}`}
                                        value={formData[fieldName] || ''}
                                        onChange={(e) => handleChange(fieldName, e.target.value)}
                                        className="ml-4"
                                    />
                                </div>
                                {errors[fieldName] && (
                                    <p className="absolute text-red-500 text-sm ml-44">{errors[fieldName]}</p>
                                )}
                            </div>
                        );
                    }
                    
                case 'select':
                    if (label == 'State'){

                        return (
                            <div key={index} className="mb-5">
                                <div className="flex items-center">
                                    <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                                        {label}{required && <span className="text-red-500">*</span>} :
                                    </label>
                                    <select
                                        id={fieldName}
                                        value={formData[fieldName] || ''}
                                        onChange={(e) => handleChange(fieldName, e.target.value)}
                                        className={`w-3/4 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                                            errors[fieldName] ? 'border-red-500' : ''
                                        }`}
                                    >
                                        <option value="">Select {label}</option>
                                        {states?.map((state, index) => (
                                            <option key={index} value={state._id}>{state.name}</option>
                                        ))}
                                    </select>
                                </div>
                                {errors[fieldName] && (
                                    <p className="absolute text-red-500 text-sm ml-44">{errors[fieldName]}</p>
                                )}
                            </div>
                        );
                    } else if (label == 'Powerplant Type'){
                        return (
                            <div key={index} className="mb-5">
                                <div className="flex items-center">
                                    <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                                        {label}{required && <span className="text-red-500">*</span>} :
                                    </label>
                                    <select
                                        id={fieldName}
                                        value={formData[fieldName] || ''}
                                        onChange={(e) => handleChange(fieldName, e.target.value)}
                                        className={`w-3/4 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                                            errors[fieldName] ? 'border-red-500' : ''
                                        }`}
                                    >
                                        <option value="">Select {label}</option>
                                        {powerPlantTypes?.map((powerPlantType, index) => (
                                            <option key={index} value={powerPlantType?.name.toLowerCase()}>{powerPlantType.name}</option>
                                        ))}
                                    </select>
                                </div>
                                {errors[fieldName] && (
                                    <p className="absolute text-red-500 text-sm ml-44">{errors[fieldName]}</p>
                                )}
                            </div>
                        );
                    } else {
                        return (
                            <div key={index} className="mb-5">
                                <div className="flex items-center">
                                    <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                                        {label}{required && <span className="text-red-500">*</span>} :
                                    </label>
                                    <select
                                        id={fieldName}
                                        value={formData[fieldName] || ''}
                                        onChange={(e) => handleChange(fieldName, e.target.value)}
                                        className={`w-3/4 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                                            errors[fieldName] ? 'border-red-500' : ''
                                        }`}
                                    >
                                        <option value="">Select {label}</option>
                                        {options?.map((option, index) => (
                                            <option key={index} value={option}>{option.charAt(0).toUpperCase() + option.slice(1)}</option>
                                        ))}
                                    </select>
                                </div>
                                {errors[fieldName] && (
                                    <p className="absolute text-red-500 text-sm ml-44">{errors[fieldName]}</p>
                                )}
                            </div>
                        );
                    } 
                case 'button':
                    return (
                        <div key={index} className="mb-5">
                            <div className="flex items-center">
                                <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                                    {label}{required && <span className="text-red-500">*</span>} :
                                </label>
                                <button
                                    id={fieldName}
                                    onClick={handleMapOpen}
                                    className="px-3 py-2 text-blue-500"
                                >
                                    View on Map
                                </button>
                            </div>
                            {errors[fieldName] && (
                                <p className="absolute text-red-500 text-sm ml-44">{errors[fieldName]}</p>
                            )}
                        </div>
                    );
                default:
                    return null;
            }
        });
    };

    const handleChange = (fieldName, value) => {
        if (fieldName === 'latitude' || fieldName === 'longitude') {
            setFormData({
                ...formData,
                location: {
                    ...formData.location,
                    [fieldName]: value
                }
            });
        } else {
            setFormData({ ...formData, [fieldName]: value });
        }
        setErrors(prevErrors => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[fieldName];
            return updatedErrors;
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const validationErrors = {};
        if (!formData.name) {
            validationErrors.name = "Name is required";
        }
        if (!formData.address) {
            validationErrors.address = "Address is required";
        }
        if (!formData.city) {
            validationErrors.city = "City is required";
        }
        if (!formData.district) {
            validationErrors.district = "District is required";
        }
        if (!formData.stateId) {
            validationErrors.stateId = "State is required";
        }
        if (!formData.type) {
            validationErrors.type = "Powerplant Type is required";
        }
        if (!latitude) {
            validationErrors.latitude = "Latitude is required";
        }
        if (!longitude) {
            validationErrors.longitude = "Longitude is required";
        }
        
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const payload = {
            ...formData,
            location : {
                type: "Point",
                coordinates : [latitude, longitude]
            }
        }

        try {
            const response = await updatePowerPlantById(editId, payload)

            // console.log(response,'response')
            if (response.data.data.length == 0){
                toast.error(`${response?.data?.message}`, {
                    position: "top-center",
                });
                
            } else {
                toast.success(`${response?.data?.message}`, {
                    position: "top-center",
                    autoClose: 3000
                });
                setUpdateCount(prevCount => prevCount + 1);
                setEditFormOpen(false);
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("Failed to edit Power Plant details. Please try again.", {
                position: "top-center",
            })
        }
    };

    const handleMapOpen = (e) => {
        e.preventDefault();
        setIsMapOpen(true);
    };

    const handleMapClose = (e) => {
        setIsMapOpen(false);
    };

    const handleCoordinateSelect = ({ lat, lng, city, district, address, state, stateCode }) => {
        setClickedCoords({ lat, lng });
        setLatitude(lat.toFixed(4));
        setLongitude(lng.toFixed(4));
    
        const matchingState = states.find(st => stateMatches(st.name, state));
    
        setFormData(prevFormData => ({
            ...prevFormData,
            address,
            city,
            district,
            stateId: matchingState ? matchingState._id : '',
            location: {
                latitude: lat.toFixed(4),
                longitude: lng.toFixed(4),
            }
        }));
    };

    const stateMatches = (stateInList, stateFromMap) => {
        const normalizedStateInList = stateInList.toLowerCase().trim();
        const normalizedStateFromMap = stateFromMap.toLowerCase().trim();

        return normalizedStateInList.includes(normalizedStateFromMap);
    };


    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
            <div className="bg-white p-4 shadow-lg w-full max-w-3xl max-h-[98vh] overflow-y-auto rounded-lg">
                <h2 className="text-lg font-semibold">Edit Power Plant Details</h2>
                <form>
                    <div className="flex-grow overflow-auto mt-3">
                        <div className="max-w-8xl">
                            <div className="bg-white shadow-md overflow-hidden p-4">
                                {renderFormFields()}
                            </div>
                        </div>
                    </div>
                </form>
                <div className="sticky bottom-0 bg-white px-4 py-2 flex justify-center gap-8">
                    <button
                        type="button"
                        onClick={onClose}
                        className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4"
                    >
                        Save
                    </button>
                </div>
                {isMapOpen && (
                    <GooglePlacesMap
                    // address={address}
                    onClose={handleMapClose}
                    onMapClick={handleCoordinateSelect}
                    />
                )}
                {/* <ToastContainer position="top-center" autoClose={3000} /> */}
            </div>
        </div>
    );
}
