import React, { useState, useRef, useEffect } from "react";
import {
  addArithmaticFormula,
  getGlobalVariableList,
  gloabalAutoComplete,
} from "../../services/apis/formulaList";
import { useNavigate } from "react-router-dom";
// import "./NestedFormulaAutoComplete.css";
const CreateArithmaticFormula = ({stateCode, financialYears ,setErrorState}) => {
  console.log(stateCode,financialYears)
  const [Suggestions] = useState([
    { "+": "+" },
    { "-": "-" },
    { "*": "*" },
    { "/": "/" },
    { "min()": "min()" },
    { "max()": "max()" },
    { "average()": "average()" },
    { "sum()": "sum()" },
  ]);

  const [updatedSuggestions, setupdatedSuggestions] = useState([]);

  const [usedGlobalVariables, setUsedGlobalVariables] = useState([]);
  const [errors, setErrors] = useState({
    stateCode: { error:"", show:false },
    formulaName: { error: "", show: true },
    inputValue: { error: "", show: true },
  });
  const [showErrors, setShowErrors] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [lastChar, setLastChar] = useState("");
  const [cursorPosition, setCursorPosition] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [formulaName, setFormulaName] = useState("");

  const [variableList, setVariableList] = useState([]);

  const navigate = useNavigate();

  const handleFormulaName = (e) => {
    setFormulaName(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key.length === 1) {
      setLastChar(e.key);
    }
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      const length = inputRef.current.value.length;
      inputRef.current.focus();
      inputRef.current.setSelectionRange(length, length);
    }
  }, []);
  const handleChange = (e) => {
    const userInput = e.target.value;
    console.log({ userInput });
    setCursorPosition(e.target.selectionStart);
    console.log({ charPos: userInput.charAt(e.target.selectionStart - 2) });
    setInputValue(userInput);
    console.log({ cursorPosition: e.target.selectionStart });
    console.log({ input: e.target.value });

    console.log({ lastChar });
    console.log("adding keyword");
    const keywordString = keyword;
    console.log({ keywordString });
    const getkeyword = getLastWord(e.target.value, e.target.selectionStart);
    setKeyword(getkeyword);
  };

  const updateSuggestions = (keyword) => {
    console.log({ Suggestedkeyword: keyword });
    console.log({ updatedSuggestions });
    const filtered = updatedSuggestions
      .filter((suggestion) => {
        const key = Object.keys(suggestion)[0].toLowerCase();
        return key.includes(keyword.toLowerCase());
      })
      .map((suggestion) => Object.keys(suggestion)[0]);
    console.log({ filtered });
    setFilteredSuggestions(filtered);
    setShowSuggestions(true);
  };

  const handleClick = (suggestion) => {
    console.log({ inputValue });
    console.log({ cursorPosition });
    const newInputSuggestion = replaceLastWordBeforeCursor(
      inputValue,
      cursorPosition,
      suggestion,
      keyword
    );

    const foundObject = Suggestions.find((obj) =>
      obj.hasOwnProperty(suggestion)
    );

    if (foundObject === undefined) {
      console.log({ suggestion });
      const objInUpdatedSugegstions = updatedSuggestions.find((obj) =>
        obj.hasOwnProperty(suggestion)
      );
      console.log({ objInUpdatedSugegstions });
      setUsedGlobalVariables((prev) => [...prev, objInUpdatedSugegstions]);
    }
    console.log({ newInputSuggestion });
    setInputValue(newInputSuggestion);

    setFilteredSuggestions([]);
    setShowSuggestions(false);
  };

  function replaceSubstring(str, arr) {
    if (typeof str !== "string" || !Array.isArray(arr)) {
      return "";
    }
    arr.forEach((obj) => {
      Object.keys(obj).forEach((key) => {
        if (str.includes(key)) {
          str = str.replace(key, obj[key]);
        }
      });
    });
    return str;
  }

  useEffect(() => {
    console.log({ keyword });
    if (keyword.length > 0) {
      updateSuggestions(keyword);
    }
  }, [keyword]);

  useEffect(() => {
    console.log({ cursorPosition });
  }, [cursorPosition]);

  useEffect(() => {
    console.log({ updatedSuggestions });
    updateSuggestions(keyword);
  }, [updatedSuggestions]);

  useEffect(() => {
    console.log({ usedGlobalVariables });
  }, [usedGlobalVariables]);

  function replaceLastWordBeforeCursor(
    text,
    cursorPosition,
    replacement,
    word
  ) {
    console.log({ text, cursorPosition, replacement, word });
    console.log(text.slice(0, cursorPosition)); // Extract the part before the replacement
    let start = text.slice(0, cursorPosition - word.length);
    // Extract the part after the replacement
    let end = text.slice(cursorPosition);
    // Concatenate the start, replaceString, and end
    const finalword = start + " " + replacement + " " + end;
    console.log({ finalword });
    return finalword;
  }

  //   getting global variables
  const getGlobalVariables = async () => {
    try {
      const response = await getGlobalVariableList();
      console.log({ response });
      const data = response?.data?.data?.results;
      console.log({ data });
      setVariableList(data);
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const result = getGlobalVariables();
  }, []);

  //   getting global variables
  const getAutocompleteGlobalVariableList = async (keyword) => {
    try {
      console.log("autocomplete api call");
      const response = await gloabalAutoComplete(keyword);
      console.log({ response });
      const data = response?.data?.data;
      console.log({ data });
      if (data && data.length > 0) {
        const resultArray = data.map((item) => {
          return {
            [item.name]: item.uniqueName,
          };
        });
        console.log({ resultArray });
        console.log({ resultArray: [...Suggestions, ...resultArray] });
        setupdatedSuggestions([...Suggestions, ...resultArray]);
      } else {
        setupdatedSuggestions([...Suggestions]);
      }

      //   setVariableList(data);
      //   return data;
    } catch (e) {
      console.log(e);
    }
  };

  function getLastWord(text, cursorPosition) {
    console.log("getting last word");
    const substring = text.slice(0, cursorPosition);
    const reversedSubstring = substring.split("").reverse().join("");
    const match = reversedSubstring.match(/\b[\w$]+\b/);
    if (match) {
      const lastWordReversed = match[0];
      const lastWord = lastWordReversed.split("").reverse().join("");
      console.log({ lastWord });
      return lastWord;
    } else {
      return "";
    }
  }

  useEffect(() => {
    console.log({ keyword });
    if (keyword.length >= 3) {
      const result = getAutocompleteGlobalVariableList(keyword);
    }
  }, [keyword]);

  const addFormula = async () => {
    console.log(stateCode,"state selected")
    if(!stateCode){
      setErrorState()
    }

    if(formulaName ===""){
        setShowErrors(true);
        errors.formulaName.show = true;
        errors.formulaName.error = "Formula Name Required";
        setErrors({...errors});
        
    }

    if(inputValue === ""){
        setShowErrors(true);
        errors.inputValue.show = true;
        errors.inputValue.error = "Formula is required";
        setErrors({...errors});
        
    }

    if(!errors.formulaName.show && !errors.inputValue.show){
      const updatedString= replaceSubstring(inputValue, usedGlobalVariables)
      const formattedsting = formatTextArea(updatedString);
      const apiResponse = await addArithmaticFormula({
          type: "arithmetic",
          stateCode:stateCode,
          financialYear:financialYears?.split("-")[0],
          content: [
            {
              formula:formattedsting ,
            },
          ],
          globalRecVariableId: formulaName,
        });
        console.log({ apiResponse });
        if (apiResponse.data.statusCode === 201) {
          console.log("formula created successfully");
          navigate("/recommendations/formula/list");
        } else {
          console.log("Something Went Wrong...");
        }
    }
    
  };


  function formatTextArea(inputText) {
    // Remove line breaks and excess spaces
    let formattedText = inputText
        .replace(/\s+/g, ' ') 
    
    
    return formattedText;
}

 const handleFormulaNameValidations =()=>{
    if(formulaName === ""){
        errors.formulaName.show = true;
        errors.formulaName.error = "Formula Name  Required";
        setErrors({...errors});
        
    }else{
        errors.formulaName.show = false;
        errors.formulaName.error = "";
        setErrors({...errors});
    }
 }




useEffect(() => {
    handleFormulaNameValidations();
}, [formulaName]);


const handleInputValueValidations =()=>{
    if(inputValue === ""){
        errors.inputValue.show = true;
        errors.inputValue.error = "Formula  Required";
        setErrors({...errors});
        
    }else{
        errors.inputValue.show = false;
        errors.inputValue.error = "";
        setErrors({...errors});
    }
 }




useEffect(() => {
    handleInputValueValidations();
}, [inputValue]);




  return (
    <div className="flex flex-col py-4 w-full">
      {" "}
      <div className="my-2  w-[400px] ">
        <label className="text-sm font-semibold">Formula Name</label>
        <select
          className="min-h-[40px] px-2 my-2 border border-gray-600 rounded-lg w-full"
          value={formulaName}
          onChange={handleFormulaName}
        >
          <option value="">Select Formula Name</option>
          {variableList.map((variable) => (
            <option value={variable._id}>{variable.name}</option>
          ))}
        </select>
        <p className={`${errors.formulaName.show && showErrors === true ?"":"hidden"}  py-1 text-[red]`}>{errors.formulaName.error}</p>
      </div>
      <div className="my-2  w-[400px] ">
        <label className="text-sm font-semibold">Formula</label>
        <textarea
          className="min-h-[40px] px-2 mt-2  text-[16px] font-medium py-2  border border-gray-600 rounded-lg w-full"
          type="text"
          value={inputValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder="Enter Formula"
        />
        {showSuggestions && inputValue && (
          <ul className="rounded-lg border border-gray-400">
            {filteredSuggestions.length ? (
              filteredSuggestions.map((suggestion, index) => (
                <li
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-100" : "bg-gray-200"
                  }   px-2  py-1   ${index === 0 ? "rounded-t-lg" : ""}  ${
                    index === filteredSuggestions.length - 1
                      ? "rounded-b-lg"
                      : ""
                  }  cursor-pointer `}
                  onClick={() => handleClick(suggestion)}
                >
                  {suggestion}
                </li>
              ))
            ) : (
              <li>No suggestions available</li>
            )}
          </ul>
        )}
        <p className={`${errors.inputValue.show === true &&  showErrors ?"":"hidden"}  py-1 text-[red]`}>{errors.inputValue.error}</p>
      </div>
      <div className="mt-5">
        <button
          onClick={addFormula}
          className="bg-green-800 text-white  p-2 rounded-lg"
        >
          Save Formula
        </button>
      </div>
    </div>
  );
};

export default CreateArithmaticFormula;
