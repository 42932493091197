import React, { useEffect, useState } from 'react';
import config from '../../../../config/config';
import InputBox from '../../../../components/inputBox/InputBox';
import { ToastContainer, toast } from 'react-toastify';
import { fetchDiscomList, fetchStateList } from '../../../../services/apis/listings';
import { createRegulatoryCharge, fetchRegulatoryChargeById, fetchRegulatoryChargesList, updateRegulatoryChargeById } from '../../../../services/apis/regulatoryCharges';
import { fetchGlobalVariablesList } from '../../../../services/apis/globalRecommendationVariable';
import { makeYearFormate } from '../getFY';

export default function EditDiscomCharges({handleFY, onClose, editId, setUpdateCount}) {
    const [formData, setFormData] = useState({
        discomCharges: "",
        thirdPartyCharges: "",
        captiveCharges: "",
        objectType: "discom",
        objectId: "", 
        stateId: "",
        globalRecVariableId: "",
        rtsCharges:""
    });
    const [errors, setErrors] = useState({});
    const [states, setStates] = useState([]);
    const [discoms, setDiscoms] = useState([]);
    const [globalVariables, setGlobalVariables] = useState([]);

    useEffect(() => {
        const getRegulatoryChargeById = async () => {
            const response = await fetchRegulatoryChargeById(editId);
            const data = response?.data?.data;
            setFormData({
                discomCharges: data?.discomCharges || "",
                thirdPartyCharges: data?.thirdPartyCharges || "",
                captiveCharges: data?.captiveCharges || "",
                objectType: "discom",
                objectId: data?.objectId || "", 
                stateId: data?.stateId || "",
                rtsCharges: data?.rtsCharges || "",               
                globalRecVariableId: data?.globalRecVariableId?._id || ""
            })
            handleFY(makeYearFormate(data?.financialYear))
            // console.log(data,'edit-data');
        };
        getRegulatoryChargeById();
    },[])

    useEffect(() => {
        const getGlobalVariablesList = async () => {
            const payload = {
                type: 'regulatoryCharge'
            }
            const response = await fetchGlobalVariablesList(payload);
            const data = response?.data?.data?.results;
            setGlobalVariables(data);
            // console.log(data,'data')
        };
        getGlobalVariablesList();
    },[])

    useEffect(() => {
        const getStateList = async () => {
            const response = await fetchStateList();
            const stateList = response?.data?.results;
            if (stateList) {
                // Sort the state list alphabetically by state name
                const sortedStateList = stateList.sort((a, b) => a.name.localeCompare(b.name));
                setStates(sortedStateList);
            }
        };
        getStateList();
    },[])

    useEffect(() => {
        const getDiscomList = async () => {
            const response = await fetchDiscomList();
            const discomList = response?.data?.results;
            if (discomList) {
                // Sort the state list alphabetically by state name
                const sortedDiscomList = discomList.sort((a, b) => a.name.localeCompare(b.name));
                setDiscoms(sortedDiscomList);
            }
        };
        getDiscomList();
    },[])

    const renderFormFields = () => {
        const fields = config.regulatoryChargesSettings.discom.fields;

        return Object.keys(fields)?.map((fieldName, index) => {
            const field = fields[fieldName];
            const isDisabled = fieldName === 'globalRecVariableId';

            const { label, type, required, options } = field;

            switch (type) {
                case 'text':
                case 'number':
                    return (
                        <>
                            <div key={index} className="flex items-center">
                                <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                                    {label}{required && <span className="text-red-500">*</span>} :
                                </label>
                                <InputBox
                                    type={type}
                                    width="w-3/4"
                                    placeholder={`Enter ${label}`}
                                    value={formData[fieldName]}
                                    onChange={(e) => handleChange(fieldName, e.target.value)}
                                    className="ml-4"
                                />
                            </div>
                            <div className='mb-6'>
                                {errors[fieldName] && (
                                    <p className="absolute text-red-500 text-sm ml-[150px]">
                                        {errors[fieldName]}
                                    </p>
                                )}
                            </div>
                        </>
                    );
                case 'select':
                    if (label == 'State') {
                        return (
                            <>
                                <div key={index} className="flex items-center">
                                    <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                                        {label}{required && <span className="text-red-500">*</span>} :
                                    </label>
                                    <select
                                        id={fieldName}
                                        value={formData[fieldName]}
                                        onChange={(e) => handleChange(fieldName, e.target.value)}
                                        className={`w-3/4 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                                            errors[fieldName] ? "border-red-500" : ""
                                        }`}
                                    >
                                        <option value="">Select {label}</option>
                                        {states?.map((state, index) => (
                                            <option key={index} value={state?._id}>{state?.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='mb-6'>
                                    {errors[fieldName] && (
                                        <p className="absolute text-red-500 text-sm ml-[150px]">
                                            {errors[fieldName]}
                                        </p>
                                    )}
                                </div>
                            </>
                        );
                    } else if (label == 'Discom') {
                        return (
                            <>
                                <div key={index} className="flex items-center">
                                    <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                                        {label}{required && <span className="text-red-500">*</span>} :
                                    </label>
                                    <select
                                        id={fieldName}
                                        value={formData[fieldName]}
                                        onChange={(e) => handleChange(fieldName, e.target.value)}
                                        className={`w-3/4 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                                            errors[fieldName] ? "border-red-500" : ""
                                        }`}
                                    >
                                        <option value="">Select {label}</option>
                                        {discoms?.map((discom, index) => (
                                            <option key={index} value={discom?._id}>{discom?.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='mb-6'>
                                    {errors[fieldName] && (
                                        <p className="absolute text-red-500 text-sm ml-[150px]">
                                            {errors[fieldName]}
                                        </p>
                                    )}
                                </div>
                            </>
                        );
                    } else {
                        return (
                            <>
                                <div key={index} className="flex items-center">
                                    <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                                        {label}{required && <span className="text-red-500">*</span>} :
                                    </label>
                                    <select
                                        disabled={isDisabled}
                                        id={fieldName}
                                        value={formData[fieldName]}
                                        onChange={(e) => handleChange(fieldName, e.target.value)}
                                        className={`w-3/4 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                                            errors[fieldName] ? "border-red-500" : ""
                                        } ${isDisabled ? "cursor-not-allowed" : ""}`}
                                    >
                                        <option value="">Select {label}</option>
                                        {globalVariables?.map((globalVariable, index) => (
                                            <option key={index} value={globalVariable?._id}>{globalVariable?.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='mb-6'>
                                    {errors[fieldName] && (
                                        <p className="absolute text-red-500 text-sm ml-[150px]">
                                            {errors[fieldName]}
                                        </p>
                                    )}
                                </div>
                            </>
                        );
                    }
                default:
                    return null;
            }
        });
    };

    const handleChange = (fieldName, value) => {
        setFormData({ ...formData, [fieldName]: value });

        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };

            if (value.trim()) {
                delete updatedErrors[fieldName];
            } else {
                updatedErrors[fieldName] = `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} is required`;
            }

            return updatedErrors;
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        // Validation logic
        const validationErrors = {};
    
        // Check if the 'global variable' field is empty
        if (!formData.globalRecVariableId) {
            validationErrors.globalRecVariableId = "Global Variable is required";
        }
    
        // Check if the 'stateList' field is empty
        if (!formData.stateId) {
            validationErrors.stateId = "State is required";
        }

        // Check if the 'discomList' field is empty
        if (!formData.objectId) {
            validationErrors.objectId = "Discom is required";
        }
    
        // Check if the 'discomCharges' field is empty
        if (!formData.discomCharges) {
            validationErrors.discomCharges = "Discom Charges is required";
        }
    
        // Check if the 'thirdPartyCharges' field is empty
        if (!formData.thirdPartyCharges) {
            validationErrors.thirdPartyCharges = "Third Party Charges is required";
        }
    
        // Check if the 'captiveCharges' field is empty
        if (!formData.captiveCharges) {
            validationErrors.captiveCharges = "Captive Charges is required";
        }
        if (!formData.rtsCharges) {
            validationErrors.rtsCharges = "RTS Charges is required";
        }

        // if (!formData.status) {
        //     validationErrors.status = "Status is required";
        // }
    
        // If there are validation errors, update the state and return
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
    
        try {
            const response = await updateRegulatoryChargeById(editId, formData);

            if (response.data.data.length == 0){
                toast.error(`${response?.data?.message}`, {
                    position: "top-center",
                });
                
            } else {
                toast.success(`${response?.data?.message}`, {
                    position: "top-center",
                });
                setUpdateCount(prevCount => prevCount + 1);
                onClose();
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("Failed to add regulation. Please try again.", {
                position: "top-center",
            })
        }

    };

    return (
        <div className="flex flex-col h-full">
            <div className="flex-grow overflow-auto pt-4 px-2 py-1">
                <div className="max-w-10xl">
                    <div>
                        <div className="max-w-8xl">
                            <div className="px-4">
                                {renderFormFields()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sticky bottom-0 bg-white flex justify-center gap-8">
                <button
                    type="button"
                    onClick={onClose}
                    className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    onClick={handleSubmit}
                    className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4"
                >
                    Save
                </button>
            </div>
        </div>
    );
}
