import React, { useEffect, useState } from 'react';
import config from '../../../../config/config';
import InputBox from '../../../../components/inputBox/InputBox';
import { ToastContainer, toast } from 'react-toastify';
import { createRegulatoryCharge } from '../../../../services/apis/regulatoryCharges';
import { useNavigate } from 'react-router-dom';
import { fetchGlobalVariablesList } from '../../../../services/apis/globalRecommendationVariable';

export default function AddCentralCharges({financialYear,onClose, setUpdateCount}) {

    const navigate = useNavigate();
    const [globalVariables, setGlobalVariables] = useState([]);
    const [formData, setFormData] = useState({
        financialYear:financialYear.split("-")[0],
        globalRecVariableId: "",
        discomCharges: "",
        thirdPartyCharges: "",
        captiveCharges: "",
        objectType: "central",
        rtsCharges:""
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const getGlobalVariablesList = async () => {
            const payload = {
                status: 'active',
                // isMap: false,
                type: 'regulatoryCharge'
            }
            const response = await fetchGlobalVariablesList(payload);
            const data = response?.data?.data?.results;
            setGlobalVariables(data);
            // console.log(data,'data')
        };
        getGlobalVariablesList();
    },[])

    const renderFormFields = () => {
        const fields = config.regulatoryChargesSettings.central.fields;

        return Object.keys(fields)?.map((fieldName, index) => {
            const field = fields[fieldName];
            const { label, type, required, options } = field;

            switch (type) {
                case 'text':
                case 'number':
                    return (
                        <>
                            <div key={index} className="flex items-center">
                                <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                                    {label}{required && <span className="text-red-500">*</span>} :
                                </label>
                                <InputBox
                                    type={type}
                                    width="w-3/4"
                                    placeholder={`Enter ${label}`}
                                    value={formData[fieldName]}
                                    onChange={(e) => handleChange(fieldName, e.target.value)}
                                    className="ml-4"
                                />
                            </div>
                            <div className='mb-5'>
                                {errors[fieldName] && (
                                    <p className="absolute text-red-500 text-sm ml-36">
                                        {errors[fieldName]}
                                    </p>
                                )}
                            </div>
                        </>
                    );
                case 'select':
                    return (
                        <>
                            <div key={index} className="flex items-center">
                                <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                                    {label}{required && <span className="text-red-500">*</span>} :
                                </label>
                                <select
                                    id={fieldName}
                                    value={formData[fieldName]}
                                    onChange={(e) => handleChange(fieldName, e.target.value)}
                                    className={`w-3/4 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                                        errors[fieldName] ? "border-red-500" : ""
                                    }`}
                                >
                                    <option value="">Select {label}</option>
                                    {globalVariables?.map((globalVariable, index) => (
                                        <option key={index} value={globalVariable?._id}>{globalVariable?.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='mb-5'>
                                {errors[fieldName] && (
                                    <p className="absolute text-red-500 text-sm ml-36">
                                        {errors[fieldName]}
                                    </p>
                                )}
                            </div>
                        </>
                    );
                default:
                    return null;
            }
        });
    };

    const handleChange = (fieldName, value) => {
        setFormData({ ...formData, [fieldName]: value });

        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };

            if (value.trim()) {
                delete updatedErrors[fieldName];
            } else {
                updatedErrors[fieldName] = `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} is required`;
            }

            return updatedErrors;
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const validationErrors = {};
        if(!formData.financialYear){
            validationErrors.globalRecVariableId = "FY is required";
        }

        if (!formData.globalRecVariableId) {
            validationErrors.globalRecVariableId = "Global Variable is required";
        }
        if (!formData.discomCharges.trim()) {
            validationErrors.discomCharges = "Discom Charges is required";
        }
        if (!formData.thirdPartyCharges.trim()) {
            validationErrors.thirdPartyCharges = "Third Party Charges is required";
        }
        if (!formData.captiveCharges.trim()) {
            validationErrors.captiveCharges = "Captive Charges is required";
        }
        if (!formData.rtsCharges) {
            validationErrors.rtsCharges = "RTS Charges is required";
        }
        // if (formData.status == '') {
        //     validationErrors.status = "Status is required";
        // }
        
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        
        try {
            const response = await createRegulatoryCharge(formData);

            if (response.data.data.length == 0){
                toast.error(`${response?.data?.message}`, {
                    position: "top-center",
                });
                
            } else {
                toast.success(`${response?.data?.message}`, {
                    position: "top-center",
                });
                setUpdateCount(prevCount => prevCount + 1);
                onClose();
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("Failed to add regulation. Please try again.", {
                position: "top-center",
            })
        }
    
    };


    return (
        <div className="flex flex-col h-full">
            <div className="flex-grow overflow-auto pt-4 px-2 py-1">
                <div className="max-w-10xl">
                    <div>
                        <div className="max-w-8xl">
                            <div className="px-4">
                                {renderFormFields()}
                            </div>
                        </div>
                        {/* <ToastContainer position="top-center" autoClose={3000} /> */}
                    </div>
                </div>
            </div>
            <div className="sticky bottom-0 bg-white flex justify-center">
                <button
                    type="button"
                    onClick={onClose}
                    className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    onClick={handleSubmit}
                    className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4"
                >
                    Save
                </button>
            </div>
        </div>
    );
}
