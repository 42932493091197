import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await login({ email, password });
      if (result) {
        toast.success("Login Successful!");
        navigate("/");
      } else {
        toast.error("Please enter valid credentials");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex h-screen flex-col  justify-center items-center w-full max-w-2xl m-auto">
      <div className="max-w-[60rem] h-screen md:max-h-[48.75rem] py-[1.125rem] px-[1.875rem] w-full flex flex-col justify-center items-center shadow-lg bg-[#F7FCFA]">
        <div>
          <img src="/Logo-full.png" alt="Logo" className="w-[13rem] h-[6rem]" />
        </div>
        <form className="w-full" onSubmit={handleSubmit}>
          <input type="hidden" name="remember" value="true" />
          <div>
            <div className="w-full mb-[1rem]">
              <div
                htmlFor="email-address"
                className="pt-[0.625rem] pb-[1.125rem] p-0 font-normal leading-[1.375rem] md:p-[0.625rem] md:pb-[1.1875rem]"
              >
                Email
              </div>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="border font-normal w-full rounded-[1.25rem] text-[1rem] leading-[1.25rem] border-[#B9B9B9] py-[1.5625rem] px-[1.25rem] md:ml-[0.625rem] md:max-w-[34.0625rem]"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="w-full">
              <div
                htmlFor="password"
                className="pt-[0.625rem] pb-[1.125rem] p-0 font-normal leading-[1.375rem] md:p-[0.625rem] md:pb-[1.1875rem]"
              >
                Password
              </div>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="border font-normal w-full rounded-[1.25rem] text-[1rem] leading-[1.25rem] border-[#B9B9B9] py-[1.5625rem] px-[1.25rem] md:ml-[0.625rem] md:max-w-[34.0625rem]"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <div className="mt-[3rem] flex justify-center">
            <button
              type="submit"
              className={`text-center rounded-[0.625rem] p-[0.625rem] text-[1rem] font-medium leading-[1.5rem] text-white bg-[#067C4E] h-[2.75rem] w-[8rem] md:h-[2.75rem] md:w-[13rem]`}
            >
              Sign in
            </button>
          </div>
        </form>
        <div>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
    </div>
  );
}
