import React from 'react'

export default function Listing() {
  return (
    <div class="bg-gray-200 p-8 mt-0.5">
        <div class="bg-white-300 p-6">
            <div class="flex justify-between items-center">
                <div class="text-2xl font-bold text-white-900 p-0 rounded-lg">Inventory List</div>
            </div>
        </div>

        <div class="bg-white p-8">
            <div class="flex justify-between items-center">
                <div class="w-1/4">
                    <label for="searchProduct" class="text-lg">Search</label>
                    <div class="flex items-center mt-4 border border-gray-400 rounded">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-500 ml-2"><path d="M0 0h24v24H0z" fill="none"/><path d="M15.5 14h-.79l-.28-.27a6.5 6.5 0 1 0-.7.7l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zm0-10C5.91 4 4 5.91 4 8s1.91 4 4.5 4 4-1.91 4-4-1.91-4-4.5-4z"/></svg>
                        <input type="text" id="searchProduct" placeholder="Search Product" class="w-full py-2 pl-2 text-gray-800 focus:outline-none" />
                    </div>
                </div>
                {/* <div class="w-1/2">
                    <label for="statusFilter" class="text-lg">STATUS BY</label>
                    <select id="statusFilter" class="block w-full py-2 pl-2 pr-8 text-gray-800 mt-4 bg-white border border-gray-400 rounded focus:outline-none">
                        <option value="" disabled selected>Select Status</option>
                    </select>
                </div> */}
                <button class="bg-blue-500 text-white px-4 py-2 rounded-md mt-4">Reset</button>
                
            </div>
            <div class="mt-8">
                    <div class="container mx-auto">
                        <table class="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                            <thead class="bg-gray-800 text-white">
                            <tr>
                                <th class="px-4 py-2">Column 1</th>
                                <th class="px-4 py-2">Column 2</th>
                                <th class="px-4 py-2">Column 3</th>
                                <th class="px-4 py-2">Column 4</th>
                                <th class="px-4 py-2">Column 5</th>
                                <th class="px-4 py-2">Column 6</th>
                                <th class="px-4 py-2">Column 7</th>
                            </tr>
                            </thead>
                            <tbody class="text-gray-700">
                            <tr>
                                <td class="border px-4 py-2">Data 1</td>
                                <td class="border px-4 py-2">Data 2</td>
                                <td class="border px-4 py-2">Data 3</td>
                                <td class="border px-4 py-2">Data 4</td>
                                <td class="border px-4 py-2">Data 5</td>
                                <td class="border px-4 py-2">Data 6</td>
                                <td class="border px-4 py-2">Data 7</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
        </div>
    </div>
  )
}
