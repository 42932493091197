let googleMapsApiPromise;

const loadGoogleMapsApi = () => {
  if (!googleMapsApiPromise) {
    const apiKey = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;

    googleMapsApiPromise = new Promise((resolve, reject) => {
      if (window.google && window.google.maps) {
        resolve();
      } else {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = () => {
          resolve();
        };
        script.onerror = () => {
          reject(new Error('Failed to load Google Maps API'));
        };

        document.body.appendChild(script);
      }
    });
  }

  return googleMapsApiPromise;
};

export default loadGoogleMapsApi;
