import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="flex h-screen justify-center items-center flex-col">
      <div className="w-[400px]">
        <img
          src="https://cdn1.iconfinder.com/data/icons/photo-stickers-words/128/word_18-1024.png"
          alt="Not Found"
          className="not-found-image"
        />
      </div>
      <h1 className="text-4xl font-bold">Page Not Found</h1>
      <br />
      <p className="text-xl">The page you are looking for does not exist.</p>
      <br />

      <p>
        <Link to="/" className="underline text-blue">
          {" "}
          Go to Home{" "}
        </Link>
      </p>
    </div>
  );
};

export default NotFound;
