import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

// Create a context for the authentication state
 const AuthContext = createContext();

// Create a provider component to wrap the app and manage authentication state
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(localStorage.getItem('user'));
  const API_URL = process.env.REACT_APP_API_URL; 

  // Check if the user is already authenticated (e.g., using a token stored in localStorage)
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
  }, [user]);

  // Function to handle user login
  const login = async(userData) => {
    try {
        
        const response = await axios.post(`${API_URL}/user/login`, userData);
        
        if (response.status === 200) {
          const { data } = response;
          setUser(data);
          localStorage.setItem('user', JSON.stringify(data));
          return true;
        } else {
          throw new Error('Invalid login credentials');
        }
      } catch (error) {
        console.log(error.response.data.error.code)
        // alert(error.response.data.error.message);
        return false;
      }
  };

  // Function to handle user logout
  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
  };

  // Provide the authentication state and functions through the context
  const contextValue = {
    user,
    login,
    logout,
    isAuthenticated: !!user,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to access the authentication context
export  const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
