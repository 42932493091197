import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";

const ExcelUploadButton = ({ setIsUploadPopupOpen, text, px, py, smpy, smpx }) => {



  return (
    <>
      <div className="border cursor-pointer py-2 px-2 p-[5px] bg-[#a9dbc3] rounded-lg" onClick={(e) => setIsUploadPopupOpen(true)}>
        <FontAwesomeIcon size="xl" icon={faUpload} id="uploadExcel" />
      </div>
      <Tooltip
        anchorSelect="#uploadExcel"
        content="Upload Excel of Power Generation Data"
      />
    </>
  );
};

export default ExcelUploadButton;
