import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const user = JSON.parse(localStorage.getItem('user'));
const token = user?.result?.token

export const createUserRole = async (formData) => {
    try {
        const response = await axios.post(`${apiUrl}/userRole`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
        }); 
        return response?.data;
    } catch (error) {
        console.error('Error creating user role:', error);
        return error?.response?.data;
    }
}

export const fetchUserRoleList = async (payload) => {
    try {
        const response = await axios.post(`${apiUrl}/userRole/list`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        return response?.data;
    } catch (error) {
        console.error('Error fetching user role list:', error);
        return false;
    }
}

export const getUserRoleById = async (id) => {
    try {
        const response = await axios.get(`${apiUrl}/userRole/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        return response?.data;
    } catch (error) {
        console.error('Error getting user role:', error);
        return false;
    }
}

export const updateUserRoleById = async (id, formData) => {
    try {
        const response = await axios.put(`${apiUrl}/userRole/${id}`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        return response?.data;
    } catch (error) {
        console.error('Error updaating user role:', error);
        return false;
    }
}

export const deleteUserRoleById = async (id) => {
    try {
        const response = await axios.delete(`${apiUrl}/userRole/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        return response?.data;
    } catch (error) {
      console.error('Error deleting content:', error);
      return false;
      // throw error;
    }
}