import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faTimes, faTimesCircle, faTrashAlt, faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons';

export default function DeleteConfirmation({ isOpen, onClose, onConfirm, itemName }) {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="relative bg-white rounded-lg shadow-lg p-8 w-1/3 text-center">
            <button 
                className="absolute -top-2 -right-2 text-gray-700 hover:text-red-500"
                onClick={onClose}
            >
                <FontAwesomeIcon icon={faCircleXmark} className="w-8 h-8 " />
            </button>
            <div className="mb-4">
                <FontAwesomeIcon icon={faTrashCan} className="w-20 h-20 text-red-500" />
                {/* <img src="/path/to/trash-icon.png" alt="Delete Icon" className="mx-auto w-16 h-16" /> */}
            </div>
            <h2 className="text-xl font-semibold mb-2">Delete {itemName}</h2>
            <p className="text-gray-700 mb-6">
                Are you sure you want to delete {itemName}?<br />
                This action cannot be undone.
            </p>
            <div className="flex justify-center gap-4">
                <button 
                    className="bg-red-600 text-white hover:bg-red-700 px-4 py-2 rounded-md"
                    onClick={onConfirm}
                >
                    Delete
                </button>
            </div>
        </div>
    </div>
    );
}
