const SEOSettingList = [
    {
        _id: "1",
        title: "About",
        metaKeyword: "",
        metaUrl: "",
        publishDate: "2022-01-01",
    },
    {
        _id: "2",
        title: "Home",
        metaKeyword: "",
        metaUrl: "",
        publishDate: "2022-02-01",
    },
]

export default SEOSettingList;