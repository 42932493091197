import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useEffect } from "react";

export default function ProtectedRoute({ children }) {
    const { user, isAuthenticated } = useAuth();
    const navigate = useNavigate();
  
    useEffect(() => {
      if (!isAuthenticated) {
        navigate("/login");
      }
    }, [isAuthenticated, user]);
  
    return children;
  }