import axios from 'axios';

const user = JSON.parse(localStorage.getItem('user'));
const token = user?.result?.token

const API_URL = process.env.REACT_APP_API_URL; 

export async function fetchImages(page = 1, limit = 10) {

  try {
    const response = await axios.post(`${API_URL}/media/list`, {
      page,
      limit
    });

    return response?.data?.data?.results; 
  } catch (error) {
    console.error('Error fetching images:', error);
    return []; 
  }
}

export const uploadImage = async (file, objectType, type) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('objectType', objectType);
    formData.append('type', type);
    formData.append('storage', "local");

    const response = await axios.post(`${API_URL}/media`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `bearer ${token}`
      },
    });

    return response.data.result;
  } catch (error) {
    console.error('Error uploading image:', error);
    return false;
    // throw new Error('Failed to upload image');
  }
};