import React, { useEffect, useState } from 'react';
import config from '../../../config/config';
import InputBox from '../../../components/inputBox/InputBox';
import { fetchBillingUnitById, updateBillingUnit } from '../../../services/apis/billingUnit';
import { toast } from 'react-toastify';


export default function EditBillingUnit({ onClose, editId, setEditFormOpen, setUpdateCount }) {

    const [formData, setFormData] = useState({
        name: "",
        symbol: "",
        status: 'active',
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const getBillingUnitById = async () => {
            const response = await fetchBillingUnitById(editId);
            const data = response?.data?.data;
            setFormData({
                name: data?.name || "",
                symbol: data?.symbol || "",
                status: data?.status || '',
            })
            // console.log(data,'data')
        };
        getBillingUnitById();
    },[])

    const renderFormFields = () => {
        const fields = config.billingUnitSettings.fields;

        return Object.keys(fields)?.map((fieldName, index) => {
            const field = fields[fieldName];
            const { label, type, required, options } = field;

            switch (type) {
                case 'text':
                    return (
                        <div key={index} className="mb-6">
                            <div className="flex items-center">
                                <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                                    {label}{required && <span className="text-red-500">*</span>} :
                                </label>
                                <InputBox
                                    width="w-3/4"
                                    placeholder={`Enter ${label}`}
                                    value={formData[fieldName] || ''}
                                    onChange={(e) => handleChange(fieldName, e.target.value)}
                                    className="ml-4"
                                />
                            </div>
                            {errors[fieldName] && (
                                <p className="absolute text-red-500 text-sm ml-44">{errors[fieldName]}</p>
                            )}
                        </div>
                    );
                case 'select':
                    return (
                        <div key={index} className="mb-6">
                            <div className="flex items-center">
                                <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                                    {label}{required && <span className="text-red-500">*</span>} :
                                </label>
                                <select
                                    id={fieldName}
                                    value={formData[fieldName] || ''}
                                    onChange={(e) => handleChange(fieldName, e.target.value)}
                                    className={`w-3/4 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                                        errors[fieldName] ? 'border-red-500' : ''
                                    }`}
                                >
                                    <option value="">Select {label}</option>
                                    {options?.map((option, index) => (
                                        <option key={index} value={option}>{option}</option>
                                    ))}
                                </select>
                            </div>
                            {errors[fieldName] && (
                                <p className="absolute text-red-500 text-sm ml-44">{errors[fieldName]}</p>
                            )}
                        </div>
                    );
                default:
                    return null;
            }
        });
    };

    const handleChange = (fieldName, value) => {
        
        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: ''
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const validationErrors = {};
        if (!formData.name.trim()) {
            validationErrors.name = "Name is required";
        }
        if (!formData.symbol.trim()) {
            validationErrors.symbol = "Symbol is required";
        }
        
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            const response = await updateBillingUnit(editId, formData);

            if (response.data.data.length == 0){
                toast.error(`${response?.data?.message}`, {
                    position: "top-center",
                });
                
            } else {
                toast.success(`${response?.data?.message}`, {
                    position: "top-center",
                    autoClose: 3000
                });
                setUpdateCount(prevCount => prevCount + 1);
                setEditFormOpen(false);
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("Failed to edit global variable. Please try again.", {
                position: "top-center",
            })
        } 
    };
    
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
            <div className="bg-white p-4 shadow-lg w-full max-w-3xl max-h-[95vh] overflow-y-auto rounded-lg">
                <h2 className="text-lg font-semibold">Edit Billing Unit</h2>
                <form>
                    <div className="flex-grow overflow-auto">
                        <div className="max-w-8xl">
                            <div className="bg-white shadow-md overflow-hidden p-4">
                                {renderFormFields()}
                            </div>
                        </div>
                    </div>
                </form>
                <div className="sticky bottom-0 bg-white p-4 flex justify-center gap-8">
                    <button
                        type="button"
                        onClick={onClose}
                        className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4"
                    >
                        Save
                    </button>
                </div>
                {/* <ToastContainer position="top-center" autoClose={3000} /> */}
            </div>
        </div>
    )
}

