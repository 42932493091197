// src/Routes.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../pages/home/Home";
import About from "../pages/about/About";
import Dashboard from "../pages/dashboard/Dashboard";
import Listing from "../pages/listing/Listing";
import BlogList from "../pages/blogList/BlogList";
import MainLayout from "../layouts/mainLayout/MainLayout";
import NotFound from "../pages/notFound/NotFound";
import LoginPage from "../pages/login/login";
import { useAuth } from "../hooks/useAuth";
import ProtectedRoute from "./ProtectedRoute";
import AddBlog from "../pages/addBlog/AddBlog";
import EditBlog from "../pages/editBlog/EditBlog";
import NewsList from "../pages/newsList/NewsList";
import AddNews from "../pages/addNews/AddNews";
import EditNews from "../pages/editNews/EditNews";
import SEOSettingsList from "../pages/seoSettingsList/SEOSettingsList";
import AddSEOSettings from "../pages/addSEOSettings/AddSEOSettings";
import EditSEOSettings from "../pages/editSEOSettings/EditSEOSettings";
// import Signup from "../pages/signup/Signup";
import ArticlesList from "../pages/articlesList/ArticlesList";
import EditArticle from "../pages/editArticle/EditArticle";
import AddArticle from "../pages/addArticle/AddArticle";
import RegulationsList from "../pages/regulationsList/RegulationsList";
import AddRegulation from "../pages/addRegulation/AddRegulation";
import EditRegulation from "../pages/editRegulation/EditRegulation";
import LegalUpdatesList from "../pages/legalUpdatesList/LegalUpdatesList";
import AddLegalUpdate from "../pages/addLegalUpdate/AddLegalUpdate";
import EditLegalUpdate from "../pages/editLegalUpdate/EditLegalUpdate";
import PagesList from "../pages/pagesList/PagesList";
import CMSList from "../pages/cmsList/CMSList";
import AddCMS from "../pages/addCMS/AddCMS";
import AddSolution from "../pages/addSolution/AddSolution";
import AddCareer from "../pages/addCareer/AddCareer";
import AddPage from "../pages/addPage/AddPage";
import EditCMS from "../pages/editCMS/EditCMS";
import AddUserRole from "../pages/addUserRole/AddUserRole";
import UserRoleList from "../pages/userRoleList/UserRoleList";
import EditUserRole from "../pages/editUserRole/EditUserRole";
import AddUser from "../pages/addUser/AddUser";
import UserList from "../pages/userList/UserList";
import EditUser from "../pages/editUser/EditUser";
import OrganisationList from "../pages/organisationList/OrganisationList";
import FactoryList from "../pages/factoryList/FactoryList";
import UnitsList from "../pages/unitsList/UnitsList";
import MetersList from "../pages/metersList/MetersList";
import StateList from "../pages/state/StateList";
import DiscomList from "../pages/discom/DiscomList";
import BankingPolicy from "../pages/recommendations/todBankingPolicy/BankingPolicy";
import TodIncentives from "../pages/recommendations/todBankingPolicy/TodIncentives";
import RegulatoryChargesListing from "../pages/recommendations/regulatoryCharges/RegulatoryChargesListing";
import GlobalVariablesListing from "../pages/recommendations/globalVariables/GlobalVariablesListing";
import PowerPlantListing from "../pages/recommendations/powerPlant/PowerPlantListing";
import ToDBankingPolicy from "../pages/recommendations/todBankingPolicy/ToDBankingPolicy";
import ListPowerPlantData from "../pages/listPowerPlantData/ListPowerPlantData";
import PowerPlantDataEdit from "../pages/recommendations/powerPlantData/PowerPlantDataEdit";
import PowerPlantDataAdd from "../pages/recommendations/powerPlantData/PowerPlantDataAdd";
import IntermediateVariables from "../pages/recommendations/intermediateVariables/IntermediateVariablesListing";
import BillingUnitListing from "../pages/recommendations/billingUnit/BillingUnitListing";
import PowerPlantTypeListing from "../pages/recommendations/powerPlantType/PowerPlantTypeListing";
import CreateFormula from "../pages/formula/CreateFormula";
import PowerPlantUnitListing from "../pages/recommendations/powerPlantUnit/PowerPlantUnitListing";
import ListFormula from "../pages/formula/ListFormula";
import ConstantVariableListing from "../pages/recommendations/constantVariable/ConstantVariableListing";
import EditFormula from "../pages/formula/EditFormula";
import UnitListing from "../pages/recommendations/unitList/UnitListing";
import OcrList from "../pages/ocrList/ocrList";
import IEXDiscount from "../pages/recommendations/iexDiscount/IEXDiscountDataList";
import AddDiscomForm from "../pages/discom/DiscomForm";

function AppRoutes() {
  const { user, isAuthenticated, login, logout } = useAuth();

  return (
    <Router>
      <Routes>
        <Route
          path="/recommendations/billing-unit-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <BillingUnitListing />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/recommendations/global-variables-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <GlobalVariablesListing />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/recommendations/regulatory-charges-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <RegulatoryChargesListing />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/recommendations/intermediate-variables"
          element={
            <ProtectedRoute>
              <MainLayout>
                <IntermediateVariables />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/recommendations/constant-variables"
          element={
            <ProtectedRoute>
              <MainLayout>
                <ConstantVariableListing />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/recommendations/formula/create"
          element={
            <ProtectedRoute>
              <MainLayout>
                <CreateFormula />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/recommendations/formula/edit/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <EditFormula />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/recommendations/formula/list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <ListFormula />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/recommendations/powerplant-type-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <PowerPlantTypeListing />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/recommendations/powerplant-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <PowerPlantListing />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/recommendations/powerplant-data-edit"
          element={
            <ProtectedRoute>
              <MainLayout>
                <PowerPlantDataEdit />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/recommendations/power-plant-list/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <PowerPlantUnitListing />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/recommendations/powerplant-data-add"
          element={
            <ProtectedRoute>
              <MainLayout>
                <PowerPlantDataAdd />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/recommendations/tod-banking-policy"
          element={
            <ProtectedRoute>
              <MainLayout>
                <ToDBankingPolicy />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/recommendations/unit-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <UnitListing />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/recommendations/generate-power-plant-data-list/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <ListPowerPlantData />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/content-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <CMSList />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/organisation-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <OrganisationList />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/factory-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FactoryList />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/units-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <UnitsList />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/meters-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <MetersList />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/ocr-list/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <OcrList />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-content"
          element={
            <ProtectedRoute>
              <MainLayout>
                <AddCMS />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-user"
          element={
            <ProtectedRoute>
              <MainLayout>
                <AddUser />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <UserList />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-user/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <EditUser />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-user-role"
          element={
            <ProtectedRoute>
              <MainLayout>
                <AddUserRole />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-role-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <UserRoleList />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-user-role/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <EditUserRole />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-content/:type/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <EditCMS />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-solution"
          element={
            <ProtectedRoute>
              <MainLayout>
                <AddSolution />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-career"
          element={
            <ProtectedRoute>
              <MainLayout>
                <AddCareer />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-page"
          element={
            <ProtectedRoute>
              <MainLayout>
                <AddPage />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Home />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/about"
          element={
            <ProtectedRoute>
              <MainLayout>
                <About />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Dashboard />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/listing"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Listing />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/blog-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <BlogList />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/news-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <NewsList />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-news"
          element={
            <ProtectedRoute>
              <MainLayout>
                <AddNews />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-news/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <EditNews />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/seo-settings-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <SEOSettingsList />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-seo-settings"
          element={
            <ProtectedRoute>
              <MainLayout>
                <AddSEOSettings />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-seo-settings/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <EditSEOSettings />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/articles-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <ArticlesList />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-article"
          element={
            <ProtectedRoute>
              <MainLayout>
                <AddArticle />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-article/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <EditArticle />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/regulations-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <RegulationsList />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-regulation"
          element={
            <ProtectedRoute>
              <MainLayout>
                <AddRegulation />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-regulation/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <EditRegulation />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/legal-updates-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <LegalUpdatesList />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-legal-update"
          element={
            <ProtectedRoute>
              <MainLayout>
                <AddLegalUpdate />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-legal-update/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <EditLegalUpdate />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/pages-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <PagesList />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-legal-update"
          element={
            <ProtectedRoute>
              <MainLayout>
                <AddLegalUpdate />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/state-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <StateList />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/discom-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <DiscomList />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/discom-list/create"
          element={
            <ProtectedRoute>
              <MainLayout>
                <AddDiscomForm />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/discom-list/edit/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <AddDiscomForm />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/recommendations/tod-banking-policy/banking-policy/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <BankingPolicy />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/recommendations/tod-banking-policy/tod-incentives/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <TodIncentives />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/recommendations/iex-discount/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <IEXDiscount />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<LoginPage />} />
        {/* <Route path="/signup" element={<Signup />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
