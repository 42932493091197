import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes, faUpload } from "@fortawesome/free-solid-svg-icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import "../common.css";
import { editContentById, getContentById } from "../../services/apis/content";
import { useNavigate } from "react-router-dom";
import AddImagePopUp from "../../components/addImagePopUp/AddImagePopUp";
import config from "../../config/config";
import InputBox from "../../components/inputBox/InputBox";

export default function EditBlog() {
  const { id } = useParams();

  const [showProfileImage, setShowProfileImage] = useState(false);
  const [showAddImageOverlay, setShowAddImageOverlay] = useState(false);
  const [popupContext, setPopupContext] = useState(null);
  const [contentData, setContentData] = useState({});
  const quillRef = useRef(null);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    type: "blog",
    slug: "",
    status: "status",
    profileImage: "",
    shortDescription: "",
    description: "",
    metaTitle: "",
    metaDataDescription: "",
    metaKeyword: "",
    publishdate: "",
    authorName: "",
  });

  const [errors, setErrors] = useState({});
  const [formReset, setFormReset] = useState(false);

  const fetchContentData = async () => {
    try {
      const response = await getContentById(id);
      const content = response?.result;

      setContentData(content);

      if (content) {
        // const decodedContent = window.atob(content.description);

        const decodedDescriptionContent = window.atob(content.description);
        const decodedContent = decodeURIComponent(decodedDescriptionContent);
        setFormData({
          title: content.title,
          type: content.type ,
          slug: content.slug ,
          status: content.status ,
          profileImage: content.profileImage._id ,
          shortDescription: content.shortDescription ,
          description: decodedContent ,
          metaTitle: content.metaTitle ,
          metaDataDescription: content.metaDataDescription ,
          metaKeyword: content.metaKeyword,
          publishdate: content.publishdate ,
          authorName: content.authorName 
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchContentData();
  }, [id]);

  const handleChange = (name, value) => {
    let slugValue =
      name === "title"
        ? value
            .toLowerCase()
            .replace(/\s+/g, "-")
            .replace(/[^a-zA-Z0-9-]/g, "")
        : formData.slug;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      slug: slugValue,
    }));
    if (name === "title" && errors.slug && value.trim() !== "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        slug: "",
      }));
    }
    let error = "";
    if (
      name === "title" ||
      name === "slug" ||
      name === "shortDescription" ||
      name === "description" ||
      name === "status"
    ) {
      if (!value.trim()) {
        error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
      }
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleImageChange = () => {
    setShowAddImageOverlay(true);
    setPopupContext("profile");
  };

  const handleContentAddImage = () => {
    setShowAddImageOverlay(true);
    setPopupContext("content");
  };

  const handleContentChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      description: value,
    }));
    setErrors({});
  };

  const RemoveImageButton = () => {
    const removeImage = () => {
      const quill = quillRef.current.editor;
      const range = quill.getSelection(true);
      quill.deleteText(range.index, 1, "user");
    };

    return (
      <button className="ql-remove-image">
        <FontAwesomeIcon icon={faTimes} onClick={removeImage} />
      </button>
    );
  };

  const modules = {
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["clean"],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ["image"],
        //[{ 'remove-image': <RemoveImageButton /> }], // Custom button for removing images
      ],
      // handlers: {
      //   "remove-image": () => {
      //     const quill = quillRef.current.editor;
      //     const range = quill.getSelection(true);
      //     quill.deleteText(range.index, 1, 'user');
      //   }
      // }
    },
  };

  // const modules = {
  //   toolbar: {
  //     container: [
  //       [{ header: "1" }, { header: "2" }, { font: [] }],
  //       [{ size: [] }],
  //       ["bold", "italic", "underline", "strike", "blockquote"],
  //       [
  //         { list: "ordered" },
  //         { list: "bullet" },
  //         { indent: "-1" },
  //         { indent: "+1" },
  //       ],
  //       ["clean"],
  //       [{ color: [] }, { background: [] }],
  //       [{ align: [] }],
  //       ["image"],
  //       [{"remove-image": <RemoveImageButton />} ],
  //     ],
  //     handlers: {
  //       "remove-image": () => {
  //         const quill = quillRef.current.editor;
  //         const range = quill.getSelection(true);
  //         quill.deleteText(range.index, 1, 'user');
  //       }
  //     }
  //   },
  // };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "background",
    "align",
  ];

  const handleCancel = () => {
    setFormData({
      title: "",
      type: "blog",
      slug: "",
      status: "status",
      profileImage: "",
      shortDescription: "",
      description: "",
      metaTitle: "",
      metaDataDescription: "",
      metaKeyword: "",
      publishdate: "",
    });
    setErrors({});
    navigate("/content-list");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formErrors = {};

    if (!formData.title.trim()) {
      formErrors.title = "Title is required";
    }

    if (!formData.slug.trim()) {
      formErrors.slug = "Slug URL is required";
    }

    if (formData.status == "status") {
      formErrors.status = "Status is required";
    }

    if (formData.type == "type") {
      formErrors.type = "Type is required";
    }

    if (!formData.shortDescription.trim()) {
      formErrors.shortDescription = "Short Description is required";
    }

    // if (!formData.profileImage) {
    //   formErrors.profileImage = "Image is required";
    // }

    if (!formData.description) {
      formErrors.description = "Content is required";
    }

    // if (!formData.metaDataDescription) {
    //   formErrors.metaDataDescription = "Meta Data Description is required";
    // }

    // if (!formData.metaKeyword) {
    //   formErrors.metaKeyword = "Meta Keyword is required";
    // }

    // if (!formData.metaTitle) {
    //   formErrors.metaTitle = "Meta Title is required";
    // }

    const encodedURIContent = encodeURIComponent(formData.description);
    const encodedContent = window.btoa(encodedURIContent);

    // const encodedContent = window.btoa(formData.description);

    if (Object.keys(formErrors).length === 0) {
      try {
        const formDataWithEncodedContent = {
          ...formData,
          description: encodedContent,
        };

        const response = await editContentById(
          contentData?._id,
          formDataWithEncodedContent
        );

        if (response.success == true) {
          setFormData({
            title: "",
            type: "blog",
            slug: "",
            status: "status",
            profileImage: "",
            shortDescription: "",
            description: "",
            metaTitle: "",
            metaDataDescription: "",
            metaKeyword: "",
            publishdate: "",
            authorName: "",
          });
          setErrors({});

          toast.success("Blog Updated Successfully!", {
            position: "top-center",
          });
          setFormReset(true);
          setTimeout(() => {
            navigate("/content-list");
          }, 3000);
        } else {
          toast.error(`${response?.error?.message}`, {
            position: "top-center",
          });
        }
      } catch (error) {
        console.error("Error:", error);

        toast.error("Failed to update blog. Please try again later.", {
          position: "top-center",
        });
      }
    } else {
      setErrors(formErrors);
    }
  };

  const handleAddImage = (imageUrl) => {
    const quill = quillRef.current.editor;

    quill.insertEmbed(quill.getSelection(true).index, "image", imageUrl);
    setShowAddImageOverlay(false);
  };

  const handleAddProfileImage = (imageUrl, imageId) => {
    setShowProfileImage(imageUrl);
    setContentData((prevData) => ({
      ...prevData,
      profileImage: { _id: imageUrl, url: imageUrl },
    }));
    setFormData((prevData) => ({
      ...prevData,
      profileImage: imageId,
    }));
  };

  const handleCloseOverlay = () => {
    setShowAddImageOverlay(false);
  };

  const renderFormFields = () => {
    const fields = config.cmsSettings.blog.fields;

    return Object.keys(fields).map((fieldName, index) => {
      const field = fields[fieldName];
      const { label, type, required, options } = field;

      switch (type) {
        case "text":
          return (
            <div key={index} className="mb-6 max-w-5xl relative">
              <label htmlFor={fieldName} className="mb-2 block text-gray-700">
                {label}
                {required && <span className="text-red-500">*</span>} :
              </label>
              <InputBox
                width="max-w-5xl"
                placeholder={`Enter ${label}`}
                value={formData[fieldName]}
                onChange={(e) => handleChange(fieldName, e.target.value)}
              />
              {errors[fieldName] && (
                <p className="text-red-500 text-sm absolute">
                  {errors[fieldName]}
                </p>
              )}
            </div>
          );
        case "select":
          return (
            <div key={index} className="mb-6 max-w-sm relative">
              <label htmlFor={fieldName} className="mb-2 block text-gray-700">
                {label}
                {required && <span className="text-red-500">*</span>} :
              </label>
              <select
                id={fieldName}
                value={formData[fieldName]}
                onChange={(e) => handleChange(fieldName, e.target.value)}
                className={`w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                  errors[fieldName] ? "border-red-500" : ""
                }`}
              >
                <option value="">Select {label}</option>
                {options.map((option, index) => (
                  <option key={index} value={option}>
                    {option.charAt(0).toUpperCase() + option.slice(1)}
                  </option>
                ))}
              </select>
              {errors[fieldName] && (
                <p className="text-red-500 text-sm absolute">
                  {errors[fieldName]}
                </p>
              )}
            </div>
          );
        case "richText":
          return (
            <div key={index} className="mb-16 max-w-5xl relative">
              <label htmlFor={fieldName} className="block text-gray-700">
                {label}
                {required && <span className="text-red-500">*</span>} :
              </label>
              <ReactQuill
                ref={quillRef}
                id={fieldName}
                value={formData[fieldName]}
                modules={modules}
                formats={formats}
                onChange={handleContentChange}
                placeholder={`Enter ${label}`}
                className={`form-textarea mt-1 block w-full ${
                  errors[fieldName] ? "border-red-500" : ""
                }`}
                style={{ height: "400px" }}
              />
              {errors[fieldName] && (
                <p className="text-red-500 text-sm mt-1">{errors[fieldName]}</p>
              )}
            </div>
          );
        case "textarea":
          return (
            <div key={index} className="mb-6 max-w-5xl relative">
              <label htmlFor={fieldName} className="mb-2 block text-gray-700">
                {label}
                {required && <span className="text-red-500">*</span>} :
              </label>
              <textarea
                id={fieldName}
                value={formData[fieldName]}
                onChange={(e) => handleChange(fieldName, e.target.value)}
                placeholder={`Enter ${label}`}
                className={`w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                  errors[fieldName] ? "border-red-500" : ""
                }`}
                rows={fieldName === "shortDescription" ? 4 : undefined}
              />
              {errors[fieldName] && (
                <p className="text-red-500 text-sm absolute">
                  {errors[fieldName]}
                </p>
              )}
            </div>
          );
        case "button":
          if (label === "Add Profile Image") {
            return (
              <div key={index}>
                <div
                  id="profileImage"
                  onClick={handleImageChange}
                  className={`w-fit mb-4 bg-blue-100 px-4 py-2 border border-gray-300 rounded-md cursor-pointer focus:outline-none focus:border-blue-400`}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="text-gray-500 mr-2"
                  />
                  Update Profile Image
                </div>
                {contentData && (
                  <img
                    src={contentData?.profileImage?.url}
                    className="mb-2"
                    style={{ maxWidth: "150px", maxHeight: "150px" }}
                  />
                )}
              </div>
            );
          } else {
            return (
              <div key={index} className="mb-6 max-w-5xl relative">
                <button
                  type="button"
                  onClick={handleContentAddImage}
                  className={`w-fit mb-4 bg-blue-100 px-4 py-2 border border-gray-300 rounded-md cursor-pointer focus:outline-none focus:border-blue-400`}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="text-gray-500 mr-2"
                  />
                  {label}
                </button>
              </div>
            );
          }
        default:
          return null;
      }
    });
  };

  return (
    <>
      <div className="flex">
        <div className="flex-grow">
          <div className="max-w-10xl">
            <div>
              <form onSubmit={handleSubmit}>
                <div className="max-w-8xl">
                  <div className="shadow-md p-4">
                    {renderFormFields()}
                    <div>
                      <button
                        type="button"
                        onClick={handleCancel}
                        className=" bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] mt-10 hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className=" bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] mt-10 hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
                <ToastContainer position="top-center" autoClose={3000} />
              </form>
            </div>
          </div>
        </div>
      </div>
      {showAddImageOverlay && (
        <div className="modal-overlay" onClick={handleCloseOverlay}>
          <div className="modal" onClick={(e) => e.stopPropagation()}>
            <button onClick={handleCloseOverlay}>Close</button>
            <AddImagePopUp
              onAddImage={handleAddImage}
              onAddProfileImage={handleAddProfileImage}
              onClose={handleCloseOverlay}
              popupContext={popupContext}
            />
          </div>
        </div>
      )}
    </>
  );
}
