import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';


export default function TypeSelectDropdown({ label, options, selectedValue, onChange, disabled }) {
  return (
    <div className="relative">
        <label className="block mb-1 mt-0 text-sm font-medium text-gray-700">{label} :</label>
        <select
            value={selectedValue}
            onChange={(e) => onChange(e.target.value)}
            className="block appearance-none w-48 bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded-lg shadow leading-tight focus:outline-none focus:shadow-outline"
            disabled={disabled}
        >
            <option value="">{`Select ${label}`}</option>
            {options.map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 pt-6 text-gray-700">
            <FontAwesomeIcon icon={faAngleDown} />
        </div>
    </div>
  )
}
