import React, { useState, useEffect} from 'react';
import './AddRolePopUp.css';
import { fetchUserRoleList } from '../../services/apis/userRole';
import { getUserById, getUserList, updateUserById } from '../../services/apis/user';
import { ToastContainer, toast } from 'react-toastify';


export default function AddRolePopUp({ userId, onClose, onUpdateUserList }) {

    const [userRoles, setUserRoles] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userRoleList = await fetchUserRoleList();
                const data = userRoleList?.data?.results;
                const roles = data?.map(role => ({
                    _id: role._id,
                    name: role.name
                }));
                setUserRoles(roles);

                const userDetails = await getUserById(userId);
                if (userDetails && userDetails.success && userDetails.result.roles) {
                    const userRoleIds = userDetails.result.roles.map(role => role._id);
                    setSelectedRoles(userRoleIds);
                }
            } catch (error) {
                console.error('Error fetching user roles:', error);
            }
        };

        fetchData();
    }, []);


    const handleRoleChange = (event) => {
        const roleId = event.target.value;
        
        if (event.target.checked) {
            setSelectedRoles([...selectedRoles, roleId]);
        } else {
            setSelectedRoles(selectedRoles.filter(role => role !== roleId));
        }
    };

    const handleSave = async () => {
        try {
            await updateUserById(userId, { roles: selectedRoles });
            const userList = await getUserList();
            const userListUpdatedData = userList?.data?.results;
            onUpdateUserList(userListUpdatedData);
            onClose();
        } catch (error) {
            console.error('Error updating user roles:', error);
        }
    };

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <h2>Select Role</h2>
                <form>
                    <ul>
                    <h3 className='mb-3'>Roles :</h3>
                        {userRoles?.map(role => (
                            <li key={role._id}>
                                <label>
                                    <input
                                        type="checkbox"
                                        value={role._id}
                                        checked={selectedRoles.includes(role._id)}
                                        onChange={handleRoleChange}
                                    /> {role.name}
                                </label>
                            </li>
                        ))}
                    </ul>
                    <div className="p-4 flex gap-8 justify-center">
                        <button 
                            type="button" 
                            className=" bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] mt-10 hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4  mr-4"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button 
                            type="button" 
                            className=" bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] mt-10 hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4  mr-4" 
                            onClick={handleSave}
                        >
                            Save
                        </button>
                        
                    </div>
                </form>
                <ToastContainer position="top-center" autoClose={3000} />
            </div>
        </div>
    );
}
