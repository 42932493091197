import React, { useEffect, useState } from "react";
import PageTitleCard from "../../../components/pageTitleCard/PageTitleCard";
import Sidebar from "../../../components/sidebar/Sidebar";
import SearchBar from "../../../components/searchBar/SearchBar";
import SearchById from "../../../components/searchById/SearchById";
import ResetButton from "../../../components/resetButton/ResetButton";
import PowerPlantTable from "../powerPlant/PowerPlantTable";
import config from "../../../config/config";
import AddPowerPlantDetails from "./AddPowerPlantDetails";
import EditPowerPlantDetails from "./EditPowerPlantDetails";
import {
  deletePowerPlantById,
  fetchPowerPlantList,
} from "../../../services/apis/powerPlant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import SelectDropdown from "../../../components/selectDropdown/SelectDropdown";
import { fetchStateList } from "../../../services/apis/listings";
import RecordsPerPageDropdown from "../../../components/recordsPerPageDropdown/RecordsPerPageDropdown";
import PowerPlantUnitListing from "../powerPlantUnit/PowerPlantUnitListing";
import RecommendationsTable from "../recommendationsTable.js/RecommendationsTable";
import DeleteConfirmation from "../../../components/deleteConfirmation/DeleteConfirmation";
import AddConstant from "./AddConstant";
import FilterDropdown from "../../../components/filterDropdown/FilterDropdown";
import { makeFinancialYear, makeYearFormate } from "../constantVariable/getFY";

export default function PowerPlantListing() {

  const capitalizeWords = (str) => {
    return str
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before capital letters
      .split(' ') // Split the string into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' '); // Join the words back together with a space
  };

  const { powerPlantListHeaders } = config;
  const powerplantTypes = ["solar", "wind", "hydroelectric", "nuclear", "coal", "naturalGas", "biomass"]

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editId, setEditId] = useState("");
  const [updateCount, setUpdateCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [states, setStates] = useState([]);
  const [stateId, setStateId] = useState("");
  const [searchCityQuery, setSearchCityQuery] = useState("");
  const [searchDistrictQuery, setSearchDistrictQuery] = useState("");
  const [searchAddressQuery, setSearchAddressQuery] = useState("");
  const [searchById, setSearchById] = useState("");
  const [tableData, setTableData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "_id",
    direction: "asc",
  });
  const [limit, setLimit] = useState(() => {
    const storedLimit = parseInt(localStorage.getItem('recordsPerPage'), 10);
    return storedLimit || 50;
  });
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [stateFilter, setStateFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");

  const [deleteModalOpen, setDeleteModalOpen] = useState(false); 
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [deleteItemName, setDeleteItemName] = useState("");
  const [addConstantPopup, setAddConstantPopup] = useState(false)
  const [financialYear,setFinancialYear] = useState("2024")
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    const getStateList = async () => {
      const response = await fetchStateList();
      const stateList = response?.data?.results;

      if (stateList) {
        // Sort the state list alphabetically by state name
        const sortedStateList = stateList.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setStates(sortedStateList);
      }
    };
    getStateList();
  }, []);

  useEffect(() => {
    const getPowerPlantList = async () => {
      setLoader(true)
      const payload = {
        page: currentPage,
        pageSize: limit,
        name: searchQuery,
        city: searchCityQuery,
        district: searchDistrictQuery,
        address: searchAddressQuery,
        id: searchById,
        sortBy: sortConfig.key,
        sortOrder: sortConfig.direction,
        stateId: stateFilter,
        type: typeFilter,
        financialYear: financialYear
      };
      const response = await fetchPowerPlantList(payload);
      const data = response?.data?.data?.results;

      setTableData(data);
      setTotalCount(response?.data?.data?.total);
      setLoader(false)
    };
    getPowerPlantList();
  }, [
    updateCount,
    searchById,
    searchAddressQuery,
    searchCityQuery,
    searchDistrictQuery,
    searchQuery,
    currentPage,
    sortConfig,
    stateFilter,
    limit,
    typeFilter,
    financialYear
  ]);

  const handleAddButtonClick = () => {
    setIsFormOpen(true);
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
  };

  const handleEditCloseForm = () => {
    setEditFormOpen(false);
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleCitySearch = (event) => {
    const query = event.target.value;
    setSearchCityQuery(query);
    setCurrentPage(1);
  };

  const handleDistrictSearch = (event) => {
    const query = event.target.value;
    setSearchDistrictQuery(query);
    setCurrentPage(1);
  };

  const handleAddressSearch = (event) => {
    const query = event.target.value;
    setSearchAddressQuery(query);
    setCurrentPage(1);
  };

  const handleSearchById = (event) => {
    const id = event.target.value;
    setSearchById(id);
    setCurrentPage(1);
  };

  const handleResetFilters = () => {
    setSearchById("");
    setSearchQuery("");
    setSearchCityQuery("");
    setSearchDistrictQuery("");
    setSearchAddressQuery("");
    setStateFilter("");
    setTypeFilter("");
    setFinancialYear("2024")
  };

  const handleEdit = (id) => {
    setEditFormOpen(true);
    setEditId(id);
  };

  const handleAddNewConstant =(id) =>{
    setAddConstantPopup(true)
    setEditId(id)
  }

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    setCurrentPage(1);
  };

  // const handleDelete = async (id) => {
  //   confirmAlert({
  //     title: "",
  //     closeOnClickOutside: false,
  //     message: `Are you sure you want to delete this Powerplant Details?`,
  //     buttons: [
  //       {
  //         label: "Yes",
  //         onClick: async () => {
  //           const response = await deletePowerPlantById(id);

  //           if (response.data.data.length == 0) {
  //             toast.error(
  //               `Failed to delete the Powerplant. Please try again later.`,
  //               {
  //                 position: "top-center",
  //                 autoClose: 1500,
  //               }
  //             );
  //           } else {
  //             toast.success(`Powerplant deleted successfully!`, {
  //               position: "top-center",
  //               autoClose: 1500,
  //             });
  //             setUpdateCount((prevCount) => prevCount + 1);
  //           }
  //         },
  //       },
  //       {
  //         label: "No",
  //         onClick: () => {
  //           // Do nothing
  //         },
  //       },
  //     ],
  //   });
  // };

  const handleDelete = (id, name) => {
    setDeleteModalOpen(true);
    setDeleteItemId(id);
    setDeleteItemName(name);
  };

  const confirmDelete = async () => {
      const response = await deletePowerPlantById(deleteItemId);

      if (response?.data?.data?.length === 0) {
          toast.error('Failed to delete the Power Plant. Please try again later.', {
              position: "top-center",
              autoClose: 1500,
          });
      } else {
          toast.success('Power Plant deleted successfully!', {
              position: "top-center",
              autoClose: 1500,
          });
          setUpdateCount((prevCount) => prevCount + 1);
      }
      setDeleteModalOpen(false);
  };

  const handleStateChange = (selectedState) => {
    setStateId(selectedState);
    if (selectedState) {
      setStateFilter(selectedState);
    }
  };

  const handleTypeChange = (selectedType) => {
    if (selectedType) {
      setTypeFilter(selectedType);
    }
  };

  const handleRecordsPerPageChange = (newLimit) => {
    setLimit(newLimit);
    setCurrentPage(1);
  };

  const handleCloseaddConstant =()=>{
    setAddConstantPopup(false)
  }

  return (
    <div className="flex w-full">
      <Sidebar />
      <div className="overflow-x-auto p-4">
        <div className="max-w-[1920px]">
          <PageTitleCard
            title="Power Plants List"
            onAddButtonClick={handleAddButtonClick}
            addLinkText="Add New Power Plant Details"
          />
          <div className="">
            <div className="overflow-hidden mt-2 rounded-xl ">
              <div className="flex">
                <div className="p-3 flex flex-wrap items-center justify-start gap-4 space-y-4 md:space-y-0 md:flex-row">
                  <SearchBar
                    searchQuery={searchQuery}
                    handleSearch={handleSearch}
                    title="Name"
                  />
                  <SearchBar
                    searchQuery={searchAddressQuery}
                    handleSearch={handleAddressSearch}
                    title="Address"
                  />
                  <SearchBar
                    searchQuery={searchCityQuery}
                    handleSearch={handleCitySearch}
                    title="City Name"
                  />
                  <SearchBar
                    searchQuery={searchDistrictQuery}
                    handleSearch={handleDistrictSearch}
                    title="District Name"
                  />
                  <SearchById
                    filterById={searchById}
                    handleFilterById={handleSearchById}
                  />
                  <SelectDropdown
                    label="State"
                    selectedValue={stateFilter}
                    onChange={(e) => handleStateChange(e.target.value)}
                    options={states?.map((state) => {
                      return { label: state?.name, value: state?._id };
                    })}
                  />
                  <SelectDropdown
                    label="Powerplant Type"
                    selectedValue={typeFilter}
                    onChange={(e) => handleTypeChange(e.target.value)}
                    options={powerplantTypes?.map((powerplant) => {
                      return { label: capitalizeWords(powerplant), value: powerplant };
                    })}
                  />
                  <RecordsPerPageDropdown onRecordsPerPageChange={handleRecordsPerPageChange} />
                  <FilterDropdown
                    label="FY"
                    options={makeFinancialYear()}
                    selectedValue={makeYearFormate(financialYear)}
                    onChange={(value) => setFinancialYear(value.split("-")[0])}
                  />
                </div>
                <div className="p-3 mt-[60px] mr-2 reset-button-container">
                  <ResetButton onClick={handleResetFilters} />
                </div>
              </div>
              <div className='bg-white p-2 rounded-xl min-h-[350px]'>
                {/* <RecommendationsTable */}
                <PowerPlantTable
                  loader = {loader}
                  fy={financialYear}
                  headings={powerPlantListHeaders}
                  data={tableData}
                  handleEdit={(id) => handleEdit(id)}
                  handleDelete={(id, name) => handleDelete(id, name)}
                  handleSort={handleSort}
                  sortConfig={sortConfig}
                  title="Edit"
                  handleAddNewConstant={(id)=>handleAddNewConstant(id)}
                />
              </div>
            </div>
            {totalCount > limit && (
              <div className="pagination-controls bg-white rounded-b-xl border-[#abaaaa] mb-2 pb-2 flex flex-row items-center justify-end pt-8 -mt-2">
                  <button className={`bg-[#e1e0e0] ${currentPage === 1 ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl`} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                  <span className="mx-4">{Math.min(currentPage * limit, totalCount) ? Math.min(currentPage * limit, totalCount) : '0'} off {totalCount ? totalCount : '0'}</span>
                  <button className={`bg-[#e1e0e0] ${currentPage * limit >= totalCount ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl mr-2`} disabled={currentPage * limit >= totalCount} onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
              </div>
            )}
          </div>
        </div>
        {isFormOpen && (
          <AddPowerPlantDetails
            onClose={handleCloseForm}
            setUpdateCount={setUpdateCount}
            setIsFormOpen={setIsFormOpen}
          />
        )}
        {editFormOpen && (
          <EditPowerPlantDetails
            onClose={handleEditCloseForm}
            editId={editId}
            setUpdateCount={setUpdateCount}
            setEditFormOpen={setEditFormOpen}
          />
        )}
        {addConstantPopup && (
          <AddConstant
            fy={financialYear}
            data={tableData}
            onClose={handleCloseaddConstant}
            addId={editId}
            setUpdateCount={setUpdateCount}
          />
        )}
        <ToastContainer position="top-center" autoClose={3000} />
        <DeleteConfirmation
            isOpen={deleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            onConfirm={confirmDelete}
            itemName={deleteItemName}
        />
      </div>
    </div>
  );
}
