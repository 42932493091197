import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEdit,
  faTrash,
  faPlus,
  faLocationArrow,
  faSquarePlus,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

export default function AddEditViewButtons({ handleAdd, handleEdit, handleView, handleMapPowerPlantOpen, title, rt=false,handleRTArea }) {
  return (
    <div className="flex flex-row">
        <button className="mx-1 mr-3 p-1 hover:bg-gray-200 hover:rounded-md" id="add" onClick={handleAdd}>
            <FontAwesomeIcon icon={faEdit} className="mr-1" />{title ? `Update ${title}` : ""}
            <Tooltip anchorSelect="#add" content={`Update ${title}`} />
        </button>
        {/* <button className="mx-1" onClick={handleEdit}>
            <FontAwesomeIcon id="edit" icon={faEdit} />
            <Tooltip anchorSelect="#edit" content={`Edit ${title}`} />
        </button> */}
        <button className="mx-1 mr-3 p-1 hover:bg-gray-200 hover:rounded-md" id="view" onClick={handleView}>
            <FontAwesomeIcon icon={faEye} className="mr-1"/>{title ? `View ${title}` : ""}
            <Tooltip anchorSelect="#view" content={`View ${title}`} />
        </button>
        <button className="mx-1 p-1 hover:bg-gray-200 hover:rounded-md" id="map" onClick={handleMapPowerPlantOpen}>
            <FontAwesomeIcon icon={faPlusCircle} className="mr-1"/>Map Power Plant
            <Tooltip anchorSelect="#map" content={`Map Power Plant`} />
        </button>
        {rt &&<button className="mx-1 mr-3 p-1 hover:bg-gray-200 hover:rounded-md" id="rtsadd" onClick={handleRTArea}>
            <FontAwesomeIcon icon={faEdit} className="mr-1" />Add rooftop area
            <Tooltip anchorSelect="#rtsadd" content={`Add rooftop area`} />
        </button>}
    </div>
  );
}

