import LegalUpdatesList from "../dummyData/legalUpdatesList";
// const api = process.env.REACT_APP_API_URL;

export const getLegalUpdatesListData = () => {
    try{
        return LegalUpdatesList;
    } catch (e) {
        console.log(e);
    }
}

export const getLegalUpdateById = (legalUpdateId) => {
    try {
        const legalUpdatesList = getLegalUpdatesListData();
        const legalUpdate = legalUpdatesList.find(legalUpdate => legalUpdate._id == legalUpdateId);
        return legalUpdate;
    } catch (error) {
        console.error(error);
        return null;
    }
}