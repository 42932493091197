import React, { useState } from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import PageTitleCard from '../../components/pageTitleCard/PageTitleCard'
import TypeSelectDropdown from '../../components/typeSelectDropdown/TypeSelectDropdown'
import AddBlog from '../addBlog/AddBlog';
import AddArticle from '../addArticle/AddArticle';
import AddCareer from '../addCareer/AddCareer';
import AddPage from '../addPage/AddPage';
import AddSolution from '../addSolution/AddSolution';
import AddFAQs from '../addFAQs/AddFAQs';
import AddHomePage from '../addHomePage/AddHomePage';

export default function AddCMS() {

    const types = ["Blog", "Article", "Solution", "Career", "Page", "Home Page", "FAQs"];
    const [selectedType, setSelectedType] = useState('');

    const handleTypeChange = (value) => {
        setSelectedType(value);
    }

    const renderComponentByType = () => {
        switch (selectedType) {
            case 'Blog':
                return <AddBlog />;
            case 'Article':
                return <AddArticle />;
            case 'Solution':
                return <AddSolution />;
            case 'Career':
                return <AddCareer />;
            case 'Page':
                return <AddPage />;
            case 'FAQs':
                return <AddFAQs />;
            case 'Home Page':
                return <AddHomePage />;
            default:
                return null;
        }
    };

    return (
        <div className="flex">
            <Sidebar />
            <div className="flex-grow p-4 overflow-auto">
                <div className="max-w-8xl">
                    <PageTitleCard title="Add New Content" />
                    <div className="bg-white shadow-md overflow-hidden mt-4 rounded-xl">
                        <div className="p-4 flex flex-wrap items-center justify-between space-y-4 md:space-y-0 md:flex-row">
                            <TypeSelectDropdown
                                label="Type"
                                options={types}
                                selectedValue={selectedType}
                                onChange={handleTypeChange}
                            />
                        </div>
                        {renderComponentByType()}
                    </div>
                </div>
            </div>
        </div>
    )
}
