import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../components/sidebar/Sidebar";
import { addNewPolicy, getStateById, getStateByStateCode } from "../../../services/apis/listings";
import { editStateId } from "../../../services/apis/content";
import { ToastContainer, toast } from "react-toastify";
import BackButton from "../../../components/backButton/BackButton";
import FilterDropdown from "../../../components/filterDropdown/FilterDropdown";
import { makeFinancialYear, makeYearFormate } from "../constantVariable/getFY";
import CloneFYpop from "./CloneYearPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const validatePayload = (payload) => {
    const errors = [];
    
    if (typeof payload.todIncentive !== 'object' || payload.todIncentive === null) {
        errors.push("Invalid format for todIncentive data");
        return errors;
    }

    for (const year of Object.keys(payload.todIncentive)) {
        const incentives = payload.todIncentive[year];
        
        if (!Array.isArray(incentives)) {
            errors.push(`Incentives data for year ${year} is not an array`);
            continue;
        }

        const hasAtLeastOneTOD = incentives.some(incentive => incentive.tod);

        if (!hasAtLeastOneTOD) {
            errors.push(`At least one ToD slot must be filled for the year ${year}`);
        }

        if (errors.length > 0) {
            break;
        }
    }

    return errors;
};

export default function TodIncentives() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [todIncentiveData, setTodIncentiveData] = useState([]);
    const [todIncentiveDataYT, setTodIncentiveDataYT] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [stateName, setStateName] = useState("");
    const [stateCode, setStateCode] = useState("");
    const [noOfTodSlots, setNoOfTodSLots] = useState(1);
    const [validationErrors, setValidationErrors] = useState([]);
    const [financialYear,setFinancialYear] =useState("2024")
    const [clonePopup, setClonePopup] = useState(false);

    const todSlots = Array.from({ length: noOfTodSlots }, (_, index) => `ToD ${index + 1}`);

    useEffect(() => {
        const getStateList = async () => {
            setIsLoading(true);
            const response = await getStateById(id);
            setStateName(response.data.name);
            setStateCode(response.data.code);
            setNoOfTodSLots(response.data.noOfTodSlots);
            const data = generateDefaultData(response?.data?.todIncentive);
            setTodIncentiveData(data);
            setIsLoading(false);
        };
        getStateList();
    }, [id]);

    useEffect(() => {
        setTodIncentiveDataYT(todIncentiveData[financialYear])
    },[todIncentiveData,financialYear])

    // const generateDefaultData = (data) => {
    //     // let result
    //     const defaultData = [];
    //     for (let hour = 0; hour < 24; hour++) {
    //         for (let minutes = 0; minutes < 60; minutes += 15) {
    //             const hourString = hour.toString().padStart(2, "0");
    //             const minuteString = minutes.toString().padStart(2, "0");
    //             defaultData.push({ hours: `${hourString}:${minuteString}`, todName: "", tod: "", incentive: 0.0 });
    //         }
    //     }
    //     // for( year 2021, 2022, 2023, 2024){
    //     //     result.2021=defaultData. ..
    //     // }
    //     // data is like result but it will have less data i want merge them with out losing the data
    //     return defaultData;
    // };
    const generateDefaultData = (data) => {
        const defaultData = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minutes = 0; minutes < 60; minutes += 15) {
                const hourString = hour.toString().padStart(2, "0");
                const minuteString = minutes.toString().padStart(2, "0");
                defaultData.push({ hours: `${hourString}:${minuteString}`, todName: "", tod: "", incentive: 0.0 });
            }
        }
    
        const result = {};
        const years = [2021, 2022, 2023, 2024];
        years.forEach(year => {
            const yearData = [...defaultData];
            if (data && data[year]) {
                data[year].forEach(existingItem => {
                    const index = yearData.findIndex(item => item.hours === existingItem.hours);
                    if (index !== -1) {
                        yearData[index] = { ...yearData[index], ...existingItem };
                    }
                });
            }
            result[year] = yearData;
        });
    
        return result;
    };

    const handleTODChange = (index, value) => {
        const updatedData = [...todIncentiveDataYT];
        updatedData[index].tod = value;
        setTodIncentiveDataYT(updatedData);
    };

    const handleSave = async () => {
        try {
            const payload = {
                code: stateCode,
                todIncentive: {...todIncentiveData, [financialYear]:todIncentiveDataYT},
            };

            const validationErrors = validatePayload(payload);

            if (validationErrors.length > 0) {
                validationErrors.forEach((error) => {
                    toast.error(error, {
                        position: "top-center",
                    });
                });
                return;
            }

            const response = await editStateId(payload);

            toast.success(`${response.message}`);
            setTimeout(() => {
                navigate('/recommendations/tod-banking-policy')
            }, 3000);
        } catch (error) {
            console.error("Error saving TOD Incentives:", error);
            toast.error('Failed to update TOD Incentives');
        }
    };


    const handleCancel = () => {
        navigate('/recommendations/tod-banking-policy');
    };

    const handleFilterChange =(value)=>{
        setFinancialYear((value.split("-")[0]));
    }

    return (
        <div className="flex w-[100%]">
            <Sidebar />
            <div className="w-full p-4">
                <ToastContainer position="top-center" autoClose={3000} />
                <div className="flex-grow mb-1">
                    <div className="bg-white shadow-md overflow-hidden rounded-xl">
                        <div className="p-4 mt-1 bg-white text-black flex flex-wrap justify-between items-center">
                            <h2 className="text-lg font-semibold">{`ToD Incentives for ${stateName}`}</h2>
                            <div className="flex justify-end">
                                <FilterDropdown
                                    label="FY"
                                    options={makeFinancialYear()}
                                    selectedValue={makeYearFormate(financialYear) }
                                    onChange={handleFilterChange}
                                />
                            </div>
                            <div className="flex justify-end gap-4">
                                <button
                                    className="gap-2 bg-[#a9dbc3] overflow-hidden text-black p-2 rounded-md hover:bg-[#daf3e3] hover:text-black hover:px-2 hover:rounded-md flex justify-between items-center"
                                    onClick={()=>setClonePopup(true)}
                                    >
                                    Clone ToD Incentives
                                </button>
                                <BackButton/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-4 relative bg-white overflow-x-auto overflow-y-auto shadow-md sm:rounded-lg rounded-lg state-list">
                    <table className="w-full text-sm text-left rtl:text-right text-black dark:text-gray-400">
                        <thead className="text-xs uppercase text-black bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-4 cursor-pointer w-[25%] bg-gray-100 rounded-tl-xl rounded-bl-xl">Hours</th>
                                <th scope="col" className="px-6 py-4 cursor-pointer w-[25%] bg-gray-100">ToD</th>
                                <th scope="col" className="px-6 py-4 w-[25%] bg-gray-100">ToD Name</th>
                                <th scope="col" className="px-6 py-4 w-[25%] bg-gray-100 rounded-tr-xl rounded-br-xl">Incentive</th>
                            </tr>
                        </thead>
                        <tbody>
                            {todIncentiveData[financialYear]?.map((policy, index) => (
                                <tr key={index}>
                                    <td className="px-6 py-1 text-black">
                                        <input
                                            type="text"
                                            value={policy.hours}
                                            onChange={(e) => {
                                                const updatedData = [...todIncentiveDataYT];
                                                updatedData[index].hours = e.target.value;
                                                setTodIncentiveDataYT(updatedData);
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <select
                                            value={policy.tod}
                                            onChange={(e) => handleTODChange(index, e.target.value)}
                                            className="px-3 appearance-none w-full bg-white shadow focus:outline-none focus:shadow-outline"
                                        >
                                            <option value="">Select ToD</option>
                                            {todSlots.map((slot) => (
                                                <option key={slot} value={slot}>{slot}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td className="px-4 py-1 text-black">
                                        <input
                                            type="text"
                                            value={policy.todName}
                                            className="px-3 w-full bg-white shadow-outline shadow"
                                            placeholder="Add Slot Name"
                                            onChange={(e) => {
                                                const updatedData = [...todIncentiveDataYT];
                                                updatedData[index].todName = e.target.value;
                                                setTodIncentiveDataYT(updatedData);
                                            }}
                                        />
                                    </td>
                                    <td className="px-4 py-1 text-black">
                                        <input
                                            type="number"
                                            value={policy.incentive}
                                            className="px-3 w-full bg-white shadow-outline shadow"
                                            placeholder="Add Incentive"
                                            onChange={(e) => {
                                                const updatedData = [...todIncentiveDataYT];
                                                updatedData[index].incentive = parseFloat(e.target.value);
                                                setTodIncentiveDataYT(updatedData);
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {validationErrors.length > 0 && (
                        <div className="mt-4 absolute text-red-600 right-4">
                            {validationErrors.map((error, index) => (
                                <p key={index}>{error}</p>
                            ))}
                        </div>
                    )}
                    <div className="flex justify-center gap-8 mt-12">
                        <button
                            onClick={handleCancel}
                            className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleSave}
                            className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
            {clonePopup && <CloneFYpop label={"ToD Incentives"} stateId={id} onClose={()=>setClonePopup(false)} setUpdateCount ={()=>{}}/>}  
        </div>
    );
}
