import React from 'react';

export default function ResetButton({ onClick }) {
    const handleReset = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <button onClick={handleReset} className="text-black bg-[#a9dbc3] w-[140px] px-4 py-2 rounded-md leading-tight">
            Reset Filters
        </button>
    );
}
