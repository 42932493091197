import React, { useEffect, useState } from 'react'
import config from '../../../config/config';
import Sidebar from '../../../components/sidebar/Sidebar'
import PageTitleCard from '../../../components/pageTitleCard/PageTitleCard'
import SearchBar from '../../../components/searchBar/SearchBar'
import SearchById from '../../../components/searchById/SearchById'
import FilterDropdown from '../../../components/filterDropdown/FilterDropdown'
import RecordsPerPageDropdown from '../../../components/recordsPerPageDropdown/RecordsPerPageDropdown'
import ResetButton from '../../../components/resetButton/ResetButton'
import GlobalVariablesTable from '../globalVariables/GlobalVariablesTable'
import { confirmAlert } from 'react-confirm-alert';
import { deleteGlobalVariableById } from '../../../services/apis/globalRecommendationVariable';
import { ToastContainer, toast } from 'react-toastify';
import { deletePowerPlantTypeById, fetchPowerPlantsTypesList } from '../../../services/apis/powerPlantType';
import AddPowerPlantType from './AddPowerPlantType';
import EditPowerPlantType from './EditPowerPlantType';
import PowerPlantTypeTable from './PowerPlantTypeTable';
import RecommendationsTable from '../recommendationsTable.js/RecommendationsTable';
import DeleteConfirmation from '../../../components/deleteConfirmation/DeleteConfirmation';

export default function PowerPlantTypeListing() {

    const { powerPlantTypeListHeaders } = config;
    const statuses = ["active", "inactive"]; 

    const [isFormOpen, setIsFormOpen] = useState(false);
    const [editFormOpen, setEditFormOpen] = useState(false);
    const [editId, setEditId] = useState("");
    const [searchQuery, setSearchQuery] = useState('');
    const [searchById, setSearchById] = useState("");
    const [tableData, setTableData] = useState([]);
    const [updateCount, setUpdateCount] = useState(1);
    const [statusFilter, setStatusFilter] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: "_id", direction: 'asc' });
    const [limit, setLimit] = useState(() => {
        const storedLimit = parseInt(localStorage.getItem('recordsPerPage'), 10);
        return storedLimit || 50;
    });
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);  // State for delete modal
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [deleteItemName, setDeleteItemName] = useState("");
    const [loader, setLoader] = useState(true)

    const getPowerPlantTypesList = async () => {
        setLoader(true)
        const payload = {
            page: currentPage,
            pageSize: limit,
            id: searchById,
            status: statusFilter,
            name: searchQuery,
            sortBy: sortConfig.key,
            sortOrder: sortConfig.direction,
        };
        const response = await fetchPowerPlantsTypesList(payload);
        const data = response?.data?.data?.results;
        setTableData(data);
        setTotalCount(response?.data?.data?.total)
        // console.log(data,'data')
        setLoader(false)
    };

    useEffect(() => {
        getPowerPlantTypesList();
    },[currentPage, statusFilter, sortConfig, searchQuery, searchById, updateCount, limit])

    const handleAddButtonClick = () => {
        setIsFormOpen(true);
    };

    const handleCloseForm = () => {
        setIsFormOpen(false);
    };

    const handleEditCloseForm = () => {
        setEditFormOpen(false);
    };

    const handleSearch = (event) =>{
        const query = event.target.value;
        setSearchQuery(query);
        setCurrentPage(1);
    }

    const handleSearchById = (event) => {
        const id = event.target.value;
        setSearchById(id);
        setCurrentPage(1);
    };

    const handleEdit = (id, objectType) => {
        setEditFormOpen(true);
        setEditId(id);
    };

    const handleDelete = (id, name) => {
        setDeleteModalOpen(true);
        setDeleteItemId(id);
        setDeleteItemName(name);
    };

    const confirmDelete = async () => {
        const response = await deletePowerPlantTypeById(deleteItemId);

        if (response?.data?.data?.length === 0) {
            toast.error('Failed to delete the Power Plant Type. Please try again later.', {
                position: "top-center",
                autoClose: 1500,
            });
        } else {
            toast.success('Power Plant Type deleted successfully!', {
                position: "top-center",
                autoClose: 1500,
            });
            setUpdateCount((prevCount) => prevCount + 1);
        }
        setDeleteModalOpen(false);
    };

    const handleFilterChange = (selectedStatus) => {
        if(selectedStatus){
            setStatusFilter(selectedStatus);
        }
        setCurrentPage(1);
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        setCurrentPage(1);
    };

    const handleResetFilters = () => {
        setSearchQuery('');
        setSearchById('');
        setStatusFilter('');
    };

    const handleRecordsPerPageChange = (newLimit) => {
        setLimit(newLimit);
        setCurrentPage(1); 
    };

    return (
        <div className="flex">
            <Sidebar />
            <div className="flex-grow p-4">
                <div className="max-w-[1920px]">
                    <PageTitleCard title="Power Plant Types List" onAddButtonClick={handleAddButtonClick} addLinkText="Add New Power Plant Type"/>
                    <div className=''>
                        <div className=" overflow-hidden mt-2 rounded-xl ">
                            <div className="flex">
                                <div className="p-3 flex flex-wrap items-center justify-start gap-4 space-y-4 md:space-y-0 md:flex-row">
                                    <SearchBar searchQuery={searchQuery} handleSearch={handleSearch} title="Power Plant Type"/>
                                    <SearchById filterById={searchById} handleFilterById={handleSearchById}/>
                                    <FilterDropdown
                                        label="Status"
                                        options={statuses} 
                                        selectedValue={statusFilter}
                                        onChange={(value) => handleFilterChange(value)}
                                    />
                                    <RecordsPerPageDropdown onRecordsPerPageChange={handleRecordsPerPageChange} />
                                </div>
                                <div className="flex-grow"></div>
                                <div className="p-3 mt-[5px] mr-2 reset-button-container">
                                    <ResetButton onClick={handleResetFilters} />
                                </div>
                            </div>
                            <div className='bg-white p-2 rounded-xl min-h-[350px]'>
                                <RecommendationsTable 
                                    loader={loader}
                                    headings={powerPlantTypeListHeaders} 
                                    data={tableData} 
                                    handleEdit={(id, type) => handleEdit(id, type)} 
                                    handleDelete={(id, varName) => handleDelete(id, varName)} 
                                    handleSort={handleSort} 
                                    sortConfig={sortConfig} 
                                    title="Edit" 
                                />
                            </div>
                            </div>
                            {totalCount > limit && (
                                <div className="pagination-controls bg-white rounded-b-xl border-[#abaaaa] mb-2 pb-2 flex flex-row items-center justify-end pt-8 -mt-2">
                                    <button className={`bg-[#e1e0e0] ${currentPage === 1 ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl`} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                    <span className="mx-4">{Math.min(currentPage * limit, totalCount) ? Math.min(currentPage * limit, totalCount) : '0'} off {totalCount ? totalCount : '0'}</span>
                                    <button className={`bg-[#e1e0e0] ${currentPage * limit >= totalCount ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl mr-2`} disabled={currentPage * limit >= totalCount} onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
                                </div>
                            )}
                        </div>
                </div>
                {isFormOpen && <AddPowerPlantType onClose={handleCloseForm} setIsFormOpen={setIsFormOpen} setUpdateCount={setUpdateCount}/>}
                {editFormOpen && <EditPowerPlantType onClose={handleEditCloseForm} editId={editId} setEditFormOpen={setEditFormOpen} setUpdateCount={setUpdateCount} />}
                <ToastContainer position="top-center" autoClose={3000} />
                <DeleteConfirmation
                    isOpen={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    onConfirm={confirmDelete}
                    itemName={deleteItemName}
                />
            </div>
        </div>
    )
}
