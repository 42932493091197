import React, { useEffect, useState } from 'react';
import { fetchUnitById, updateUnitById } from '../../../services/apis/listings';
import { toast } from 'react-toastify';

export default function RTPopup({id, onClose, setUpdateCount}) {

  const RSTOCunits=["Rs./kwh"]
  const units=["sq-ft","sq-mtr"]
  
  const [fromData, setFormData] = useState({
    RTSAreaValue: "",
    RTSAreaUnit: "", 

    RTSRequiredValue: "", 
    RTSRequiredUnit: "",

    RTSOperatingChargesValue: "", 
    RTSOperatingChargesUnit: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if(name === "RTSAreaUnit"){
      console.log("fromData.RTSAreaUnit",value)
      setFormData((prevFormData) => ({
        ...prevFormData,
        RTSRequiredUnit: value,
        RTSRequiredValue: "",
      }))
    }
  };

  useEffect(() => {
    const getData = async() => {
        const response =  await fetchUnitById(id)

        let { unit: rstunit, value: rstvalue } = response?.data?.result?.RTSArea || {};
        let { unit: rstocunit, value: rstocvalue } = response?.data?.result?.RTSOperatingCharges || {};
        let { unit: requiredperKWunit, value: requiredperKWvalue } = response?.data?.result?.areaParOnekW || {};

        console.log({ rstunit, rstvalue, requiredperKWunit, requiredperKWvalue },"------>")

        if(response?.data?.result){
          setFormData({
            RTSAreaValue: rstvalue,
            RTSAreaUnit: rstunit,
            RTSOperatingChargesValue: rstocvalue, 
            RTSOperatingChargesUnit: rstocunit,
            RTSRequiredValue: requiredperKWvalue,
            RTSRequiredUnit: requiredperKWunit??rstunit
          })
        }
    }
    getData()
  },[id])

  const handleSubmit = async(event) => {
    event.preventDefault();

    if (!fromData.RTSAreaUnit || !fromData.RTSAreaValue || fromData.RTSAreaValue==="") {
      toast.error("Please select RTS Value and Unit", {
          position: "top-center",
      });
      return;
    }
    
    if (!fromData.RTSRequiredValue || fromData.RTSRequiredValue === "") {
      toast.error("Please Enter area required for 1 kW", {
          position: "top-center",
      });
      return;
    }

    if (!fromData.RTSOperatingChargesUnit || !fromData.RTSOperatingChargesValue || fromData.RTSOperatingChargesValue==="") {
      toast.error("Please select RTS Operating Charges Value and Unit", {
          position: "top-center",
      });
      return;
    }

    try {
        const response = await updateUnitById(id, fromData);

        if (response?.success === false) {
            toast.error(`${response?.data?.message}`, {
                position: "top-center",
            });
        } else {
            toast.success(`${response?.data?.message}`, {
                position: "top-center",
            });
            onClose();
            setUpdateCount()
        }
    } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to add regulation. Please try again.", {
            position: "top-center",
        });
    }
  };
  
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
      <div className="bg-white p-4 shadow-lg w-full max-w-2xl max-h-[95vh] overflow-y-auto rounded-lg">
        <h2 className="text-lg font-semibold w-full text-center">Add RTS Values</h2>
        <form onSubmit={handleSubmit}>
          <div className="flex-grow overflow-auto">
            <div className="max-w-6xl">
                <div className="flex gap-4 flex-col bg-white shadow-md overflow-hidden p-4">
                    <div className="flex items-center gap-2">
                      <label className="flex text-gray-700 w-1/4 text-left pr-4">
                        Total Rooftop Area:
                      </label>
                      <input
                        type="number"
                        name='RTSAreaValue'
                        placeholder="Enter RTS Area"
                        className="block w-1/2 px-4 py-2 border border-gray-500 rounded-lg"
                        value={fromData.RTSAreaValue}
                        onChange={handleChange}
                      />
                      <select
                        id={"RTSAreaUnit"}
                        value={fromData.RTSAreaUnit}
                        onChange={handleChange}
                        name='RTSAreaUnit'
                        className={`w-1/4 px-3 py-2 border border-gray-400 rounded-md focus:outline-none`}
                      >
                        <option className='text-gray-400' value="">Select Unit</option>
                        {units.map((unit) => (
                          <option key={unit} value={unit}>{unit.split('-').join(' ')}</option>
                        ))}
                      </select>
                    </div>
                    <div className="flex items-center gap-2">
                      <label className="flex text-gray-700 w-1/4 text-left pr-4">
                        RTS area required for 1 kW  :
                      </label>
                      <input
                        type="number"
                        name='RTSRequiredValue'
                        placeholder="Enter RTS Area required for 1 kW"
                        className="block w-1/2 px-4 py-2 border border-gray-500 rounded-lg"
                        value={fromData.RTSRequiredValue}
                        onChange={handleChange}
                      />
                      <select
                        disabled
                        id={"RTSRequiredUnit"}
                        value={fromData.RTSRequiredUnit}
                        onChange={handleChange}
                        name='RTSRequiredUnit'
                        className={`w-1/4 px-3 py-2 border border-gray-400 rounded-md focus:outline-none`}
                      >
                        <option className='text-gray-400' value="">Select Unit</option>
                        {units.map((unit) => (
                          <option key={unit} value={unit}>{unit.split('-').join(' ')}</option>
                        ))}
                      </select>
                    </div>
                    <div className="flex items-center gap-2">
                      <label className="flex text-gray-700 w-1/4 text-left pr-4">
                        RTS Operating Charges:
                      </label>
                      <input
                        type="number"
                        name='RTSOperatingChargesValue'
                        placeholder="Enter RTS Operating value"
                        className="block w-1/2 px-4 py-2 border border-gray-500 rounded-lg"
                        value={fromData.RTSOperatingChargesValue}
                        onChange={handleChange}
                      />
                      <select
                        id={"RTSAreaUnit"}
                        value={fromData.RTSOperatingChargesUnit}
                        onChange={handleChange}
                        name='RTSOperatingChargesUnit'
                        className={`w-1/4 px-3 py-2 border border-gray-400 rounded-md focus:outline-none`}
                      >
                        <option className='text-gray-400' value="">Select Unit</option>
                        {RSTOCunits.map((unit) => (
                            <option key={unit} value={unit}>{unit.split('-').join(' ')}</option>
                        ))}
                      </select>
                    </div>
                </div>
            </div>
          </div>
          <div className="sticky bottom-0 bg-white flex justify-center gap-8">
            <button
              type="button"
              className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
