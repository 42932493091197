import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import PageTitleCard from "../../components/pageTitleCard/PageTitleCard";
import ResetButton from "../../components/resetButton/ResetButton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addPowerPlantData, deleteExistingPowerPlantDataOfParticularDate, deleteExistingPowerPlantDataOfParticularRecord, fetchPowerPlantDataList } from "../../services/apis/powerPlantData";
import PowerPlantDataTable from "../../components/table/PowerPlantDataTable";
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../components/backButton/BackButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import RecordsPerPageDropdown from "../../components/recordsPerPageDropdown/RecordsPerPageDropdown";
import { Tooltip } from "react-tooltip";
import ExcelDownloadButton from "../../components/excelDownload/ExcelDownloadButton";
import ExcelUploadButton from "../../components/excelDownload/ExcelUploadButton";
import readXlsxFile from 'read-excel-file';
import { ToastContainer, toast } from "react-toastify";

const ListPowerPlantData = () => {
  const [startDate, setStartDate] = useState("");
  const [addStartDate, setAddStartDate] = useState("");
  const [limit, setLimit] = useState(() => {
    const storedLimit = parseInt(localStorage.getItem('recordsPerPage'), 10);
    return storedLimit || 50;
  });
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({
    key: "_id",
    direction: "asc",
  });
  const [powerGenData, setPowerGenData] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isUploadPopupOpen, setIsUploadPopupOpen] = useState(false);
  const [eExcel, setEExcel] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loader, setLoader] = useState(false);
  const [uniqueDates, setUniqueDates] = useState([]);
  const [excelPowerGenerationData, setExcelPowerGenerationData] = useState();
  const [listCountUpdate, setListUpdateCount] = useState(0);

  const fetchData = async () => {
    const payload = {
      date: startDate !== "" ? formatDate(startDate) : null,
      page: currentPage,
      pageSize: limit,
      sortBy: sortConfig.key,
      sortOrder: sortConfig.direction,
      powerPlantUnitId: id
    };
    const PowerPlantDataList = await fetchPowerPlantDataList(payload);
    const data = PowerPlantDataList?.data?.data;
    setPowerGenData(data);
    setTotalCount(data?.total);
  };
  useEffect(() => {

    fetchData();
  }, [startDate, currentPage, limit, listCountUpdate]);

  const formatDate = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd'T'00:00:00.000'Z'");
    return formattedDate;
  };
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // Function to toggle popup visibility
  const togglePopup = () => setIsPopupOpen(!isPopupOpen);
  function formatDateToddmmyyyy(date) {
    let d = new Date(date);
    let day = ("0" + d.getDate()).slice(-2);
    let month = ("0" + (d.getMonth() + 1)).slice(-2); // Months are zero-indexed
    let year = d.getFullYear();

    return `${year}-${month}-${day}`;
  }
  const handleSave = () => {
    // togglePopup()
    navigate(
      `/recommendations/powerplant-data-add?date=${formatDateToddmmyyyy(
        addStartDate
      )}&powerplantunitId=${id}`
    );
  };

  const [isChecked, setIsChecked] = useState(false);
  const [isTablePopupOpen, setIsTablePopupOpen] = useState(false);
  const toggleTablePopup = () => setIsTablePopupOpen(!isTablePopupOpen);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };


  const handleDelete = async (paramsId, date) => {
    // console.log({ paramsId, date });
    if (isChecked) {
      // delete full day data
      const deletedData = await deleteExistingPowerPlantDataOfParticularDate(
        date, id
      );
      fetchData();
      toggleTablePopup()
    } else {
      // delete single record
      const deleteData = await deleteExistingPowerPlantDataOfParticularRecord(paramsId);
      fetchData();
      toggleTablePopup()

    }
  };

  const handleRecordsPerPageChange = (newLimit) => {
    setLimit(newLimit);
    setCurrentPage(1);
  };


  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file) {
      const extension = file.name.split('.').pop();

      if (extension === 'xlsx') {
        setLoader(true);
        setEExcel("")
        try {
          const excelPowerGenerationData = await readXlsxFile(file);
          // console.log(excelPowerGenerationData, 'excelPowerGenerationData');

          const result = [];
          const datesSet = new Set();

          for (let i = 1; i < excelPowerGenerationData.length - 1; i++) {
            const currentRow = excelPowerGenerationData[i];
            const nextRow = excelPowerGenerationData[i + 1];

            if (currentRow.length >= 2 && nextRow.length >= 2) {
              const [currentDateTime, currentEnergyValue] = currentRow;
              const [nextDateTime] = nextRow;

              const intervalStart = new Date(currentDateTime);
              const intervalEnd = new Date(nextDateTime);

              const formattedIntervalStart = intervalStart.toISOString().split('T')[1].slice(0, 5);
              const formattedIntervalEnd = intervalEnd.toISOString().split('T')[1].slice(0, 5);

              result.push({
                date: intervalStart.toISOString().split('T')[0],
                intervalStart: formattedIntervalStart,
                intervalEnd: formattedIntervalEnd,
                energy: {
                  value: currentEnergyValue,
                  unit: "kwh"
                },
                powerPlantUnitId: id
              });
              datesSet.add(intervalStart.toISOString().split('T')[0]);
              datesSet.add(intervalEnd.toISOString().split('T')[0]);
            }
          }
          const uniqueDatesArray = Array.from(datesSet);
          setUniqueDates(uniqueDatesArray);
          // console.log('Final result:', result);
          setExcelPowerGenerationData(result)
          setLoader(false);

        } catch (error) {
          console.error('Error reading Excel file:', error);
        }
      } else {
        setEExcel('Invalid file type. Please upload an Excel file (.xlsx).');
      }
    } else {
      setEExcel('No file selected.');
    }
  };

  const handleExcelSave = async () => {
    try {
      setLoader(true);
      const chunkSize = 100; // Process 100 rows at a time
      const totalRows = excelPowerGenerationData.length;

      // Delete data for each date sequentially
      for (let date of uniqueDates) {
        const deletedData = await deleteExistingPowerPlantDataOfParticularDate(
          date,
          id
        );
        // console.log(`Deleted data for date: ${date}`, deletedData);
      }

      for (let i = 0; i < totalRows; i += chunkSize) {
        const chunk = excelPowerGenerationData.slice(i, i + chunkSize);
        const response = await addPowerPlantData(chunk);
        // console.log(`Added ${chunk.length} rows. Response:`, response);
      }

      setLoader(true);
      toast.success('Excel data saved successfully');
      setIsUploadPopupOpen(false);
      setListUpdateCount(count => count + 1)
    } catch (error) {
      console.error('Error handling Excel save:', error);
      toast.error('Error handling Excel save. Please try again.');
    }
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-grow p-4">
        <div className="max-w-[1920px]">
          <div className="flex-grow mb-1">
            <div className=" overflow-hidden rounded-xl">
              <div className="p-4 mt-1 rounded-xl shadow-sm bg-white text-black flex justify-between items-center">
                <h2 className="text-lg font-semibold">{`Power Plants Data List`}</h2>
                <div className="flex  items-center gap-4">
                  <ExcelUploadButton
                    setIsUploadPopupOpen={setIsUploadPopupOpen}
                  />
                  {isUploadPopupOpen && (
                    <div className="fixed z-20  top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-80">
                      <div className="bg-white p-5 rounded shadow-lg w-fit">
                        <div className="mt-2">
                          <ExcelDownloadButton />
                        </div>
                        <div className="flex flex-row mt-10">
                          <div className="font-semibold mr-2 mt-2">Upload Power Generation Data : </div>
                          <div className="flex flex-col">
                            <input
                              id="file-input"
                              type="file"
                              accept=".xlsx, .xls"
                              onChange={handleFileUpload}
                              className='text-[14px] w-[100%] bg-white border  pl-[20px] rounded-[10px] h-[40px] pt-[6px] border-[#B9B9B9]'
                            />
                            {
                              loader && (
                                <div className="loader-container">
                                  <div className="loader"></div>
                                  <div>Wait uploading data</div>
                                </div>
                              )}
                            {eExcel && (
                              <p className="text-red-500 text-sm">{eExcel}</p>
                            )}
                          </div>
                        </div>
                        <div className="flex gap-8 justify-center mt-8">
                          <button
                            className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4"
                            onClick={() => {
                              setIsUploadPopupOpen(false);
                              setEExcel("");
                              setSelectedFile(null);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4"
                            onClick={handleExcelSave}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="border cursor-pointer bg-[#a9dbc3] py-2 px-2 p-[5px] rounded-lg">
                    {" "}
                    <FontAwesomeIcon
                      className=" focus:border-none"
                      onClick={togglePopup}
                      id="addtooltip"
                      size="xl"
                      icon={faAdd}
                    />
                    <Tooltip
                      anchorSelect="#addtooltip"
                      content="Add Power Plant Data"
                    />
                    {isPopupOpen && (
                      <div className="fixed z-20  top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-80">
                        <div className="bg-white p-5 rounded-xl shadow-lg w-96">
                          <h2 className="text-lg font-semibold">Select Date</h2>
                          <div className="mt-4 w-full flex powerPlant ">
                            <DatePicker
                              className="border border-gray-400 w-full px-2 py-1 rounded-lg"
                              placeholderText="Please Select Date"
                              selected={addStartDate}
                              dateFormat="dd-MM-yyyy"
                              onChange={(date) => setAddStartDate(date)}
                            />
                            <FontAwesomeIcon icon={faCalendarAlt} className="mt-2 -ml-6 z-50 text-gray-500" />
                          </div>
                          <div className="flex gap-8 justify-center mt-8">
                            <button
                              className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 "
                              onClick={() => { togglePopup(); setAddStartDate("") }}
                            >
                              Cancel
                            </button>
                            <button
                              className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 "
                              onClick={handleSave}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <BackButton />
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-xl">
            <div className="px-2 py-4 overflow-hidden mt-4 rounded-t-xl min-h-[500px]">
              <div className="flex gap-6 px-4">
                <div className=" ">
                  <DatePicker
                    className="border border-gray-400 px-2 py-1 rounded-lg"
                    placeholderText="Please Select Date"
                    selected={startDate}
                    dateFormat="dd-MM-yyyy"
                    onChange={(date) => setStartDate(date)}
                  />
                </div>
                <RecordsPerPageDropdown onRecordsPerPageChange={handleRecordsPerPageChange} />
                <div className="ml-[580px] " onClick={() => setStartDate("")}>
                  <ResetButton />
                </div>
              </div>
              <div className='bg-white p-2 rounded-xl min-h-[350px] mt-6'>
                <PowerPlantDataTable id={id} isTablePopupOpen={isTablePopupOpen} toggleTablePopup={toggleTablePopup} isChecked={isChecked} handleCheckboxChange={handleCheckboxChange} data={powerGenData} handleDelete={handleDelete} startDate={startDate} />
              </div>
              {totalCount > limit && (
                  <div className="pagination-controls bg-white rounded-b-xl border-[#abaaaa] mb-2 pb-2 flex flex-row items-center justify-end pt-8 -mt-2">
                      <button className={`bg-[#e1e0e0] ${currentPage === 1 ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl`} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                      <span className="mx-4">{Math.min(currentPage * limit, totalCount) ? Math.min(currentPage * limit, totalCount) : '0'} off {totalCount ? totalCount : '0'}</span>
                      <button className={`bg-[#e1e0e0] ${currentPage * limit >= totalCount ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl mr-2`} disabled={currentPage * limit >= totalCount} onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
                  </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListPowerPlantData;
