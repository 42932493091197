import React, { useEffect, useState } from 'react'
import { cleanUpData, fetchFactoryList, fetchOrganisationList } from '../../services/apis/listings';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import config from '../../config/config';
import Sidebar from '../../components/sidebar/Sidebar';
import PageTitleCard from '../../components/pageTitleCard/PageTitleCard';
import Table from '../../components/table/Table';
import SelectDropdown from '../../components/selectDropdown/SelectDropdown';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ToastContainer, toast } from 'react-toastify';
import RecordsPerPageDropdown from '../../components/recordsPerPageDropdown/RecordsPerPageDropdown';
import DeleteConfirmation from '../../components/deleteConfirmation/DeleteConfirmation';
import ResetButton from '../../components/resetButton/ResetButton';


export default function FactoryList() {
    const location = useLocation();

    const navigate = useNavigate();

    const { factoryListTableHeadings } = config;

    const [organisationList, setOrganisationList] = useState([]);
    const [factoryListData, setFactoryListData] = useState([]);
    const [org, setOrg] = useState("");
    const [sortConfig, setSortConfig] = useState({ key: "_id", direction: 'asc' });
    const [count, setCount] = useState(1);
    const [limit, setLimit] = useState(() => {
        const storedLimit = parseInt(localStorage.getItem('recordsPerPage'), 10);
        return storedLimit || 50;
    });
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState({
        organisationId: ""
    })
    const [deleteModalOpen, setDeleteModalOpen] = useState(false); 
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [deleteItemName, setDeleteItemName] = useState("");
    const [loader,setLoader] = useState(true)

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const orgId = queryParams.get('organisationId');
        if (orgId) {
            setOrg(orgId);
            setFilters((filters) => ({ ...filters, organisationId: orgId }));
         } else {
            setOrg("");
            setFilters({ organisationId: "" });
        }
    }, [location.search]);

    useEffect(() => {
        const fetchParentList = async () => {
            setLoader(true)
            const response = await fetchOrganisationList();
            const data = response?.data?.results;
            setOrganisationList(data);
            // console.log(data,'data')
            setLoader(false)
        }
        fetchParentList();
    },[])

    useEffect(() => {
        const fetchData = async () => {
            const payload = {
                ...filters,
                page: currentPage,
                pageSize: limit,
                sortBy: sortConfig.key,
                sortOrder: sortConfig.direction,
            }
            const data = await fetchFactoryList(payload);
            const factoryList = data?.data?.results;
            const tableData = factoryList?.map(item => ({
                _id: item?._id,
                name: item?.name,
            }));
            setFactoryListData(tableData);
            setTotalCount(data?.data?.total)
            // console.log(factoryList,'factoryList')
        }
        fetchData();
    },[count, currentPage, sortConfig, filters, limit]);

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        setCurrentPage(1);
    };

    const handleParentChange = (selectedOrg) => {
        setOrg(selectedOrg);
        if (selectedOrg) {
            setFilters({ ...filters, organisationId: selectedOrg });
        }
    };

    const updateCount = () => {
        setCount(prevCount => prevCount + 1);
    };

    const handleDelete = (id, name) => {
        setDeleteModalOpen(true);
        setDeleteItemId(id);
        setDeleteItemName(name);
    };

    const confirmDelete = async () => {
        const payload = {
            factoryId: deleteItemId
        }
        const response = await cleanUpData(payload);

        if (response.status == 200) {
            toast.success(`Factory Data deleted successfully`, {
                position: "top-center",
                autoClose: 1500,
            });
            updateCount();
        } else {
            toast.error(`Factory Data could not be deleted`, {
                position: "top-center",
                autoClose: 1500,
            });
        }
        setDeleteModalOpen(false);
    };

    const handleView = (factoryId) => {
        navigate(`/units-list?factoryId=${factoryId}`);
    }

    const handleRecordsPerPageChange = (newLimit) => {
        setLimit(newLimit);
        setCurrentPage(1); 
    };

    const handleResetFilters = () => {
        setFilters({
            organisationId: ""
        });
    };

    return (
        <div className="flex">
            <Sidebar />
            <div className="flex-grow p-4">
                <div className="max-w-8xl">
                    <PageTitleCard title="Factories List" />
                    <div className=''>
                        <div className="overflow-hidden mt-2 rounded-xl">
                            <div className="flex">
                                <div className="overflow-hidden ">
                                    <div className="p-3 flex flex-row gap-4">
                                        <SelectDropdown
                                            label="Organisation"
                                            options={organisationList?.map((organisation) => {
                                                return { label: organisation?.name, value: organisation?._id };
                                            })}
                                            selectedValue={org}
                                            onChange={(e) => handleParentChange(e.target.value)}
                                        />
                                        <RecordsPerPageDropdown onRecordsPerPageChange={handleRecordsPerPageChange} />
                                    </div>
                                </div>
                                <div className="p-3 ml-[550px] reset-button-container">
                                    <ResetButton onClick={handleResetFilters} />
                                </div>
                            </div>
                            <div className='bg-white p-2 rounded-xl min-h-[450px]'>
                                <Table loader={loader} headings={factoryListTableHeadings} data={factoryListData} handleView={handleView} handleDelete={handleDelete} handleSort={handleSort} sortConfig={sortConfig} title="View Units List"/>
                            </div>
                        </div>
                        {totalCount > limit && (
                            <div className="pagination-controls bg-white rounded-b-xl border-[#abaaaa] mb-2 pb-2 flex flex-row items-center justify-end pt-8 -mt-2">
                                <button className={`bg-[#e1e0e0] ${currentPage === 1 ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl`} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                <span className="mx-4">{Math.min(currentPage * limit, totalCount) ? Math.min(currentPage * limit, totalCount) : '0'} off {totalCount ? totalCount : '0'}</span>
                                <button className={`bg-[#e1e0e0] ${currentPage * limit >= totalCount ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl mr-2`} disabled={currentPage * limit >= totalCount} onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
                            </div>
                        )}
                    </div>
                </div>
                <ToastContainer position="top-center" autoClose={3000} />
                <DeleteConfirmation
                    isOpen={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    onConfirm={confirmDelete}
                    itemName={deleteItemName}
                />
            </div>
        </div>
    )
}
