import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";

const ViewFormulaPopup = ({ isTablePopupOpen, toggleTablePopup, data }) => {
  useEffect(() => {
    console.log({ data });
  }, [data]);

  return (
    <>
      {" "}
      {isTablePopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded shadow-lg max-w-sm w-full">
            <div className="flex justify-between">
              <h2 className="text-lg font-bold mb-4">Formula</h2>
              <FontAwesomeIcon onClick={toggleTablePopup} className="p-2 bg-gray-400 rounded-lg cursor-pointer" icon={faClose} />
            </div>

            {data.type === "arithmetic" ? (
              <textarea
                readOnly={true}
                className="w-full border border-black rounded-lg  p-4"
                value={data.content[0].formula}
              />
            ) : (
              <div>
                <textarea
                  readOnly={true}
                  className="w-full border border-black rounded-lg  p-4"
                  value={JSON.stringify(data.content, null, 2)}
                  rows={10}
                  cols={50}
                  style={{ whiteSpace: "pre", fontFamily: "monospace" }}
                />
              </div>
            )}
            {/* <div className="w-full flex justify-left mt-2">
              <button
                onClick={toggleTablePopup}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Close
              </button>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default ViewFormulaPopup;
