import React, { useEffect, useState } from 'react';
import config from '../../config/config';
import InputBox from '../../components/inputBox/InputBox';
import Sidebar from '../../components/sidebar/Sidebar';
import PageTitleCard from '../../components/pageTitleCard/PageTitleCard';
import { createUserRole, getUserRoleById, updateUserRoleById } from '../../services/apis/userRole';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";

export default function EditUserRole() {

    const { id } = useParams();

    const navigate = useNavigate();

    const [errors, setErrors] = useState({});
    const [formReset, setFormReset] = useState(false);
    const [userRoleData, setUserRoleData] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        uniqueKey: "",
        status: "status"
    })

    useEffect(() => {
        const fetchUserRoleData = async () => {
            try {
                const response = await getUserRoleById(id);
                const userRole = response?.result;
                console.log(userRole,'userRole');

                setUserRoleData(userRole)

                if (userRole) {
                    setFormData({
                        name: userRole.name || "",
                        uniqueKey: userRole.uniqueKey || "",
                        status: userRole.status || ""
                    });
                }
            } catch (error) {
                console.error(error);
            }
        }
        fetchUserRoleData();
    },[id])

    const handleChange = (name, value) => {
        let uniqueKeyValue =
            name === "name" ? value.toLowerCase().replace(/\s+/g, "-").replace(/[^a-zA-Z0-9-]/g, "") : formData.uniqueKey;
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                uniqueKey: uniqueKeyValue,
            }));
            if (name === "name" && errors.uniqueKey && value.trim() !== "") {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  uniqueKey: "",
                }));
              }
            let error = "";
            if (!value.trim()) {
                error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
            }
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: error,
            }));
      };

    const handleCancel = () => {
        setFormData({
            name: "",
            uniqueKey: "",
            status: "status"
        })
        navigate("/user-role-list")
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let formErrors = {};

        if (!formData.name.trim()){
            formErrors.name = "Name is required";
        }

        if (!formData.uniqueKey.trim()){
            formErrors.uniqueKey = "Unique Key is required";
        }

        if (formData.status == "status") {
            formErrors.status = "Status is required";
        }

        if (Object.keys(formErrors).length === 0){

            try {

                const updatedData = {};
                if (userRoleData?.name !== formData.name){
                    updatedData.name = formData.name;
                }
                if (userRoleData?.uniqueKey !== formData.uniqueKey){
                    updatedData.uniqueKey = formData.uniqueKey;
                }
                if (userRoleData?.status !== formData.status){
                    updatedData.status = formData.status;
                }
                
                await updateUserRoleById(userRoleData?._id, updatedData);

                setFormData({
                    name: "",
                    uniqueKey: "",
                    status: "status"
                });
                setErrors({});

                toast.success("User Role Updated Successfully!", {
                    position: 'top-center',
                });
                setFormReset(true);
                setTimeout(() => {
                    navigate("/user-role-list");
                }, 3000);

            } catch (error) {
                console.error("Error:", error);
        
                toast.error("Failed to update user role. Please try again later.", {
                position: "top-center",
                });
            }

        } else {
            setErrors(formErrors);
        }        
        
    };

    const renderFormFields = () => {
        const fields = config.userRoleSettings.fields;
        
        return Object.keys(fields).map((fieldName, index) => {
            
            const field = fields[fieldName];
            const { label, type, required, options } = field;
            console.log(options, 'options')

            switch (type) {
                case 'text':
                    return (
                        <div key={index} className="mb-6 max-w-5xl relative">
                            <label htmlFor={fieldName} className="mb-2 block text-gray-700">
                                {label}{required && <span className="text-red-500">*</span>} :
                            </label>
                            <InputBox
                                width="max-w-5xl"
                                placeholder={`Enter ${label}`}
                                value={formData[fieldName]}
                                onChange={(e) => handleChange(fieldName, e.target.value)}
                            />
                            {errors[fieldName] && (
                                <p className="text-red-500 text-sm absolute">
                                    {errors[fieldName]}
                                </p>
                            )}
                        </div>
                    );
                case 'select':
                    return (
                        <div key={index} className="mb-6 max-w-sm relative">
                            <label htmlFor={fieldName} className="mb-2 mt-6 block text-gray-700">
                                {label}{required && <span className="text-red-500">*</span>} :
                            </label>
                            <select
                                id={fieldName}
                                value={formData[fieldName]}
                                onChange={(e) => handleChange(fieldName, e.target.value)}
                                className={`w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                                    errors[fieldName] ? "border-red-500" : ""
                                }`}
                            >
                                <option value="" >Select {label}</option>
                                {options.map((option, index) => (
                                    <option key={index} value={option}>{option.charAt(0).toUpperCase() + option.slice(1)}</option>
                                ))}
                            </select>
                            {errors[fieldName] && (
                                <p className="text-red-500 text-sm absolute">
                                    {errors[fieldName]}
                                </p>
                            )}
                        </div>
                    );
                default:
                    return null;
            }
            
        })

    }

    return (
        <div className="flex">
            <Sidebar />
            <div className="flex-grow p-4">
                {/* <div className="max-w-8xl">
                    <PageTitleCard title="Edit User Role" />
                    <form onSubmit={handleSubmit}>
                        <div className="bg-white shadow-md overflow-hidden mt-4">
                            <div className="p-4 mb-4 items-center space-y-4 md:space-y-0 md:flex-row">
                                {renderFormFields()}
                                <div>
                                    <button
                                        type="button"
                                        onClick={handleCancel}
                                        className=" bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] mt-10 hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4" >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className=" bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] mt-10 hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4" >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                        <ToastContainer position="top-center" autoClose={3000} />
                    </form>
                </div> */}
            </div>
        </div>
    )
}
