import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/sidebar/Sidebar'
import PageTitleCard from '../../components/pageTitleCard/PageTitleCard'
import SearchBar from '../../components/searchBar/SearchBar'
import FilterDropdown from '../../components/filterDropdown/FilterDropdown';
import SearchById from '../../components/searchById/SearchById';
import Table from '../../components/table/Table';
import { deleteContentById, fetchContentList } from '../../services/apis/content';
import config from '../../config/config';
import Pagination from '../../components/pagination/Pagination';
import ResetButton from '../../components/resetButton/ResetButton';
import '../../components/resetButton/resetButton.css'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import RecordsPerPageDropdown from '../../components/recordsPerPageDropdown/RecordsPerPageDropdown';
import DeleteConfirmation from '../../components/deleteConfirmation/DeleteConfirmation';


export default function CMSList() {
    const navigate = useNavigate();

    const { cmsListTableHeadings } = config;
    
    const types = ["blog", "articles", "solution", "career", "page", "home_page","faqs"];
    const statuses = ["draft", "published", "deleted"]; 
    // const ids = ["Draft", "Published", "Deleted"]; 
    // const createdBy = ["Mitesh", "Akash", "Pankaj"]; 

    const [searchQuery, setSearchQuery] = useState('');
    const [totalContentData, setTotalContentData] = useState([]);
    const [contentData, setContentData] = useState([]);
    const [count, setCount] = useState(1);
    const [contentTableData, setContentTableData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: "_id", direction: 'asc' });
    // const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [filterById, setFilterById] = useState('');
    const [createdByOptions, setCreatedByOptions] = useState([]);
    const [limit, setLimit] = useState(() => {
        const storedLimit = parseInt(localStorage.getItem('recordsPerPage'), 10);
        return storedLimit || 50;
    });
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    
    const [filters, setFilters] = useState({
        type: '',
        status: '',
        authorName: ''
    });
    const [deleteModalOpen, setDeleteModalOpen] = useState(false); 
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [deleteItemName, setDeleteItemName] = useState("");
    const [loader,setLoader] = useState(true)
    
    useEffect(() => {
        const fetchData = async () => {
            setLoader(true)
            const payload = {
                page: currentPage,
                pageSize: limit,
                id: filterById,
                type: filters.type,
                status: filters.status,
                authorName: filters.authorName,
                title: searchQuery,
                sortBy: sortConfig.key,
                sortOrder: sortConfig.direction,
            };
            
            const contentList = await fetchContentList(payload);
            const data = contentList?.data?.results;
            setTotalCount(contentList?.data?.total)
            const tableData = data?.map(item => ({
                _id: item?._id,
                type: item?.type,
                title: item?.title,
                createdBy: item?.authorName, 
                date: new Date(item?.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }), 
                status: item?.status,
            }));
            if (tableData){
                setTotalContentData(tableData);
                setContentTableData(tableData);
            }
            setContentData(contentList?.data?.results);

            const createdBySet = new Set(); 
            data?.forEach(item => {
                const createdBy = item?.authorName; 
                
                if (createdBy?.trim() !== undefined) {
                    createdBySet.add(createdBy);
                }
            });
            
            const uniqueCreatedBy = [...createdBySet].filter((value, index, self) =>
                self.indexOf(value) === index
            );

            setCreatedByOptions(uniqueCreatedBy);
            setLoader(false)
        };
        fetchData();
        
    }, [count, currentPage, filters, filterById, searchQuery, sortConfig, limit]);

    const handleFilterChange = (filterType, value) => {
        setFilters({ ...filters, [filterType]: value });
        setCurrentPage(1);
    };

    const handleFilterById = (event) => {
        const id = event.target.value;
        setFilterById(id);
        setCurrentPage(1);
    }; 
    
    const handleSearch = (event) =>{
        const query = event.target.value;
        setSearchQuery(query);
        setCurrentPage(1);
    }

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        setCurrentPage(1);
    };

    const updateCount = () => {
        setCount(prevCount => prevCount + 1);
    };

    const handleResetFilters = () => {
        
        setFilters({
            type: '',
            status: '',
            authorName: ''
        });
        setFilterById('');
        setSearchQuery('');
        // setCurrentPage(1);
        setContentTableData(totalContentData);
    };

    // const handleDelete = async (id) => {

    //     confirmAlert({
    //         title: '',
    //         closeOnClickOutside: false,
    //         message: `Are you sure you want to delete this Content?`,
    //         buttons: [
    //             {
    //                 label: 'Yes',
    //                 onClick: async () => {
    //                     const response = await deleteContentById(id);
    //                     if (response.success) {
    //                         toast.success(`Content deleted successfully!`, {
    //                             position: "top-center",
    //                             autoClose: 1500,
    //                         });
    //                         updateCount();
    //                     } else {
    //                         toast.error(`Failed to delete the Content. Please try again later.`, {
    //                             position: "top-center",
    //                             autoClose: 1500,
    //                         });
    //                     }
    //                 }
    //             },
    //             {
    //                 label: 'No',
    //                 onClick: () => {
    //                     // Do nothing
    //                 }
    //             }
    //         ]
    //     });

    // }

    const handleDelete = (id, name) => {
        setDeleteModalOpen(true);
        setDeleteItemId(id);
        setDeleteItemName(name);
    };

    const confirmDelete = async () => {
        const response = await deleteContentById(deleteItemId);

        if (response.success) {
            toast.success(`Content deleted successfully!`, {
                position: "top-center",
                autoClose: 1500,
            });
            updateCount();
        } else {
            toast.error(`Failed to delete the Content. Please try again later.`, {
                position: "top-center",
                autoClose: 1500,
            });
        }
        setDeleteModalOpen(false);
    };

    const handleEdit = (id, type) => {
        navigate(`/edit-content/${type}/${id}`)
    }

    const handleRecordsPerPageChange = (newLimit) => {
        setLimit(newLimit);
        setCurrentPage(1); 
    };

    return (
        <div className="flex">
            <Sidebar />
            <div className="flex-grow p-4">
                <div className="max-w-8xl">
                    <PageTitleCard title="Content List" addLink="/add-content" addLinkText="Add New Content"/>
                    <div className=''>
                        <div className="overflow-hidden mt-2 rounded-xl">
                            <div className="flex">
                                <div className="p-3 flex flex-wrap items-center justify-start gap-4 space-y-4 md:space-y-0 md:flex-row">
                                    <SearchBar searchQuery={searchQuery} handleSearch={handleSearch} title="Name"/>
                                    <SearchById filterById={filterById} handleFilterById={handleFilterById}/>
                                    <FilterDropdown
                                        label="Type"
                                        options={types}
                                        selectedValue={filters.type}
                                        onChange={(value) => handleFilterChange('type', value)}
                                    />
                                    <FilterDropdown
                                        label="Status"
                                        options={statuses} 
                                        selectedValue={filters.status}
                                        onChange={(value) => handleFilterChange('status', value)}
                                    />
                                    <FilterDropdown
                                        label="Created By"
                                        options={createdByOptions} 
                                        selectedValue={filters.authorName}
                                        onChange={(value) => handleFilterChange('authorName', value)}
                                    />
                                    <RecordsPerPageDropdown onRecordsPerPageChange={handleRecordsPerPageChange} />
                                </div>
                                <div className="p-3 mt-[60px] mr-2 reset-button-container">
                                    <ResetButton onClick={handleResetFilters} />
                                </div>
                            </div>
                            <div className='bg-white p-2 rounded-xl min-h-[350px]'>
                                <Table loader={loader} headings={cmsListTableHeadings} data={contentTableData} handleEdit={(id, type) => handleEdit(id, type)} handleSort={handleSort} handleDelete={() => handleDelete()} sortConfig={sortConfig} />
                            </div>
                            </div>
                            {totalCount > limit && (
                                <div className="pagination-controls bg-white rounded-b-xl border-[#abaaaa] mb-2 pb-2 flex flex-row items-center justify-end pt-8 -mt-2">
                                    <button className={`bg-[#e1e0e0] ${currentPage === 1 ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl`} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                    <span className="mx-4">{Math.min(currentPage * limit, totalCount) ? Math.min(currentPage * limit, totalCount) : '0'} off {totalCount ? totalCount : '0'}</span>
                                    <button className={`bg-[#e1e0e0] ${currentPage * limit >= totalCount ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl mr-2`} disabled={currentPage * limit >= totalCount} onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
                                </div>
                            )}
                        </div>
                    </div>
                <ToastContainer position="top-center" autoClose={3000} />
                <DeleteConfirmation
                    isOpen={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    onConfirm={confirmDelete}
                    itemName={deleteItemName}
                />
            </div>
        </div>
    )
}
