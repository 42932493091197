import React, { useEffect, useState } from 'react';
import config from '../../../config/config';
import InputBox from '../../../components/inputBox/InputBox';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { fetchCollectionsFieldList, fetchCollectionsList, fetchGlobalVariablesList } from '../../../services/apis/globalRecommendationVariable';
import { createIntermediateVariables } from '../../../services/apis/regulatoryCharges';

export default function AddIntermediateVariables({ onClose, setUpdateCount }) {
    const navigate = useNavigate();
    const [globalVariables, setGlobalVariables] = useState([]);
    const [collectionList, setCollectionList] = useState([]);
    const [collectionFieldList, setCollectionFieldList] = useState([]);
    const [formData, setFormData] = useState({
        description: "",
        modelName: "",
        mapping: "",
        globalRecVariableId: "",
        type: ""
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        const getGlobalVariablesList = async () => {
            const payload = {
                type: "intermediateVariable",
                isMap:false,
                status:'active'
            }
            const response = await fetchGlobalVariablesList(payload);
            const data = response?.data?.data?.results;
            setGlobalVariables(data);
            // console.log(data, 'data')
        };
        getGlobalVariablesList();
    }, [])

    const formatModelName = (modelName) => {
        return modelName
            .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
            .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
            .replace(/^\w/, c => c.toUpperCase())
            .replace(/\b\w/g, c => c.toUpperCase());
    };


    useEffect(() => {
        const getGlobalVariablesList = async () => {
            const response = await fetchCollectionsList();
            const data = response?.data?.data;
            setCollectionList(data);
        };
        getGlobalVariablesList();
    }, []);

    useEffect(() => {
        const getGlobalVariablesList = async () => {
            if (formData.modelName) {

                const data = {
                    modelName: formData.modelName
                }
                const response = await fetchCollectionsFieldList(data);
                const collectionsFieldList = response?.data?.data;
                setCollectionFieldList(collectionsFieldList);
            }
        };
        getGlobalVariablesList();
    }, [formData.modelName]);

    const getOptions = (fieldName) => {
        switch (fieldName) {
            case 'globalRecVariableId':
                return globalVariables.map((option) => (
                    <option key={option._id} value={option._id}>
                        {option.name}
                    </option>))
            case 'modelName':
                return collectionList.map((option) => (
                    <option key={option.modelName} value={option.modelName}>
                        {formatModelName(option.modelName)}
                    </option>))

            case 'mapping':
                return collectionFieldList.map((option) => (
                    <option key={option.name} value={option.name}>
                        {option.name}
                    </option>))
            default:
                return [];
        }
    };

    const renderFormFields = () => {
        const fields = config.intermediateVariablesSettings.fields;
        return Object.keys(fields)?.map((fieldName, index) => {
            const field = fields[fieldName];
            const { label, type, required, options } = field;

            switch (type) {
                case 'text':
                case 'number':
                    return (
                        <div key={index}>
                            <div key={index} className="flex items-center">
                                <label htmlFor={fieldName} className="w-[28%] text-gray-700">
                                    {label}{required && <span className="text-red-500">*</span>} :
                                </label>
                                <InputBox
                                    type={type}
                                    width="w-[72%]"
                                    placeholder={`Enter ${label}`}
                                    value={formData[fieldName]}
                                    onChange={(e) => handleChange(fieldName, e.target.value)}
                                    className="ml-4"
                                />
                            </div>
                            <div className='mb-5'>
                                {errors[fieldName] && (
                                    <p className="absolute text-red-500 text-sm ml-44">
                                        {errors[fieldName]}
                                    </p>
                                )}
                            </div>
                        </div>
                    );
                case 'select':
                    return (
                        <div key={index}>
                            <div className="flex items-center">
                                <label htmlFor={fieldName} className="w-[28%] text-gray-700">
                                    {label}{required && <span className="text-red-500">*</span>} :
                                </label>
                                <select
                                    id={fieldName}
                                    value={formData[fieldName]}
                                    onChange={(e) => handleChange(fieldName, e.target.value)}
                                    className={`w-[72%] px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${errors[fieldName] ? "border-red-500" : ""
                                        }`}
                                >
                                    <option value="">Select {label}</option>
                                    {getOptions(fieldName)}
                                </select>
                            </div>
                            <div className='mb-5'>
                                {errors[fieldName] && (
                                    <p className="absolute text-red-500 text-sm ml-44">
                                        {errors[fieldName]}
                                    </p>
                                )}
                            </div>
                        </div>
                    );
                default:
                    return null;
            }
        });
    };

    const handleChange = (fieldName, value) => {
        setFormData({ ...formData, [fieldName]: value });

        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };

            if (value.trim()) {
                delete updatedErrors[fieldName];
            } else {
                updatedErrors[fieldName] = `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} is required`;
            }

            return updatedErrors;
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const validationErrors = {};
        if (!formData.globalRecVariableId) {
            validationErrors.globalRecVariableId = "Global Variable is required";
        }

        if (!formData.modelName) {
            validationErrors.modelName = "Collection Name is required";
        }
        if (!formData.mapping) {
            validationErrors.mapping = "Collection Field Name is required";
        }
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            const response = await createIntermediateVariables(formData);
            if (response.data.data.length == 0) {
                toast.error(`${response?.data?.message}`, {
                    position: "top-center",
                });

            } else {
                toast.success(`${response?.data?.message}`, {
                    position: "top-center",
                });
                setUpdateCount(prevCount => prevCount + 1);
                onClose();
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("Failed to add regulation. Please try again.", {
                position: "top-center",
            })
        }

    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
            <div className="bg-white p-4 shadow-lg w-full max-w-3xl max-h-[95vh] overflow-y-auto rounded-lg">
                <div className="flex flex-col h-full">
                    <div className="flex-grow overflow-auto p-2">
                        <div className="max-w-10xl">
                            <div>
                                <div className="max-w-8xl">
                                    <div className="p-4">
                                        {renderFormFields()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sticky bottom-0 bg-white p-4 flex justify-center gap-8">
                        <button
                            type="button"
                            onClick={onClose}
                            className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            onClick={handleSubmit}
                            className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
