import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
export const getGlobalVariableList = async () => {
  try {
    const response = await axios.post(
      `${API_URL}/globalRecommendationsVariable/list`,
      { type: "formula", isMap: false }
    );
    // console.log(response,'reg-list');
    return response;
  } catch (error) {
    console.error("Error :", error);
    return error?.response?.data;
  }
};


export const getAllGlobalVariableList = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/globalRecommendationsVariable/list`);
      // console.log(response,'reg-list');
      return response;
    } catch (error) {
      console.error("Error :", error);
      return error?.response?.data;
    }
  };



export const addArithmaticFormula = async (data) => {
    try {
      const response = await axios.post(
        `${API_URL}/formula`,
        data
      );
      // console.log(response,'reg-list');
      return response;
    } catch (error) {
      console.error("Error :", error);
      return error?.response?.data;
    }
  };

  export const editArithmaticFormula = async (id,data) => {
    try {
      const response = await axios.put(
        `${API_URL}/formula/${id}`,
        data
      );
      // console.log(response,'reg-list');
      return response;
    } catch (error) {
      console.error("Error :", error);
      return error?.response?.data;
    }
  };



  export const gloabalAutoComplete = async (keyword) => {
    try {
      const response = await axios.get(
        `${API_URL}/autocomplete?type=global-variable&q=${keyword}`
      );
      // console.log(response,'reg-list');
      return response;
    } catch (error) {
      console.error("Error :", error);
      return error?.response?.data;
    }
  };


  export const fetchFormulaList = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/formula/list`, payload);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}


export const deleteFormulaApi = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/formula/${id}`);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}


export const getFormulaApi = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/formula/${id}`);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}



