import NewsList from "../dummyData/newsList";

export const getNewsListData = () => {
    try{
        return NewsList;
    } catch (e) {
        console.log(e);
    }
}

export const getNewsById = (newsId) => {
    try {
        const newsList = getNewsListData();
        const news = newsList.find(news => news._id == newsId);
        return news;
    } catch (error) {
        console.error(error);
        return null;
    }
}