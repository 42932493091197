export const makeFinancialYear = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 2021;
    
    const financialYears = [];
    
    for (let year = startYear; year <= currentYear; year++) {
        const nextYear = year + 1;
        financialYears.push(`${year}-${nextYear}`);
    }
    
    return financialYears;
};

export const makeYearFormate=(str)=>{
    return `${str}-${parseInt(str)+1}`
}

