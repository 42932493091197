import axios from 'axios';

const user = JSON.parse(localStorage.getItem('user'));
const token = user?.result?.token

const API_URL = process.env.REACT_APP_API_URL; 

export const createBillingUnit = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/billingUnit`, payload);
        // console.log(response,'response')
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const fetchBillingUnitsList = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/billingUnit/list`, payload);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const fetchBillingUnitById = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/billingUnit/${id}`);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const updateBillingUnit = async (id, payload) => {
    try {
        const response = await axios.put(`${API_URL}/billingUnit/${id}`, payload);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const deleteBillingUnitById = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/billingUnit/${id}`);
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}