import React, { useState, useEffect, useRef } from 'react';
import loadGoogleMapsApi from '../../utils/googleMapApi';

const GooglePlacesMap = ({ onClose, address, onMapClick }) => {
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const [selectedLatLng, setSelectedLatLng] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedStateCode, setSelectedStateCode] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const autocompleteRef = useRef(null);

  useEffect(() => {
    let map;
    let geocoder;
    let autocomplete;

    const initializeMap = async () => {
      try {
        await loadGoogleMapsApi();

        geocoder = new window.google.maps.Geocoder();
        map = new window.google.maps.Map(mapRef.current, {
          center: { lat: 20.5937, lng: 78.9629 }, // Center on India initially
          zoom: 5,
        });

        if (address) {
          geocoder.geocode({ address }, (results, status) => {
            if (status === 'OK' && results[0] && results[0].geometry) {
              const location = results[0].geometry.location;
              map.setCenter(location);
              map.setZoom(12);

              markerRef.current = new window.google.maps.Marker({
                map,
                position: location,
              });
            } else {
              console.error('Geocode was not successful for the following reason: ' + status);
            }
          });
        }

        map.addListener('click', (event) => {
          const clickedLatLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };

          if (markerRef.current) {
            markerRef.current.setMap(null);
          }

          markerRef.current = new window.google.maps.Marker({
            map,
            position: clickedLatLng,
          });

          setSelectedLatLng(clickedLatLng);
          reverseGeocode(geocoder, clickedLatLng);
        });

        // Initialize Places Autocomplete
        autocomplete = new window.google.maps.places.Autocomplete(
          document.getElementById('search-input'),
          {
            types: ['geocode'],
            componentRestrictions: { country: 'in' },
          }
        );

        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          if (!place.geometry || !place.geometry.location) {
            console.log('No location found for input:', place.name);
            return;
          }
          const location = place.geometry.location;
          map.setCenter(location);
          map.setZoom(12);

          if (markerRef.current) {
            markerRef.current.setMap(null);
          }

          markerRef.current = new window.google.maps.Marker({
            map,
            position: location,
          });

          const latLng = { lat: location.lat(), lng: location.lng() };
          setSelectedLatLng(latLng);
          extractAddressComponents(place.address_components);
          setSelectedAddress(place.formatted_address);
        });

        autocompleteRef.current = autocomplete;
      } catch (error) {
        console.error('Error loading Google Maps API', error);
      }
    };

    initializeMap();

    return () => {
      if (autocompleteRef.current) {
        window.google.maps.event.clearInstanceListeners(autocompleteRef.current);
      }
      if (markerRef.current) {
        markerRef.current.setMap(null);
      }
    };
  }, [address]);

  const reverseGeocode = (geocoder, latLng) => {
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === 'OK' && results[0]) {
        extractAddressComponents(results[0].address_components);
        setSelectedAddress(results[0].formatted_address);
      } else {
        setSelectedAddress('Address not found');
      }
    });
  };

  const extractAddressComponents = (components) => {
    let city = '';
    let state = '';
    let stateCode = '';
    let district = '';
    for (const component of components) {
      if (component.types.includes('locality')) {
        city = component.long_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        state = component.long_name;
        stateCode = component.short_name;
      } else if (component.types.includes('administrative_area_level_3')) {
        district = component.long_name;
      }
    }
    setSelectedCity(city);
    setSelectedState(state);
    setSelectedStateCode(stateCode);
    setSelectedDistrict(district);
  };

  const handleConfirmClick = () => {
    if (selectedLatLng) {
      onMapClick({
        lat: selectedLatLng.lat,
        lng: selectedLatLng.lng,
        city: selectedCity,
        district: selectedDistrict,
        address: selectedAddress,
        state: selectedState,
        stateCode: selectedStateCode,
      });
      onClose();
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="relative w-4/5 h-4/5 bg-white rounded-lg overflow-hidden">
        <button
          className="absolute top-3 lg:right-16 right-16 z-10 bg-red-500 text-white p-2 rounded-lg focus:outline-none"
          onClick={onClose}
        >
          Close
        </button>
        <div ref={mapRef} className="w-full h-full"></div>
        <form className="absolute top-16 lg:top-4 left-1/2 transform -translate-x-1/2 z-10" onSubmit={handleFormSubmit}>
          <input
            id="search-input"
            type="text"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder="Search Location, Place, City..."
            className="w-full max-w-lg lg:w-[130%] px-6 py-3 text-sm bg-white border border-gray-300 rounded-full focus:outline-none focus:ring focus:border-blue-500"
          />
        </form>
        {selectedLatLng && (
          <div className="absolute top-28 md:top-16 right-4 w-full max-w-xs lg:max-w-[350px] bg-white border border-gray-200 p-4 rounded-lg shadow-lg">
            <div>Address: {selectedAddress}</div>
            <div>City: {selectedCity}</div>
            <div>District: {selectedDistrict}</div>
            <div>State: {selectedState} ({selectedStateCode})</div>
            <div>Latitude: {selectedLatLng.lat.toFixed(4)}</div>
            <div>Longitude: {selectedLatLng.lng.toFixed(4)}</div>
            <button onClick={handleConfirmClick} className="bg-blue-500 text-white px-2 py-1 rounded mt-2 hover:bg-blue-700 focus:outline-none">
              Confirm
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default GooglePlacesMap;
