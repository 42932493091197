import React from 'react';

export default function VariableTypeSelectDropdown({ label, options, selectedValue, onChange, disabled }) {
    return (
        <div className="relative flex items-center">
            <label className="text-gray-700 w-1/4 px-2">{label}:</label>
            <select
                value={selectedValue}
                onChange={(e) => onChange(e.target.value)}
                className={`block w-3/4 bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 mx-2 rounded-lg shadow focus:outline-none focus:shadow-outline ml-2 ${disabled ? "cursor-not-allowed" : ""}`}
                disabled={disabled}
            >
                <option value="">{`Select ${label}`}</option>
                {options.map((option) => (
                <option key={option} value={option}>{option}</option>
                ))}
            </select>
        </div>
    );
}
