import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import ActionButtons from '../../../components/actionButtons/ActionButtons';
import EditActionButton from '../../../components/actionButtons/EditActionButton';
import AddEditViewButtons from '../../../components/actionButtons/AddEditViewButtons';
import Loading from '../../../components/loader/Loader';
import { makeYearFormate } from '../constantVariable/getFY';

export default function RecommendationsTable({loader=false ,headings, data, handleSort, sortConfig, handleEdit, handleDelete, handleAdd, handleView, handleMapPowerPlantOpen, title,handleOpenRT=()=>{}}) {
    const displayType = (type) => {
        switch (type) {
            case 'regulatoryCharge':
                return 'Regulatory Charge';
            case 'intermediateVariable':
                return 'Intermediate Variable';
            case 'formula':
                return 'Formula';
            case 'constant':
                return 'Constant';
            default:
                return type;
        }
    };

    return (
        <div className="bg-white rounded-xl">
            <div className="overflow-x-auto overflow-y-hidden">
                <table className="min-w-full px-2">
                    <thead>
                        <tr>
                            {Object.entries(headings).map(([key, heading], index, array) => (
                                <th
                                    key={heading}
                                    className={`px-6 py-4 bg-gray-100 text-left text-xs leading-5 font-medium text-black uppercase tracking-wider ${heading !== 'Actions' && heading !== 'Status' && heading !== 'State' && heading !== 'Discom' ? 'cursor-pointer' : ''}`}
                                    onClick={() => heading !== 'Actions' && heading !== 'Status' && heading !== 'State' && heading !== 'Discom' && handleSort(key)}
                                    style={{
                                        borderBottomLeftRadius: index === 0 ? '0.5rem' : '0',
                                        borderBottomRightRadius: index === array.length - 1 ? '0.5rem' : '0',
                                        borderTopLeftRadius: index === 0 ? '0.5rem' : '0',
                                        borderTopRightRadius: index === array.length - 1 ? '0.5rem' : '0',
                                    }}
                                >
                                    {heading} {sortConfig.key === key && (
                                        sortConfig.direction === 'asc' ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />
                                    )}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-300">
                        {data?.length > 0 && !loader ? (
                            data?.map((row, index) => (
                                <tr key={index}>
                                    {Object.entries(headings).map(([key, value]) => (
                                        <td key={key} className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black">
                                            {
                                                key === 'isMap' ? (
                                                    row[key] ? 'true' : 'false'
                                                ) : key === 'billingUnitActions' || key === 'powerPlantTypeActions' || key === 'constantActions' ? (
                                                    <ActionButtons handleEdit={() => handleEdit(row?._id, row?.objectType)} title={title} 
                                                        handleDelete={() => handleDelete(row?._id, row?.name)}
                                                    />
                                                ) : key === 'globalActions' ? (
                                                    <EditActionButton 
                                                        handleEdit={() => handleEdit(row?._id, row?.objectType)} 
                                                        title={title} 
                                                        handleDelete={() => handleDelete(row?._id, row?.name)}
                                                    />
                                                ) : key === 'recUnitActions' ? (
                                                    <AddEditViewButtons 
                                                        handleEdit={() => handleEdit(row?._id, row?.stateCode)} 
                                                        handleView={() => handleView(row?._id, row?.stateCode)} 
                                                        handleAdd={() => handleAdd(row?._id, row?.stateCode)} 
                                                        handleRTArea={()=>handleOpenRT(row?._id)}
                                                        handleMapPowerPlantOpen={() => handleMapPowerPlantOpen(row?._id, row?.name)} 
                                                        id={row?._id} 
                                                        title={title} 
                                                        rt={true}
                                                    />
                                                ) : key === 'status' ? (
                                                    <span className="p-1 px-2 inline-flex leading-5">
                                                        {row[key].charAt(0).toUpperCase() + row[key].slice(1)}
                                                    </span>
                                                ) : key === 'financialYear' ? (
                                                    makeYearFormate(row[key]) 
                                                ) : key === 'type' ? (
                                                    displayType(row[key])
                                                ) : (
                                                    row[key]
                                                )
                                            }

                                        </td>
                                    ))}
                                </tr>
                            ))
                        ) : loader ? 
                        (
                            <tr className="">
                                <td colSpan={Object.keys(headings).length} className="">
                                    <Loading/>
                                </td>
                            </tr>
                            
                        ):
                        (
                            <tr>
                                <td colSpan={Object.keys(headings).length} className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 text-center">
                                    No Records Available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
