import React, { useEffect, useState } from 'react'
import InputBox from '../../../components/inputBox/InputBox';
import config from '../../../config/config';
import { ToastContainer, toast } from 'react-toastify';
import GooglePlacesMap from '../../../components/googlePlaceMap/GooglePlacesMap';
import { createPowerPlant, fetchPowerPlantList } from '../../../services/apis/powerPlant';
import { fetchStateList, getStateByStateCode } from '../../../services/apis/listings';
import { createPowerPlantUnit } from '../../../services/apis/powerPlantUnit';

export default function AddPowerPlantUnitDetails({ onClose, setIsFormOpen, setUpdateCount, powerPlant, powerPlantName }) {
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        name: "",
        powerPlantId: "",
        status: "active",
    });
    const [powerPlants, setPowerPlants] = useState();

    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            powerPlantId: powerPlant
        }));
        const getPowerPlantList = async () => {

            const response = await fetchPowerPlantList();
            const data = response?.data?.data?.results;
            setPowerPlants(data);
        };
        getPowerPlantList();
    }, [powerPlant]);

    const renderFormFields = () => {
        const fields = config.powerPlantUnitSettings.fields;

        return Object.keys(fields)?.map((fieldName, index) => {
            const field = fields[fieldName];
            const { label, type, required, options } = field;

            switch (type) {
                case 'text':

                    return (
                        <div key={index} className="mb-5">
                            <div className="flex items-center">
                                <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                                    {label}{required && <span className="text-red-500">*</span>} :
                                </label>
                                <InputBox
                                    width="w-3/4"
                                    placeholder={`Enter ${label}`}
                                    value={formData[fieldName] || ''}
                                    onChange={(e) => handleChange(fieldName, e.target.value)}
                                    className="ml-4"
                                />
                            </div>
                            {errors[fieldName] && (
                                <p className="absolute text-red-500 text-sm ml-44">{errors[fieldName]}</p>
                            )}
                        </div>
                    );

                case 'select':
                    if (fieldName === "powerPlantId") {
                        return (
                            <div key={index} className="mb-5">
                                <div className="flex items-center">
                                    <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                                        {label}{required && <span className="text-red-500">*</span>} :
                                    </label>
                                    <select
                                        disabled
                                        id={fieldName}
                                        value={formData[fieldName] || ''}
                                        onChange={(e) => handleChange(fieldName, e.target.value)}
                                        className={`w-3/4 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${errors[fieldName] ? 'border-red-500' : ''
                                            }`}
                                    >
                                        <option value="">Select {label}</option>
                                        {powerPlants?.map((option, index) => (
                                            <option key={index} value={option._id}>{option.name}</option>
                                        ))}
                                    </select>
                                </div>
                                {errors[fieldName] && (
                                    <p className="absolute text-red-500 text-sm ml-44">{errors[fieldName]}</p>
                                )}
                            </div>
                        );
                    } else {
                        return (
                            <div key={index} className="mb-5">
                                <div className="flex items-center">
                                    <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                                        {label}{required && <span className="text-red-500">*</span>} :
                                    </label>
                                    <select
                                        id={fieldName}
                                        value={formData[fieldName] || ''}
                                        onChange={(e) => handleChange(fieldName, e.target.value)}
                                        className={`w-3/4 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${errors[fieldName] ? 'border-red-500' : ''
                                            }`}
                                    >
                                        <option value="">Select {label}</option>
                                        <option value="active">Active</option>
                                        <option value="inactive">InActive</option>

                                    </select>
                                </div>
                                {errors[fieldName] && (
                                    <p className="absolute text-red-500 text-sm ml-44">{errors[fieldName]}</p>
                                )}
                            </div>
                        );
                    }
                default:
                    return null;
            }
        });
    };

    const handleChange = (fieldName, value) => {
        setFormData({ ...formData, [fieldName]: value });
        setErrors(prevErrors => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[fieldName];
            return updatedErrors;
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const validationErrors = {};
        if (!formData.name.trim()) {
            validationErrors.name = "Name is required";
        }
        if (!formData.powerPlantId.trim()) {
            validationErrors.name = "Power plant unit is required";
        }
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const payload = {
            ...formData,
        }

        try {
            const response = await createPowerPlantUnit(payload);
            if (response.data.statusCode === 200) {
                toast.success('Power plant unit added succesfully.', {
                    position: "top-center",
                    autoClose: 3000
                });
                setUpdateCount(prevCount => prevCount + 1);
                setIsFormOpen(false);
            } else {
                toast.error('Error while creating power plant unit.', {
                    position: "top-center",
                });
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("Error while creating power plant unit.", {
                position: "top-center",
            })
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
            <div className="bg-white p-4 shadow-lg w-full max-w-3xl max-h-[98vh] overflow-y-auto rounded-lg">
                <h2 className="text-lg font-semibold">Add New Power Plant Unit for: {powerPlantName}</h2>
                <form>
                    <div className="flex-grow overflow-auto mt-3">
                        <div className="max-w-8xl">
                            <div className="bg-white shadow-md overflow-hidden p-4">
                                {renderFormFields()}
                            </div>
                        </div>
                    </div>
                </form>
                <div className="sticky bottom-0 bg-white p-4 flex justify-center gap-8">
                    <button
                        type="button"
                        onClick={onClose}
                        className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}
