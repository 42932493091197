import React, { useEffect, useState } from 'react';
import { fetchUnitById, getStateByStateCode, updateUnitById } from '../../../services/apis/listings';
import { toast } from 'react-toastify';

export default function EditToDPurchase({ id, stateCode, onClose, setEditFormOpen, setUpdateCount }) {
    const [stateData, setStateData] = useState(null);
    const [todSlots, setTodSlots] = useState([]);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const getStateDataByStateCode = async () => {
            const response = await getStateByStateCode(stateCode);
            const data = response?.data;
            setStateData(data);
            setTodSlots(Array(data.noOfTodSlots).fill(''));
        };
        getStateDataByStateCode();
    }, [stateCode]);

    useEffect(() => {
        const getUnitById = async () => {
            const response = await fetchUnitById(id);
            const data = response?.data?.result;
            if (data && data.toDPurchase) {
                const { toDPurchase } = data;
                const initialTodSlots = Object.keys(toDPurchase).map((key) => toDPurchase[key]);
                setTodSlots(initialTodSlots);
            } else {
                setTodSlots(Array(stateData?.noOfTodSlots || 0).fill(''));
            }
        };
        if (stateData) {
            getUnitById();
        }
    }, [id, stateData]);

    const handleInputChange = (index, value) => {
        const newTodSlots = [...todSlots];
        newTodSlots[index] = value;
        setTodSlots(newTodSlots);
        setErrors((prev) => ({ ...prev, [index]: '' }));
    };

    const validateFields = () => {
        const newErrors = {};
        todSlots.forEach((slot, index) => {
            if (!slot || isNaN(slot) || Number(slot) <= 0) {
                newErrors[index] = `ToD ${index + 1} is required`;
            }
        });
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            const toDPurchase = todSlots.reduce((acc, slot, index) => {
                acc[`ToD${index + 1}`] = Number(slot);
                return acc;
            }, {});

            const payload = {
                toDPurchase: toDPurchase,
            };

            // Send payload to the API
            // console.log('Submitting:', toDPurchase);

            const response = await updateUnitById(id, payload);
            // console.log(response,'res')
            // return;

            // On success
            setEditFormOpen(false);
            setUpdateCount((prev) => prev + 1);
            toast.success('ToD Purchase details updated successfully');
        } catch (error) {
            toast.error('Failed to add ToD Purchase details');
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
            <div className="bg-white p-4 shadow-lg w-full max-w-3xl max-h-[95vh] overflow-y-auto rounded-lg">
                <h2 className="text-lg font-semibold">Edit ToD Purchase Details</h2>
                <form onSubmit={handleSubmit}>
                    <div className="flex-grow overflow-auto">
                        <div className="max-w-8xl">
                            <div className="bg-white shadow-md overflow-hidden p-4">
                                {stateData && (
                                    <div>
                                        {todSlots.map((slot, index) => (
                                            <>
                                                <div key={index} className="mb-4 flex">
                                                    <label className="block text-sm text-gray-700 w-24 mt-2 ml-8">
                                                        ToD {index + 1}:
                                                    </label>
                                                    <input
                                                        type="number"
                                                        value={slot}
                                                        onChange={(e) => handleInputChange(index, e.target.value)}
                                                        className="mt-1 block w-full px-4 py-2 border border-blue-300  rounded-lg mr-16"
                                                    />
                                                </div>
                                                {errors[index] && (
                                                    <p className="absolute text-red-500 text-sm mt-[-18px] ml-28">{errors[index]}</p>
                                                )}
                                            </>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="sticky bottom-0 bg-white shadow-md p-4 flex justify-end">
                        <button
                            type="button"
                            onClick={onClose}
                            className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4"
                        >
                            Save
                        </button>
                    </div>
                    {/* <ToastContainer position="top-center" autoClose={3000} /> */}
                </form>
            </div>
        </div>
    );
}
