const PagesList = [
    {
        _id: 1,
        title: "EcoPower Insights",
        description: "Lorem Ipsum Montreal School",
        type: "page",
        publishDate: "2022-01-01",
        status: "publish",
        slug: "ecopower-insights",
        metaTitle: "",
        metaDataDescription: "",
        metaKeyword: ""
    },
    {
        _id: 2,
        title: "Renewable Rhythms",
        description: "Lorem Ipsum Montreal Rhythms",
        type: "page",
        publishDate: "2022-01-02",
        status: "draft",
        slug: "renewable-rhythms",
        metaTitle: "",
        metaDataDescription: "",
        metaKeyword: ""
    }
]

export default PagesList;