import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const user = JSON.parse(localStorage.getItem("user"));
const token = user?.result?.token;

export const createContent = async (formData) => {
  try {
    const response = await axios.post(`${API_URL}/content`, formData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error creating content:", error);
    return error?.response?.data;
    // throw error;
  }
};

export const fetchContentList = async (payload) => {
  try {
    const response = await axios.post(`${API_URL}/content/list`, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    console.error("Error fetching content:", error);
    return [];
    // throw error;
  }
};

export const editContentById = async (id, formData) => {
  try {
    const response = await axios.put(`${API_URL}/content/${id}`, formData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    console.error("Error editing creating content:", error);
    return error?.response?.data;
    // throw error;
  }
};

export const getContentById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/content/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    console.error("Error getting content:", error);
    return false;
    // throw error;
  }
};

export const deleteContentById = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/content/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    console.error("Error deleting content:", error);
    return false;
    // throw error;
  }
};

export const fetchCategories = async () => {
  try {
    const response = await axios.post(`${API_URL}/contentCat/list`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    console.error("Error fetching content:", error);
    return [];
    // throw error;
  }
};

export const editStateId = async (data) => {
  try {
    const response = await axios.put(`${API_URL}/state/${data?.code}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    console.error("Error editing creating content:", error);
    return error?.response?.data;
    // throw error;
  }
};

export const editDiscomId = async (data) => {
  try {
    const formData = {
      status: data.status,
      name: data.name,
      code: data.code,
      // Note: cant we change all the values
    };
    const response = await axios.put(
      `${API_URL}/disComCode/${data.discomId}`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data;
  } catch (error) {
    console.error("Error editing creating content:", error);
    return error?.response?.data;
    // throw error;
  }
};

export const createNewDisCom = async (data) => {
  try {
    const formData = {
      status: data.status,
      name: data.name,
      code: data.code,
      stateId: data.stateId,
    };
    const response = await axios.post(`${API_URL}/disComCode/add`, formData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    console.error("Error creating new disCom code:", error);
    return error?.response?.data;
  }
};

export const getDisComId = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/disComCode/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    console.error("Error retrieving disCom code by ID:", error);
    return error?.response?.data;
  }
};
