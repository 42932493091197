import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { cleanUpData, deleteOCRData, fetchMetersList, fetchOCRList, fetchmetersList } from '../../services/apis/listings';
import Sidebar from '../../components/sidebar/Sidebar';
import PageTitleCard from '../../components/pageTitleCard/PageTitleCard';
import Table from '../../components/table/Table';
import config from '../../config/config';
import SelectDropdown from '../../components/selectDropdown/SelectDropdown';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ToastContainer, toast } from 'react-toastify';
import RecordsPerPageDropdown from '../../components/recordsPerPageDropdown/RecordsPerPageDropdown';
import DeleteConfirmation from '../../components/deleteConfirmation/DeleteConfirmation';
import ResetButton from '../../components/resetButton/ResetButton';


export default function OcrList() {
    const location = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();

    const { ocrListTableHeadings } = config;

    const [meterList, setMeterList] = useState([]);
    const [meter, setMeter] = useState(id);
    const [ocrListData, setOcrListData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: "_id", direction: 'asc' });
    const [count, setCount] = useState(1);
    const [limit, setLimit] = useState(() => {
        const storedLimit = parseInt(localStorage.getItem('recordsPerPage'), 10);
        return storedLimit || 50;
    });
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState({
        consumerNo: id
    })
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [deleteItemName, setDeleteItemName] = useState("");
  
    useEffect(() => {
        const fetchParentList = async () => {
            const response = await fetchMetersList();
            const data = response?.data?.results;
            setMeterList(data);
        }
        fetchParentList();
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const payload = {
                ...filters,
                page: currentPage,
                pageSize: limit,
                sortBy: sortConfig.key,
                sortOrder: sortConfig.direction,
            }
            const data = await fetchOCRList(payload);
            const ocrList = data?.data?.results;
            const tableData = ocrList?.map(item => ({
                _id: item?._id,
                meterNo: item?.data?.meterNo?.value,
                consumerNo: item?.data?.consumerNo?.value,
                consumerName: item?.data?.consumerName?.value,
                readingDate: item?.data?.readingDate?.value,
                jobId: item?.readingDate?.value
            }));
            setOcrListData(tableData);
            setTotalCount(data?.data?.total)
        }
        fetchData();
    }, [count, currentPage, sortConfig, filters]);

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        setCurrentPage(1);
    };

    const handleParentChange = (selectedMeter) => {
        setMeter(selectedMeter);
        if (selectedMeter) {
            setFilters({ ...filters, consumerNo: selectedMeter });
        }
    };

    const updateCount = () => {
        setCount(prevCount => prevCount + 1);
    };

    const handleDelete = (id, name) => {
        console.log(id, name,'id, name');
        setDeleteModalOpen(true);
        setDeleteItemId(id);
        setDeleteItemName(name);
    };

    const confirmDelete = async () => {
        const payload = {
            ocrId: deleteItemId
        }
        const response = await deleteOCRData(payload);

        if (response.status == 200) {
            toast.success(`OCR Data deleted successfully`, {
                position: "top-center",
                autoClose: 1500,
            });
            updateCount();
        } else {
            toast.error(`OCR Data could not be deleted`, {
                position: "top-center",
                autoClose: 1500,
            });
        }
        setDeleteModalOpen(false);
    };

    const handleRecordsPerPageChange = (newLimit) => {
        setLimit(newLimit);
        setCurrentPage(1);
    };

    const handleResetFilters = () => {
        setFilters({
            consumerNo: ""
        });
    };
    const handleEdit = (consumerNo) => {
        navigate(`/ocr-list/${consumerNo}`);
    }


    return (
        <div className="flex">
            <Sidebar />
            <div className="flex-grow p-4">
                <div className="max-w-8xl">
                    <PageTitleCard title="Meters List" />
                    <div className=''>
                        <div className="overflow-hidden mt-2 rounded-xl">
                            <div className="flex">
                                <div className="overflow-hidden ">
                                    <div className="p-3 flex flex-row gap-4">
                                        <SelectDropdown
                                            label="Consumer No."
                                            options={meterList?.map((meter) => {
                                                return { label: meter?.consumerNo, value: meter?.consumerNo };
                                            })}
                                            selectedValue={meter}
                                            onChange={(e) => handleParentChange(e.target.value)}
                                        />
                                        <RecordsPerPageDropdown onRecordsPerPageChange={handleRecordsPerPageChange} />
                                    </div>
                                </div>
                                <div className="p-3 ml-[550px] reset-button-container">
                                    <ResetButton onClick={handleResetFilters} />
                                </div>
                            </div>
                            <div className='bg-white p-2 rounded-xl min-h-[450px]'>
                                <Table headings={ocrListTableHeadings} data={ocrListData} handleSort={handleSort} handleDelete={handleDelete} sortConfig={sortConfig} />
                            </div>
                        </div>
                        {totalCount > limit && (
                            <div className="pagination-controls bg-white rounded-b-xl border-[#abaaaa] mb-2 pb-2 flex flex-row items-center justify-end pt-8 -mt-2">
                                <button className={`bg-[#e1e0e0] ${currentPage === 1 ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl`} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                <span className="mx-4">{Math.min(currentPage * limit, totalCount) ? Math.min(currentPage * limit, totalCount) : '0'} off {totalCount ? totalCount : '0'}</span>
                                <button className={`bg-[#e1e0e0] ${currentPage * limit >= totalCount ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl mr-2`} disabled={currentPage * limit >= totalCount} onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
                            </div>
                        )}
                    </div>
                </div>
                <ToastContainer position="top-center" autoClose={3000} />
                <DeleteConfirmation
                    isOpen={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    onConfirm={confirmDelete}
                    itemName={deleteItemName}
                />
            </div>
        </div>
    )
}
