import { useState, useEffect } from 'react';

const useRecordsPerPage = () => {
    const getInitialRecordsPerPage = () => {
        return parseInt(localStorage.getItem('recordsPerPage'), 10) || 50;
    };

    const [recordsPerPage, setRecordsPerPage] = useState(getInitialRecordsPerPage);

    useEffect(() => {
        localStorage.setItem('recordsPerPage', recordsPerPage);
    }, [recordsPerPage]);

    return [recordsPerPage, setRecordsPerPage];
};

export default useRecordsPerPage;
