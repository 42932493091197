import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import PageTitleCard from '../../components/pageTitleCard/PageTitleCard'
import Table from '../../components/table/Table'
import { cleanUpData, fetchOrganisationList } from '../../services/apis/listings';
import config from '../../config/config';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ToastContainer, toast } from 'react-toastify';
import SearchBar from '../../components/searchBar/SearchBar';
import RecordsPerPageDropdown from '../../components/recordsPerPageDropdown/RecordsPerPageDropdown';
import DeleteConfirmation from '../../components/deleteConfirmation/DeleteConfirmation';
import ResetButton from '../../components/resetButton/ResetButton';


export default function OrganisationList() {

    const navigate = useNavigate();

    const { orgListTableHeadings } = config;

    const [searchQuery, setSearchQuery] = useState('');
    const [organisationListData, setOrganisationListData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: "_id", direction: 'asc' });
    const [count, setCount] = useState(1);
    const [limit, setLimit] = useState(() => {
        const storedLimit = parseInt(localStorage.getItem('recordsPerPage'), 10);
        return storedLimit || 50;
    });
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false); 
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [deleteItemName, setDeleteItemName] = useState("");
    const [loader, setLoader] = useState(true)


    useEffect(() => {
        const fetchData = async () => {
            setLoader(true)
            const payload = {
                page: currentPage,
                pageSize: limit,
                sortBy: sortConfig.key,
                sortOrder: sortConfig.direction,
                name: searchQuery
            }
            const data = await fetchOrganisationList(payload);
            const organisationList = data?.data?.results;
            const tableData = organisationList?.map(item => ({
                _id: item?._id,
                name: item?.name,
            }));
            setOrganisationListData(tableData);
            setTotalCount(data?.data?.total)
            // console.log(tableData,'tableData')
            // console.log(organisationList,'organisationList')
            setLoader(false)
        }
        fetchData();
    },[count, currentPage, sortConfig, searchQuery, limit]);

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        setCurrentPage(1);
    };

    const handleSearch = (event) =>{
        const query = event.target.value;
        setSearchQuery(query);
        setCurrentPage(1);
    }

    const updateCount = () => {
        setCount(prevCount => prevCount + 1);
    };

    const handleDelete = (id, name) => {
        setDeleteModalOpen(true);
        setDeleteItemId(id);
        setDeleteItemName(name);
    };

    const confirmDelete = async () => {
        const payload = {
            organisationId: deleteItemId
        }
        const response = await cleanUpData(payload);

        if (response.status == 200) {
            toast.success(`Organisation Data deleted successfully`, {
                position: "top-center",
                autoClose: 1500,
            });
            updateCount();
        } else {
            toast.error(`Organisation Data could not be deleted`, {
                position: "top-center",
                autoClose: 1500,
            });
        }
        setDeleteModalOpen(false);
    };

    const handleView = (orgId) => {
        navigate(`/factory-list?organisationId=${orgId}`);
    };

    const handleRecordsPerPageChange = (newLimit) => {
        setLimit(newLimit);
        setCurrentPage(1); 
    };

    const handleResetFilters = () => {
        setSearchQuery("");
    };

    return (
        <div className="flex">
            <Sidebar />
            <div className="flex-grow p-4">
                <div className="max-w-8xl">
                    <PageTitleCard title="Organisation List" />
                    <div className=''>
                        <div className="overflow-hidden mt-2 rounded-xl">
                            <div className="flex">
                                <div className="overflow-hidden">
                                    <div className="p-3 flex flex-row gap-4">
                                        <SearchBar
                                            searchQuery={searchQuery} 
                                            handleSearch={handleSearch}
                                            title="Organisation"
                                        />
                                        <RecordsPerPageDropdown onRecordsPerPageChange={handleRecordsPerPageChange} />
                                    </div>
                                </div>
                                <div className="p-3 ml-[550px] reset-button-container">
                                    <ResetButton onClick={handleResetFilters} />
                                </div>
                            </div>
                            <div className='bg-white p-2 rounded-xl min-h-[450px]'>
                                <Table loader={loader} headings={orgListTableHeadings} data={organisationListData} handleView={handleView} handleDelete={handleDelete} handleSort={handleSort} sortConfig={sortConfig} title="View Factories List"/>
                            </div>
                        </div>
                        {totalCount > limit && (
                            <div className="pagination-controls bg-white rounded-b-xl border-[#abaaaa] mb-2 pb-2 flex flex-row items-center justify-end pt-8 -mt-2">
                                <button className={`bg-[#e1e0e0] ${currentPage === 1 ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl`} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                <span className="mx-4">{Math.min(currentPage * limit, totalCount) ? Math.min(currentPage * limit, totalCount) : '0'} off {totalCount ? totalCount : '0'}</span>
                                <button className={`bg-[#e1e0e0] ${currentPage * limit >= totalCount ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl mr-2`} disabled={currentPage * limit >= totalCount} onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
                            </div>
                        )}
                    </div>
                </div>
                <ToastContainer position="top-center" autoClose={3000} />
                <DeleteConfirmation
                    isOpen={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    onConfirm={confirmDelete}
                    itemName={deleteItemName}
                />
            </div>
        </div>
    )
}
