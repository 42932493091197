import axios from 'axios';

const user = JSON.parse(localStorage.getItem('user'));
const token = user?.result?.token

const API_URL = process.env.REACT_APP_API_URL; 

export const createGlobalVariable = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/globalRecommendationsVariable`, payload);
        // console.log(response,'response')
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const fetchGlobalVariablesList = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/globalRecommendationsVariable/list`, payload);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const fetchCollectionsList = async () => {
    try {
        const response = await axios.get(`${API_URL}/collections/list`);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const fetchCollectionsFieldList = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/collections/fieldList`,payload);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const fetchGlobalVariableById = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/globalRecommendationsVariable/${id}`);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const updateGlobalVariable = async (id, payload) => {
    try {
        const response = await axios.put(`${API_URL}/globalRecommendationsVariable/${id}`, payload);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const deleteGlobalVariableById = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/globalRecommendationsVariable/${id}`);
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}