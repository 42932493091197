import React, { useEffect, useState } from 'react';
import { editStateId } from '../../services/apis/content';
import { toast } from 'react-toastify';
import { makeFinancialYear } from '../../pages/recommendations/constantVariable/getFY';

export default function AddSlotsPopup({ title, onClose, setStatusUpdate, selectedState }) {

    const [numberOfSlots, setNumberOfSlots] = useState("");
    const [maxPNetmetering , setMaxPNetmetering] = useState("");
    const [financialYear,setFinancialYear] = useState("2024-2025")
    const [error, setError] = useState({});

    useEffect(() => {
        setNumberOfSlots(selectedState?.noOfTodSlots || "");
        setMaxPNetmetering(selectedState?.settings?.netRTSMeteringCapacity["2024"] || "")
    }, [selectedState]);

    useEffect(()=>{
        let fy= financialYear.split("-")[0]
        setMaxPNetmetering(selectedState?.settings?.netRTSMeteringCapacity[fy]||"")
    },[financialYear])

    const validateFields = () => {
        const newErrors = {};
        if (!numberOfSlots || isNaN(numberOfSlots) || Number(numberOfSlots) <= 0) {
            newErrors.numberOfSlots = 'No. of slots is required and must be a positive number';
        }
        if (!maxPNetmetering || isNaN(maxPNetmetering) || Number(maxPNetmetering) <= 0) {
            newErrors.maxPNetmetering = 'Field is required and must be a positive number';
        }
        return newErrors;
    };

    const handleSaveSlots = async () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setError(validationErrors);
            return;
        }

        try {
            const payload = {
                "code": selectedState.code,
                "noOfTodSlots": numberOfSlots,
                "maxPNetmetering": maxPNetmetering,
                "financialYear": financialYear.split("-")[0]
            };
            await editStateId(payload);
            onClose();
            setStatusUpdate(true);
            toast.success('State Data Updated successfully!');
        } catch (error) {
            toast.error('Failed to update State data!');
        }
    };
    const handleChangeFY = (e) => {
        setFinancialYear(e.target.value);
    }

    return (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-xl">
                <h2 className="text-xl font-bold mb-4">{title}</h2>
                <div className='flex flex-row'>
                    <label className='w-[22rem] py-2 mr-1'>No. of Slots<span className="text-red-500">*</span> :</label>
                    <input
                        type="number"
                        value={numberOfSlots}
                        onChange={(e) => {
                            setNumberOfSlots(e.target.value);
                            setError((prev) => ({ ...prev, numberOfSlots: '' }));
                        }}
                        placeholder={`Enter No. of slots for ${selectedState?.name}`}
                        className="w-full h-10 px-4 py-2 border border-gray-300 rounded-lg mb-6 ml-2"
                    />
                </div>
                {error.numberOfSlots && <p className="absolute text-xs text-red-500 mt-[-25px] px-1 ml-52">{error.numberOfSlots}</p>}
                <div className="flex flex-row">
                    <label className='w-[22rem] py-2 mr-1'>Financial Year<span className="text-red-500">*</span> :</label>
                    
                    <select
                        id="financialYear"
                        value={financialYear}
                        onChange={handleChangeFY}
                        className="w-full h-10 px-4 py-2 border border-gray-300 rounded-lg ml-2 mb-6">
                        <option value="">Select Financial Year</option>
                        {makeFinancialYear()?.map((slot) => (
                            <option key={slot} value={slot}>
                                {slot}
                            </option>
                        ))}
                    </select>
                </div>                
                <div className='flex flex-row releative'>
                    <label className='flex flex-col w-[22rem]'>
                        <span className=' mr-1'>Maximum Possible Rooftop Capacity<span className="text-red-500">*</span> :
                        </span>
                        <span className='text-xs text-gray-800'>(As per Netmetering regulations)</span>
                    </label>
                    

                    <input
                        // type="number"
                        value={maxPNetmetering}
                        onChange={(e) => {
                            setMaxPNetmetering(e.target.value);
                            setError((prev) => ({ ...prev, maxPNetmetering: '' }));
                        }}
                        placeholder={`Max Possible As per Netmetering regulations`}
                        className="w-full h-10 px-4 py-2 border border-gray-300 rounded-lg ml-2"
                    />
                    {/* As per Netmetering regulations */}
                    {error.maxPNetmetering && <p className="absolute text-red-500 text-xs mt-10 px-1 ml-52">{error.maxPNetmetering}</p>}
                </div>
                    {/* {error.maxPNetmetering && <p className="absolute text-red-500 text-xs top-0 px-1 ml-52">{error.maxPNetmetering}</p>} */}
                <div className="flex justify-center p-4 gap-8">
                    <button
                        onClick={onClose}
                        className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSaveSlots}
                        className="bg-white border-2 w-[85px] border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}
