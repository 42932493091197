import PagesList from "../dummyData/pagesList";
// const api = process.env.REACT_APP_API_URL;

export const getPagesListData = () => {
    try{
        return PagesList;
    } catch (e) {
        console.log(e);
    }
}

export const getPageById = (pageId) => {
    try {
        const pagesList = getPagesListData();
        const page = pagesList.find(page => page._id == pageId);
        return page;
    } catch (error) {
        console.error(error);
        return null;
    }
}