import React from "react";
import { RotatingLines } from "react-loader-spinner";

const Loading = ({width,height}) => {
  return (
    <div className="flex justify-center items-center">
      <RotatingLines
        visible={true}
        height={height?height:"96"}
        width={width?width:"96"}
        color="grey"
        strokeWidth="5"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
};

export default Loading;