import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import { fetchStateList } from '../../services/apis/listings';
import { editStateId } from '../../services/apis/content';
import PageTitleCard from '../../components/pageTitleCard/PageTitleCard';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../../components/loader/Loader';

export default function StateList() {
    const [stateListData, setStateListData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [statusUpdate, setStatusUpdate] = useState(false);
    const [sortColumn, setSortColumn] = useState("name");
    const [sortOrder, setSortOrder] = useState('asc');
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const getStateList = async () => {
            setIsLoading(true);
            const response = await fetchStateList();
            setStatusUpdate(false);
            const stateList = response?.data?.results;

            if (stateList) {
                const sortedStateList = sortData(stateList, sortColumn, sortOrder);
                setStateListData(sortedStateList);
            }
            setIsLoading(false);
        };
        getStateList();
    }, [statusUpdate, sortColumn, sortOrder]);

    const sortData = (data, column, order) => {
        if (!column) return data;
        const sortedData = [...data].sort((a, b) => {
            if (a[column] < b[column]) return order === 'asc' ? -1 : 1;
            if (a[column] > b[column]) return order === 'asc' ? 1 : -1;
            return 0;
        });
        return sortedData;
    };

    const handleSort = (column) => {
        const order = (sortColumn === column && sortOrder === 'asc') ? 'desc' : 'asc';
        setSortColumn(column);
        setSortOrder(order);
    };

    const handleToggle = async (stateId, stateName, stateCode, status) => {
        const newStatus = status === "active" ? "inactive" : "active";
        const payload = {
            "status": newStatus,
            "name": stateName,
            "code": stateCode,
        };
        await editStateId(payload);
        setStatusUpdate(true);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredStateList = stateListData.filter(state =>
        state.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        state.code.toLowerCase().includes(searchTerm.toLowerCase())
    );


    return (
        <div className="flex w-[100%]">
            <Sidebar />
            <div className={`flex-grow p-4`}>
                <PageTitleCard title="State List" />
                <div className='max-w-8xl mt-2 overflow-hidden rounded-xl'>
                    <div className="mb-2 p-2">
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={handleSearch}
                            placeholder="Search..."
                            className="w-[20%] px-4 py-2 border border-gray-200 rounded-lg"
                        />
                    </div>
                    <div className='bg-white p-2 rounded-xl'>
                        <div className="bg-white relative overflow-x-auto h-[700px] overflow-y-auto sm:rounded-lg rounded-lg state-list">
                            <table className="w-full text-sm text-left text-black ">
                                <thead className="text-xs uppercase font-medium text-black bg-gray-100">
                                    <tr>
                                        <th scope="col" className="px-6 py-4 cursor-pointer w-[25%]"
                                            onClick={() => handleSort('name')}
                                            style={{
                                                borderBottomLeftRadius: '0.5rem' ,
                                                borderTopLeftRadius: '0.5rem' ,
                                            }}
                                        >
                                            State {sortColumn === 'name' ? (sortOrder === 'asc' ? '▲' : '▼') : ''}
                                        </th>
                                        <th scope="col" className="px-6 py-4 cursor-pointer w-[16%]"
                                            onClick={() => handleSort('code')}>
                                            State Code {sortColumn === 'code' ? (sortOrder === 'asc' ? '▲' : '▼') : ''}
                                        </th>
                                        <th scope="col" className="px-3 py-4 w-[17%]"
                                            style={{
                                                borderBottomRightRadius: '0.5rem' ,
                                                borderTopRightRadius: '0.5rem' ,
                                            }}
                                        >
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                {filteredStateList.length>0 && !isLoading ? <tbody>
                                    {filteredStateList?.map((state) => (
                                        <tr
                                            className={`border-b text-black`}
                                            key={state?._id}
                                        >
                                            <td className="px-8 py-4 whitespace-nowrap flex items-center justify-left">
                                                {state?.name}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {state?.code}
                                            </td>
                                            <td className="px-[10px] py-4 flex items-center justify-left">
                                                <label className="inline-flex items-center cursor-pointer">
                                                    <input
                                                        type="checkbox"
                                                        className="sr-only peer"
                                                        onChange={() => handleToggle(state?._id, state?.name, state?.code, state?.status)}
                                                        checked={state?.status === 'active'}
                                                    />
                                                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                    <span className="ms-3 w-[3.5rem] text-sm text-black dark:text-gray-300">{state.status}</span>
                                                </label>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>:
                                isLoading?
                                <tr>
                                    <td colSpan={3} className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 text-center">
                                        <Loading/>
                                    </td>
                                </tr>
                                :
                                <tr>
                                    <td colSpan={3} className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black text-center">
                                        No Records Available
                                    </td>
                                </tr>}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={3000} />
        </div>
    );
}
