import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/sidebar/Sidebar'
import PageTitleCard from '../../../components/pageTitleCard/PageTitleCard'
import AddRegulatoryCharges from './addRegulatoryCharges/AddRegulatoryCharges';
import ResetButton from '../../../components/resetButton/ResetButton';
import FilterDropdown from '../../../components/filterDropdown/FilterDropdown';
import SearchById from '../../../components/searchById/SearchById';
import SearchBar from '../../../components/searchBar/SearchBar';
import { deleteRegulatoryChargeById, fetchRegulatoryChargesList } from '../../../services/apis/regulatoryCharges';
import config from '../../../config/config';
import ChargesTable from "./ChargesTable";
import EditRegulatoryCharges from './editRegulatoryCharges/EditRegulatoryCharges';
import { fetchDiscomList, fetchStateList } from '../../../services/apis/listings';
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RecordsPerPageDropdown from '../../../components/recordsPerPageDropdown/RecordsPerPageDropdown';
import { confirmAlert } from 'react-confirm-alert';
import DeleteConfirmation from '../../../components/deleteConfirmation/DeleteConfirmation';
import { makeFinancialYear } from './getFY';
import CloneFYpop from './CloneFYPop';



export default function RegulatoryChargesListing() {

    const types = ["central", "state", "discom"];
    const statuses = ["active","inactive"]; 
    
    const { regulatoryChargesListHeaders } = config;

    const [isFormOpen, setIsFormOpen] = useState(false);
    const [editFormOpen, setEditFormOpen] = useState(false);
    const [editId, setEditId] = useState("");
    const [editType, setEditType] = useState("");
    const [updateCount, setUpdateCount] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchUniqueQuery, setSearchUniqueQuery] = useState('');
    const [searchById, setSearchById] = useState("");
    const [tableData, setTableData] = useState([]);
    const [states, setStates] = useState([]);
    const [stateId, setStateId] = useState('');
    const [discoms, setDiscoms] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: "_id", direction: 'asc' });
    const [limit, setLimit] = useState(() => {
        const storedLimit = parseInt(localStorage.getItem('recordsPerPage'), 10);
        return storedLimit || 50;
    });
    
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState({
        financialYear:"2024-2025",
        objectType: '',
        status: '',
        state: '',
        discom: ''
    });
    const [deleteModalOpen, setDeleteModalOpen] = useState(false); 
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [deleteItemName, setDeleteItemName] = useState("");
    const [isCloneFormOpen,setIsCloneFormOpen] =useState(false)
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        const getStateList = async () => {
            const response = await fetchStateList();
            const stateList = response?.data?.results;

            if (stateList) {
                // Sort the state list alphabetically by state name
                const sortedStateList = stateList.sort((a, b) => a.name.localeCompare(b.name));
                setStates(sortedStateList);
            }
        };
        getStateList();
    },[])

    useEffect(() => {
        if(stateId){
            const getDiscomList = async () => {
                const payload = {
                    data: {
                        stateId,
                    }
                }
                const response = await fetchDiscomList(payload);
                const discomList = response?.data?.results;
                if (discomList) {
                    // Sort the state list alphabetically by state name
                    const sortedDiscomList = discomList.sort((a, b) => a.name.localeCompare(b.name));
                    setDiscoms(sortedDiscomList);
                }
            };
            getDiscomList();
        }
    },[stateId])

    const getRegulatoryChargesList = async () => {
        setLoader(true)
        const payload = {
            page: currentPage,
            pageSize: limit,
            id: searchById,
            financialYear:filters.financialYear?.split("-")[0],
            objectType: filters.objectType,
            status: filters.status,
            state: filters.state,
            discom: filters.discom,
            name: searchQuery,
            uniqueName: searchUniqueQuery,
            sortBy: sortConfig.key,
            sortOrder: sortConfig.direction,
        };
        const response = await fetchRegulatoryChargesList(payload);
        const data = response?.data?.data?.results;
        setTableData(data);
        setTotalCount(response?.data?.data?.total)
        // console.log(data,'data');
        setLoader(false)
    };

    useEffect(() => {
        getRegulatoryChargesList();
    },[currentPage, filters, sortConfig, searchQuery, searchUniqueQuery, searchById, updateCount, limit])

    const handleAddButtonClick = () => {
        setIsFormOpen(true);
    };

    const handleCloseForm = () => {
        setIsFormOpen(false);
    };

    const handleEditCloseForm = () => {
        setEditFormOpen(false);
    };
    const handleCloseCloneFrom= () =>{
        setIsCloneFormOpen(false)
    }
    const handleOpneCloneFrom= () =>{
        setIsCloneFormOpen(true)
    }

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        setCurrentPage(1);
    };

    const handleEdit = (id, objectType) => {
        setEditFormOpen(true);
        setEditId(id);
        setEditType(objectType);
    };

    const handleDelete = (id, name) => {
        setDeleteModalOpen(true);
        setDeleteItemId(id);
        setDeleteItemName(name);
    };

    const confirmDelete = async () => {
        const response = await deleteRegulatoryChargeById(deleteItemId);

        if (response?.data?.data?.length === 0) {
            toast.error('Failed to delete the Regulatory Charge. Please try again later.', {
                position: "top-center",
                autoClose: 1500,
            });
        } else {
            toast.success('Regulatory Charge deleted successfully!', {
                position: "top-center",
                autoClose: 1500,
            });
            setUpdateCount((prevCount) => prevCount + 1);
        }
        setDeleteModalOpen(false);
    };

    const handleFilterChange = (filterType, value) => {
        setFilters({ ...filters, [filterType]: value });
        setCurrentPage(1);
    };

    const handleSearch = (event) =>{
        const query = event.target.value;
        setSearchQuery(query);
        setCurrentPage(1);
    }

    const handleUniqueSearch = (event) =>{
        const query = event.target.value;
        setSearchUniqueQuery(query);
        setCurrentPage(1);
    }

    const handleSearchById = (event) => {
        const id = event.target.value;
        setSearchById(id);
        setCurrentPage(1);
    }; 

    const handleResetFilters = () => {
        
        setFilters({
            financialYear:'2024-2025',
            objectType: '',
            status: '',
            state: '',
            discom: ''
        });
        setDiscoms([]);
        setSearchById('');
        setSearchQuery('');
        setSearchUniqueQuery('');;
        // setCurrentPage(1);
    };

    const handleStateChange = (selectedState) => {
        setStateId(selectedState);
        if (selectedState) {
            setFilters({ ...filters, state: selectedState });
        }
    };

    const handleDiscomChange = (selectedDiscom) => {
        // setStateId(selectedDiscom);
        if (selectedDiscom) {
            setFilters({ ...filters, discom: selectedDiscom });
        }
    };

    const handleRecordsPerPageChange = (newLimit) => {
        setLimit(newLimit);
        setCurrentPage(1); 
    };

    return (
        <div className="flex">
            <Sidebar />
            <div className="p-4 overflow-x-auto">
                <div className="max-w-[1920px]">
                    <PageTitleCard title="Regulatory Charges List" onAddButtonClick={handleAddButtonClick} addLinkText="Add New Regulatory Charges" clone={true} cloneFromOpen={handleOpneCloneFrom} />
                    <div className=''>
                        <div className=" overflow-hidden mt-2 rounded-xl ">
                            <div className="flex flex-wrap">
                                <div className="p-3 flex flex-wrap items-center justify-start gap-4 space-y-4 md:space-y-0 md:flex-row">
                                    <SearchBar searchQuery={searchQuery} handleSearch={handleSearch} title="Variable Name"/>
                                    <SearchBar searchQuery={searchUniqueQuery} handleSearch={handleUniqueSearch} title="Unique Name"/>
                                    <SearchById filterById={searchById} handleFilterById={handleSearchById}/>
                                    <FilterDropdown
                                        label="FY"
                                        options={makeFinancialYear()}
                                        
                                        selectedValue={filters.financialYear}
                                        onChange={(value) => handleFilterChange('financialYear', value)}
                                    />
                                    <FilterDropdown
                                        label="Variable Type"
                                        options={types}
                                        selectedValue={filters.objectType}
                                        onChange={(value) => handleFilterChange('objectType', value)}
                                    />
                                    <FilterDropdown
                                        label="Status"
                                        options={statuses} 
                                        selectedValue={filters.status}
                                        onChange={(value) => handleFilterChange('status', value)}
                                    />
                                    <SelectDropdown
                                        label="State"
                                        selectedValue={filters.state}
                                        onChange={(e) => handleStateChange(e.target.value)}
                                        options={states?.map((state) => {
                                            return { label: state?.name, value: state?._id };
                                        })}
                                    />
                                    <SelectDropdown
                                        label="Discom"
                                        options={discoms?.map((discom) => {
                                            return { label: discom?.name, value: discom?._id };
                                        })}
                                        selectedValue={filters.discom}
                                        onChange={(e) => handleDiscomChange(e.target.value)}
                                    />
                                    <RecordsPerPageDropdown onRecordsPerPageChange={handleRecordsPerPageChange} />
                                </div>
                                <div className="p-3 mr-2 reset-button-container">
                                    <ResetButton onClick={handleResetFilters} />
                                </div>
                            </div>
                            <div className='bg-white p-2 rounded-xl min-h-[350px]'>
                                <ChargesTable loader={loader} headings={regulatoryChargesListHeaders} data={tableData} handleEdit={(id, objectType) => handleEdit(id, objectType)} handleDelete={(id, name) => handleDelete(id, name)} handleSort={handleSort} sortConfig={sortConfig} title="Edit" />
                            </div>
                        </div>
                        {totalCount > limit && (
                            <div className="pagination-controls bg-white rounded-b-xl border-[#abaaaa] mb-2 pb-2 flex flex-row items-center justify-end pt-8 -mt-2">
                                <button className={`bg-[#e1e0e0] ${currentPage === 1 ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl`} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                <span className="mx-4">{Math.min(currentPage * limit, totalCount) ? Math.min(currentPage * limit, totalCount) : '0'} off {totalCount ? totalCount : '0'}</span>
                                <button className={`bg-[#e1e0e0] ${currentPage * limit >= totalCount ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl mr-2`} disabled={currentPage * limit >= totalCount} onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
                            </div>
                        )}
                    </div>
                </div>
                {isFormOpen && <AddRegulatoryCharges onClose={handleCloseForm} setUpdateCount={setUpdateCount}/>}
                {editFormOpen && <EditRegulatoryCharges onClose={handleEditCloseForm} editType={editType} editId={editId} setUpdateCount={setUpdateCount}/>}
                {isCloneFormOpen && <CloneFYpop onClose={handleCloseCloneFrom} setUpdateCount={setUpdateCount}/>}
                <ToastContainer position="top-center" autoClose={3000} />
                <DeleteConfirmation
                    isOpen={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    onConfirm={confirmDelete}
                    itemName={deleteItemName}
                />
            </div>
        </div>
    )
}
