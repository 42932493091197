import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import EditActionButton from "../../../components/actionButtons/EditActionButton";
import ActionButtons from "../../../components/actionButtons/ActionButtons";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Link } from "react-router-dom";

const getDisplayValue = (key, row) => {
  switch (key) {
    case "powerPlantId":
      return row.powerPlantId?.name;
    default:
      return row[key] || "-";
  }
};

export default function PowerPlantUnitTable({
  headings,
  data,
  handleSort,
  sortConfig,
  handleEdit,
  handleDelete,
  title,
}) {
  return (
    <div className="bg-white overflow-hidden ">
      <div className="">
        <div className="">
          <table className="min-w-full px-2">
            <thead>
              <tr>
                {Object.entries(headings).map(([key, heading], index, array) => (
                  <th
                    key={heading}
                    className={`px-6 py-4 bg-gray-100 text-left text-xs leading-4 font-medium text-black uppercase tracking-wider ${
                      heading !== "Actions" && heading !== "State"
                        ? "cursor-pointer"
                        : ""
                    }`}
                    onClick={() =>
                      heading !== "Actions" &&
                      heading !== "State" &&
                      handleSort(key)
                    }
                    style={{
                        borderBottomLeftRadius: index === 0 ? '0.5rem' : '0',
                        borderBottomRightRadius: index === array.length - 1 ? '0.5rem' : '0',
                        borderTopLeftRadius: index === 0 ? '0.5rem' : '0',
                        borderTopRightRadius: index === array.length - 1 ? '0.5rem' : '0',
                    }}
                  >
                    {heading}{" "}
                    {sortConfig.key === key &&
                      (sortConfig.direction === "asc" ? (
                        <FontAwesomeIcon icon={faArrowUp} />
                      ) : (
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          data-tip="Add new item"
                        />
                      ))}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data?.length > 0 ? (
                data?.map((row, index) => (
                  <tr key={index}>
                    {Object?.entries(headings)?.map(([key, value]) => (
                      <td
                        key={key}
                        className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black"
                      >
                        {key === "actions" ? (
                          <div className="flex flex-row">
                            <ActionButtons
                              handleEdit={() => handleEdit(row?._id)}
                              handleDelete={() => handleDelete(row?._id, row?.name)}
                              title={title}
                            />
                            <Link to={`/recommendations/generate-power-plant-data-list/${row?._id}`}>
                            <FontAwesomeIcon
                              id="my-tooltip"
                              className="cursor-pointer"
                              icon={faPlus}
                            />
                            </Link>
                            <Tooltip
                              anchorSelect="#my-tooltip"
                              content="Generate Power Plant Data"
                            />
                          </div>
                        ) : (
                          getDisplayValue(key, row)
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={Object.keys(headings).length}
                    className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black text-center"
                  >
                    No Records Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
