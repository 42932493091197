import { faAdd, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef, useEffect } from "react";

const LogicalFormulaBuilder = ({
  conditions,
  setConditions,
  updatedSuggestions,
  keyword,
  setKeyword,
  showErrors,
  addFormula,
  setShowErrors,
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeField, setActiveField] = useState({ index: -1, field: "" });
  const [suggestions, setSuggestions] = useState([]);
  const conditionRefs = useRef([]);
  const valueRefs = useRef([]);

  const handleConditionChange = (index, field, value) => {
    console.log({ index, field, value });
    setConditions((prevConditions) => {
      const newConditions = [...prevConditions];
      newConditions[index][field] = value;
      newConditions[index]["error"] = "";
      return newConditions;
    });

    const inputRefs = field === "condition" ? conditionRefs : valueRefs;

    // Ensure the ref is correctly assigned before accessing selectionStart
    if (inputRefs.current[index]) {
      const cursorPosition = inputRefs.current[index].selectionStart;
      console.log({ cursorPosition });
      const leftPart = value.slice(0, cursorPosition);
      const lastWord = leftPart
        .split(/[^a-zA-Z0-9_&|!^~<>=+\-*\/%\[\]{}.,;:]/)
        .pop();
      setKeyword(lastWord);
      console.log({ updatedSuggestions });
      console.log({ lastWord });
      if (field === "condition" || field === "value") {
        setSuggestions(
          updatedSuggestions.filter((option) =>
            option.toLowerCase().includes(lastWord.toLowerCase())
          )
        );
        setShowSuggestions(true);
      }
    }
  };

  useEffect(() => {
    console.log({ updatedSuggestions });
    setSuggestions(
      updatedSuggestions.filter((option) =>
        option.toLowerCase().includes(keyword.toLowerCase())
      )
    );
    setShowSuggestions(true);
  }, [updatedSuggestions]);

  useEffect(() => {
    console.log("conditionRefs.current", conditionRefs.current);
    console.log("valueRefs.current", valueRefs.current);
  }, [conditionRefs.current, valueRefs.current]);

  const handleSuggestionClick = (index, field, suggestion) => {
    const inputRefs = field === "condition" ? conditionRefs : valueRefs;
    const cursorPosition = inputRefs.current[index]?.selectionStart || 0;
    const value = conditions[index][field];
    const leftPart = value.slice(0, cursorPosition);
    const rightPart = value.slice(cursorPosition);
    const lastWord = leftPart
      .split(/[^a-zA-Z0-9_&|!^~<>=+\-*\/%\[\]{}.,;:]/)
      .pop();
    if (lastWord === suggestion) {
      const newLeftPart = leftPart.replace(`${lastWord}$`, `  ${suggestion}  `);

      console.log({ newLeftPart });
      const newConditions = [...conditions];
      newConditions[index][field] = newLeftPart + rightPart;

      setConditions(newConditions);
      setShowSuggestions(false);
    } else {
      const newLeftPart = leftPart.replace(
        new RegExp(`${lastWord}$`),
        ` ${suggestion} `
      );

      console.log({ newLeftPart });
      const newConditions = [...conditions];
      newConditions[index][field] = newLeftPart + rightPart;

      setConditions(newConditions);
      setShowSuggestions(false);
    }
  };

  const handleInputFocus = (index, field) => {
    setActiveField({ index, field });
    setShowSuggestions(false); // Hide suggestions initially on focus
  };

  const isValidLogicalExpression = (expression) => {
    const regex =
      /^([a-zA-Z_$][a-zA-Z_$0-9]*\s*\(\s*(([a-zA-Z_$][a-zA-Z_$0-9]*|\d+(\.\d+)?)(\s*[\+\-\*\/]\s*([a-zA-Z_$][a-zA-Z_$0-9]*|\d+(\.\d+)?))*)(\s*,\s*(([a-zA-Z_$][a-zA-Z_$0-9]*|\d+(\.\d+)?)(\s*[\+\-\*\/]\s*([a-zA-Z_$][a-zA-Z_$0-9]*|\d+(\.\d+)?))*))*\s*\)|[a-zA-Z_$][a-zA-Z_$0-9]*|\d+(\.\d+)?)(\s*[\+\-\*\/]\s*([a-zA-Z_$][a-zA-Z_$0-9]*|\d+(\.\d+)?))*\s*(==|!=|>|<|>=|<=)\s*([a-zA-Z_$][a-zA-Z_$0-9]*|\d+(\.\d+)?|'[^']*'|"[^"]*")$/;

    return regex.test(expression.trim());
  };

  const validateConditions = () => {
    const newConditions = conditions.map((cond) => {
      if (cond.type !== "else" && (!cond.condition || !cond.value)) {
        return { ...cond, error: "Condition and value are required" };
      }

      //   else if (
      //     cond.type !== "else" &&
      //     !isValidLogicalExpression(cond.condition)
      //   ) {
      //     return {
      //       ...cond,
      //       error: "Condition must be a valid logical expression",
      //     };
      //   }
      else if (cond.type === "else" && !cond.value) {
        return { ...cond, error: "Value is required" };
      }
      return { ...cond, error: "" };
    });

    setConditions(newConditions);
    return newConditions.every((cond) => cond.error === "");
  };

  const addCondition = () => {
    if (validateConditions()) {
      setConditions([
        ...conditions,
        { type: "elseif", condition: "", value: "", error: "" },
      ]);
    }else{
        setShowErrors(true);
    }
  };

  const removeCondition = (index) => {
    const newConditions = conditions.filter((_, i) => i !== index);
    setConditions(newConditions);
  };

  return (
    <div className="py-4 max-w-2xl">
      {conditions &&
        conditions?.map((cond, index) => (
          <div key={index} className="mb-4">
            <div className="grid grid-cols-12 gap-2 items-start">
              <select
                className="border border-gray-300 rounded px-2 py-1 col-span-2"
                value={cond.type}
                onChange={(e) =>
                  handleConditionChange(index, "type", e.target.value)
                }
              >
                {index === 0 ? (
                  <option value="if">if</option>
                ) : (
                  <>
                    <option value="elseif">elseif</option>
                    <option value="else">else</option>
                  </>
                )}
              </select>
              {cond.type !== "else" && (
                <div className="relative col-span-8">
                  <textarea
                    type="text"
                    className={`border rounded px-2 text-[16px] font-medium py-1 w-full ${
                      cond.error && showErrors
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                    placeholder="condition"
                    value={cond.condition}
                    onChange={(e) =>
                      handleConditionChange(index, "condition", e.target.value)
                    }
                    onFocus={() => handleInputFocus(index, "condition")}
                    ref={(el) => (conditionRefs.current[index] = el)}
                  />
                  {showSuggestions &&
                    activeField.index === index &&
                    activeField.field === "condition" && (
                      <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded mt-1 max-h-48 overflow-y-auto">
                        {suggestions.map((suggestion, i) => (
                          <li
                            key={i}
                            className="px-2 py-1 cursor-pointer hover:bg-gray-200"
                            onClick={() =>
                              handleSuggestionClick(
                                index,
                                "condition",
                                suggestion
                              )
                            }
                          >
                            {suggestion}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              )}
              {cond.type === "else" && (
                <div className="relative col-span-8">
                  <textarea
                    type="text"
                    className={`border rounded px-2 text-[16px] py-1 w-full ${
                      cond.error && showErrors
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                    placeholder="value"
                    value={cond.value}
                    onChange={(e) =>
                      handleConditionChange(index, "value", e.target.value)
                    }
                    onFocus={() => handleInputFocus(index, "value")}
                    ref={(el) => (valueRefs.current[index] = el)}
                  />
                  {showSuggestions &&
                    activeField.index === index &&
                    activeField.field === "value" && (
                      <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded mt-1 max-h-48 overflow-y-auto">
                        {suggestions.map((suggestion, i) => (
                          <li
                            key={i}
                            className="px-2 py-1 cursor-pointer hover:bg-gray-200"
                            onClick={() =>
                              handleSuggestionClick(index, "value", suggestion)
                            }
                          >
                            {suggestion}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              )}
              {index !== 0 && (
                <button
                  className=" text-black p-2 py-1 rounded col-span-2"
                  onClick={() => removeCondition(index)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              )}

              {cond.error !== "" && cond.type === "else" && showErrors && (
                <div className="text-red-500  pb-2 capitalize flex justify-end text-sm col-span-10">
                  {cond.error}
                </div>
              )}
            </div>
            {cond.type !== "else" && (
              <div className="grid grid-cols-12 gap-2 items-start mt-2">
                <label className="col-span-2 text-center">then</label>
                <div className="relative col-span-8">
                  <textarea
                    type="text"
                    className={`border rounded px-2  text-[16px]  py-1 w-full ${
                      cond.error && showErrors
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                    placeholder="value"
                    value={cond.value}
                    onChange={(e) =>
                      handleConditionChange(index, "value", e.target.value)
                    }
                    onFocus={() => handleInputFocus(index, "value")}
                    ref={(el) => (valueRefs.current[index] = el)}
                  />
                  {showSuggestions &&
                    activeField.index === index &&
                    activeField.field === "value" && (
                      <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded mt-1 max-h-48 overflow-y-auto">
                        {suggestions.map((suggestion, i) => (
                          <li
                            key={i}
                            className="px-2 py-1 cursor-pointer hover:bg-gray-200"
                            onClick={() =>
                              handleSuggestionClick(index, "value", suggestion)
                            }
                          >
                            {suggestion}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
                {cond.error !== "" && showErrors && (
                  <span className="text-red-500 capitalize flex justify-end text-sm col-span-10">
                    {cond.error}
                  </span>
                )}
              </div>
            )}
          </div>
        ))}

      { (
        <div className="grid grid-cols-12 gap-2 items-end mt-8">
        <div className="col-span-2"></div>
        <div className="col-span-4 justify-center"><button
            onClick={addFormula}
            className="bg-green-800 text-white w-[160px] p-2 rounded-lg"
          >
            Save Formula
          </button></div>

    {conditions && conditions[conditions.length - 1].type !== "else" &&(<div className="col-span-4 ">
        <button
          className="bg-green-800 text-white   w-[160px]  justify-end  px-4 py-2 rounded"
          onClick={addCondition}
        >
          <FontAwesomeIcon icon={faAdd} /> Add Condition
        </button>
        </div>)}
        </div>
      )}
    </div>
  );
};

export default LogicalFormulaBuilder;
