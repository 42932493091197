import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Sidebar from "../../../components/sidebar/Sidebar";

import { ToastContainer, toast } from "react-toastify";
import BackButton from "../../../components/backButton/BackButton";
import {
  addPowerPlantData,
  deleteExistingPowerPlantDataOfParticularDate,
  fetchPowerPlantDataList,
} from "../../../services/apis/powerPlantData";
import { format, parse, isValid } from "date-fns";

export default function PowerPlantDataAdd() {
  const [hourlyData, setHourlyData] = useState([]);
  const [editCell, setEditCell] = useState({ row: -1, field: "" });
  let [searchParams, setSearchParams] = useSearchParams();

  const [date, setDate] = useState("");
  const [powerPlantUnitId, setPowerPlantUnitId] = useState("");
  const navigate = useNavigate();
  const handleEdit = (value, rowIndex, field) => {
    const updatedData = hourlyData.map((item, index) => {
      if (index === rowIndex) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setHourlyData(updatedData);
  };

  useEffect(() => {
    const date = searchParams.get("date");
    setDate(date);
    const powerplantUnitId = searchParams.get("powerplantunitId");
    setPowerPlantUnitId(powerplantUnitId);
  }, [searchParams]);

  async function generateHourlyData(date,powerPlantUnitID) {
    if (date) {
      const [year, month, day] = date.split("-");
      const formattedDate = `${year}-${month}-${day}T00:00:00Z`;
      const apiData = await fetchPowerPlantDataList({ date: formattedDate,powerPlantUnitId:powerPlantUnitID });
      return apiData?.data?.data?.results;
    }
  }

  const formatDate = (date) => {
    const [year, month, day] = date.split("-");
    const formattedDate = `${year}-${month}-${day}T00:00:00Z`;
    return formattedDate;
  };

  useEffect(() => {
    async function fetchData() {
      if (date && powerPlantUnitId !== "") {

        const hourlyApiData = await generateHourlyData(date,powerPlantUnitId);
        const outputArray = hourlyApiData.map((item) => {
          const date = new Date(item.date);
          const formattedDate = date
            .toLocaleDateString("en-GB")
            .split("/")
            .reverse()
            .join("-");
          return {
            date: formattedDate,
            startInterval: item.intervalStart,
            endInterval: item.intervalEnd,
            energyValue: item.energy.value,
            energyUnit: item.energy.unit,
          };
        });

    
            const hourlyData = [];
            for (let hour = 0; hour < 24; hour++) {
                if (outputArray[hour]) { // Check if the element exists
                    const startInterval = `${String(hour).padStart(2, "0")}:00`;
                    const endInterval = `${String(hour + 1).padStart(2, "0")}:00`;
                    let energyValue = 0;
                    let energyUnit ="kwh"
                    outputArray.forEach((row)=>{
                      if(row.startInterval === startInterval && row.endInterval === endInterval){
                        energyValue = row.energyValue
                        energyUnit= row.energyUnit
                      }
                    })
                    hourlyData.push({
                      date,
                      startInterval,
                      endInterval,
                      energyValue,
                      energyUnit,
                    });
                  } else { // Handle the case where outputArray[hour] is undefined
                    const startInterval = `${String(hour).padStart(2, "0")}:00`;
                    const endInterval = `${String(hour + 1).padStart(2, "0")}:00`;
                    hourlyData.push({
                      date,
                      startInterval,
                      endInterval,
                      energyValue: 0, // Default value when data is missing
                      energyUnit: "kwh", // Default unit
                    });
                  }
                }
          setHourlyData(hourlyData);
        
      }
    }
    fetchData();
  }, [date]);

  const convertArray = (input, id) => {
    return input.map((item) => {
      // Convert date format to "YYYY-MM-DDT00:00:00Z"

      const formattedDate = format(item.date, "yyyy-MM-dd'T'00:00:00.000'Z'");

      // Return the transformed object
      return {
        date: formattedDate,
        intervalStart: item.startInterval,
        intervalEnd: item.endInterval,
        energy: {
          value: item.energyValue, // Get energy value from energyValue
          unit: item.energyUnit, // Get energy unit from energyUnit
        },
        powerPlantUnitId: id,
      };
    });
  };
  const handleSave = async () => {
    try {
      // delete existing data
      const formattedDate = formatDate(date);

      const deletedData = await deleteExistingPowerPlantDataOfParticularDate(
        formattedDate,powerPlantUnitId
      );

      const convertedArray = convertArray(hourlyData, powerPlantUnitId);


      const response = await addPowerPlantData(convertedArray);
      toast.success(`${response.data.message}`);
      navigate(`/recommendations/generate-power-plant-data-list/${powerPlantUnitId}`);
    } catch (error) {
      console.error("Error saving TOD Incentives:", error);
      toast.error("Failed to update TOD Incentives");
    }
  };

  return (
    <div className="flex w-[100%]">
      <Sidebar />
      <div className="w-full p-4">
        <ToastContainer position="top-center" autoClose={3000} />
        <div className="flex-grow mb-1">
          <div className="bg-white overflow-hidden rounded-xl">
            <div className="p-4 mt-1 bg-white text-black flex justify-between items-center">
              <h2 className="text-lg font-semibold">{`Power Plant Data `}</h2>
              <BackButton />
            </div>
          </div>
        </div>
        <div className="overflow-x-auto relative bg-white mt-4 p-2 rounded-xl">
          <table className="w-full text-center text-sm text-black ">
            <thead className="text-xs text-black uppercase">
              <tr className=" ">
                <th scope="col" className="py-4 px-6 leading-5 bg-gray-100 rounded-tl-xl rounded-bl-xl">
                  Date
                </th>
                <th scope="col" className="py-4 px-6 leading-5 bg-gray-100">
                  Start Interval
                </th>
                <th scope="col" className="py-4 px-6 leading-5 bg-gray-100">
                  End Interval
                </th>
                <th scope="col" className="py-4 px-6 leading-5 bg-gray-100">
                  Energy Value
                </th>
                <th scope="col" className="py-4 px-6 leading-5 bg-gray-100 rounded-tr-xl rounded-br-xl">
                  Energy Unit
                </th>
              </tr>
            </thead>
            <tbody>
              {hourlyData?.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  {Object.entries(row).map(([field, value]) => (
                    <td key={field} className="py-4 px-6">
                        {editCell.row === rowIndex && editCell.field === field ? (
                            <input
                                type="text"
                                className="px-2 text-center max-w-[100px]"
                                value={value}
                                onChange={(e) =>
                                    handleEdit(e.target.value, rowIndex, field)
                                }
                                onBlur={() => setEditCell({ row: -1, field: "" })}
                            />
                        ) : (
                            <span
                                onMouseEnter={() => setEditCell({ row: rowIndex, field })}
                                className="hover:cursor-pointer"
                            >
                                {value}
                            </span>
                        )}
                    </td>
                ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-8 mb-5 w-full flex justify-end">
          <button
            onClick={handleSave}
            className="bg-green-800 text-white rounded-lg px-2 py-2"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
}
