import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const ExcelDownloadButtonForIexDiscount = ({text,px,py ,smpy ,smpx }) => {

  const handleDownload = () => {
    const fileName = "IEX _DSICOUNT_FORMAT.xlsx";
    const fileUrl = process.env.PUBLIC_URL + "/" + fileName;
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  return (
    <div className={`flex w-fit mx-auto border bg-[#067C4E] text-white border-gray-300 shadow-lg ${smpy?smpy:"sm:py-2"} ${smpx?smpx:"sm:px-2"} ${px?px:"px-1"} ${py?py:"py-1"} rounded-md justify-center cursor-pointer items-center gap-2`} onClick={handleDownload}>
      <FontAwesomeIcon size="xl" icon={faDownload} />
      <span>{text ? text : "DownLoad Excel Format"}</span>
    </div>
  );
};

export default ExcelDownloadButtonForIexDiscount;
