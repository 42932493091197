import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify';
import { makeFinancialYear, makeYearFormate } from '../../../pages/recommendations/constantVariable/getFY';
import config from '../../../config/config';
import InputBox from '../../../components/inputBox/InputBox';
import { addConstantToPowerPlant } from '../../../services/apis/listings';


export default function AddConstant({fy, data, onClose, addId, setUpdateCount }) {
    const financialYearData=makeFinancialYear()

    const [formData, setFormData] = useState({
        financialYear: fy,
        value: null,
    });
    const [errors, setErrors] = useState({});

    const constantPrice = useMemo(() => {
        const item = data.find(item => item._id === addId);
        if (item && formData.financialYear) {
            return item.constantPrice[formData.financialYear] || '';
        }
        return '';
    }, [data, addId, formData.financialYear]);

    useEffect(() => {
        setFormData(prev => ({
            ...prev,
            value: constantPrice
        }));
    }, [constantPrice]);

    const renderFormFields = () => {
        const fields = config.powerPantConstantAdd.fields;

        return Object.keys(fields)?.map((fieldName, index) => {
            const field = fields[fieldName];
            const { label, type, required } = field;

            switch (type) {
                case 'text':
                    return (
                        <div key={index} className="mb-6">
                            <div className="flex items-center">
                                <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                                    {label}{required && <span className="text-red-500">*</span>} :
                                </label>
                                <InputBox
                                    width="w-3/4"
                                    placeholder={`Enter ${label}`}
                                    value={fieldName === 'content' ? formData.value : formData[fieldName] || ''}
                                    onChange={(e) => handleChange(fieldName, e.target.value)}
                                    className="ml-4"
                                />
                            </div>
                            {errors[fieldName] && (
                                <p className="absolute text-red-500 text-sm ml-44">{errors[fieldName]}</p>
                            )}
                        </div>
                    );
                case 'select':
                    return (
                        <div key={index} className="mb-6">
                            <div className="flex items-center">
                                <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                                    {label}{required && <span className="text-red-500">*</span>} :
                                </label>
                                <select
                                    id={fieldName}
                                    value={makeYearFormate(formData[fieldName])}
                                    onChange={(e) => handleChange(fieldName, e.target.value.split("-")[0])}
                                    className={`w-3/4 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                                        errors[fieldName] ? 'border-red-500' : ''
                                    }`}
                                >
                                    <option value="">Select {label}</option>
                                    { financialYearData?.map((opt,index)=>(
                                        <option key={index} value={opt}>{opt}</option>
                                    ))}
                                </select>
                            </div>
                            {errors[fieldName] && (
                                <p className="absolute text-red-500 text-sm ml-44">{errors[fieldName]}</p>
                            )}
                        </div>
                    );
                default:
                    return null;
            }
        });
    };

    const handleChange = (fieldName, value) => {
        if (fieldName === 'content') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                content: [{ value }]
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [fieldName]: value,
            }));
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: ''
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const validationErrors = {};

        if (!formData.financialYear) {
            validationErrors.financialYear = "Financial Year is required";
        }
        if (!formData.value) {
            validationErrors.value = "Value is required";
        }
        
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            const response = await addConstantToPowerPlant(addId, formData);

            if (response.length == 0){
                toast.error(`${response?.data?.message}`, {
                    position: "top-center",
                });
                
            } else {
                toast.success(`${response?.message}`, {
                    position: "top-center",
                    autoClose: 3000
                });
                setUpdateCount(prevCount => prevCount + 1);
            }
            onClose()
        } catch (error) {
            console.error("Error:", error);
            toast.error("Failed to add global variable. Please try again.", {
                position: "top-center",
            })
        } 
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
            <div className="bg-white p-4 shadow-lg w-full max-w-3xl max-h-[95vh] overflow-y-auto rounded-lg">
                <h2 className="text-lg font-semibold">Add a Constant price</h2>
                <form>
                    <div className="flex-grow overflow-auto">
                        <div className="max-w-8xl">
                            <div className="bg-white shadow-md overflow-hidden p-4">
                                {renderFormFields()}
                            </div>
                        </div>
                    </div>
                </form>
                <div className="sticky bottom-0 bg-white p-4 flex justify-center gap-8">
                    <button
                        type="button"
                        onClick={onClose}
                        className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4"
                    >
                        Save
                    </button>
                </div>
                {/* <ToastContainer position="top-center" autoClose={3000} /> */}
            </div>
        </div>
    );
}
