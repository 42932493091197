import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/sidebar/Sidebar'
import PageTitleCard from '../../../components/pageTitleCard/PageTitleCard'
import ResetButton from '../../../components/resetButton/ResetButton';
import FilterDropdown from '../../../components/filterDropdown/FilterDropdown';
import SearchById from '../../../components/searchById/SearchById';
import SearchBar from '../../../components/searchBar/SearchBar';
import { deleteIntermediateVariablesById, fetchIntermediateVariables } from '../../../services/apis/regulatoryCharges';
import config from '../../../config/config';
import { fetchDiscomList, fetchStateList } from '../../../services/apis/listings';
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditIntermediateVariables from './EditIntermediateVariables';
import AddIntermediateVariables from './AddIntermediateVariables';
import ChargesTable from './ChargesTable';
import { fetchCollectionsFieldList, fetchCollectionsList, fetchGlobalVariablesList } from '../../../services/apis/globalRecommendationVariable';
import RecordsPerPageDropdown from '../../../components/recordsPerPageDropdown/RecordsPerPageDropdown';
import DeleteConfirmation from '../../../components/deleteConfirmation/DeleteConfirmation';

export default function IntermediateVariables() {

    const types = ["central", "state", "discom"];
    const statuses = ["active", "inactive"];

    const { intermediateVariablesListHeaders } = config;

    const [isFormOpen, setIsFormOpen] = useState(false);
    const [editFormOpen, setEditFormOpen] = useState(false);
    const [editId, setEditId] = useState("");
    const [editType, setEditType] = useState("");
    const [updateCount, setUpdateCount] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchUniqueQuery, setSearchUniqueQuery] = useState('');
    const [searchById, setSearchById] = useState("");
    const [tableData, setTableData] = useState([]);
    const [states, setStates] = useState([]);
    const [stateId, setStateId] = useState('');
    const [discoms, setDiscoms] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: "_id", direction: 'asc' });
    const [limit, setLimit] = useState(() => {
        const storedLimit = parseInt(localStorage.getItem('recordsPerPage'), 10);
        return storedLimit || 50;
    });
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState({
        globalRecVariableId: '',
        modelName: '',
        mapping: ''
    });
    const [collection, setCollection] = useState();
    const [globalRecVariableId, setGlobalRecVariableId] = useState();
    const [mapping, setMapping] = useState();
    const [globalVariables, setGlobalVariables] = useState([]);
    const [collectionList, setCollectionList] = useState([]);
    const [collectionFieldList, setCollectionFieldList] = useState([]);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false); 
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [deleteItemName, setDeleteItemName] = useState("");
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        const getStateList = async () => {
            const response = await fetchStateList();
            const stateList = response?.data?.results;

            if (stateList) {
                // Sort the state list alphabetically by state name
                const sortedStateList = stateList.sort((a, b) => a.name.localeCompare(b.name));
                setStates(sortedStateList);
            }
        };
        getStateList();
    }, [])

    useEffect(() => {
        if (stateId) {
            const getDiscomList = async () => {
                const payload = {
                    data: {
                        stateId,
                    }
                }
                const response = await fetchDiscomList(payload);
                const discomList = response?.data?.results;
                if (discomList) {
                    // Sort the state list alphabetically by state name
                    const sortedDiscomList = discomList.sort((a, b) => a.name.localeCompare(b.name));
                    setDiscoms(sortedDiscomList);
                }
            };
            getDiscomList();
        }
    }, [stateId])

    const getRegulatoryChargesList = async () => {
        setLoader(true)
        const payload = {
            page: currentPage,
            pageSize: limit,
            // globalRecVariableId: filters.globalRecVariableId,
            name: searchQuery,
            modelName: filters.modelName,
            mapping: filters.mapping,
            fieldName: searchQuery,
            sortBy: sortConfig.key,
            sortOrder: sortConfig.direction,
        };
        const response = await fetchIntermediateVariables(payload);
        // console.log(response,'response');
        const data = response?.data?.data?.results;
        setTableData(data);
        setTotalCount(response?.data?.data?.total);
        // console.log(data,'data');
        setLoader(false)
    };

    useEffect(() => {
        getRegulatoryChargesList();
    }, [currentPage, filters, sortConfig, updateCount, limit, searchQuery])

    const handleAddButtonClick = () => {
        setIsFormOpen(true);
    };

    const handleCloseForm = () => {
        setIsFormOpen(false);
    };

    const handleEditCloseForm = () => {
        setEditFormOpen(false);
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        setCurrentPage(1);
    };

    const handleEdit = (id, objectType) => {
        setEditFormOpen(true);
        setEditId(id);
        setEditType(objectType);
    };

    const handleFilterChange = (filterType, value) => {
        setFilters({ ...filters, [filterType]: value });
        setCurrentPage(1);
    };

    const handleResetFilters = () => {

        setFilters({
            // globalVar: '',
            globalRecVariableId:"",
            modelName: '',
            mapping: ''
        });
        setSearchById('');
        setSearchQuery('');
        setSearchUniqueQuery('');;
        // setCurrentPage(1);
    };

    const formatModelName = (modelName) => {
        return modelName
            .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
            .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
            .replace(/^\w/, c => c.toUpperCase())
            .replace(/\b\w/g, c => c.toUpperCase());
    };

    const handleCollectionChange = (selectedCollection) => {
        setCollection(selectedCollection);
        if (selectedCollection) {
            setFilters({ ...filters, modelName: selectedCollection });
        }
    };
    const handleCollectionFieldChange = (selectedCollectionField) => {
        setMapping(selectedCollectionField);
        if (selectedCollectionField) {
            setFilters({ ...filters, mapping: selectedCollectionField });
        }
    };

    const handleGlobalVarChange = (selectedGlobalVar) => {
        setGlobalRecVariableId(selectedGlobalVar);
        if (selectedGlobalVar) {
            setFilters({ ...filters, globalRecVariableId: selectedGlobalVar });
        }
    };

    useEffect(() => {
        const getGlobalVariablesList = async () => {
            const payload = {
                type: "intermediateVariable"
            }
            const response = await fetchGlobalVariablesList(payload);
            const data = response?.data?.data?.results;
            setGlobalVariables(data);
            // console.log(data, 'data')
        };
        getGlobalVariablesList();
    }, []);

    useEffect(() => {
        const getGlobalVariablesList = async () => {
            const response = await fetchCollectionsList();
            const data = response?.data?.data;
            setCollectionList(data);
        };
        getGlobalVariablesList();
    }, []);

    useEffect(() => {
        const getGlobalVariablesList = async () => {
            if (collection) {

                const data = {
                    modelName: collection
                }
                const response = await fetchCollectionsFieldList(data);
                const collectionsFieldList = response?.data?.data;
                setCollectionFieldList(collectionsFieldList);
            }
        };
        getGlobalVariablesList();
    }, [collection]);

    const handleRecordsPerPageChange = (newLimit) => {
        setLimit(newLimit);
        setCurrentPage(1); 
    };

    const handleSearch = (event) =>{
        const query = event.target.value;
        setSearchQuery(query);
        setCurrentPage(1);
    }

    const handleDelete = (id, name) => {
        setDeleteModalOpen(true);
        setDeleteItemId(id);
        setDeleteItemName(name);
    };

    const confirmDelete = async () => {
        const response = await deleteIntermediateVariablesById(deleteItemId);

        if (response?.data?.data?.length === 0) {
            toast.error('Failed to delete the Intermediate Variable. Please try again later.', {
                position: "top-center",
                autoClose: 1500,
            });
        } else {
            toast.success('Intermediate Variable deleted successfully!', {
                position: "top-center",
                autoClose: 1500,
            });
            setUpdateCount((prevCount) => prevCount + 1);
        }
        setDeleteModalOpen(false);
    };

    return (
        <div className="flex w-full">
            <Sidebar />
            <div className="p-4 w-full">
                <div className="max-w-[1920px]">
                    <PageTitleCard title="Intermediate Variables List" onAddButtonClick={handleAddButtonClick} addLinkText="Add New Intermediate Variable" />
                    <div className=''>
                        <div className="overflow-hidden mt-2 rounded-xl">
                            <div className="flex">
                                <div className="p-3 flex flex-wrap items-center justify-start gap-4 space-y-4 md:space-y-0 md:flex-row">
                                    <SearchBar searchQuery={searchQuery} handleSearch={handleSearch} title="Global Variable"/>
                                    <SelectDropdown
                                        label="Collection"
                                        selectedValue={filters.modelName}
                                        onChange={(e) => handleCollectionChange(e.target.value)}
                                        options={collectionList?.map((collection) => {
                                            return { label: formatModelName(collection?.modelName), value: collection?.modelName };
                                        })}
                                    />
                                    <SelectDropdown
                                        label="Collection Field List"
                                        selectedValue={filters.mapping}
                                        onChange={(e) => handleCollectionFieldChange(e.target.value)}
                                        options={collectionFieldList?.map((collectionField) => {
                                            return { label: collectionField?.name, value: collectionField?.name };
                                        })}
                                    />
                                    <RecordsPerPageDropdown onRecordsPerPageChange={handleRecordsPerPageChange} />
                                </div>
                                <div className="flex-grow"></div>
                                <div className="p-3 mt-[5px] mr-2 reset-button-container">
                                    <ResetButton onClick={handleResetFilters} />
                                </div>
                            </div>
                            <div className='bg-white p-2 rounded-xl min-h-[350px]'>
                                <ChargesTable loader={loader} headings={intermediateVariablesListHeaders} data={tableData} handleEdit={(id, objectType) => handleEdit(id, objectType)} handleDelete={(id, name) => handleDelete(id, name)} handleSort={handleSort} sortConfig={sortConfig} title="Edit" setUpdateCount={setUpdateCount} />
                            </div>
                        </div>
                        {totalCount > limit && (
                            <div className="pagination-controls bg-white rounded-b-xl border-[#abaaaa] mb-2 pb-2 flex flex-row items-center justify-end pt-8 -mt-2">
                                <button className={`bg-[#e1e0e0] ${currentPage === 1 ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl`} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                <span className="mx-4">{Math.min(currentPage * limit, totalCount) ? Math.min(currentPage * limit, totalCount) : '0'} off {totalCount ? totalCount : '0'}</span>
                                <button className={`bg-[#e1e0e0] ${currentPage * limit >= totalCount ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl mr-2`} disabled={currentPage * limit >= totalCount} onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
                            </div>
                        )}
                    </div>
                </div>
                {isFormOpen && <AddIntermediateVariables onClose={handleCloseForm} setUpdateCount={setUpdateCount} />}
                {editFormOpen && <EditIntermediateVariables onClose={handleEditCloseForm} setUpdateCount={setUpdateCount} editId={editId} />}
                <ToastContainer position="top-center" autoClose={3000} />
                <DeleteConfirmation
                    isOpen={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    onConfirm={confirmDelete}
                    itemName={deleteItemName}
                />
            </div>
        </div>
    )
}
