import React, { useEffect, useState } from "react";
import PageTitleCard from "../../../components/pageTitleCard/PageTitleCard";
import Sidebar from "../../../components/sidebar/Sidebar";
import SearchBar from "../../../components/searchBar/SearchBar";
import SearchById from "../../../components/searchById/SearchById";
import ResetButton from "../../../components/resetButton/ResetButton";
import PowerPlantTable from "../powerPlant/PowerPlantTable";
import config from "../../../config/config";
import {
  deletePowerPlantById,
  fetchPowerPlantById,
  fetchPowerPlantList,
} from "../../../services/apis/powerPlant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import SelectDropdown from "../../../components/selectDropdown/SelectDropdown";
import { fetchStateList } from "../../../services/apis/listings";
import RecordsPerPageDropdown from "../../../components/recordsPerPageDropdown/RecordsPerPageDropdown";
import AddPowerPlantUnitDetails from "./AddPowerPlantUnitDetails";
import EditPowerPlantUnitDetails from "./EditPowerPlantUnitDetails";
import { deletePowerPlantUnitById, fetchPowerPlantUnitList } from "../../../services/apis/powerPlantUnit";
import { useParams } from "react-router-dom";
import PowerPlantUnitTable from "./PowerPlantUnitTable";
import DeleteConfirmation from "../../../components/deleteConfirmation/DeleteConfirmation";

export default function PowerPlantUnitListing() {
  const { powerPlantUnitListHeaders } = config;
  const powerplantTypes = ["solar", "wind", "hydroelectric", "nuclear", "coal", "naturalGas", "biomass"]
  const { id } = useParams();
  const [powerPlantName, setPowerPlantName] = useState("");
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editId, setEditId] = useState("");
  const [updateCount, setUpdateCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [states, setStates] = useState([]);
  const [stateId, setStateId] = useState("");
  const [searchCityQuery, setSearchCityQuery] = useState("");
  const [searchDistrictQuery, setSearchDistrictQuery] = useState("");
  const [searchAddressQuery, setSearchAddressQuery] = useState("");
  const [searchById, setSearchById] = useState("");
  const [tableData, setTableData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "_id",
    direction: "asc",
  });
  const [limit, setLimit] = useState(() => {
    const storedLimit = parseInt(localStorage.getItem('recordsPerPage'), 10);
    return storedLimit || 50;
  });
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [stateFilter, setStateFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false); 
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [deleteItemName, setDeleteItemName] = useState("");

  useEffect(() => {
    const getStateList = async () => {
      const response = await fetchStateList();
      const stateList = response?.data?.results;

      if (stateList) {
        // Sort the state list alphabetically by state name
        const sortedStateList = stateList.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setStates(sortedStateList);
      }
    };
    getStateList();
  }, []);

  useEffect(() => {
    const getPowerPlantById = async () => {
      const response = await fetchPowerPlantById(id);
      const data = response?.data?.data;
      setPowerPlantName(data?.name)
    }
    getPowerPlantById();
  },[])

  useEffect(() => {
    const getPowerPlantList = async () => {
      const payload = {
        page: currentPage,
        pageSize: limit,
        name: searchQuery,
        city: searchCityQuery,
        district: searchDistrictQuery,
        address: searchAddressQuery,
        id: searchById,
        sortBy: sortConfig.key,
        sortOrder: sortConfig.direction,
        stateId: stateFilter,
        type: typeFilter,
      };
      const response = await fetchPowerPlantList(payload);
      const data = response?.data?.data?.results;

      // setTableData(data);
      // setTotalCount(response?.data?.data?.total);
    };
    getPowerPlantList();
  }, []);

  const [powerPlants, setPowerPlants] = useState();

  useEffect(() => {
    const getPowerPlantUnitList = async () => {
      const payload = {
        powerPlantId: id
      }
      const response = await fetchPowerPlantUnitList(payload);
      const data = response?.data?.data?.results;
      setTableData(data);
      // setPowerPlants(data);
    };
    getPowerPlantUnitList();
  }, [id, updateCount]);

  const handleAddButtonClick = () => {
    setIsFormOpen(true);
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
  };

  const handleEditCloseForm = () => {
    setEditFormOpen(false);
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleCitySearch = (event) => {
    const query = event.target.value;
    setSearchCityQuery(query);
    setCurrentPage(1);
  };

  const handleDistrictSearch = (event) => {
    const query = event.target.value;
    setSearchDistrictQuery(query);
    setCurrentPage(1);
  };

  const handleAddressSearch = (event) => {
    const query = event.target.value;
    setSearchAddressQuery(query);
    setCurrentPage(1);
  };

  const handleSearchById = (event) => {
    const id = event.target.value;
    setSearchById(id);
    setCurrentPage(1);
  };

  const handleResetFilters = () => {
    setSearchById("");
    setSearchQuery("");
    setSearchCityQuery("");
    setSearchDistrictQuery("");
    setSearchAddressQuery("");
    setStateFilter("");
    setTypeFilter("");
  };

  const handleEdit = (id) => {
    setEditFormOpen(true);
    setEditId(id);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    setCurrentPage(1);
  };

  const handleStateChange = (selectedState) => {
    setStateId(selectedState);
    if (selectedState) {
      setStateFilter(selectedState);
    }
  };

  const handleTypeChange = (selectedType) => {
    if (selectedType) {
      setTypeFilter(selectedType);
    }
  };

  const handleRecordsPerPageChange = (newLimit) => {
    setLimit(newLimit);
    setCurrentPage(1);
  };

  const handleDelete = (id, name) => {
    setDeleteModalOpen(true);
    setDeleteItemId(id);
    setDeleteItemName(name);
  };

  const confirmDelete = async () => {
      const response = await deletePowerPlantUnitById(deleteItemId);

      if (response.data.statusCode === 200) {
        toast.success(`Power Plant Unit deleted successfully!`, {
          position: "top-center",
          autoClose: 1500,
        });
        setUpdateCount((prevCount) => prevCount + 1);
      } else {
        toast.error(
          `Failed to delete the Power Plant Unit. Please try again later.`,
          {
            position: "top-center",
            autoClose: 1500,
          }
        );
      }
      setDeleteModalOpen(false);
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-grow p-4">
        <div className="max-w-[1920px]">
          <PageTitleCard
            title={`Power Plants Units List of ${powerPlantName}`}
            onAddButtonClick={handleAddButtonClick}
            addLinkText="Add New Power Plant Unit"
          />
          <div className="">
            <div className=" overflow-hidden mt-4 rounded-xl ">

              <div className="flex-grow"></div>
              <div className='bg-white p-2 rounded-xl min-h-[500px]'>
                <PowerPlantUnitTable
                  headings={powerPlantUnitListHeaders}
                  data={tableData}
                  handleEdit={(id) => handleEdit(id)}
                  handleDelete={(id, name) => handleDelete(id, name)}
                  handleSort={handleSort}
                  sortConfig={sortConfig}
                  title="Edit"
                />
              </div>
            </div>

          </div>
        </div>
        {isFormOpen && (
          <AddPowerPlantUnitDetails
            onClose={handleCloseForm}
            setUpdateCount={setUpdateCount}
            setIsFormOpen={setIsFormOpen}
            powerPlant={id}
            powerPlantName={powerPlantName}
          />
        )}
        {editFormOpen && (
          <EditPowerPlantUnitDetails
            onClose={handleEditCloseForm}
            editId={editId}
            setUpdateCount={setUpdateCount}
            setEditFormOpen={setEditFormOpen}
            powerPlantName={powerPlantName}
          />
        )}
        <ToastContainer position="top-center" autoClose={3000} />
        <DeleteConfirmation
            isOpen={deleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            onConfirm={confirmDelete}
            itemName={deleteItemName}
        />
      </div>
    </div>
  );
}
