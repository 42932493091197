import React, { useEffect, useState } from "react";
import config from "../../config/config";
import InputBox from "../../components/inputBox/InputBox";
import Sidebar from "../../components/sidebar/Sidebar";
import PageTitleCard from "../../components/pageTitleCard/PageTitleCard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { fetchStateList } from "../../services/apis/listings";
import axios from "axios";
import {
  createNewDisCom,
  editDiscomId,
  getDisComId,
} from "../../services/apis/content";

export default function AddDiscomForm() {
  const params = useParams();
  // console.log(params,"params")

  // if params.id is null its a new else its a edit form
  // write some fetch to get the data to prefill
  //temporary
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user?.result?.token;

  const navigate = useNavigate();
  useEffect(() => {
    if (params.id) {
      const fetchData = async () => {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/disComCode/${params.id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log(data, "discom");
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [params.id, token]);

  const [errors, setErrors] = useState({});
  const [statesCode, setStateCode] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    code: "",
    stateId: "",
    status: "status",
  });
  useEffect(() => {
    if (params.id) {
      const fetchData = async () => {
        try {
          const { data } = await getDisComId(params.id);
          let { name, code, stateId, status } = data;
          setFormData({
            name,
            code,
            stateId,
            status,
          });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [params.id, token]);

  useEffect(() => {
    const getStateList = async () => {
      const response = await fetchStateList();
      const stateList = response?.data?.results;
      setStateCode(stateList);
    };
    getStateList();
  }, []);

  const handleChange = (name, value) => {
    console.log(name, value, "handle");
    let uniqueKeyValue =
      name === "name"
        ? value
            .toLowerCase()
            .replace(/\s+/g, "-")
            .replace(/[^a-zA-Z0-9-]/g, "")
        : formData.uniqueKey;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      uniqueKey: uniqueKeyValue,
    }));
    if (name === "name" && errors.uniqueKey && value.trim() !== "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        uniqueKey: "",
      }));
    }
    let error = "";
    if (!value.trim()) {
      error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleCancel = () => {
    setFormData({
      name: "",
      code: "",
      stateId: "",
      status: "status",
    });
    navigate("/discom-list");
  };
  //submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    let formErrors = {};

    if (!formData.name.trim()) {
      formErrors.name = "Name is required";
    }

    if (!formData.stateId.trim()) {
      formErrors.stateId = "Please select a State ";
    }
    if (!formData.code.trim()) {
      formErrors.code = "Code is required";
    }

    if (formData.status == "status") {
      formErrors.status = "Status is required";
    }

    if (Object.keys(formErrors).length === 0) {
      try {
        let response;
        if (params.id) {
          response = editDiscomId({ ...formData, discomId: params.id });
          console.log(response);
        } else {
          response = createNewDisCom(formData);
          console.log(response);
        }
        if (response) {
          setFormData({
            name: "",
            code: "",
            stateId: "",
            status: "status",
          });
          setErrors({});

          toast.success(
            params.id
              ? "DisCom Edited Successfully!"
              : "DisCom Added Successfully!",
            {
              position: "top-center",
            }
          );
          setTimeout(() => {
            navigate("/discom-list");
          }, 3000);
        } else {
          toast.error(`${response?.error?.message}`, {
            position: "top-center",
          });
        }
      } catch (error) {
        toast.error("Failed to add blog. Please try again later.", {
          position: "top-center",
        });
      }
    } else {
      setErrors(formErrors);
    }
  };

  const renderFormFields = (data = []) => {
    const fields = config.discom.fields;
    console.log(formData, "state");

    return Object.keys(fields).map((fieldName, index) => {
      const field = fields[fieldName];
      const { label, type, required, options } = field;

      switch (type) {
        case "text":
          return (
            <div key={index} className="mb-6 max-w-5xl relative">
              <label htmlFor={fieldName} className="my-2 block text-gray-700">
                {label}
                {required && <span className="text-red-500">*</span>} :
              </label>
              <InputBox
                width="max-w-5xl"
                placeholder={`Enter ${label}`}
                value={formData[fieldName]}
                onChange={(e) => handleChange(fieldName, e.target.value)}
              />
              {errors[fieldName] && (
                <p className="text-red-500 text-sm absolute">
                  {errors[fieldName]}
                </p>
              )}
            </div>
          );
        case "select":
          return (
            <div key={index} className="mb-4 max-w-sm relative">
              <label
                htmlFor={fieldName}
                className="mb-2 mt-6 block text-gray-700"
              >
                {label}
                {required && <span className="text-red-500">*</span>} :
              </label>
              <select
                id={fieldName}
                value={formData[fieldName]}
                onChange={(e) => handleChange(fieldName, e.target.value)}
                className={`w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                  errors[fieldName] ? "border-red-500" : ""
                }`}
              >
                <option value="">Select {label}</option>
                {options.map((option, index) => (
                  <option key={index} value={option}>
                    {option.charAt(0).toUpperCase() + option.slice(1)}
                  </option>
                ))}
              </select>
              {errors[fieldName] && (
                <p className="text-red-500 text-sm absolute">
                  {errors[fieldName]}
                </p>
              )}
            </div>
          );
        case "selectApi":
          return (
            <div key={index} className="mb-4 max-w-sm relative">
              <label
                htmlFor={fieldName}
                className="mb-2 mt-6 block text-gray-700"
              >
                {label}
                {required && <span className="text-red-500">*</span>} :
              </label>
              <select
                disabled={params.id}
                id={fieldName}
                value={formData[fieldName]}
                // value={"test"}
                onChange={(e) => handleChange(fieldName, e.target.value)}
                className={`w-1/2 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                  errors[fieldName] ? "border-red-500" : ""
                }`}
              >
                <option value="">Select {label}</option>
                {data.map((option, index) => (
                  <option key={index} value={option._id}>
                    {option.code.toUpperCase()}
                  </option>
                ))}
              </select>
              {errors[fieldName] && (
                <p className="text-red-500 text-sm absolute">
                  {errors[fieldName]}
                </p>
              )}
            </div>
          );
        default:
          return null;
      }
    });
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-grow p-4">
        <div className="max-w-8xl">
          <PageTitleCard
            title={!params.id ? "Add New Discom" : "Edit Discom"}
          />
          <form onSubmit={handleSubmit}>
            <div className="bg-white shadow-md overflow-hidden mt-4">
              <div className="p-4 mb-4 items-center space-y-4 md:space-y-0 md:flex-row">
                <div className="h-auto">{renderFormFields(statesCode)}</div>
                <div>
                  <button
                    type="button"
                    onClick={handleCancel}
                    className=" bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] mt-10 hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className=" bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] mt-10 hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
            <ToastContainer position="top-center" autoClose={3000} />
          </form>
        </div>
      </div>
    </div>
  );
}
