import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";

class Home extends React.Component {
  render() {
    return (
      <div className="flex">
        <Sidebar/>
        <div className="m-5 flex-grow flex justify-center items-center">
          <div className="max-w-4xl text-center">
            <h1 className="text-5xl font-semibold mb-8">
              Welcome to Admin Power Marketplace
            </h1>
            {/* <p className="text-lg font-normal leading-relaxed">
              This is a platform where buyers can find the best deals and sellers
              can reach out to potential customers. We strive to create a fair and
              transparent marketplace where everyone can benefit. Our platform is
              user-friendly and designed with the needs of both buyers and sellers
              in mind. We offer a wide range of products and services to cater to
              diverse needs. Our customer service team is always ready to assist you
              with any queries or issues. Join us today and experience the difference!
            </p> */}
            {/* <div className="flex justify-around mt-10 gap-5">
              <Link to="/buy">
                <div className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  Buy
                </div>
              </Link>
              <Link to="/sell">
                <div className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                  Sell
                </div>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
