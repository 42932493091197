import React, { useState, useMemo } from 'react';
import config from '../../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import ActionButtons from '../actionButtons/ActionButtons';
import AddRoleButton from '../addRoleButton/AddRoleButton';
import ViewActionButton from '../actionButtons/ViewActionButton';
import EditActionButton from '../actionButtons/EditActionButton';
import Loading from '../loader/Loader';


export default function Table({loader=false, headings, data, handleSort, sortConfig, handleDelete, handleEdit, handleAddRole, handleView, title }) {

    return (
        <div className="bg-white overflow-hidden rounded-xl">
            <div className="">
                <div className="">
                    <table className="min-w-full px-2">
                        <thead>
                            <tr>
                                {Object.entries(headings).map(([key, heading], index, array) => (
                                    <th
                                        key={heading}
                                        className={`px-6 py-4 bg-gray-100 text-left text-xs leading-5 font-medium text-black uppercase tracking-wider ${heading !== 'Actions' && heading !== 'Add Role' && heading !== 'Roles' && heading !== 'Status' ? 'cursor-pointer' : ''}`}
                                        onClick={() => heading !== 'Actions' && heading !== 'Add Role' && heading !== 'Roles' && heading !== 'Status' && handleSort(key)}
                                        style={{
                                            borderBottomLeftRadius: index === 0 ? '0.5rem' : '0',
                                            borderBottomRightRadius: index === array.length - 1 ? '0.5rem' : '0',
                                            borderTopLeftRadius: index === 0 ? '0.5rem' : '0',
                                            borderTopRightRadius: index === array.length - 1 ? '0.5rem' : '0',
                                        }}
                                    >
                                        {heading} {sortConfig.key === key && (
                                            sortConfig.direction === 'asc' ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />
                                        )}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-300">
                            {data?.length > 0 && !loader ? (
                                data?.map((row, index) => {

                                    return (
                                        <tr key={index}>
                                            {Object?.entries(headings)?.map(([key, value]) => (
                                                <td key={key} className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black">
                                                    {key === 'roles' ? (
                                                        row[key]?.map((role, index) => (
                                                            <span key={role?._id}>
                                                                {role?.name}
                                                                {index < row[key]?.length - 1 && ', '}
                                                            </span>
                                                        ))
                                                    ) : (
                                                        key === 'meterActions' ? (
                                                            <ActionButtons handleEdit={() => handleEdit(row?.consumerNo)} handleDelete={() => handleDelete(row?._id)} id={row?._id} />
                                                        ) :
                                                            key === 'actions' ? (
                                                                <ActionButtons handleEdit={() => handleEdit(row?._id, row?.type)} handleDelete={() => handleDelete(row?._id)} id={row?._id} />
                                                            ) : key === 'addRole' ? (
                                                                <AddRoleButton handleAddRole={() => handleAddRole(row?._id)} />
                                                            ) : key === 'viewActions' ? (
                                                                <ViewActionButton handleView={() => handleView(row?._id)} handleDelete={() => handleDelete(row?._id)} title={title} />
                                                            ) : key === 'editactions' ? (
                                                                <EditActionButton handleEdit={() => handleEdit(row?._id, row?.type)} title={title} />
                                                            ) : (
                                                                key === 'createdBy' ? (
                                                                    row[key] ? row[key] : "-"
                                                                ) : (
                                                                    key === 'status' ? (
                                                                        row[key].charAt(0).toUpperCase() + row[key].slice(1)
                                                                    ) : (
                                                                        (row[key])
                                                                    )
                                                                )))}
                                                </td>
                                            ))}
                                        </tr>
                                    )
                                })
                            ) :loader ? 
                            (
                                <tr>
                                    <td colSpan={Object.keys(headings).length} className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 text-center">
                                        <Loading/>
                                    </td>
                                </tr>
    
                            ): (
                                <tr>
                                    <td colSpan={Object.keys(headings).length} className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black text-center">
                                        No Records Available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
