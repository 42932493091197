import React, { useState,useRef, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import PageTitleCard from "../../components/pageTitleCard/PageTitleCard";
import Formulae from "../../components/formula/CreateArithmaticFormula";
import Operator from "../../components/formula/AddLogicalFormula";
import FormulaVariablesTable from "../../components/formula/FormulaVariablesTable";
import CreateArithmaticFormula from "../../components/formula/CreateArithmaticFormula";
import AddLogicalFormula from "../../components/formula/AddLogicalFormula";
import VariableTypeSelectDropdown from "../recommendations/regulatoryCharges/VariableTypeSelectDropdown";
import { getAllStates } from "../../services/apis/listings";
import { makeFinancialYear } from "../recommendations/constantVariable/getFY";

const CreateFormula = () => {
    const childRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState("arithmatic");
  const [operatorPlanMatric, setOperatorPlanMatric] = useState('');
  var [planOperator, setPlanOperator] = useState([]);
  const [operatorList, setOperatorList] = useState([]);
  const [operatorValidationError, setOperatorValidationError] = useState('');
  const [numbers, setNumbers] = useState(0);
  const [stateCode,setStateCode]=useState("")
  const [financialYears,setFinancialYears] = useState("2024-2025")
  const [allStates,setAllStates] =useState([])
  const [errorState, setErrorState] = useState(false)

  useEffect(() => {
    const getData = async () => {
      const response = await getAllStates();
      setAllStates(response?.data?.results)
    }
    getData()
    
  }, []);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const operatorarr = (arr) => {
    setOperatorList(arr.map((elem) => {
        // if (Object.keys(elem)[0] === '' && Object.values(elem)[0] === '') {
        // childRef.current.savePlanValues();
        // setOperatorValidationError('Please Fill Both Fields');
        // }
        const { id, ...rest } = elem
        return rest;
    }));
  }

  const handleStateChange =(e)=>{
    setStateCode(e.target.value)
    setErrorState(false)
  }
  const handleFinancialYearChange = (e)=>{
    setFinancialYears(e.target.value)
  }

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-grow p-4">
        <div className="max-w-[1920px] w-full flex ">
          <div className="w-full">
            <PageTitleCard title="Create Formula" />
            <div className="w-full mt-4 min-h-[400px] shadow-[0_4px_6px_-1px_rgb(0.5,0,0,0.5),0_2px_4px_-2px_rgb(0,0,0,0.3)] p-4 border border-gray-200 rounded-md">
              <div className="flex">
                <div className="w-[60%]">
                  <div className="my-2  w-[400px] ">
                    <label disable className="text-sm font-semibold">Select State</label>
                    <select
                      className="min-h-[40px] px-2 my-2 border border-gray-600 rounded-lg w-full"
                      value={stateCode}
                      onChange={handleStateChange}
                    >
                      <option disabled value="">Select State</option>
                      {allStates.map((variable) => (
                        <option value={variable.code}>{variable.name}</option>
                      ))}
                    </select>
                    <p className={`${errorState === true ?"":"hidden"}  py-1 text-[red]`}>{"State is Required"}</p>
                  </div>
                  <div className="my-2  w-[400px] ">
                    <label disable className="text-sm font-semibold">Select Financial Year</label>
                    <select
                      className="min-h-[40px] px-2 my-2 border border-gray-600 rounded-lg w-full"
                      value={financialYears}
                      onChange={handleFinancialYearChange}
                    >
                      <option disabled value="">Select Financial Year</option>
                      {makeFinancialYear().map((variable) => (
                        <option value={variable}>{variable}</option>
                      ))}
                    </select>
                    <p className={`${errorState === true ?"":"hidden"}  py-1 text-[red]`}>{"State is Required"}</p>
                  </div>
                  <div className="flex gap-[40px] mt-4  space-x-4">
                    <label className="flex  items-center space-x-2">
                      <input
                        type="radio"
                        value="arithmatic"
                        checked={selectedOption === "arithmatic"}
                        onChange={handleOptionChange}
                        className="form-radio text-blue-600"
                      />
                      <span>Arithmatic</span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <input
                        type="radio"
                        value="logical"
                        checked={selectedOption === "logical"}
                        onChange={handleOptionChange}
                        className="form-radio text-blue-600"
                      />
                      <span>Conditional </span>
                    </label>
                  </div>
                  {selectedOption === "arithmatic" ? (
                    <div>
                      <CreateArithmaticFormula 
                        stateCode={stateCode}
                        financialYears={financialYears}
                        setErrorState={()=>setErrorState(true)}
                      />
                    </div>
                  ) : (
                    <div>
                      <AddLogicalFormula
                        stateCode={stateCode}
                        financialYears={financialYears}
                        matric={operatorPlanMatric}
                        planValues={planOperator}
                        createPlanFunction={operatorarr}
                        errorText={operatorValidationError}
                        ref={childRef}
                        offsugg={numbers}
                      />
                    </div>
                  )}
                </div>
                <div className="w-[40%]">
                  <FormulaVariablesTable/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateFormula;
