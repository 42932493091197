import "./App.css";
import { AuthProvider } from "./hooks/useAuth";
import MainLayout from "./layouts/mainLayout/MainLayout";
import AppRoutes from "./routes/routes";

function App() {
  return (
  <>

    <AppRoutes/>
  </>
  );
}

export default App;
