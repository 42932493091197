import ArticlesList from "../dummyData/articlesList";
// const api = process.env.REACT_APP_API_URL;

export const getArticlesListData = () => {
    try{
        return ArticlesList;
    } catch (e) {
        console.log(e);
    }
}

export const getArticleById = (articleId) => {
    try {
        const articlesList = getArticlesListData();
        const article = articlesList.find(article => article._id == articleId);
        return article;
    } catch (error) {
        console.error(error);
        return null;
    }
}