import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faTrash } from '@fortawesome/free-solid-svg-icons';
import EditActionButton from '../../../components/actionButtons/EditActionButton';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ToastContainer, toast } from 'react-toastify';
import { deleteIntermediateVariablesById } from '../../../services/apis/regulatoryCharges';
import Loading from '../../../components/loader/Loader';

export default function ChargesTable({ loader=false, headings, data, handleSort, sortConfig, handleEdit, handleDelete, setUpdateCount, title }) {

    const getDisplayValue = (key, row) => {
        switch (key) {
            case 'name':
                return row.globalRecVariableId?.name || '-';
            default:
                return row[key] || '-';
        }
    };

    const formatModelName = (modelName) => {
        return modelName
            .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
            .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
            .replace(/^\w/, c => c.toUpperCase())
            .replace(/\b\w/g, c => c.toUpperCase());
    };

    return (
        <div className="bg-white overflow-hidden">
            <div className="">
                <div className="">
                    <table className="min-w-full px-2">
                        <thead>
                            <tr>
                                {Object.entries(headings).map(([key, heading], index, array) => (
                                    <th
                                        key={heading}
                                        className={`px-6 py-4 bg-gray-100 text-left text-xs leading-4 font-medium text-black uppercase tracking-wider ${heading !== 'Actions' && heading !== 'Status' && heading !== 'State' && heading !== 'Discom' ? 'cursor-pointer' : ''}`}
                                        onClick={() => heading !== 'Actions' && heading !== 'Status' && heading !== 'State' && heading !== 'Discom' && handleSort(key)}
                                        style={{
                                            borderBottomLeftRadius: index === 0 ? '0.5rem' : '0',
                                            borderBottomRightRadius: index === array.length - 1 ? '0.5rem' : '0',
                                            borderTopLeftRadius: index === 0 ? '0.5rem' : '0',
                                            borderTopRightRadius: index === array.length - 1 ? '0.5rem' : '0',
                                        }}
                                    >
                                        {heading} {sortConfig.key === key && (
                                            sortConfig.direction === 'asc' ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />
                                        )}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {data?.length > 0 ? (
                                data?.map((row, index) => {
                                    // console.log(row)
                                    return (
                                        <tr key={index}>
                                            {Object?.entries(headings)?.map(([key, value]) => (
                                                <td key={key} className="px-4 py-4 whitespace-no-wrap text-left text-sm leading-5 text-black">
                                                    {key === 'globalRecVariableId' ? row[key]?.name || '' : 
                                                    key === 'actions' ?
                                                        <>
                                                            <EditActionButton handleEdit={() => handleEdit(row?._id)} />
                                                            <FontAwesomeIcon icon={faTrash} className="text-black cursor-pointer hover:text-gray-600 ml-1" onClick={() => handleDelete(row?._id, row?.globalRecVariableId?.name)} />
                                                        </> :
                                                    key === "modelName" ? formatModelName(row[key]) :
                                                    key === "description" ? 
                                                        <span title={row[key]}>
                                                            {row[key]?.length > 30 ? row[key]?.substring(0, 30) + '...' : row[key]}
                                                        </span> :
                                                    row[key]}
                                                </td>
                                            ))}
                                        </tr>
                                    )
                                })
                            ): loader ? 
                            (
                              <tr className="">
                                  <td colSpan={Object.keys(headings).length} className="">
                                      <Loading/>
                                  </td>
                              </tr>  
                            ): (
                                <tr>
                                    <td colSpan={Object.keys(headings).length} className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black text-center">
                                        No Records Available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
