import React, { useEffect, useState } from 'react';
import Sidebar from '../../../components/sidebar/Sidebar';
import PageTitleCard from '../../../components/pageTitleCard/PageTitleCard';
import AddGlobalVariable from './AddGlobalVariable';
import config from '../../../config/config';
import SearchBar from '../../../components/searchBar/SearchBar';
import SearchById from '../../../components/searchById/SearchById';
import ResetButton from '../../../components/resetButton/ResetButton';
import { deleteGlobalVariableById, fetchGlobalVariablesList } from '../../../services/apis/globalRecommendationVariable';
import GlobalVariablesTable from './GlobalVariablesTable';
import EditGlobalVariable from './EditGlobalVariable';
import FilterDropdown from '../../../components/filterDropdown/FilterDropdown';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RecordsPerPageDropdown from '../../../components/recordsPerPageDropdown/RecordsPerPageDropdown';
import { confirmAlert } from 'react-confirm-alert';
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown';
import RecommendationsTable from '../recommendationsTable.js/RecommendationsTable';

export default function GlobalVariablesListing() {

    const { globalRecommendationVariablesListHeaders } = config;
    const statuses = ["active", "inactive"]; 
    const types = ["regulatoryCharge", "intermediateVariable", "formula", "constant"]; 

    const [isFormOpen, setIsFormOpen] = useState(false);
    const [editFormOpen, setEditFormOpen] = useState(false);
    const [editId, setEditId] = useState("");
    const [editType, setEditType] = useState("");
    const [searchQuery, setSearchQuery] = useState('');
    const [searchUniqueQuery, setSearchUniqueQuery] = useState('');
    const [searchById, setSearchById] = useState("");
    const [tableData, setTableData] = useState([]);
    const [updateCount, setUpdateCount] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: "_id", direction: 'asc' });
    const [limit, setLimit] = useState(() => {
        const storedLimit = parseInt(localStorage.getItem('recordsPerPage'), 10);
        return storedLimit || 50;
    });
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [typeFilter, setTypeFilter] = useState("")
    const [filters, setFilters] = useState({
        status: "",
    })
    const [loader, setLoader] = useState(false)

    const getGlobalVariablesList = async () => {
        setLoader(true)
        const payload = {
            page: currentPage,
            pageSize: limit,
            id: searchById,
            status: filters.status,
            type: typeFilter,
            name: searchQuery,
            uniqueName: searchUniqueQuery,
            sortBy: sortConfig.key,
            sortOrder: sortConfig.direction,
        };
        const response = await fetchGlobalVariablesList(payload);
        const data = response?.data?.data?.results;
        setTableData(data);
        setTotalCount(response?.data?.data?.total)
        // console.log(data,'data')
        setLoader(false)
    };

    useEffect(() => {
        getGlobalVariablesList();
    },[currentPage, filters, sortConfig, searchQuery, searchUniqueQuery, searchById, updateCount, limit, typeFilter])

    const handleFilterChange = (filterType, value) => {
        setFilters({ ...filters, [filterType]: value });
        setCurrentPage(1);
    };

    const handleTypeChange = (selectedType) => {
        if (selectedType) {
          setTypeFilter(selectedType);
        }
    };

    const handleAddButtonClick = () => {
        setIsFormOpen(true);
    };

    const handleCloseForm = () => {
        setIsFormOpen(false);
    };

    const handleEditCloseForm = () => {
        setEditFormOpen(false);
    };

    const handleSearch = (event) =>{
        const query = event.target.value;
        setSearchQuery(query);
        setCurrentPage(1);
    }

    const handleUniqueSearch = (event) =>{
        const query = event.target.value;
        setSearchUniqueQuery(query);
        setCurrentPage(1);
    }

    const handleSearchById = (event) => {
        const id = event.target.value;
        setSearchById(id);
        setCurrentPage(1);
    };

    const handleEdit = (id, objectType) => {
        setEditFormOpen(true);
        setEditId(id);
        setEditType(objectType);
    };

    const handleDelete = async (id, name) => {
        confirmAlert({
          title: "",
          closeOnClickOutside: false,
          message: `Are you sure you want to delete "${name}" Global Variable?`,
          buttons: [
            {
              label: "Yes",
              onClick: async () => {
                const response = await deleteGlobalVariableById(id);
    
                if (response.data.data.length == 0) {
                  toast.error(
                    `Failed to delete the Global Variable. Please try again later.`,
                    {
                      position: "top-center",
                      autoClose: 1500,
                    }
                  );
                } else {
                  toast.success(`Global Variable deleted successfully!`, {
                    position: "top-center",
                    autoClose: 1500,
                  });
                  setUpdateCount((prevCount) => prevCount + 1);
                }
              },
            },
            {
              label: "No",
              onClick: () => {
                // Do nothing
              },
            },
          ],
        });
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        setCurrentPage(1);
    };

    const handleResetFilters = () => {
        setFilters({
            status: '',
        });
        setTypeFilter('');
        setSearchQuery('');
        setSearchById('');
        setSearchUniqueQuery('');
    };

    const handleRecordsPerPageChange = (newLimit) => {
        setLimit(newLimit);
        setCurrentPage(1); 
    };

    const capitalizeWords = (str) => {
        return str
          .replace(/([a-z])([A-Z])/g, '$1 $2') 
          .split(' ') 
          .map(word => word.charAt(0).toUpperCase() + word.slice(1)) 
          .join(' '); 
    };

    return (
        <div className="flex w-full">
            <Sidebar />
            <div className="p-4 overflow-x-auto">
                <div className="max-w-[1920px]">
                    <PageTitleCard title="Global Recommendation Variables List" onAddButtonClick={handleAddButtonClick} addLinkText="Add New Global Variable"/>
                    <div className=''>
                        <div className="overflow-hidden mt-2 rounded-xl ">
                            <div className="flex">
                                <div className="p-3 flex flex-wrap items-center justify-start gap-4 space-y-4 md:space-y-0 md:flex-row">
                                    <SearchBar searchQuery={searchQuery} handleSearch={handleSearch} title="Name"/>
                                    <SearchBar searchQuery={searchUniqueQuery} handleSearch={handleUniqueSearch} title="Unique Name"/>
                                    <SearchById filterById={searchById} handleFilterById={handleSearchById}/>
                                    <FilterDropdown
                                        label="Status"
                                        options={statuses} 
                                        selectedValue={filters.status}
                                        onChange={(value) => handleFilterChange('status', value)}
                                    />
                                    <SelectDropdown
                                        label="Type"
                                        options={types?.map((type) => {
                                            return { label: capitalizeWords(type), value: type };
                                        })}
                                        selectedValue={typeFilter}
                                        onChange={(e) => handleTypeChange(e.target.value)}
                                    />
                                    <RecordsPerPageDropdown onRecordsPerPageChange={handleRecordsPerPageChange} />
                                </div>
                                <div className="flex-grow"></div>
                                <div className="p-3 mt-[60px] mr-2 reset-button-container">
                                    <ResetButton onClick={handleResetFilters} />
                                </div>
                            </div>
                                <div className='bg-white p-2 rounded-xl min-h-[350px]'>
                                    <RecommendationsTable loader={loader} headings={globalRecommendationVariablesListHeaders} data={tableData} handleEdit={(id, type) => handleEdit(id, type)} handleDelete={(id, varName) => handleDelete(id, varName)} handleSort={handleSort} sortConfig={sortConfig} title="Edit" />
                                </div>
                            </div>
                            {totalCount > limit && (
                                <div className="pagination-controls bg-white rounded-b-xl border-[#abaaaa] mb-2 pb-2 flex flex-row items-center justify-end pt-8 -mt-2">
                                    <button className={`bg-[#e1e0e0] ${currentPage === 1 ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl`} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                    <span className="mx-4">{Math.min(currentPage * limit, totalCount) ? Math.min(currentPage * limit, totalCount) : '0'} off {totalCount ? totalCount : '0'}</span>
                                    <button className={`bg-[#e1e0e0] ${currentPage * limit >= totalCount ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl mr-2`} disabled={currentPage * limit >= totalCount} onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
                                </div>
                            )}
                        </div>
                    </div>
                {isFormOpen && <AddGlobalVariable onClose={handleCloseForm} setIsFormOpen={setIsFormOpen} setUpdateCount={setUpdateCount}/>}
                {editFormOpen && <EditGlobalVariable onClose={handleEditCloseForm} editType={editType} editId={editId} setEditFormOpen={setEditFormOpen} setUpdateCount={setUpdateCount} />}
                <ToastContainer position="top-center" autoClose={3000} />
            </div>
        </div>
    )
}
