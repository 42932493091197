import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faPlus,
  faEye
} from "@fortawesome/free-solid-svg-icons";
import EditActionButton from "../../../components/actionButtons/EditActionButton";
import ActionButtons from "../../../components/actionButtons/ActionButtons";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Link } from "react-router-dom";
import Loading from "../../../components/loader/Loader";

const capitalizeWords = (str) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2') 
    .split(' ') 
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) 
    .join(' '); 
};



export default function PowerPlantTable({
  loader=true,
  fy,
  headings,
  data,
  handleSort,
  sortConfig,
  handleEdit,
  handleDelete,
  handleViewPowerPlantUnits,
  title,
  handleAddNewConstant
}) {

  const getDisplayValue = (key, row) => {
    switch (key) {
      case "state":
        return row.stateId?.name;
      case "type":
        return capitalizeWords(row.type);
      case "constantPrice":
        return row.constantPrice[fy] || "-"
      default:
        return row[key] || "-";
    }
  };

  return (
    <div className="bg-white overflow-x-auto rounded-xl">
      <div className="">
        <div className=" overflow-y-hidden">
          <table className="min-w-full px-2 ">
            <thead>
              <tr className="">
                {Object.entries(headings).map(([key, heading], index, array) => (
                  <th
                    key={heading}
                    className={`px-6 py-4 bg-gray-100 text-left text-xs leading-4 font-medium text-black uppercase tracking-wider ${heading !== "Actions" && heading !== "State"
                      ? "cursor-pointer"
                      : ""
                      }`}
                    onClick={() =>
                      heading !== "Actions" &&
                      heading !== "State" &&
                      handleSort(key)
                    }
                    style={{
                        borderBottomLeftRadius: index === 0 ? '0.5rem' : '0',
                        borderBottomRightRadius: index === array.length - 1 ? '0.5rem' : '0',
                        borderTopLeftRadius: index === 0 ? '0.5rem' : '0',
                        borderTopRightRadius: index === array.length - 1 ? '0.5rem' : '0',
                    }}
                  >
                    {heading}{" "}
                    {sortConfig.key === key &&
                      (sortConfig.direction === "asc" ? (
                        <FontAwesomeIcon icon={faArrowUp} />
                      ) : (
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          data-tip="Add new item"
                        />
                      ))}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-300">
              {data?.length > 0 && !loader ? (
                data?.map((row, index) => (
                  <tr key={index}>
                    {Object?.entries(headings)?.map(([key, value]) => (
                      <td
                        key={key}
                        className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black"
                      >
                        {key === "roles" ? (
                          row[key]?.map((role, index) => (
                            <span key={role?._id}>
                              {role?.name}
                              {index < row[key]?.length - 1 && ", "}
                            </span>
                          ))
                        ) : key === "actions" ? (
                          <div className="flex flex-row justify-around">
                            <span>

                              <ActionButtons
                                handleEdit={() => handleEdit(row?._id)}
                                handleDelete={() => handleDelete(row?._id, row?.name)}
                                handleAddNewConstant={()=>handleAddNewConstant(row?._id)}
                                title={title}
                              />
                            </span>
                            <span className="ml-1">
                              <Link to={`/recommendations/power-plant-list/${row?._id}`}>
                                <FontAwesomeIcon
                                  id="mylist-tooltip"
                                  className="cursor-pointer"
                                  icon={faEye}
                                />
                              </Link>
                            </span>
                            <Tooltip
                              anchorSelect="#mylist-tooltip"
                              content="View Power Plant Unit List"
                            />
                          </div>
                        ) : (
                          getDisplayValue(key, row)
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              ) : loader ? 
              (
                <tr className="">
                    <td colSpan={Object.keys(headings).length} className="">
                        <Loading/>
                    </td>
                </tr>  
              ): (
                <tr>
                  <td
                    colSpan={Object.keys(headings).length}
                    className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black text-center"
                  >
                    No Records Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
