import React, { useEffect, useState } from "react";
import LogicalFormulaBuilder from "../../pages/formula/LogicalFormulaBuilder";
import {
  addArithmaticFormula,
  getAllGlobalVariableList,
  getGlobalVariableList,
  gloabalAutoComplete,
} from "../../services/apis/formulaList";
import { useNavigate } from "react-router-dom";

const AddLogicalFormula = () => {
  const navigate = useNavigate();
  const [formulaName, setFormulaName] = useState("");
  const handleFormulaName = (e) => {
    setFormulaName(e.target.value);
  };
  const [variableList, setVariableList] = useState([]);

  const [conditions, setConditions] = useState([
    { type: "if", condition: "", value: "", error: "" },
  ]);

  const [Suggestions, setSuggestions] = useState([
      // Mathematical operators
  '+', '-', '*', '/', '%', '=', '==', '!=', '>', '<', '>=', '<=',
  
  // Logical operators
  '&&', '||', '!',  '&', '|', '^',

  // Brackets and punctuation
  '(', ')', '{', '}', '[', ']', 
    "min()",
    "max()",
    "average()",
  ]);

  const [updatedSuggestions, setupdatedSuggestions] = useState([]);
  const [errors, setErrors] = useState({
    formulaName: { error: "", show: true },
  });
  const [showErrors, setShowErrors] = useState(false);
  const [keyword, setKeyword] = useState("");
  //   getting global variables
  const getGlobalVariables = async () => {
    try {
      const response = await getGlobalVariableList();
      console.log({ response });
      const data = response?.data?.data?.results;
      console.log({ data });
      setVariableList(data);
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const result = getGlobalVariables();
  }, []);

  const validateCondtions = () => {
    const updatedConditions = conditions.map((con) => {
      if (con.type !== "else" && (con.condition === "" || con.value === "")) {
        con.error = "Condition and Value Required*";
      } else if (con.type === "else" && con.value === "") {
        con.error = " Value  Required*";
      } else {
        con.error = "";
      }
      return con;
    });
    setConditions(updatedConditions);
  };

  const handleFormulaNameValidations = () => {
    if (formulaName === "") {
      errors.formulaName.show = true;
      errors.formulaName.error = "Formula Name  Required";
      setErrors({ ...errors });
    } else {
      errors.formulaName.show = false;
      errors.formulaName.error = "";
      setErrors({ ...errors });
    }
  };

  useEffect(() => {
    handleFormulaNameValidations();
  }, [formulaName]);


  const addFormula = async () => {
    setShowErrors(true);
    validateCondtions();
    let errorInCondtion = false;
    conditions.forEach((con) => {
      if (con.error !== "") {
        errorInCondtion = true;
      } 
    });

    if (errors.formulaName.error === "" && !errorInCondtion) {
      const globalVariablesList = await getAllGlobalVariableList();
      console.log({ globalVariablesList });
      console.log({
        globalVariablesList: globalVariablesList.data?.data?.results,
      });
      const variables = globalVariablesList.data?.data?.results;

      const uppdateFormula = replaceSubstring(
        variables,
        convertArray(conditions)
      );
      console.log({ uppdateFormula });

      const apiResponse = await addArithmaticFormula({
        type: "conditional",
        content: uppdateFormula,
        globalRecVariableId: formulaName,
      });
      console.log({ apiResponse });
      if (apiResponse.data.statusCode === 201) {
        console.log("formula created successfully");
        navigate("/recommendations/formula/list");
      } else {
        console.log("Something Went Wrong...");
      }
    }
  };



  // Create a mapping of variable names to unique names

  function replaceSubstring(variableArray, inputArray) {
    const variableMapping = {};
    variableArray.forEach((varItem) => {
      variableMapping[varItem.name] = varItem.uniqueName;
    });

    inputArray.forEach((item) => {
      for (const key in item) {
        item[key] = replaceVariables(item[key], variableMapping);
      }
    });
    console.log(inputArray);
    return inputArray;
  }

  function replaceVariables(inputStr, variableMapping) {
    for (const [name, uniqueName] of Object.entries(variableMapping)) {
      inputStr = inputStr.replace(new RegExp(name, "g"), uniqueName);
    }
    return inputStr;
  }

  useEffect(() => {
    console.log({ conditions });
    console.log({ convertedArray: convertArray(conditions) });
  }, [conditions]);

  function convertArray(inputArray) {
    let outputArray = [];

    inputArray.forEach((item) => {
      let condition = item.condition;
      if (item.type === "if") {
        outputArray.push({
          if: condition,
        });
        outputArray.push({
          then: item.value,
        });
      } else if (item.type === "elseif") {
        outputArray.push({
          elseif: condition,
        });
        outputArray.push({
          then: item.value,
        });
      } else if (item.type === "else") {
        outputArray.push({
          else: item.value,
        });
      }
    });

    return outputArray;
  }

  function formatTextArea(inputText) {
    // Remove line breaks and excess spaces
    let formattedText = inputText
        .replace(/\s+/g, ' ') 
    
    
    return formattedText;
}

  //   =============================================
  //   getting global variables
  const getAutocompleteGlobalVariableList = async (keyword) => {
    try {
        if (keyword.length >= 3) {
      console.log("autocomplete api call");
      const response = await gloabalAutoComplete(keyword);
      console.log({ response });
      const data = response?.data?.data;
      console.log({ data });
      if (data && data.length > 0) {
        const resultArray = data.map((item) => {
          return item.name;
        });
        console.log({ resultArray });
        console.log({ resultArray: [...Suggestions, ...resultArray] });
        setupdatedSuggestions([...Suggestions, ...resultArray]);
      } else {
        setupdatedSuggestions([...Suggestions]);
      }}else{
        setupdatedSuggestions([...Suggestions]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    console.log({ keyword });
    
      getAutocompleteGlobalVariableList(keyword);
    
  }, [keyword]);

  return (
    <div>
      <div className="flex flex-col py-4 w-full">
        {" "}
        <div className="my-2  w-[425px] ">
          <label className="text-sm font-semibold">Formula Name</label>
          <select
            className="min-h-[40px] px-2 my-2 border border-gray-600 rounded-lg w-full"
            value={formulaName}
            onChange={handleFormulaName}
          >
            <option value="">Select Formula Name</option>
            {variableList.map((variable) => (
              <option value={variable._id}>{variable.name}</option>
            ))}
          </select>
          <p
            className={`${
              errors.formulaName.show && showErrors === true ? "" : "hidden"
            }  py-1 text-[red]`}
          >
            {errors.formulaName.error}
          </p>
        </div>
        <div className="my-2   ">
          <LogicalFormulaBuilder
            conditions={conditions}
            setConditions={setConditions}
            updatedSuggestions={updatedSuggestions}
            keyword={keyword}
            showErrors={showErrors}
            setKeyword={setKeyword}
            errors={errors}
            setErrors={setErrors}
            addFormula={addFormula}
            setShowErrors={setShowErrors}
          />
        </div>
        {/* <div className="mt-5">
          <button
            onClick={addFormula}
            className="bg-green-800 text-white  p-2 rounded-lg"
          >
            Save Formula
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default AddLogicalFormula;
