import React, { useEffect, useState } from 'react';
import { fetchUnitById, getStateByStateCode } from '../../../services/apis/listings';

export default function ViewToDPurchase({ id, stateCode, onClose }) {
    const [stateData, setStateData] = useState(null);
    const [unitData, setUnitData] = useState(null);

    useEffect(() => {
        const getStateDataByStateCode = async () => {
            const response = await getStateByStateCode(stateCode);
            const data = response?.data;
            setStateData(data);
        };
        getStateDataByStateCode();
    }, [stateCode]);

    useEffect(() => {
        const getUnitById = async () => {
            const response = await fetchUnitById(id);
            const data = response?.data?.result;
            setUnitData(data);
        };
        if (id) {
            getUnitById();
        }
    }, [id]);

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
            <div className="bg-white p-4 shadow-lg w-full max-w-3xl max-h-[95vh] overflow-y-auto rounded-lg relative">
                <h2 className="text-lg font-semibold ml-4 mt-2 mb-8">View ToD Purchase Details</h2>
                <button
                    type="button"
                    onClick={onClose}
                    className="absolute top-2 right-2 bg-[#a9dbc3] rounded-lg p-2 text-gray-700 hover:bg-gray-300 hover:text-white font-bold"
                >
                    Close
                </button>
                {unitData && unitData?.toDPurchase ? (
                    <table className="min-w-full bg-white">
                        <thead>
                            <tr className="bg-gray-200 ">
                                <th className="py-4 px-4 text-center rounded-tl-xl rounded-bl-xl">ToD Slot</th>
                                <th className="py-4 px-4 text-center ">Power Exchange (%)</th>
                                <th className="py-4 px-4 text-center rounded-tr-xl rounded-br-xl">Third Party (%)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(unitData.toDPurchase.powerExchange).map((slot) => (
                                <tr key={slot}>
                                    <td className="py-2 px-4 border-b border-gray-200 text-center">{slot}</td>
                                    <td className="py-2 px-4 border-b border-gray-200 text-center">
                                        {unitData.toDPurchase.powerExchange[slot]}
                                    </td>
                                    <td className="py-2 px-4 border-b border-gray-200 text-center">
                                        {unitData.toDPurchase.thirdParty[slot]}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="text-center mb-4">No ToD Purchase data available.</p>
                )}
            </div>
        </div>
    );
}
