import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import axios from 'axios'
import { getAllStates } from '../../services/apis/listings'
import { makeFinancialYear } from '../recommendations/constantVariable/getFY'
import VariableTypeSelectDropdown from '../recommendations/regulatoryCharges/VariableTypeSelectDropdown'

export default function CloneStatepop({onClose,setRefresh}) {
    const [allStates,setAllStates] =useState([])
    const [state,setState] =useState("")

    const [toFinancialYear,setToFinancialYear] =useState("2024-2025")
    const [fromFinancialYear,setFromFinancialYear] =useState("2024-2025")

    const handleToFYchange=(value)=>{
        setToFinancialYear(value)
    }
    const handleFromFYchange=(value)=>{
        setFromFinancialYear(value)
    }


    useEffect(() => {
        const getData = async () => {
          const response = await getAllStates();
          setAllStates(response?.data?.results)
        }
        getData()
      }, []);

    
    const handleStatechange=(value)=>{
        setState(value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (state==="") {
            toast.error("Please select a State", {
                position: "top-center",
            });
            return;
        }
        
        const fromYear = fromFinancialYear.split("-")[0];
        const toYear = toFinancialYear.split("-")[0];
    
        if (fromYear === toYear) {
            toast.error("The 'from' and 'to' financial years cannot be the same.", {
                position: "top-center",
            });
            return;
        }
    
        try {
            let response = await axios.post(process.env.REACT_APP_API_URL + "/formula/clone", {
                stateCode:state,
                fromYear:fromYear,
                toYear:toYear
            })
    
            if (response?.status !== 200) {
                toast.error(`${response?.data?.message}`, {
                    position: "top-center",
                });
            } else {
                toast.success(`${response?.data?.message}`, {
                    position: "top-center",
                });
                onClose();
                setRefresh()
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("Failed to add regulation. Please try again.", {
                position: "top-center",
            });
        }
    }
    


    return (
        <>
            <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
                <div className="bg-white p-4 rounded-lg shadow-lg w-full max-w-2xl min-h-[30vh] max-h-[50vh] overflow-y-auto">
                    <h2 className="text-xl py-2 w-full text-center font-semibold">Clone Fromula</h2>
                    <form>
                        <div className="flex-grow overflow-auto">
                            <div className="max-w-2xl">
                                <div className="bg-white overflow-hidden p-4 gap-4">
                                    <div className="flex flex-col items-left justify-between gap-4 px-4">
                                        <DropDownSelect
                                            label="From State"
                                            options={allStates}
                                            selectedValue={state}
                                            onChange={handleStatechange}
                                        />
                                        <VariableTypeSelectDropdown
                                            label="From Financial Year"
                                            options={makeFinancialYear()}
                                            selectedValue={fromFinancialYear}
                                            onChange={handleFromFYchange}

                                        />
                                        <VariableTypeSelectDropdown
                                            label="To Financial Year"
                                            options={makeFinancialYear()}
                                            selectedValue={toFinancialYear}
                                            onChange={handleToFYchange}
                                        />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="sticky bottom-0 bg-white px-4 py-2 flex justify-center gap-8">
                            <button
                                onClick={onClose}
                                className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                onClick={handleSubmit}
                                className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4"
                            >
                                Clone
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

//
const DropDownSelect = ({ label, options, selectedValue, onChange }) => {

    return (
        <div className="relative flex items-center">
            <label className="text-gray-700 w-1/4 px-2">{label}:</label>
            <select
                value={selectedValue}
                onChange={(e) => onChange(e.target.value)}
                className={`block w-3/4 bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 mx-2 rounded-lg shadow focus:outline-none focus:shadow-outline ml-2`}
            >
                <option value="" disabled>Select {label}</option>
                {options.map((option, key) => (
                    <option key={key} value={option.code}>{option.name}</option>
                ))}
            </select>
        </div>
    );
};
