import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../common.css';

export default function AddSEOSettings() {

    const [formData, setFormData] = useState({
        pageName: "",
        metaUrl: "",
        metaKeyword: ""
    });

    const [errors, setErrors] = useState({});
    const [formReset, setFormReset] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value
        });
        if (errors[name]) {
        setErrors({
            ...errors,
            [name]: ''
        });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let formErrors = {};

        if (!formData.pageName.trim()) {
        formErrors.pageName = "Page name is required";
        }

        if (!formData.metaUrl.trim()) {
        formErrors.metaUrl = "Meta URL is required";
        }

        if (!formData.metaKeyword) {
        formErrors.metaKeyword = "Meta Keyword is required";
        }

        if (Object.keys(formErrors).length === 0) {
        console.log(formData);
        toast.success('Global SEO Settings Added Successfully!', {
            position: "top-center"
        });
        setFormReset(true);
        } else {
        setErrors(formErrors);
        }
    };

    const handleCancel = () => {
        setFormData({
        pageName: "",
        metaUrl: "",
        metaKeyword: ""
        });
        setErrors({});
    };

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-grow p-4">
        <div className="max-w-8xl">
            {/* Add Global SEO Settings Title Card */}
            <div className="bg-white shadow-md overflow-hidden">
              <div className="p-4 bg-white text-gray">
                  <h2 className="text-lg font-semibold">Add Global SEO Settings</h2>
              </div>
            </div>
            <div >
              <form onSubmit={handleSubmit}>
                <div className="max-w-8xl">
                  <div className="mt-8 shadow-md p-4">
                  <div className="mb-6 max-w-5xl relative">
                    <label htmlFor="pageName" className="mt-4 mb-2 block text-gray-700">Page Name :</label>
                    <input
                        type="text"
                        placeholder="Enter Page Name"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400"
                        value={formData.pageName}
                        onChange={handleChange}
                        name="pageName"
                    />
                    {errors.pageName && (
                        <p className="text-red-500 text-sm absolute">{errors.pageName}</p>
                    )}
                  </div>
                  <div className="mb-6 max-w-5xl relative">
                      <label htmlFor="metaUrl" className="mt-4 mb-2 block text-gray-700">Meta URL :</label>
                      <input
                          type="text"
                          id="metaUrl"
                          name="metaUrl"
                          value={formData.metaUrl}
                          onChange={handleChange}
                          placeholder="Enter Meta URL"
                          className={`w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                              errors.metaUrl ? "border-red-500" : ""
                          }`}
                      />
                      {errors.metaUrl && (
                          <p className="text-red-500 text-sm absolute">{errors.metaUrl}</p>
                      )}
                  </div>
                  <div className="mb-4 max-w-5xl relative">
                    <label htmlFor="metaKeyword" className="mt-4 mb-2 block text-gray-700">Meta Keywords :</label>
                    <input
                        type="text"
                        placeholder="Enter Meta Tags"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400"
                        value={formData.metaKeyword}
                        onChange={handleChange}
                        name="metaKeyword"
                    />
                    {errors.metaKeyword && (
                      <p className="text-red-500 text-sm absolute">{errors.metaKeyword}</p>
                    )}
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={handleCancel}
                      className="bg-gray-500 mt-10 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-4"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="bg-blue-500 mt-10 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Save
                    </button>
                  </div>
                  </div>
                </div>
                <ToastContainer position="top-center" autoClose={3000} />
              </form>
            </div>
        </div>
      </div>
    </div>
  )
}
