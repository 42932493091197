import React, { useState, useRef, useEffect } from "react";
import "./header.css";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPowerOff } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const { user, isAuthenticated, login, logout } = useAuth();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <header className="header">
      <Link to={"/"}>
        <div className="header-left">
          <div>
            <img src="/Logo-full.png" alt="Logo" className="header-logo" />
          </div>
          {/* <div>
            <h3 className="header-title">Ampera-Energy</h3>
          </div> */}
        </div>
      </Link>

      <nav className="header-right">
        <Link to={"/"} className="header-nav-item">Home</Link>
        
        {isAuthenticated ? (
          <div ref={dropdownRef} className="user-dropdown">
            <button onClick={toggleDropdown} className="user-icon">
              <FontAwesomeIcon icon={faUser} />
              <span>{user.username}</span>
            </button>
            {dropdownOpen && (
              <div className="dropdown-menu mt-4">
                <button><FontAwesomeIcon icon={faUser} /><span className="ml-2">My Account</span></button>
                <button onClick={handleLogout}><FontAwesomeIcon icon={faPowerOff} /><span className="ml-2">Logout</span></button>
              </div>
            )}
          </div>
        ) : (
          <Link to="/login" className="header-nav-item">
            Login
          </Link>
        )}
      </nav>
    </header>
  );
};

export default Header;
