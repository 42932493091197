import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import VariableTypeSelectDropdown from "../../pages/recommendations/regulatoryCharges/VariableTypeSelectDropdown";
import { makeFinancialYear, makeYearFormate } from "../../pages/recommendations/constantVariable/getFY";

const AddPolicyPopUp = ({ isOpen, closeModal, onSave, stateId, stateCode, noOfTodSlots}) => {
    const [formData, setFormData] = useState({financialYear:"2024-2025", from: "", to: "" });

    const handleChange = (e) => {
        const { id, value } = e.target;
        if(id==='financialYear'){
            setFormData((prevFormData) => ({ ...prevFormData, [id]: value.split("-")[0] }));
        }
        setFormData((prevFormData) => ({ ...prevFormData, [id]: value }));
    };

    const handleSave = async (e) => {
        e.preventDefault();
        console.log(formData);
        try {
            await onSave(stateCode, formData);
            setFormData({
                financialYear:"2024-2025",
                from: "", 
                to: "" 
            })
            closeModal();
        } catch (error) {
            console.error("Error saving policy:", error);
        }
    };

    const handleReset = () => {
        setFormData({ financialYear:"2024-2025",from: "", to: "" });
    };

    if (!isOpen) return null;

    const todSlots = Array.from({ length: noOfTodSlots }, (_, index) => `Tod ${index + 1}`);

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            {noOfTodSlots > 0 ? (
                <div className="bg-white p-6 rounded-lg shadow-lg w-2/3">
                    <div className="flex justify-between">
                        <h2 className="text-xl font-semibold mb-4 w-full text-center">Add New Policy</h2>

                        <button
                            className="text-gray-600 hover:text-gray-900 mb-4"
                            onClick={closeModal}
                            id="close"
                        >
                            <FontAwesomeIcon icon={faCircleXmark} />
                            <Tooltip anchorSelect="#close" content="Close" />
                        </button>
                    </div>
                    <form onSubmit={handleSave}>
                        <div className="bg-white overflow-hidden mt-4 rounded-xl px-4">
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="from">
                                    Select Financial Year
                                </label>
                                <select
                                    id="financialYear"
                                    value={formData.financialYear}
                                    onChange={handleChange}
                                    className="mt-2 appearance-none rounded w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 focus:outline-none focus:shadow-outline"
                                >
                                    <option value="">Select Financial Year</option>
                                    {makeFinancialYear()?.map((slot) => (
                                        <option key={slot} value={slot}>
                                            {slot}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="from">
                                    From
                                </label>
                                <select
                                    id="from"
                                    value={formData.from}
                                    onChange={handleChange}
                                    className="mt-2 appearance-none rounded w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 focus:outline-none focus:shadow-outline"
                                >
                                    <option value="">Select From</option>
                                    {todSlots.map((slot) => (
                                        <option key={slot} value={slot}>
                                            {slot}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="to">
                                    To
                                </label>
                                <select
                                    id="to"
                                    value={formData.to}
                                    onChange={handleChange}
                                    className="mt-2 appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded-lg shadow focus:outline-none focus:shadow-outline"
                                >
                                    <option value="">Select To</option>
                                    {todSlots.map((slot) => (
                                        <option key={slot} value={slot}>
                                            {slot}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex justify-center gap-8">
                                <button
                                    type="button"
                                    className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] mt-10 hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
                                    onClick={handleReset}
                                >
                                    Reset
                                </button>
                                <button
                                    onClick={handleSave}
                                    className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] mt-10 hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
                                    type="submit"
                                >
                                    Save
                                </button>
                             </div>
                        </div>
                    </form>
                </div>
            ) : (
                <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
                    <h2 className="text-xl font-semibold mb-4">Please specify the number of Time of Day (ToD) Slots to add a banking policy</h2>
                    <p className="text-gray-700 mb-4">To add a banking policy, please provide the number of Time of Day (ToD) Slots in state list Page. This helps us better manage your banking transactions.</p>
                    <button
                        className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] mt-4 hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
                        onClick={closeModal}
                    >
                        Close
                    </button>
                </div>
            )}
        </div>
    );
};

export default AddPolicyPopUp;
