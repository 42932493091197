import axios from 'axios';

const user = JSON.parse(localStorage.getItem('user'));
const token = user?.result?.token

const API_URL = process.env.REACT_APP_API_URL; 

export const createPowerPlantTYpe = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/powerPlantType`, payload);
        // console.log(response,'response')
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const fetchPowerPlantsTypesList = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/powerPlantType/list`, payload);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const fetchPowerPlantTypeById = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/powerPlantType/${id}`);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const updatePowerPlantType = async (id, payload) => {
    try {
        const response = await axios.put(`${API_URL}/powerPlantType/${id}`, payload);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const deletePowerPlantTypeById = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/powerPlantType/${id}`);
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}