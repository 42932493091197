import React, { useEffect, useState } from 'react';
import config from '../../config/config';
import PageTitleCard from '../../components/pageTitleCard/PageTitleCard';
import Sidebar from '../../components/sidebar/Sidebar';
import Table from '../../components/table/Table';
import { deleteUserRoleById, fetchUserRoleList } from '../../services/apis/userRole';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import DeleteConfirmation from '../../components/deleteConfirmation/DeleteConfirmation';


export default function UserRoleList() {
    const navigate = useNavigate();

    const { userRoleListTableHeadings } = config;

    const [userRoleData, setUserRoleData] = useState([]);
    const [totalUserRoleData, setTotalUserRoleData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: '_id', direction: 'asc' });
    const [count, setCount] = useState(1);
    const [limit, setLimit] = useState(() => {
        const storedLimit = parseInt(localStorage.getItem('recordsPerPage'), 10);
        return storedLimit || 50;
    });
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false); 
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [deleteItemName, setDeleteItemName] = useState("");
    const [loader,setLoader] =useState(true)

    const handleEdit = (id) => {
        navigate(`/edit-user-role/${id}`)
    }

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        setCurrentPage(1);
    };

    const updateCount = () => {
        setCount(prevCount => prevCount + 1);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoader(true)
            const payload = {
                page: currentPage,
                pageSize: limit,
                sortBy: sortConfig.key,
                sortOrder: sortConfig.direction,
            };
            const userRoleList = await fetchUserRoleList(payload);
            const data = userRoleList?.data?.results;
            setUserRoleData(data);
            setTotalCount(userRoleList?.data?.total)
            setTotalUserRoleData(data);
            setLoader(false)
        }
        fetchData();
    },[count, sortConfig, currentPage])

    const handleDelete = (id, name) => {
        setDeleteModalOpen(true);
        setDeleteItemId(id);
        setDeleteItemName(name);
    };

    const confirmDelete = async () => {
        const response = await deleteUserRoleById(deleteItemId);

        if (response.success == true) {
            toast.success(`User Role deleted successfully!`, {
                position: "top-center",
                autoClose: 1500,
            });
            updateCount();
        } else {
            toast.error(`Failed to delete the user role. Please try again later.`, {
                position: "top-center",
                autoClose: 1500,
            });
        }
        setDeleteModalOpen(false);
    };

    return (
        <div className="flex">
            <Sidebar />
            <div className="flex-grow p-4">
                <div className="max-w-8xl">
                    <PageTitleCard title="User Role List" addLink="/add-user-role" addLinkText="Add New User Role"/>
                    <div className='bg-white rounded-xl'>
                        <div className="bg-white overflow-hidden mt-4 rounded-xl ">
                            <div className='bg-white p-2 rounded-xl min-h-[350px]'>
                                <Table loader={loader} headings={userRoleListTableHeadings} data={userRoleData} handleDelete={handleDelete} handleEdit={(id) => handleEdit(id)} handleSort={handleSort} sortConfig={sortConfig} updateCount={updateCount} />
                            </div>
                        </div>
                        {totalCount > limit && (
                            <div className="pagination-controls bg-white rounded-b-xl border-[#abaaaa] mb-2 pb-2 flex flex-row items-center justify-end pt-8 -mt-2">
                                <button className={`bg-[#e1e0e0] ${currentPage === 1 ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl`} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                <span className="mx-4">{Math.min(currentPage * limit, totalCount) ? Math.min(currentPage * limit, totalCount) : '0'} off {totalCount ? totalCount : '0'}</span>
                                <button className={`bg-[#e1e0e0] ${currentPage * limit >= totalCount ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl mr-2`} disabled={currentPage * limit >= totalCount} onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={3000} />
            <DeleteConfirmation
                isOpen={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                onConfirm={confirmDelete}
                itemName={deleteItemName}
            />
        </div>
    )
}
