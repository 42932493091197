import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

export default function FilterDropdown({ label, options, selectedValue, onChange }) {
  return (
    <div className="relative">
        {/* <label className="block mb-1 mt-0 text-sm font-medium text-gray-700">{label}</label> */}
        <select
            value={selectedValue}
            onChange={(e) => onChange(e.target.value)}
            className="block cursor-pointer appearance-none w-60 bg-white border border-gray-200 hover:border-gray-400 px-4 py-2 pr-8 rounded-lg leading-tight focus:outline-none "
        >
            <option value="" disabled>{`Filter by ${label}`}</option>
            {options?.map((option) => (
              <option key={option} value={option}>{option.charAt(0).toUpperCase() + option.slice(1)}</option>
            ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-700">
            <FontAwesomeIcon icon={faAngleDown} />
        </div>
    </div>
  )
}
