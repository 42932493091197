import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';


export default function EditActionButton({ handleEdit, title }) {

    return (
        <>
            <button className="mx-1" onClick={handleEdit} title={title}>
                <FontAwesomeIcon icon={faEdit} />
            </button>
        </>
    );
}
