import React from 'react';
import useRecordsPerPage from '../../hooks/useRecordsPerPage';

const RecordsPerPageDropdown = ({ onRecordsPerPageChange }) => {
    const [recordsPerPage, setRecordsPerPage] = useRecordsPerPage();
    const options = [5, 10, 20, 30, 50, 100];

    const handleChange = (event) => {
        const newRecordsPerPage = parseInt(event.target.value, 10);
        setRecordsPerPage(newRecordsPerPage);
        onRecordsPerPageChange(newRecordsPerPage);
    };

    return (
        <div className="records-per-page-dropdown px-3">
            <label htmlFor="records-per-page">Records per page:</label>
            <select className='p-1 border rounded-md border-gray-300 ml-2 cursor-pointer' id="records-per-page" value={recordsPerPage} onChange={handleChange}>
                {options.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default RecordsPerPageDropdown;
