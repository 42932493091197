import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/sidebar/Sidebar';
import config from '../../../config/config';
import PageTitleCard from '../../../components/pageTitleCard/PageTitleCard';
import RecordsPerPageDropdown from '../../../components/recordsPerPageDropdown/RecordsPerPageDropdown';
import ResetButton from '../../../components/resetButton/ResetButton';
import GlobalVariablesTable from '../globalVariables/GlobalVariablesTable';
import { ToastContainer, toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { deleteGlobalVariableById } from '../../../services/apis/globalRecommendationVariable';
import AddConstantVariable from './AddConstantVariable';
import EditConstantVariable from './EditConstantVariable';
import { deleteConstantVariableById, fetchConstantVariablesList, updateConstantVariable } from '../../../services/apis/constantVariable';
import SearchBar from '../../../components/searchBar/SearchBar';
import ConstantVariableTable from './ConstantVariableTable';
import RecommendationsTable from '../recommendationsTable.js/RecommendationsTable';
import DeleteConfirmation from '../../../components/deleteConfirmation/DeleteConfirmation';
import FilterDropdown from "../../../components/filterDropdown/FilterDropdown"
import { makeFinancialYear, makeYearFormate } from './getFY';
import CloneFYpop from './CloneFYpop';

export default function ConstantVariableListing() {

    const { constantVariablesListHeaders } = config;

    const [isFormOpen, setIsFormOpen] = useState(false);
    const [editFormOpen, setEditFormOpen] = useState(false);
    const [editId, setEditId] = useState("");
    const [searchQuery, setSearchQuery] = useState('');
    const [tableData, setTableData] = useState([]);
    const [updateCount, setUpdateCount] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: "_id", direction: 'asc' });
    const [limit, setLimit] = useState(() => {
        const storedLimit = parseInt(localStorage.getItem('recordsPerPage'), 10);
        return storedLimit || 50;
    });
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false); 
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [deleteItemName, setDeleteItemName] = useState("");
    const [isCloneFormOpen,setIsCloneFormOpen] =useState(false)
    const [filters, setFilters] = useState({
        financialYear:"2024",
    });
    const [loader,setLoader]=useState(true)

    useEffect(() => {
        const getConstantVariablesList = async () => {
            setLoader(true)
            const payload = {
                financialYear:filters.financialYear,
                page: currentPage,
                pageSize: limit,
                name: searchQuery,
                sortBy: sortConfig.key,
                sortOrder: sortConfig.direction,
                type: "constant"
            }
            const response = await fetchConstantVariablesList(payload);
            const constantVariablesList = response?.data?.data?.results;
            const data = constantVariablesList?.map(item => {
                const financialYearEntries = item.financialYear || {};
                const value =  financialYearEntries[filters.financialYear]
                return {
                    _id: item?._id,
                    type: item?.type.charAt(0).toUpperCase() + item?.type.slice(1),
                    financialYear: filters.financialYear || '',
                    content: value || '',
                    name: item?.name
                };
            });
            setTableData(data);
            setTotalCount(response?.data?.data?.total);
            setLoader(false)
        }
        
        getConstantVariablesList()
    },[searchQuery, limit, currentPage, updateCount, sortConfig,filters])

    console.log(tableData,"tableData")

    const handleAddButtonClick = () => {
        setIsFormOpen(true);
    };

    const handleCloseForm = () => {
        setIsFormOpen(false);
    };

    const handleEditCloseForm = () => {
        setEditFormOpen(false);
    };

    const handleEdit = (id) => {
        setEditFormOpen(true);
        setEditId(id);
    };

    const handleSearch = (event) =>{
        const query = event.target.value;
        setSearchQuery(query);
        setCurrentPage(1);
    }

    const handleDelete = (id, name) => {
        setDeleteModalOpen(true);
        setDeleteItemId(id);
        setDeleteItemName(name);
    };

    const confirmDelete = async () => {
        const response =
        await updateConstantVariable(deleteItemId, {type: 'constant-delete',
                financialYear:filters.financialYear});

        if (response?.data?.data?.length === 0) {
            toast.error('Failed to delete the Constant Variable. Please try again later.', {
                position: "top-center",
                autoClose: 1500,
            });
        } else {
            toast.success('Constant Variable deleted successfully!', {
                position: "top-center",
                autoClose: 1500,
            });
            setUpdateCount((prevCount) => prevCount + 1);
        }
        setDeleteModalOpen(false);
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        setCurrentPage(1);
    };

    const handleResetFilters = () => {
        setSearchQuery('');
        setFilters({
            financialYear:"2024",
        })
    };

    const handleRecordsPerPageChange = (newLimit) => {
        setLimit(newLimit);
        setCurrentPage(1); 
    };

    const handleFilterChange = (field, value) => {
        setFilters(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleCloseCloneFrom= () =>{
        setIsCloneFormOpen(false)
    }
    const handleOpneCloneFrom= () =>{
        setIsCloneFormOpen(true)
    }
    return (
        <div className="flex">
            <Sidebar />
            <div className="flex-grow p-4">
                <div className="max-w-[1920px]">
                    <PageTitleCard title="Constant Variables List" onAddButtonClick={handleAddButtonClick} addLinkText="Add New Constant Variable" clone={true} cloneFromOpen={handleOpneCloneFrom} />
                    <div className=''>
                        <div className=" overflow-hidden mt-2 rounded-xl ">
                            <div className="flex">
                                <div className="p-3 flex flex-wrap items-center justify-start gap-4 space-y-4 md:space-y-0 md:flex-row">
                                    <SearchBar searchQuery={searchQuery} handleSearch={handleSearch} title="Global Variable"/>
                                    <FilterDropdown
                                        label="FY"
                                        options={makeFinancialYear()}
                                        selectedValue={makeYearFormate(filters.financialYear) }
                                        onChange={(value) => handleFilterChange('financialYear', value.split("-")[0])}
                                    />
                                    <RecordsPerPageDropdown onRecordsPerPageChange={handleRecordsPerPageChange} />
                                </div>
                                <div className="flex-grow"></div>
                                <div className="p-3 mt-[5px] mr-2 reset-button-container">
                                    <ResetButton onClick={handleResetFilters} />
                                </div>
                            </div>
                            <div className='bg-white p-2 rounded-xl min-h-[350px]'>
                                <RecommendationsTable loader={loader} headings={constantVariablesListHeaders} data={tableData} handleEdit={(id, type) => handleEdit(id, type)} handleDelete={(id, varName) => handleDelete(id, varName)} handleSort={handleSort} sortConfig={sortConfig} title="Edit" />
                                {/* <ConstantVariableTable headings={constantVariablesListHeaders} data={tableData} handleEdit={(id, type) => handleEdit(id, type)} handleDelete={(id, varName) => handleDelete(id, varName)} handleSort={handleSort} sortConfig={sortConfig} title="Edit" /> */}
                            </div>
                            </div>
                            {totalCount > limit && (
                                <div className="pagination-controls bg-white rounded-b-xl border-[#abaaaa] mb-2 pb-2 flex flex-row items-center justify-end pt-8 -mt-2">
                                    <button className={`bg-[#e1e0e0] ${currentPage === 1 ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl`} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                    <span className="mx-4">{Math.min(currentPage * limit, totalCount) ? Math.min(currentPage * limit, totalCount) : '0'} off {totalCount ? totalCount : '0'}</span>
                                    <button className={`bg-[#e1e0e0] ${currentPage * limit >= totalCount ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl mr-2`} disabled={currentPage * limit >= totalCount} onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
                                </div>
                            )}
                        </div>
                </div>
                {isFormOpen && <AddConstantVariable onClose={handleCloseForm} setIsFormOpen={setIsFormOpen} setUpdateCount={setUpdateCount}/>}
                {editFormOpen && <EditConstantVariable onClose={handleEditCloseForm} fy={filters.financialYear} editId={editId} setEditFormOpen={setEditFormOpen} setUpdateCount={setUpdateCount} />}
                {isCloneFormOpen && <CloneFYpop onClose={handleCloseCloneFrom} setUpdateCount={setUpdateCount}/>}
                <ToastContainer position="top-center" autoClose={3000} />
                <DeleteConfirmation
                    isOpen={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    onConfirm={confirmDelete}
                    itemName={deleteItemName}
                />
            </div>
        </div>
    )
}
