import React, { useEffect, useState } from "react";
import LogicalFormulaBuilder from "../../pages/formula/LogicalFormulaBuilder";
import {
  addArithmaticFormula,
  editArithmaticFormula,
  getAllGlobalVariableList,
  getGlobalVariableList,
  gloabalAutoComplete,
} from "../../services/apis/formulaList";
import { useNavigate, useParams } from "react-router-dom";

const EditLogicalFormula = ({ data }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formulaName, setFormulaName] = useState("");
  const handleFormulaName = (e) => {
    setFormulaName(e.target.value);
  };
  const [variableList, setVariableList] = useState([]);
  const [errors, setErrors] = useState({
    formulaName: { error: "", show: true },
  });
  const [showErrors, setShowErrors] = useState(false);


  const [allGlobalVariableList, setAllGlobalVariableList] = useState([])

  const [conditions, setConditions] = useState([
    { type: "if", condition: "", value: "", error: "" },
  ]);

  const [Suggestions, setSuggestions] = useState([
'+', '-', '*', '/', '%', '=', '==', '!=', '>', '<', '>=', '<=',
  
  // Logical operators
  '&&', '||', '!',  '&', '|', '^',

  // Brackets and punctuation
  '(', ')', '{', '}', '[', ']', 
    "min()",
    "max()",
    "average()",
  ]);

  const [updatedSuggestions, setupdatedSuggestions] = useState([]);

  const [keyword, setKeyword] = useState("");

  const validateCondtions = () => {
    const updatedConditions = conditions.map((con) => {
      if (con.type !== "else" && (con.condition === "" || con.value === "")) {
        con.error = "Condition and Value Required*";
      }
      else if (con.type === "else" && (con.value === "")) {
        con.error = " Value  Required*";
      } 
      
      else {
        con.error = "";
      }
      return con;
    });
    setConditions(updatedConditions);
  };

  const handleFormulaNameValidations = () => {
    if (formulaName === "") {
      errors.formulaName.show = true;
      errors.formulaName.error = "Formula Name  Required";
      setErrors({ ...errors });
    } else {
      errors.formulaName.show = false;
      errors.formulaName.error = "";
      setErrors({ ...errors });
    }
  };

  useEffect(() => {
    handleFormulaNameValidations();
  }, [formulaName]);


const getAndSetGlobalVariableList=async()=>{
    const globalVariablesList = await getAllGlobalVariableList();
    console.log({ globalVariablesList });
    console.log({
      globalVariablesList: globalVariablesList.data?.data?.results,
    });
    const variables = globalVariablesList.data?.data?.results;
    setAllGlobalVariableList(variables);
    return variables
}



  const addFormula = async () => {
    setShowErrors(true);
    validateCondtions();
    let errorInCondtion = false;
    conditions.forEach((con) => {
      if (con.error !== "") {
        errorInCondtion = true;
      } 
    });

    if (errors.formulaName.error === "" && !errorInCondtion) {
    
// const variables=await getAndSetGlobalVariableList();
      const uppdateFormula = replaceSubstring(
        allGlobalVariableList,
        convertArray(conditions)
      );
      console.log({ uppdateFormula });
      const apiResponse = await editArithmaticFormula(id, {
        type: "conditional",
        content: uppdateFormula,
        globalRecVariableId: formulaName,
      });
      console.log({ apiResponse });
      if (apiResponse.data.statusCode === 200) {
        console.log("formula updated successfully");
        navigate("/recommendations/formula/list");
      } else {
        console.log("Something Went Wrong...");
      }
    }
  };

  // Create a mapping of variable names to unique names

  function replaceSubstring(variableArray, inputArray) {
    const variableMapping = {};
    variableArray.forEach((varItem) => {
      variableMapping[varItem.name] = varItem.uniqueName;
    });

    inputArray.forEach((item) => {
      for (const key in item) {
        item[key] = replaceVariables(item[key], variableMapping);
      }
    });
    console.log(inputArray);
    return inputArray;
  }


  function replaceUniqueNames(inputArray, variableArray) {
    // Create a mapping of unique names to variable names
    const variableMapping = {};
    variableArray.forEach(varItem => {
      variableMapping[varItem.uniqueName] = varItem.name;
    });
  
    // Function to replace unique names with variable names in a string
    function replaceVariables(inputStr, variableMapping) {
      if (typeof inputStr !== 'string') return inputStr;
      for (const [uniqueName, name] of Object.entries(variableMapping)) {
        inputStr = inputStr.replace(new RegExp(uniqueName, 'g'), name);
      }
      return inputStr;
    }
  
    // Replace unique names in the input array
    inputArray.forEach(item => {
      for (const key in item) {
        item[key] = replaceVariables(item[key], variableMapping);
      }
    });
  
    return inputArray;
  }

  function replaceVariables(inputStr, variableMapping) {
    for (const [name, uniqueName] of Object.entries(variableMapping)) {
      inputStr = inputStr.replace(new RegExp(name, "g"), uniqueName);
    }
    return inputStr;
  }

  useEffect(() => {
    console.log({ conditions });
    console.log({ convertedArray: convertArray(conditions) });
  }, [conditions]);

  function convertArray(inputArray) {
    let outputArray = [];
    if (inputArray) {
      inputArray.forEach((item) => {
        let condition = item.condition;

        if (item.type === "if") {
          outputArray.push({
            if: condition,
          });
          outputArray.push({
            then: item.value,
          });
        } else if (item.type === "elseif") {
          outputArray.push({
            elseif: condition,
          });
          outputArray.push({
            then: item.value,
          });
        } else if (item.type === "else") {
          outputArray.push({
            else: item.value,
          });
        }
      });

      return outputArray;
    }
  }

  //   =============================================
  //   getting global variables
  const getAutocompleteGlobalVariableList = async (keyword) => {
    try {
        if (keyword.length >= 3) {
      console.log("autocomplete api call");
      const response = await gloabalAutoComplete(keyword);
      console.log({ response });
      const data = response?.data?.data;
      console.log({ data });
      if (data && data.length > 0) {
        const resultArray = data.map((item) => {
          return item.name;
        });
        console.log({ resultArray });
        console.log({ resultArray: [...Suggestions, ...resultArray] });
        setupdatedSuggestions([...Suggestions, ...resultArray]);
      } else {
        setupdatedSuggestions([...Suggestions]);
      }
    }else{
        setupdatedSuggestions([...Suggestions]);
    }

      //   setVariableList(data);
      //   return data;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    console.log({ keyword });

      getAutocompleteGlobalVariableList(keyword);
    
  }, [keyword]);


  const setEditData=async()=>{
    console.log({ data });

    if (!data) {
      console.error("Data is undefined or null");
      return;
    }

    console.log({ data: data.content });

    const variables = await getAndSetGlobalVariableList();

    const updatedVariablesFormula = replaceUniqueNames(data.content,variables)

    console.log({updatedVariablesFormula})

    const reversedArray = reverseConvertArray(updatedVariablesFormula);
    console.log({ reversedArray });

    setConditions(reversedArray);

    setVariableList((prev) => {
      if (!data.globalRecVariableId) {
        console.error("data.globalRecVariableId is undefined or null");
        return prev; // Return the previous state without any changes
      }

      const newData = [...prev, data.globalRecVariableId];
      const uniqueData = Array.from(
        new Set(newData.map((item) => item._id))
      ).map((id) => {
        return newData.find((item) => item._id === id);
      });

      console.log({ newData });
      console.log({ uniqueData });

      return [...uniqueData];
    });

    if (data.globalRecVariableId) {
      setFormulaName(data.globalRecVariableId._id);
    } else {
      console.error("data.globalRecVariableId is undefined or null");
    }
  }

  useEffect(() => {
    setEditData()
  }, [data]);

  function reverseConvertArray(outputArray) {
    let inputArray = [];
    let currentItem = null;
    if (outputArray) {
      outputArray.forEach((item) => {
        if (item.hasOwnProperty("if")) {
          currentItem = {
            type: "if",
            condition: item.if,
            value: "",
            error: "",
          };
          inputArray.push(currentItem);
        } else if (item.hasOwnProperty("elseif")) {
          currentItem = {
            type: "elseif",
            condition: item.elseif,
            value: "",
            error: "",
          };
          inputArray.push(currentItem);
        } else if (item.hasOwnProperty("else")) {
          currentItem = {
            type: "else",
            condition: "",
            value: item.else,
            error: "",
          };
          inputArray.push(currentItem);
        } else if (item.hasOwnProperty("then")) {
          if (currentItem) {
            currentItem.value = item.then;
          }
        }
      });

      return inputArray;
    }
  }

  return (
    <div>
      <div className="flex flex-col py-4 w-full">
        {" "}
        <div className="my-2  w-[425px] ">
          <label className="text-sm font-semibold">Formula Name</label>
          <select
            className="min-h-[40px] px-2 my-2 border border-gray-600 rounded-lg w-full"
            value={formulaName}
            aria-readonly
          >
            <option value="">Select Formula Name</option>
            {variableList.map((variable) => (
              <option value={variable._id}>{variable.name}</option>
            ))}
          </select>
          <p
            className={`${
              errors.formulaName.show && showErrors === true ? "" : "hidden"
            }  py-1 text-[red]`}
          >
            {errors.formulaName.error}
          </p>
        </div>
        <div className="my-2  w-[700px] ">
          <LogicalFormulaBuilder
            conditions={conditions}
            setConditions={setConditions}
            updatedSuggestions={updatedSuggestions}
            keyword={keyword}
            showErrors={showErrors}
            setKeyword={setKeyword}
            setShowErrors={setShowErrors}
            addFormula={addFormula}
          />
        </div>
        
      </div>
    </div>
  );
};

export default EditLogicalFormula;
