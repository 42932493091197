import SEOSettingList from "../dummyData/seoSettingsList";

export const getSEOSettingsListData = () => {
    try{
        return SEOSettingList;
    } catch (e) {
        console.log(e);
    }
}

export const getSEOSettingsById = (seoSettingsId) => {
    try {
        const seoSettingsList = getSEOSettingsListData();
        const seoSettings = seoSettingsList.find(seoSettings => seoSettings._id == seoSettingsId);
        return seoSettings;
    } catch (error) {
        console.error(error);
        return null;
    }
}