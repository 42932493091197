import React, { useEffect, useState } from 'react';
import Sidebar from '../../../components/sidebar/Sidebar';
import { fetchStateList } from '../../../services/apis/listings';
import { editStateId } from '../../../services/apis/content';
import PageTitleCard from '../../../components/pageTitleCard/PageTitleCard';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTag } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import AddSlotsPopup from '../../../components/addSlotsPopup/AddSlotsPopup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tooltip } from 'react-tooltip';
import Loading from '../../../components/loader/Loader';

export default function ToDBankingPolicy() {
    const [stateListData, setStateListData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [statusUpdate, setStatusUpdate] = useState(false);
    const [sortColumn, setSortColumn] = useState("name");
    const [sortOrder, setSortOrder] = useState('asc');
    const [searchTerm, setSearchTerm] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [selectedState, setSelectedState] = useState(null);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const getStateList = async () => {
            setIsLoading(true);
            const response = await fetchStateList();
            setStatusUpdate(false);
            const stateList = response?.data?.results;

            if (stateList) {
                const sortedStateList = sortData(stateList, sortColumn, sortOrder);
                setStateListData(sortedStateList);
            }
            setIsLoading(false);
        };
        getStateList();
    }, [statusUpdate, sortColumn, sortOrder]);

    const sortData = (data, column, order) => {
        if (!column) return data;
        const sortedData = [...data].sort((a, b) => {
            if (a[column] < b[column]) return order === 'asc' ? -1 : 1;
            if (a[column] > b[column]) return order === 'asc' ? 1 : -1;
            return 0;
        });
        return sortedData;
    };

    const handleSort = (column) => {
        const order = (sortColumn === column && sortOrder === 'asc') ? 'desc' : 'asc';
        setSortColumn(column);
        setSortOrder(order);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredStateList = stateListData.filter(state =>
        state.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        state.code.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleEditBankPolicy = (id) => {
        navigate(`/recommendations/tod-banking-policy/banking-policy/${id}`);
    };

    const handleEditTodIncentive = (id) => {
        navigate(`/recommendations/tod-banking-policy/tod-incentives/${id}`);
    };

    const handleEditIEXDiscount = (id) => {
        navigate(`/recommendations/iex-discount/${id}`);
    };

    const handleEditSlots = (state) => {
        setSelectedState(state);
        setError('');
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div className="flex w-[100%]">
            <Sidebar />
            <div className={`flex-grow p-4`}>
                <PageTitleCard title="State-Wise ToD Banking Policy" />
                <div className='max-w-8xl mt-2 overflow-hidden rounded-xl'>
                    <div className="mb-2 p-2">
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={handleSearch}
                            placeholder="Search..."
                            className="w-[20%] px-4 py-2 border border-gray-200 rounded-lg"
                        />
                    </div>
                    <div className='bg-white p-2 rounded-xl'>
                        <div className="bg-white relative overflow-x-auto h-[700px] overflow-y-auto sm:rounded-lg rounded-lg state-list">
                            <table className="w-full text-sm text-left text-black ">
                                <thead className="text-xs uppercase font-medium text-black bg-gray-100">
                                    <tr>
                                        <th scope="col" className="px-6 py-4 cursor-pointer w-[25%]"
                                            onClick={() => handleSort('name')}
                                            style={{
                                                borderBottomLeftRadius: '0.5rem' ,
                                                borderTopLeftRadius: '0.5rem' ,
                                            }}
                                        >
                                            State {sortColumn === 'name' ? (sortOrder === 'asc' ? '▲' : '▼') : ''}
                                        </th>
                                        <th scope="col" className="px-6 py-4 cursor-pointer w-[16%]"
                                            onClick={() => handleSort('code')}
                                        >
                                            State Code {sortColumn === 'code' ? (sortOrder === 'asc' ? '▲' : '▼') : ''}
                                        </th>
                                        {/* <th scope="col" className="px-3 py-4 w-[12%]">
                                            No. of ToD Slots
                                        </th>
                                        <th scope="col" className="px-3 py-4 w-[12%]">
                                            Constant Price
                                        </th> */}
                                        <th scope="col" className="px-3 py-4 w-[30%]"
                                            style={{
                                                borderBottomRightRadius: '0.5rem' ,
                                                borderTopRightRadius: '0.5rem' ,
                                            }}
                                        >
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredStateList.length > 0 && !isLoading ? (
                                        filteredStateList.map((state) => (
                                            <tr
                                                className="border-b text-black"
                                                key={state?._id}
                                            >
                                                <td className="px-8 py-4 whitespace-nowrap flex items-center justify-left">
                                                    {state?.name}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    {state?.code}
                                                </td>
                                                {/* <td className="px-6 py-4 whitespace-nowrap">
                                                    {state?.noOfTodSlots}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    {state?.constantPrice}
                                                </td> */}
                                                <td className="pl-2 pr-6 py-4 whitespace-nowrap">
                                                    <label className="flex justify-start gap-5 cursor-pointer">
                                                        <button className="text-wrap" id={`edit-${state._id}`} onClick={() => handleEditSlots(state)}>
                                                            <FontAwesomeIcon icon={faEdit} /> Edit
                                                            <Tooltip anchorSelect={`#edit-${state._id}`} content={`Edit`} />
                                                        </button>
                                                        <button className="text-wrap" id={`bank-policy-${state._id}`} onClick={() => handleEditBankPolicy(state?._id)}>
                                                            <FontAwesomeIcon icon={faEdit} /> Bank Policy
                                                            <Tooltip anchorSelect={`#bank-policy-${state._id}`} content={`Bank Policy`} />
                                                        </button>
                                                        <button className="text-wrap" id={`incentives-${state._id}`} onClick={() => handleEditTodIncentive(state?._id)}>
                                                            <FontAwesomeIcon icon={faEdit} /> ToD Incentives
                                                            <Tooltip anchorSelect={`#incentives-${state._id}`} content={`ToD Incentives`} />
                                                        </button>
                                                        <button className="text-wrap" id={`discount-${state._id}`} onClick={() => handleEditIEXDiscount(state?._id)}>
                                                            <FontAwesomeIcon icon={faTag} /> Add IEX Discount
                                                            <Tooltip anchorSelect={`#discount-${state._id}`} content={`IEX Discount`} />
                                                        </button>
                                                    </label>
                                                </td>
                                            </tr>
                                        ))
                                    ) :isLoading ? 
                                    (
                                      <tr className="">
                                          <td colSpan={5} className="">
                                              <Loading/>
                                          </td>
                                      </tr>  
                                    ):  (
                                        <tr>
                                            <td colSpan="5" className="px-6 py-4 text-center">
                                                No records found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && 
                <AddSlotsPopup
                    title={`Edit State: ${selectedState?.name}`}
                    onClose={handleCloseModal}
                    selectedState={selectedState}
                    setStatusUpdate={setStatusUpdate}
                />
            }
            <ToastContainer position="top-center" autoClose={3000} />
        </div>
    );
}
