import React, { useEffect, useState } from "react";
import config from "../../../config/config";
import InputBox from "../../../components/inputBox/InputBox";
import { ToastContainer, toast } from "react-toastify";
import {
  createGlobalVariable,
  fetchGlobalVariableById,
  updateGlobalVariable,
} from "../../../services/apis/globalRecommendationVariable";
import { fetchBillingUnitsList } from "../../../services/apis/billingUnit";
import { Value } from "sass";

const EditGlobalVariable = ({
  onClose,
  editType,
  editId,
  getGlobalVariablesList,
  setEditFormOpen,
  setUpdateCount,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    uniqueName: "",
    unit: "",
    status: "active",
    value: "",
    // type: 'Regulatory Charge'
  });
  const [errors, setErrors] = useState({});
  const [billingUnitList, setBillingUnitList] = useState([]);
  const [hideValue, setHideValue] = useState(true);

  useEffect(() => {
    const getBillingUnitList = async () => {
      const payload = {
        status: "active",
      };
      const response = await fetchBillingUnitsList(payload);
      const data = response?.data?.data?.results;
      setBillingUnitList(data);
    };
    getBillingUnitList();
  }, []);

  useEffect(() => {
    const getGlobalVariableById = async () => {
      const response = await fetchGlobalVariableById(editId);
      const data = response?.data?.data;
      setFormData({
        name: data?.name || "",
        uniqueName: data?.uniqueName || "",
        unit: data?.unit || "",
        status: data?.status || "",
        value: data?.value,
        type:
          data?.type == "regulatoryCharge"
            ? "Regulatory Charge"
            : data?.type == "intermediateVariable"
            ? "Intermediate Variable"
            : data?.type == "formula"
            ? "Formula"
            : data?.type === "constant"
            ? "Constant"
            : "",
      });
      if(data.type === "constant"){
        setHideValue(false);
      }
      // console.log(data,'data')
    };
    getGlobalVariableById();
  }, []);

  const handleChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
      ...(fieldName === "name" && {
        uniqueName: value.trim().toLowerCase().replace(/\s+/g, "-"),
      }),
    }));

    if(fieldName === "type" && value === "Constant"){
      setHideValue(false);
    }else if(fieldName === "type" && value !== "Constant"){
        setHideValue(true);
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = {};
    if (!formData.name.trim()) {
      validationErrors.name = "Name is required";
    }
    if (!formData.uniqueName.trim()) {
      validationErrors.uniqueName = "Unique Name is required";
    }
    if (!formData.unit.trim()) {
      validationErrors.unit = "Unit is required";
    }
    if (formData.type == "") {
      validationErrors.type = "Type is required";
    }
    if (formData.type === "Constant" &&  formData.value == "") {
      validationErrors.value = "Value is required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const payload = {
      ...formData,
      status: formData.status ? formData.status : "active",
      type:
        formData.type == "Regulatory Charge"
          ? "regulatoryCharge"
          : formData.type == "Intermediate Variable"
          ? "intermediateVariable"
          : formData.type == "Formula"
          ? "formula"
          : formData.type == "Constant"
          ? "constant"
          : "",
    };

    // console.log(payload,'pld')

    try {
      const response = await updateGlobalVariable(editId, payload);

      if (response.data.data.length == 0) {
        toast.error(`${response?.data?.message}`, {
          position: "top-center",
        });
      } else {
        toast.success(`${response?.data?.message}`, {
          position: "top-center",
          autoClose: 3000,
        });
        setUpdateCount((prevCount) => prevCount + 1);
        setEditFormOpen(false);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to edit global variable. Please try again.", {
        position: "top-center",
      });
    }
  };

  const renderFormFields = () => {
    const fields = config.globalRecommendationVariableSettings.global.fields;

    return Object.keys(fields)?.map((fieldName, index) => {
      const field = fields[fieldName];
      const isDisabled = fieldName === "type";

      const { label, type, required, options } = field;

      switch (type) {
        case "text":
          if (label === "Value") {
            return (
              <div key={index} style={label==="Value" && hideValue ?{display:"none"}:{display:"block"}} className="mb-6">
                <div className="flex items-center">
                  <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                    {label}
                    {required && <span className="text-red-500">*</span>} :
                  </label>
                  <InputBox
                    width="w-3/4"
                    placeholder={`Enter ${label}`}
                    value={formData[fieldName] || ""}
                    onChange={(e) => handleChange(fieldName, e.target.value)}
                    className="ml-4"
                    
                  />
                </div>
                {errors[fieldName] && (
                  <p className="absolute text-red-500 text-sm ml-44">
                    {errors[fieldName]}
                  </p>
                )}
              </div>
            );
          } else {
            return (
              <div key={index} className="mb-6">
                <div className="flex items-center">
                  <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                    {label}
                    {required && <span className="text-red-500">*</span>} :
                  </label>
                  <InputBox
                    width="w-3/4"
                    placeholder={`Enter ${label}`}
                    value={formData[fieldName] || ""}
                    onChange={(e) => handleChange(fieldName, e.target.value)}
                    className="ml-4"
                    disabled
                  />
                </div>
                {errors[fieldName] && (
                  <p className="absolute text-red-500 text-sm ml-44">
                    {errors[fieldName]}
                  </p>
                )}
              </div>
            );
          }
          // break;
        case "select":
          if (label == "Unit") {
            return (
              <div key={index} className="mb-6">
                <div className="flex items-center">
                  <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                    {label}
                    {required && <span className="text-red-500">*</span>} :
                  </label>
                  <select
                    id={fieldName}
                    value={formData[fieldName] || ""}
                    onChange={(e) => handleChange(fieldName, e.target.value)}
                    className={`w-3/4 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                      errors[fieldName] ? "border-red-500" : ""
                    }`}
                  >
                    <option value="">Select {label}</option>
                    {billingUnitList?.map((billingUnit, index) => (
                      <option key={index} value={billingUnit?.symbol}>
                        {billingUnit?.symbol}
                      </option>
                    ))}
                  </select>
                </div>
                {errors[fieldName] && (
                  <p className="absolute text-red-500 text-sm ml-44">
                    {errors[fieldName]}
                  </p>
                )}
              </div>
            );
          } else if (label == "Type") {
            return (
              <div key={index} className="mb-6">
                <div className="flex items-center">
                  <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                    {label}
                    {required && <span className="text-red-500">*</span>} :
                  </label>
                  <select
                    // disabled={isDisabled}
                    id={fieldName}
                    value={formData[fieldName] || ""}
                    onChange={(e) => handleChange(fieldName, e.target.value)}
                    className={`w-3/4 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                      errors[fieldName] ? "border-red-500" : ""
                    } `}
                  >
                    <option value="">Select {label}</option>
                    {options?.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                {errors[fieldName] && (
                  <p className="absolute text-red-500 text-sm ml-44">
                    {errors[fieldName]}
                  </p>
                )}
              </div>
            );
          } else {
            return (
              <div key={index} className="mb-6">
                <div className="flex items-center">
                  <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                    {label}
                    {required && <span className="text-red-500">*</span>} :
                  </label>
                  <select
                    id={fieldName}
                    value={formData[fieldName] || ""}
                    onChange={(e) => handleChange(fieldName, e.target.value)}
                    className={`w-3/4 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                      errors[fieldName] ? "border-red-500" : ""
                    }`}
                  >
                    <option value="">Select {label}</option>
                    {options?.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                {errors[fieldName] && (
                  <p className="absolute text-red-500 text-sm ml-44">
                    {errors[fieldName]}
                  </p>
                )}
              </div>
            );
          }
        default:
          return null;
      }
    });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
      <div className="bg-white p-4 shadow-lg w-full max-w-3xl max-h-[95vh] overflow-y-auto">
        <h2 className="text-lg font-semibold">Edit the Global Variable</h2>
        <form>
          <div className="flex-grow overflow-auto">
            <div className="max-w-8xl">
              <div className="bg-white shadow-md overflow-hidden p-4">
                {renderFormFields()}
              </div>
            </div>
          </div>
        </form>
        <div className="sticky bottom-0 bg-white p-4 flex justify-center gap-8">
          <button
              type="button"
              onClick={onClose}
              className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
          >
              Cancel
          </button>
          <button
              type="submit"
              onClick={handleSubmit}
              className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4"
          >
              Save
          </button>
        </div>
        {/* <ToastContainer position="top-center" autoClose={3000} /> */}
      </div>
    </div>
  );
};

export default EditGlobalVariable;
