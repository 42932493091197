import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import config from '../../../config/config';
import { cleanUpData, fetchFactoryList, fetchUnitsList } from '../../../services/apis/listings';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from '../../../components/sidebar/Sidebar';
import PageTitleCard from '../../../components/pageTitleCard/PageTitleCard';
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown';
import RecordsPerPageDropdown from '../../../components/recordsPerPageDropdown/RecordsPerPageDropdown';
import UnitListTable from './UnitListTable';
import AddToDPurchase from './AddToDPurchase';
import EditToDPurchase from './EditToDPurchase';
import ViewToDPurchase from './ViewToDPurchase';
import RecommendationsTable from '../recommendationsTable.js/RecommendationsTable';
import MapPowerPlant from './MapPowerPlant';
import RTPopup from './RTPopup';

export default function UnitListing() {

    const location = useLocation();

    const navigate = useNavigate();

    const { recommendationsUnitListListHeaders } = config;

    const [factoryList, setFactorylist] = useState([]);
    const [unitsListData, setUnitsListData] = useState([]);
    const [state, setState] = useState('');
    const [id, setId] = useState('');
    const [unitName, setUnitName] = useState('');
    const [addFormOpen, setAddFormOpen] = useState(false);
    const [editFormOpen, setEditFormOpen] = useState(false);
    const [viewDataOpen, setViewDataOpen] = useState(false);
    const [mapPowerPlantOpen, setMapPowerPlantOpen] = useState(false);
    const [updateCount, setUpdateCount] = useState(1);
    const [factory, setFactory] = useState("");
    const [sortConfig, setSortConfig] = useState({ key: "_id", direction: 'asc' });
    const [count, setCount] = useState(1);
    const [limit, setLimit] = useState(() => {
        const storedLimit = parseInt(localStorage.getItem('recordsPerPage'), 10);
        return storedLimit || 50;
    });
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState({
        factoryId: ""
    })
    const [loader, setLoader] = useState(true)
    const [openRTPopup, setOpenRTPopup] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const factoryId = queryParams.get('factoryId');
        if (factoryId) {
            setFactory(factoryId);
            setFilters((filters) => ({ ...filters, factoryId: factoryId }));
        } else {
            setFactory("");
            setFilters({ factoryId: "" });
        }
    }, [location.search]);

    useEffect(() => {
        const fetchParentList = async () => {
            const response = await fetchFactoryList();
            const data = response?.data?.results;
            setFactorylist(data);
            // console.log(data,'data')
        }
        fetchParentList();
    },[])

    useEffect(() => {
        setLoader(true)
        const fetchData = async () => {
            const payload = {
                ...filters,
                page: currentPage,
                pageSize: limit,
                sortBy: sortConfig.key,
                sortOrder: sortConfig.direction,
            }
            const data = await fetchUnitsList(payload);
            const unitsList = data?.data?.results;
            const tableData = unitsList?.map(item => ({
                _id: item?._id,
                name: item?.name,
                stateCode: item?.stateCode,
                rtsArea: item?.RTSArea?.value && item?.RTSArea?.unit ? (item?.RTSArea?.value + " " + item?.RTSArea?.unit.split("-").join(" ")) :"-",
                areaParOnekW: item?.areaParOnekW?.value && item?.areaParOnekW?.unit ? (item?.areaParOnekW?.value + " " + item?.areaParOnekW?.unit.split("-").join(" ")) : "-",
                rtsOperatingCharges: item?.RTSOperatingCharges?.value && item?.RTSOperatingCharges?.unit ? (item?.RTSOperatingCharges?.value + " " + item.RTSOperatingCharges?.unit?.split("-").join(" ")) : "-"
            }));
            setUnitsListData(tableData);
            setTotalCount(data?.data?.total)
            // console.log(factoryList,'factoryList')
            setLoader(false)
        }
        fetchData();
    },[count, currentPage, sortConfig, filters, limit, updateCount]);

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        setCurrentPage(1);
    };

    const handleParentChange = (selectedFactory) => {
        setFactory(selectedFactory);
        if (selectedFactory) {
            setFilters({ ...filters, factoryId: selectedFactory });
        }
    };

    const handleAdd = (id, stateCode) => {
        setId(id);
        setState(stateCode)
        setAddFormOpen(true);
    }  

    const handleOpenRT= (id)=>{
        setOpenRTPopup(true)
        setId(id)
    }  
    
    const handleMapPowerPlantOpen = (id, name) => {
        setId(id);
        setUnitName(name);
        setMapPowerPlantOpen(true);
    }  
    
    const handleEdit = (id, stateCode) => {
        setId(id);
        setState(stateCode)
        setEditFormOpen(true);
    }

    const handleView = (id, stateCode) => { 
        setId(id);
        setState(stateCode)
        setViewDataOpen(true);
    }

    const handleRecordsPerPageChange = (newLimit) => {
        setLimit(newLimit);
        setCurrentPage(1); 
    };

    const handleCloseForm = () => {
        setAddFormOpen(false);
    };

    const handleEditCloseForm = () => {
        setEditFormOpen(false);
    };

    const handleViewCloseForm = () => {
        setViewDataOpen(false);
    };

    const handleMapPowerPlantCloseForm = () => {
        setMapPowerPlantOpen(false);
    };


    return (
        <div className="flex">
            <Sidebar />
            <div className="flex-grow p-4">
                <div className="max-w-8xl">
                    <PageTitleCard title="Units List" />
                    <div className=''>
                        <div className=" overflow-hidden rounded-xl ">
                            <div className=" overflow-hidden mt-2 ">
                                <div className="p-3 flex flex-row gap-4">
                                    <SelectDropdown
                                        label="Factory"
                                        options={factoryList?.map((factory) => {
                                            return { label: factory?.name, value: factory?._id };
                                        })}
                                        selectedValue={factory}
                                        onChange={(e) => handleParentChange(e.target.value)}
                                    />
                                    <RecordsPerPageDropdown onRecordsPerPageChange={handleRecordsPerPageChange} />
                                </div>
                            </div>
                            <div className='bg-white p-2 rounded-xl'>
                                <RecommendationsTable loader={loader} headings={recommendationsUnitListListHeaders} data={unitsListData} handleView={handleView} handleAdd={handleAdd} handleMapPowerPlantOpen={(id, name) => handleMapPowerPlantOpen(id, name)} handleEdit={handleEdit} handleSort={handleSort} sortConfig={sortConfig} title="ToD Purchase"
                                    handleOpenRT={handleOpenRT}    
                                />
                                {/* <UnitListTable headings={recommendationsUnitListListHeaders} data={unitsListData} handleView={handleView} handleAdd={handleAdd} handleEdit={handleEdit} handleSort={handleSort} sortConfig={sortConfig} title="ToD Purchase Details"/> */}
                            </div>
                        </div>
                        {totalCount > limit && (
                            <div className="pagination-controls bg-white rounded-b-xl border-[#abaaaa] mb-2 pb-2 flex flex-row items-center justify-end pt-8 -mt-2">
                                <button className={`bg-[#e1e0e0] ${currentPage === 1 ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl`} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                <span className="mx-4">{Math.min(currentPage * limit, totalCount) ? Math.min(currentPage * limit, totalCount) : '0'} off {totalCount ? totalCount : '0'}</span>
                                <button className={`bg-[#e1e0e0] ${currentPage * limit >= totalCount ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl mr-2`} disabled={currentPage * limit >= totalCount} onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
                            </div>
                        )}
                    </div>
                </div>
                {addFormOpen && <AddToDPurchase id={id} stateCode={state} onClose={handleCloseForm} setAddFormOpen={setAddFormOpen} setUpdateCount={setUpdateCount}/>}
                {editFormOpen && <EditToDPurchase id={id} stateCode={state} onClose={handleEditCloseForm} setEditFormOpen={setEditFormOpen} setUpdateCount={setUpdateCount}/>}
                {viewDataOpen && <ViewToDPurchase id={id} stateCode={state} onClose={handleViewCloseForm} setViewDataOpen={setViewDataOpen}/>}
                {mapPowerPlantOpen && <MapPowerPlant id={id} unitName={unitName} stateCode={state} onClose={handleMapPowerPlantCloseForm} setMapPowerPlantOpen={setMapPowerPlantOpen}/>}
                {openRTPopup && <RTPopup id={id} onClose={() => setOpenRTPopup(false)} setUpdateCount={()=>setUpdateCount(prev=>prev+1)} />}
                <ToastContainer position="top-center" autoClose={3000} />
            </div>
        </div>
    )
}
