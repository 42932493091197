import React, { useState, useMemo, useEffect } from "react";
import config from "../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faPlus,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import ActionButtons from "../actionButtons/ActionButtons";
import AddRoleButton from "../addRoleButton/AddRoleButton";
import ViewActionButton from "../actionButtons/ViewActionButton";
import EditActionButton from "../actionButtons/EditActionButton";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { faDeleteLeft } from "@fortawesome/free-solid-svg-icons/faDeleteLeft";
import DatePicker from "react-datepicker";
import { deleteExistingPowerPlantDataOfParticularDate } from "../../services/apis/powerPlantData";

export default function IexDiscountDataTable({
  data,
  id,
  handleDelete,
  isChecked,
  handleCheckboxChange,
  isTablePopupOpen,
  toggleTablePopup,
  handleUpdate
}) {

  function formatDate(date) {
    let d = new Date(date);
    let day = ("0" + d.getDate()).slice(-2);
    let month = ("0" + (d.getMonth() + 1)).slice(-2); // Months are zero-indexed
    let year = d.getFullYear();

    return `${year}-${month}`;
  }

  const [selectedID, setSelectedID] = useState("");

  return (
    <div className="bg-white overflow-hidden rounded-xl">
      <div className="">
        <div className="">
          <table className="min-w-full text-black px-4 py-2">
            <thead>
              <tr>
                <th className="px-6 py-4 bg-gray-100 rounded-bl-xl">Sr No</th>
                <th className="px-6 py-4 bg-gray-100">State Code</th>
                <th className="px-6 py-4 bg-gray-100">Date</th>
                <th className="px-6 py-4 bg-gray-100">Slot Wise IEX Discount</th>
                <th className="px-6 py-4 bg-gray-100 rounded-br-xl">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white text-center divide-y divide-gray-200">
              {data?.length > 0 ? (
                data?.map((row, index) => {

                  const slots = row.data ? Object.entries(row.data) : [];
                  return (
                    <tr key={row._id}>
                      <td className="px-4 py-3">{index + 1}</td>
                      <td className="px-4 py-3">{row.stateCode}</td>
                      <td className="px-4 py-3">{formatDate(row.month)}</td>
                      <td className="py-3">
                        {slots.length > 0 ? (
                          slots.map(([slot, value]) => {
                            const formattedSlot = slot.replace('slot-', 'TOD ');
                            return (
                              <div key={slot} className="flex flex-row justify-center items-center">
                                <div className="px-1 w-[16%] font-semibold">
                                  {formattedSlot} :
                                </div>
                                <div className="px-1  w-[10%]">
                                  {value}
                                </div>
                              </div>
                            )
                          })
                        ) : (
                          <div>No slots available</div>
                        )}
                      </td>


                      <td className="px-4 py-3 flex gap-2 items-center justify-center">
                        <div onClick={() => handleUpdate(row._id)}>
                          <FontAwesomeIcon
                            id="my-tooltip"
                            className="cursor-pointer"
                            icon={faEdit}
                          />
                        </div>

                        <Tooltip
                          anchorSelect="#my-tooltip"
                          content="Edit Power Plant Data"
                        />

                        <FontAwesomeIcon
                          id="my-tooltip1"
                          className="cursor-pointer"
                          icon={faTrash}
                          onClick={() => {
                            toggleTablePopup();
                            setSelectedID(row._id);
                          }}
                        />

                        <Tooltip
                          anchorSelect="#my-tooltip1"
                          content="Delete Power Plant Data"
                        />

                        {isTablePopupOpen && (
                          <div
                            id="deleteModal"
                            tabIndex="-1"
                            aria-hidden={!isTablePopupOpen}
                            className={`fixed top-0  right-0 left-0    z-50 justify-center bg-black bg-opacity-10 opacity-100 items-center w-full h-modal md:h-full flex overflow-y-auto overflow-x-hidden`}
                          >
                            <div className="relative p-4 w-full  text-black  max-w-md h-full md:h-auto">
                              {/* Modal content */}
                              <div className="relative p-4 text-center  bg-white rounded-lg shadow  sm:p-5">
                                <button
                                  type="button"
                                  className=" absolute top-2.5 right-2.5 bg-white  hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                  onClick={toggleTablePopup}
                                >
                                  <svg
                                    aria-hidden="true"
                                    className="w-5 h-5"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                      clipRule="evenodd"
                                    ></path>
                                  </svg>
                                  <span className="sr-only">Close modal</span>
                                </button>
                                <svg
                                  className="text-gray-400 dark:text-black w-11 h-11 mb-3.5 mx-auto"
                                  aria-hidden="true"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                                <p className="mb-4 ">
                                  Are you sure you want to delete IEX discount
                                </p>
                                <div className="flex justify-center mt-4 bg-white items-center space-x-4">
                                  <button
                                    onClick={toggleTablePopup}
                                    type="button"
                                    className="py-2 px-3 text-sm font-medium  bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700  dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                                  >
                                    No, cancel
                                  </button>
                                  <button
                                    className="py-2 px-5 text-sm font-medium text-center text-white bg-red-500 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none "
                                    onClick={() =>
                                      handleDelete(row._id)
                                    }
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </td>

                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black text-center">
                    No Records Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
