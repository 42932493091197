import React from 'react';
import InputBox from '../inputBox/InputBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import SearchInputBox from '../inputBox/SearchInputBox';

export default function SearchById({ filterById, handleFilterById }) {
    return (
        <div className="relative">

            <SearchInputBox
                width="w-60"
                placeholder="Search by Id..."
                value={filterById}
                onChange={handleFilterById}
                className="pl-5"
            />
            <FontAwesomeIcon icon={faSearch} className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        </div>
    )
}
