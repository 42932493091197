import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../common.css';

export default function AddNews() {
  
  const [formData, setFormData] = useState({
    newsName: "",
    // slug: "",
    newsUrl: "",
    status: "status",
    image: null,
    shortDescription: "",
    sourceName: "",
    sourceWebsite: "",
    // content: "",
    metaTitle: "",
    metaDataDescription: "",
    metaKeyword: ""
  });

  const [errors, setErrors] = useState({});
  const [formReset, setFormReset] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let slugValue = name === 'newsName' ? 
                    value
                    .toLowerCase() 
                    .replace(/\s+/g, '-') 
                    .replace(/[^a-zA-Z0-9-]/g, '') : formData.slug; 
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      slug: slugValue
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ""
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      image: file
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      image: ""
    }));
  };

  // const handleContentChange = (value) => {
  //   setFormData({ ...formData, content: value });
  //   setErrors({}); 
  // };

  // const modules = {
  //   toolbar: {
  //     container: [
  //       [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
  //       [{size: []}],
  //       ['bold', 'italic', 'underline', 'strike', 'blockquote'],
  //       [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
  //       ['link', 'image', 'video'],
  //       ['clean'],
  //       [{ 'color': [] }, { 'background': [] }],
  //       [{ 'align': [] }],
  //     ],
  //   }
  // };

  // const formats = [
  //   'header', 'font', 'size',
  //   'bold', 'italic', 'underline', 'strike', 'blockquote',
  //   'list', 'bullet', 'indent',
  //   'link', 'image', 'video',
  //   'color', 'background',
  //   'align'
  // ];

  const handleCancel = () => {
    setFormData({
      newsName: "",
      // slug: "",
      newsUrl: "",
      status: "status",
      image: null,
      shortDescription: "",
      sourceName: "",
      sourceWebsite: "",
      content: "",
      metaTitle: "",
      metaDataDescription: "",
      metaKeyword: ""
    });
    setErrors({});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let formErrors = {};

    if (!formData.newsName.trim()) {
      formErrors.newsName = "News name is required";
    }

    if (!formData.newsUrl.trim()) {
      formErrors.newsUrl = "News URL is required";
    }

    if (formData.status == "status") {
      formErrors.status = "Status is required";
    }
    
    if (!formData.sourceName.trim()) {
      formErrors.sourceName = "Source Name is required";
    }
    if (!formData.sourceWebsite.trim()) {
      formErrors.sourceWebsite = "Source Website is required";
    }

    if (!formData.shortDescription.trim()) {
      formErrors.shortDescription = "Short Description is required";
    }

    if (!formData.image) {
      formErrors.image = "Image is required";
    }

    // if (!formData.content) {
    //   formErrors.content = "Content is required";
    // }

    if (!formData.metaDataDescription) {
      formErrors.metaDataDescription = "Meta Data Description is required";
    }

    if (!formData.metaKeyword) {
      formErrors.metaKeyword = "Meta Keyword is required";
    }

    if (!formData.metaTitle) {
      formErrors.metaTitle = "Meta Title is required";
    }

    if (Object.keys(formErrors).length === 0) {
      console.log(formData);
      toast.success('News Added Successfully!', {
        position: "top-center"
      });
      setFormReset(true);
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-grow p-4">
        <div className="max-w-8xl">
            {/* Add News Title Card */}
            <div className="bg-white shadow-md overflow-hidden">
              <div className="p-4 bg-white text-gray">
                  <h2 className="text-lg font-semibold">Add News</h2>
              </div>
            </div>
            <div >
              <form onSubmit={handleSubmit}>
                <div className="max-w-8xl">
                  <div className="mt-8 shadow-md p-4">
                  <div className="mb-6 max-w-5xl relative">
                    <label htmlFor="newsName" className="mt-4 mb-2 block text-gray-700">Name :</label>
                    <input
                        type="text"
                        placeholder="Enter News Name"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400"
                        value={formData.newsName}
                        onChange={handleChange}
                        name="newsName"
                    />
                    {errors.newsName && (
                        <p className="text-red-500 text-sm absolute">{errors.newsName}</p>
                    )}
                  </div>
                  <div className="mb-6 max-w-5xl relative">
                      <label htmlFor="shortDescription" className="mt-4 mb-2 block text-gray-700">Short Description :</label>
                      <textarea
                          id="shortDescription"
                          name="shortDescription"
                          value={formData.shortDescription}
                          onChange={handleChange}
                          placeholder="Enter News Short Description"
                          className={`w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                              errors.shortDescription ? "border-red-500" : ""
                          }`}
                          rows="4"
                      />
                      {errors.shortDescription && (
                          <p className="text-red-500 text-sm absolute">{errors.shortDescription}</p>
                      )}
                  </div>
                  <div className="mb-6 max-w-5xl relative">
                    <label htmlFor="sourceName" className="mt-4 mb-2 block text-gray-700">Source Name :</label>
                    <input
                        type="text"
                        placeholder="Enter Source Name"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400"
                        value={formData.sourceName}
                        onChange={handleChange}
                        name="sourceName"
                    />
                    {errors.sourceName && (
                        <p className="text-red-500 text-sm absolute">{errors.sourceName}</p>
                    )}
                  </div>
                  <div className="mb-6 max-w-5xl relative">
                    <label htmlFor="sourceWebsite" className="mt-4 mb-2 block text-gray-700">Source Website :</label>
                    <input
                        type="text"
                        placeholder="Enter Source Website"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400"
                        value={formData.sourceWebsite}
                        onChange={handleChange}
                        name="sourceWebsite"
                    />
                    {errors.sourceWebsite && (
                        <p className="text-red-500 text-sm absolute">{errors.sourceWebsite}</p>
                    )}
                  </div>
                  <div className="mb-6 max-w-5xl relative">
                    <label htmlFor="newsUrl" className="mt-4 mb-2 block text-gray-700">News URL :</label>
                    <input
                        type="text"
                        placeholder="Enter Source Name"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400"
                        value={formData.newsUrl}
                        onChange={handleChange}
                        name="newsUrl"
                    />
                    {errors.newsUrl && (
                        <p className="text-red-500 text-sm absolute">{errors.newsUrl}</p>
                    )}
                  </div>
                  {/* <div className="mb-6 max-w-5xl relative">
                      <label htmlFor="slug" className="mt-4 mb-2 block text-gray-700">Slug :</label>
                      <input
                          type="text"
                          id="slug"
                          name="slug"
                          value={formData.slug}
                          onChange={handleChange}
                          placeholder="Enter Slug URL"
                          className={`w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                              errors.slug ? "border-red-500" : ""
                          }`}
                      />
                      {errors.slug && (
                          <p className="text-red-500 text-sm absolute">{errors.slug}</p>
                      )}
                  </div> */}
                  <div className="mb-6 max-w-xl relative">
                      <label htmlFor="image" className="mt-4 mb-2 block text-gray-700">Upload Image :</label>
                      <input
                          type="file"
                          id="image"
                          name="image"
                          accept="image/*"
                          onChange={handleImageChange}
                          className={`w-full px-4 py-2 border border-gray-300 rounded-md cursor-pointer focus:outline-none focus:border-blue-400 ${
                              errors.image ? "border-red-500" : ""
                          }`}
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          <FontAwesomeIcon icon={faUpload} className="text-gray-500" style={{ marginTop: '25px', marginRight: '8px' }}/>
                      </div>
                      {errors.image && (
                          <p className="text-red-500 text-sm absolute">{errors.image}</p>
                      )}
                  </div>
                  <div className="mb-6 max-w-xl relative">
                      <label htmlFor="status" className="mt-4 mb-2 block text-gray-700">Status :</label>
                      <select
                          id="status"
                          name="status"
                          value={formData.status}
                          onChange={handleChange}
                          className={`w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                              errors.status ? "border-red-500" : ""
                          }`}
                      >
                          <option value="status" defaultValue disabled className='text-gray'>Status</option>
                          <option value="draft">Draft</option>
                          <option value="publish">Publish</option>
                          <option value="deactivate">Deactivate</option>
                      </select>
                      {errors.status && (
                          <p className="text-red-500 text-sm absolute">{errors.status}</p>
                      )}
                  </div>
                  {/* <div className="mb-16 max-w-5xl relative">
                      <label htmlFor="content" className="block text-gray-700">Content :</label>
                      <ReactQuill
                        id="content"
                        value={formData.content}
                        modules={modules}
                        formats={formats}
                        onChange={handleContentChange}
                        placeholder="Enter News Content"
                        className={`form-textarea mt-1 block w-full ${
                          errors.content ? "border-red-500" : ""
                        }`}
                        style={{ height: "150px" }}
                      />
                      {errors.content && (
                        <p className="text-red-500 text-sm mt-11 absolute">{errors.content}</p>
                      )}
                  </div> */}
                  <div className="mb-4 max-w-5xl relative">
                    <label htmlFor="metaTitle" className="mt-4 mb-2 block text-gray-700">Meta Title :</label>
                    <input
                        type="text"
                        placeholder="Enter Meta Tags"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400"
                        value={formData.metaTitle}
                        onChange={handleChange}
                        name="metaTitle"
                    />
                    {errors.metaTitle && (
                      <p className="text-red-500 text-sm absolute">{errors.metaTitle}</p>
                    )}
                  </div>
                  <div className="mb-6 max-w-5xl relative">
                    <label htmlFor="metaDataDescription" className="mt-6 mb-2 block text-gray-700">Meta Data Description :</label>
                    <input
                        type="text"
                        placeholder="Enter Meta Data Description"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400"
                        value={formData.metaDataDescription}
                        onChange={handleChange}
                        name="metaDataDescription"
                    />
                    {errors.metaDataDescription && (
                      <p className="text-red-500 text-sm absolute">{errors.metaDataDescription}</p>
                    )}
                  </div>
                  <div className="mb-4 max-w-5xl relative">
                    <label htmlFor="metaKeyword" className="mt-4 mb-2 block text-gray-700">Meta Keyword :</label>
                    <input
                        type="text"
                        placeholder="Enter Meta Tags"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400"
                        value={formData.metaKeyword}
                        onChange={handleChange}
                        name="metaKeyword"
                    />
                    {errors.metaKeyword && (
                      <p className="text-red-500 text-sm absolute">{errors.metaKeyword}</p>
                    )}
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={handleCancel}
                      className="bg-gray-500 mt-10 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-4"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="bg-blue-500 mt-10 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Save
                    </button>
                  </div>
                  </div>
                </div>
                <ToastContainer position="top-center" autoClose={3000} />
              </form>
            </div>
        </div>
      </div>
    </div>
  )
}
