import axios from 'axios';

const user = JSON.parse(localStorage.getItem('user'));
const token = user?.result?.token

const API_URL = process.env.REACT_APP_API_URL; 

export const createPowerPlant = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/powerPlant`, payload);
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const fetchPowerPlantList = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/powerPlant/list`, payload);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const fetchPowerPlantById = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/powerPlant/${id}`);
        // console.log(response,'by-id');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const updatePowerPlantById = async (id, payload) => {
    try {
        const response = await axios.put(`${API_URL}/powerPlant/${id}`, payload);
        // console.log(response,'update-by-id');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const deletePowerPlantById = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/powerPlant/${id}`);
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}