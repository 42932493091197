import React from "react";
import PropTypes from "prop-types";
import Header from "../../components/header/Header";

const MainLayout = ({ children }) => (
  <div>
    <header>
      <Header />
    </header>
    <main>{children}</main>
    <footer></footer>
  </div>
);

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
