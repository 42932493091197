import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { useAuth } from "../../hooks/useAuth";
// import LoaderComponent from '../../components/loaderCompent/LoaderComponent';
import { fetchDiscomList } from "../../services/apis/listings";
import { editDiscomId } from "../../services/apis/content";
import PageTitleCard from "../../components/pageTitleCard/PageTitleCard";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../components/loader/Loader";

export default function DiscomList() {
  const { setIsSidebarOpen, isSidebarOpen } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [discomListData, setDiscomListData] = useState([]);
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [sortColumn, setSortColumn] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc"); // 'asc' or 'desc'
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const getDiscomList = async () => {
      setIsLoading(true);
      const response = await fetchDiscomList();
      setStatusUpdate(false);
      const discomList = response?.data?.results;

      // Sort the discomList
      if (discomList) {
        const sortedDiscomList = sortData(discomList, sortColumn, sortOrder);
        setDiscomListData(sortedDiscomList);
      }
      setIsLoading(false);
    };
    getDiscomList();
  }, [statusUpdate, sortColumn, sortOrder]);

  const sortData = (data, column, order) => {
    if (!column) return data;
    const sortedData = [...data].sort((a, b) => {
      let aValue = getNestedValue(a, column);
      let bValue = getNestedValue(b, column);

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });
    return sortedData;
  };

  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  const handleSort = (column) => {
    const order = sortColumn === column && sortOrder === "asc" ? "desc" : "asc";
    setSortColumn(column);
    setSortOrder(order);
  };

  const handleToggle = async (discomId, discomName, discomCode, status) => {
    const newStatus = status === "active" ? "inactive" : "active";
    const payload = {
      status: newStatus,
      name: discomName,
      discomId: discomId,
    };
    const response = await editDiscomId(payload);
    setStatusUpdate(true);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredDiscomList = discomListData.filter(
    (discom) =>
      discom.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      discom.stateId.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      discom.stateId.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
      discom.code.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddNewDiscom = () => {
    navigate("/discom-list/create");
  };
  const handleEditDiscom = (id) => {
    navigate(`/discom-list/edit/${id}`);
  };

  return (
    <div className="flex w-[100%]">
      <Sidebar />
      <div className={`flex-grow p-4`}>
        <PageTitleCard
          title="Discom List"
          onAddButtonClick={handleAddNewDiscom}
          addLinkText="Add New Discom"
        />
        {/*  */}
        <div className="mt-2 overflow-hidden rounded-xl">
          <div className="mb-2 p-2">
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Search..."
              className="w-[20%] px-4 py-2 border border-gray-200 rounded-lg"
            />
          </div>
          <div className="bg-white p-2 rounded-xl">
            <div className="bg-white relative overflow-x-auto h-[700px] overflow-y-auto sm:rounded-lg rounded-lg discom-list">
              <table className="w-full text-sm text-left rtl:text-right text-black dark:text-gray-400">
                <thead className="text-xs uppercase text-black bg-gray-100 px-4">
                  <tr>
                    <th
                      className="max-w-64 py-2 px-4 box-border cursor-pointer"
                      scope="col"
                      onClick={() => handleSort("name")}
                      style={{
                        borderBottomLeftRadius: "0.5rem",
                        borderTopLeftRadius: "0.5rem",
                      }}
                    >
                      Discom{" "}
                      {sortColumn === "name"
                        ? sortOrder === "asc"
                          ? "▲"
                          : "▼"
                        : ""}
                    </th>
                    <th
                      className="max-w-32 py-2 cursor-pointer"
                      scope="col"
                      onClick={() => handleSort("stateId.name")}
                    >
                      State Name{" "}
                      {sortColumn === "stateId.name"
                        ? sortOrder === "asc"
                          ? "▲"
                          : "▼"
                        : ""}
                    </th>
                    <th
                      className="max-w-32 py-2 cursor-pointer"
                      scope="col"
                      onClick={() => handleSort("code")}
                    >
                      Discom Code{" "}
                      {sortColumn === "code"
                        ? sortOrder === "asc"
                          ? "▲"
                          : "▼"
                        : ""}
                    </th>
                    <th
                      className="pr-4 py-2 cursor-pointer"
                      scope="col"
                      onClick={() => handleSort("stateId.code")}
                    >
                      State Code{" "}
                      {sortColumn === "stateId.code"
                        ? sortOrder === "asc"
                          ? "▲"
                          : "▼"
                        : ""}
                    </th>
                    <th className=" py-2 cursor-pointer" scope="col">
                      Status
                    </th>
                    <th
                      className="pr-4 py-2 cursor-pointer"
                      scope="col"
                      style={{
                        borderBottomRightRadius: "0.5rem",
                        borderTopRightRadius: "0.5rem",
                      }}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                {filteredDiscomList.length > 0 && !isLoading ? <tbody>
                  {filteredDiscomList?.map((discom) => (
                    <tr className="border-b text-black" key={discom?._id}>
                      <td
                        scope="row"
                        className="max-w-64 overflow-hidden whitespace-nowrap truncate"
                      >
                        {discom?.name}
                      </td>
                      <td
                        scope="row"
                        className="max-w-32 overflow-hidden whitespace-nowrap truncate"
                      >
                        {discom?.stateId?.name}
                      </td>
                      <td
                        scope="row"
                        className="max-w-32 overflow-hidden whitespace-nowrap truncate"
                      >
                        {discom?.code}
                      </td>
                      <td scope="row">{discom?.stateId?.code}</td>
                      <td>
                        <label className="inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            className="sr-only peer"
                            onChange={() =>
                              handleToggle(
                                discom?._id,
                                discom?.name,
                                discom?.code,
                                discom?.status
                              )
                            }
                            checked={discom?.status === "active"}
                          />
                          <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                          <span className="hidden xl:block ms-3 w-[3.5rem] text-sm text-black dark:text-gray-300 truncate">
                            {discom?.status}
                          </span>
                        </label>
                      </td>
                      <td
                        scope="row"
                        className="px-4 py-2 whitespace-nowrap truncate"
                      >
                        <button
                          className="mx-1 text-blue-500"
                          onClick={() => handleEditDiscom(discom._id)}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} size="lg" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>:isLoading?
                <tr>
                  <td colSpan={6} className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 text-center">
                      <Loading/>
                  </td>
                </tr>
                :
                <tr>
                    <td colSpan={6} className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black text-center">
                        No Records Available
                    </td>
                </tr>}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
