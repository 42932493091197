import React, { useState, useMemo, useEffect } from "react";
import config from "../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { faDeleteLeft } from "@fortawesome/free-solid-svg-icons/faDeleteLeft";
import DatePicker from "react-datepicker";
import { deleteExistingPowerPlantDataOfParticularDate } from "../../services/apis/powerPlantData";
import ViewFormulaPopup from "../popup/ViewFormulaPopup";
import Loading from "../loader/Loader";

export default function FormulaTable({
  loader= false,
  data,
  isTablePopupOpen,
  toggleTablePopup,
  deleteFormula
}) {
  useEffect(() => {
    console.log({ data });
  }, [data]);

  function formatDate(date) {
    let d = new Date(date);
    let day = ("0" + d.getDate()).slice(-2);
    let month = ("0" + (d.getMonth() + 1)).slice(-2);
    let year = d.getFullYear();

    return `${year}-${month}-${day}`;
  }

  const [selectedID, setSelectedID] = useState("");
  const [popUpData, setPopUpData] = useState("");

  return (
    <div className="bg-white rounded-xl overflow-hidden mt-4">
      <div className="p-2">
        <div className="mt-2">
            <table className="min-w-full text-black px-4 py-2">
              <thead className="">
                <tr className="">
                  <th className="px-6 py-4 bg-gray-100 rounded-tl-xl rounded-bl-xl">Sr No</th>
                  <th className="px-6 py-4 bg-gray-100">Formula Name</th>
                  <th className="px-6 py-4 bg-gray-100">Unique Name</th>
                  <th className="px-6 py-4 bg-gray-100">Type</th>

                  <th className="px-6 py-4 bg-gray-100 rounded-tr-xl rounded-br-xl">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white text-center divide-y divide-gray-200">
                {data?.results?.length > 0 && !loader ? (
                  data?.results?.map((row, index) => (
                    <tr key={row._id} className="py-1">
                      <td className="px-6 py-3 ">{index + 1}</td>
                      <td className="px-6 py-3 ">{row?.globalRecVariableId?.name}</td>
                      <td className="px-6 py-3 ">{row?.globalRecVariableId?.uniqueName}</td>
                      <td className="px-6 py-3 ">{row?.type.charAt(0).toUpperCase() + row.type.slice(1)}</td>

                      {/* <td>{row.content[0].formula}</td> */}

                      <td className="px-6 py-3 flex gap-4 items-center cursor-pointer justify-center mt-2">
                        <FontAwesomeIcon
                          id="view"
                          className="cursor-pointer"
                          onClick={()=>{toggleTablePopup();setPopUpData(row)}}
                          icon={faEye}
                        />
                        <Tooltip anchorSelect="#view" content="View Formula" />
                        <Link to={`/recommendations/formula/edit/${row._id}`}>
                          <FontAwesomeIcon id="edit" icon={faEdit} />
                          <Tooltip anchorSelect="#edit" content="Edit Formula" />
                        </Link>
                        <FontAwesomeIcon id='delete' onClick={()=>deleteFormula(row?._id, row?.globalRecVariableId?.name)} icon={faTrash} />
                        <Tooltip anchorSelect="#delete" content="Delete Formula" />
                      </td>
                    </tr>
                  ))
                ):loader ? 
                (
                  <tr>
                    <td colSpan={5} className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 text-center">
                        <Loading/>
                    </td>
                </tr> 
                ):     
                (
                  <tr className="w-full">
                    <td colSpan={5} className="px-6  w-full text-center py-4 whitespace-no-wrap text-sm leading-5 text-black ">
                      No Records Available 
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
        </div>
        <ViewFormulaPopup toggleTablePopup={toggleTablePopup} isTablePopupOpen={isTablePopupOpen} data={popUpData}  />
      </div>
    </div>
  );
}
