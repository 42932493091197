import React, { useEffect, useState } from 'react';
import { fetchUnitById, getStateByStateCode, updateUnitById } from '../../../services/apis/listings';
import { toast } from 'react-toastify';

export default function AddToDPurchase({ id, stateCode, onClose, setAddFormOpen, setUpdateCount }) {
    const [stateData, setStateData] = useState(null);
    const [todSlots, setTodSlots] = useState({ powerExchange: [], thirdParty: [] });
    const [noOfTodSlots, setNoOfTodSlots] = useState(0);
    const [errors, setErrors] = useState({ powerExchange: [], thirdParty: [] });

    useEffect(() => {
        const getStateDataByStateCode = async () => {
            const response = await getStateByStateCode(stateCode);
            const data = response?.data;
            setStateData(data);
            setNoOfTodSlots(data?.noOfTodSlots);
            setTodSlots({
                powerExchange: Array(data?.noOfTodSlots || 0).fill(''),
                thirdParty: Array(data?.noOfTodSlots || 0).fill('')
            });
        };
        getStateDataByStateCode();
    }, [stateCode]);

    useEffect(() => {
        const getUnitById = async () => {
            const response = await fetchUnitById(id);
            const data = response?.data?.result;
            if (data && data.toDPurchase) {
                const { toDPurchase } = data;
                const powerExchange = Object.keys(toDPurchase.powerExchange).map((key) => toDPurchase.powerExchange[key]);
                const thirdParty = Object.keys(toDPurchase.thirdParty).map((key) => toDPurchase.thirdParty[key]);
                setTodSlots({ powerExchange, thirdParty });
            } else {
                setTodSlots({
                    powerExchange: Array(stateData?.noOfTodSlots || 0).fill(''),
                    thirdParty: Array(stateData?.noOfTodSlots || 0).fill('')
                });
            }
        };
        if (stateData) {
            getUnitById();
        }
    }, [id, stateData]);

    const handleInputChange = (index, value, type) => {
        const newTodSlots = { ...todSlots };
        newTodSlots[type][index] = value;
        setTodSlots(newTodSlots);
        setErrors((prev) => ({
            ...prev,
            [type]: { ...prev[type], [index]: '' }
        }));
    };

    const validateFields = () => {
        const newErrors = { powerExchange: [], thirdParty: [] };
        Object.keys(todSlots).forEach((type) => {
            todSlots[type].forEach((slot, index) => {
                if (slot === '' || isNaN(slot) || Number(slot) < 0) {
                    newErrors[type][index] = `Tod ${index+1} ${type} is required `;
                }
            });
        });
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateFields();
        if (Object.keys(validationErrors.powerExchange).length > 0 || Object.keys(validationErrors.thirdParty).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            const toDPurchase = {
                powerExchange: todSlots.powerExchange.reduce((acc, slot, index) => {
                    acc[`ToD${index + 1}`] = Number(slot);
                    return acc;
                }, {}),
                thirdParty: todSlots.thirdParty.reduce((acc, slot, index) => {
                    acc[`ToD${index + 1}`] = Number(slot);
                    return acc;
                }, {})
            };

            const payload = { toDPurchase };

            const response = await updateUnitById(id, payload);

            setAddFormOpen(false);
            setUpdateCount((prev) => prev + 1);
            toast.success('ToD Purchase details updated successfully');
        } catch (error) {
            toast.error('Failed to add ToD Purchase details');
        }
    };


    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
            {noOfTodSlots > 0 ? (
                <div className="bg-white p-4 shadow-lg w-full max-w-3xl max-h-[95vh] overflow-y-auto rounded-lg">
                    <h2 className="text-lg font-semibold">Add ToD Purchase Details</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="flex-grow overflow-auto">
                            <div className="max-w-8xl">
                                <div className="bg-white shadow-md overflow-hidden p-4">
                                    {stateData && (
                                        <div>
                                            <div className="flex mb-4">
                                                <div className="w-32 mt-2 ml-8"></div>
                                                <div className="block w-full px-4 py-2 text-gray-700 text-center">Power Exchange</div>
                                                <div className="block w-full px-4 py-2 text-gray-700 text-center">Third Party</div>
                                            </div>
                                            {Array.from({ length: noOfTodSlots }).map((_, index) => (
                                                <React.Fragment key={index}>
                                                    <div className="mb-4 flex">
                                                        <label className="block text-sm text-gray-700 w-32 mt-2 ml-8">
                                                            ToD {index + 1}:
                                                        </label>
                                                        <div className="w-full flex">
                                                            <input
                                                                type="number"
                                                                placeholder='Enter Power Exchange Values'
                                                                value={todSlots.powerExchange[index]}
                                                                onChange={(e) => handleInputChange(index, e.target.value, 'powerExchange')}
                                                                className="mt-1 block w-full px-4 py-2 border border-blue-300 rounded-lg "
                                                            /> 
                                                            <span className='py-2 ml-2 mr-4'>%</span>
                                                            <input
                                                                type="number"
                                                                placeholder='Enter Third Party Values'
                                                                value={todSlots.thirdParty[index]}
                                                                onChange={(e) => handleInputChange(index, e.target.value, 'thirdParty')}
                                                                className="mt-1 block w-full px-4 py-2 border border-blue-300 rounded-lg "
                                                            /> 
                                                            <span className='py-2 ml-2'>%</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {errors.powerExchange[index] && (
                                                            <p className="absolute text-red-500 text-sm mt-[-18px] ml-[150px]">{errors.powerExchange[index]}</p>
                                                        )}
                                                        {errors.thirdParty[index] && (
                                                            <p className="absolute text-red-500 text-sm mt-[-18px] ml-[450px]">{errors.thirdParty[index]}</p>
                                                        )}
                                                    </div>
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="sticky bottom-0 bg-white p-4 flex justify-center gap-8">
                            <button
                                type="button"
                                onClick={onClose}
                                className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4"
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            ) : (
                <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
                    <h2 className="text-xl font-semibold mb-4">Please specify the number of Time of Day (ToD) Slots</h2>
                    <p className="text-gray-700 mb-4">
                        To add ToD Purchase details, please provide the number of Time of Day (ToD) Slots in the ToD Banking Policy page.
                    </p>
                    <button
                        className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] mt-4 hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
            )}
        </div>
    );
}
