import axios from 'axios';

const user = JSON.parse(localStorage.getItem('user'));
const token = user?.result?.token

const API_URL = process.env.REACT_APP_API_URL; 


export const fetchIexDiscountDataList = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/iexDiscount/list`, payload);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const addIexDiscountData = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/iexDiscount/multiple`, payload);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const addSingleIexDiscountData = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/iexDiscount`, payload);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const updateSingleIexDiscountData = async (id,payload) => {
    try {
        const response = await axios.put(`${API_URL}/iexDiscount/${id}`, payload);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const getSingleIexDiscountData = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/iexDiscount/${id}`);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const deleteExistingIexDiscountRecord = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/iexDiscount/${id}`);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const getStateById = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/state/by-id/${id}`);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}


// export const deleteExistingPowerPlantDataOfParticularDate = async (date,id) => {
//     try {
//         const response = await axios.post(`${API_URL}/powerPlantData/delete-many/${date}`,{powerPlantUnitId:id});
//         // console.log(response,'reg-list');
//         return response;
//     } catch (error) {
//         console.error('Error :', error);
//         return error?.response?.data;
//     }
// }
