import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

export default function ActionButtons({ handleDelete, handleEdit, handleAddNewConstant=null }) {
  return (
    <div className="flex flex-row">
      {handleAddNewConstant && <button className="mx-1" onClick={handleAddNewConstant}>
        <FontAwesomeIcon id="add" icon={faAdd} />
        <Tooltip anchorSelect="#add" content="Add constant price" />
      </button>}
      <button className="mx-1" onClick={handleEdit}>
        <FontAwesomeIcon id="edit" icon={faEdit} />
        <Tooltip anchorSelect="#edit" content="Edit" />
      </button>
      <button className="mx-1" onClick={handleDelete}>
        <FontAwesomeIcon id="delete" icon={faTrash} />
        <Tooltip anchorSelect="#delete" content="Delete" />
      </button>
    </div>
  );
}
