import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faUsers, faListUl, faCog, faPlus, faSitemap, faNetworkWired, faMapLocationDot, faIndustry, faWarehouse, faGauge, faScaleBalanced, faAward, faSliders, faTowerCell, faFileContract, faChartLine, faUserTag } from '@fortawesome/free-solid-svg-icons';
import config from '../../config/config';
import { Link, useLocation } from 'react-router-dom';

export default function Sidebar() {
    const [activeMenu, setActiveMenu] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname;
        let foundActiveMenu = null;

        Object.keys(config.sidebar).forEach((category, index) => {
            if (config.sidebar[category].some(item => path.includes(item.link))) {
                foundActiveMenu = index;
            }
        });

        setActiveMenu(foundActiveMenu);
    }, [location]);

    const toggleSubMenu = (index) => {
        setActiveMenu(activeMenu === index ? null : index);
    };

    return (
        <div className="bg-white mt-1 text-black-500 flex flex-col min-h-screen min-w-64 shadow-lg">
            <div className="px-4 mt-8 overflow-y-auto mb-16">
                {Object.keys(config.sidebar).map((category, index) => (
                    <div key={category} className={`relative ml-1 ${index !== 0 ? 'mt-5' : ''}`}>
                        <div onClick={() => toggleSubMenu(index)} className={`rounded-md block font-semibold group p-2 cursor-pointer ${activeMenu === index ? 'bg-[#a9dbc3] text-black-300' : 'text-black-300 hover:bg-[#a9dbc3]'}`}>
                            <div className="flex items-center">
                                <span className="mr-2">
                                    {category === 'UserRole' && <FontAwesomeIcon icon={faUserTag} />}
                                    {category === 'UserList' && <FontAwesomeIcon icon={faUsers} />}
                                    {category === 'CMS' && <FontAwesomeIcon icon={faCog} />}
                                    {category === 'Analytics' && <FontAwesomeIcon icon={faChartLine} />}
                                    {category === 'Recommendations' && <FontAwesomeIcon icon={faAward} />}
                                </span>
                                {category === 'UserRole' && <span className="flex-grow ml-2">User Roles</span>}
                                {category === 'UserList' && <span className="flex-grow ml-2">Users</span>}
                                {category === 'CMS' && <span className="flex-grow ml-2">CMS</span>}
                                {category === 'Analytics' && <span className="flex-grow ml-2">Analytics</span>}
                                {category === 'Recommendations' && <span className="flex-grow ml-1">Recommendations</span>}
                                {config.sidebar[category].length > 0 && (
                                    <FontAwesomeIcon icon={faAngleDown} className={`ml-2 transition-transform ${activeMenu === index ? 'rotate-180' : ''}`} />
                                )}
                            </div>
                        </div>
                        {activeMenu === index && (
                            <div>
                                {config.sidebar[category].map((item) => (
                                    <div key={item.title} className="ml-3 mt-2 transition-colors duration-200">
                                        <Link to={item.link} className={`hover:bg-[#E2F5EC] hover:text-black-300 rounded-md py-1 px-2 cursor-pointer text-md block ${location.pathname.includes(item.link) ? ' bg-[#a9dbc3] text-black-300' : 'text-black-500'}`}>
                                            <span className="mr-2 text-xs">
                                                <FontAwesomeIcon icon={item.icon} />
                                            </span>
                                            {item.title}
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <div className="flex-grow"></div>
        </div>
    );
}
