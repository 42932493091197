import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../../components/sidebar/Sidebar'
import PageTitleCard from '../../components/pageTitleCard/PageTitleCard'
import TypeSelectDropdown from '../../components/typeSelectDropdown/TypeSelectDropdown'
import EditBlog from '../editBlog/EditBlog';
import EditArticle from '../editArticle/EditArticle';
import EditSolution from '../editSolution/EditSolution';
import EditCareer from '../editCareer/EditCareer';
import EditPage from '../editPage/EditPage';
import EditFAQs from '../editFAQs/EditFAQs';
import EditHomePage from '../editHomePage/EditHomePage';

export default function EditCMS() {
    const { type, id } = useParams();

    const types = ["blog", "articles", "solution", "career", "page", "home_page","faqs"];
    const [selectedType, setSelectedType] = useState(type);
    const isEditMode = !!id;

    const handleTypeChange = (value) => {
        setSelectedType(value);
    }

    const renderComponentByType = () => {
        switch (selectedType) {
            case 'blog':
                return <EditBlog />;
            case 'articles':
                return <EditArticle />;
            case 'solution':
                return <EditSolution />;
            case 'career':
                return <EditCareer />;
            case 'page':
                return <EditPage />;
            case 'faqs':
                return <EditFAQs />;
            case 'home_page':
                return <EditHomePage />;
            default:
                return null;
        }
    };

    return (
        <div className="flex">
            <Sidebar />
            <div className="flex-grow p-4">
                <div className="max-w-8xl">
                    <PageTitleCard title="Edit Content" />
                    <div className="bg-white shadow-md overflow-hidden mt-4">
                        <div className="p-4 flex flex-wrap items-center justify-between space-y-4 md:space-y-0 md:flex-row">
                            <TypeSelectDropdown
                                label="Type"
                                options={types}
                                selectedValue={selectedType}
                                onChange={handleTypeChange}
                                disabled={isEditMode}
                            />
                        </div>
                        {renderComponentByType()}
                    </div>
                </div>
            </div>
        </div>
    );
}
