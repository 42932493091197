import React, { useEffect, useState } from 'react';
import EditCentralCharges from "../editRegulatoryCharges/EditCentralCharges"
import EditStateCharges from './EditStateCharges';
import EditDiscomCharges from './EditDiscomCharges';
import VariableTypeSelectDropdown from '../VariableTypeSelectDropdown';
import { makeFinancialYear } from '../getFY';


export default function EditRegulatoryCharges({onClose, editType, editId, setUpdateCount}) {
    
    const types = ["central", "state", "discom"];
    const [selectedType, setSelectedType] = useState('');
    const [financialYear,setFinancialYear] =useState("2024-2025")
    const isEditMode = !!editId;

    const handleTypeChange = (value) => {
        setSelectedType(value);
    }

    const handleFY=(value)=>{
        setFinancialYear(value)
    }


    useEffect(() => {
        if (editType) {
            setSelectedType(editType);
        }
    }, [editType]);

    const renderComponentByType = () => {
        switch (selectedType) {
            case 'central':
                return <EditCentralCharges handleFY={handleFY} onClose={onClose} editId={editId} setUpdateCount={setUpdateCount}/>;
            case 'state':
                return <EditStateCharges handleFY={handleFY} onClose={onClose} editId={editId} setUpdateCount={setUpdateCount}/>;
            case 'discom':
                return <EditDiscomCharges handleFY={handleFY} onClose={onClose} editId={editId} setUpdateCount={setUpdateCount}/>;
            default:
                return null;
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
            <div className="bg-white py-4 rounded-lg shadow-lg w-full max-w-2xl min-h-[60vh] max-h-[95vh] overflow-y-auto">
                <h2 className="w-full text-center text-xl font-semibold">Edit Regulatory Charges</h2>
                <form>
                    <div className="flex-grow overflow-auto">
                        <div className="max-w-8xl">
                            <div className="bg-white overflow-hidden p-4 gap-4">
                                <div className="flex flex-col items-left justify-between gap-4 px-4">
                                    <VariableTypeSelectDropdown
                                        label="Financial Year"
                                        options={makeFinancialYear()}
                                        selectedValue={financialYear}
                                        // onChange={handleFilterChange}
                                        disabled={isEditMode}
                                    />
                                    <VariableTypeSelectDropdown
                                        label="Variable Type"
                                        options={types}
                                        selectedValue={selectedType}
                                        disabled={isEditMode}
                                    />
                                </div>
                                <div className="">
                                    {renderComponentByType()}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

