import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';


export default function PageTitleCard({title, addLink, addLinkText, onAddButtonClick,clone=false,cloneFromOpen=()=>{}}) {
  return (
    <div className="bg-white shadow-sm max-w-[1920px] overflow-hidden rounded-xl">
      <div className="p-4 bg-white text-black flex justify-between items-center">
        <h2 className="text-lg font-semibold">{title}</h2>
        <div className='flex flex-row gap-2'>
          {addLink && addLinkText && (
            <>
              <Link 
                to={addLink} 
                className=" p-2 bg-[#a9dbc3] rounded-md text-black hover:px-2 hover:rounded-md"
                data-tooltip-id="add-link-tooltip"
                data-tooltip-content={`${addLinkText}`}
              >
                <span className="flex items-center">
                  <FontAwesomeIcon icon={faPlus} className="mr-2" /> {addLinkText}
                </span>
              </Link>
              <Tooltip id="add-link-tooltip" />
            </>
          )}
        
          {clone && cloneFromOpen && 
            <button 
              onClick={cloneFromOpen} 
              className=" p-2 bg-[#a9dbc3] rounded-md text-black hover:px-2 hover:rounded-md"
              // data-tooltip-id="clone-button-tooltip"
              // data-tooltip-content={`${addLinkText}`}
            >
              Clone {title.split(" ")?.slice(0, 2)?.join(" ")}
            </button>
          }
          {onAddButtonClick && addLinkText && (
            <>
              <button 
                onClick={onAddButtonClick} 
                className=" p-2 bg-[#a9dbc3] rounded-md text-black hover:px-2 hover:rounded-md"
                data-tooltip-id="add-button-tooltip"
                data-tooltip-content={`${addLinkText}`}
              >
                <span className="flex items-center">
                  <FontAwesomeIcon icon={faPlus} className="mr-2" /> {addLinkText}
                </span>
              </button>
              <Tooltip id="add-button-tooltip" />
            </>
          )}
        </div>
      </div>
    </div>
  )
}
