import axios from 'axios';

const user = JSON.parse(localStorage.getItem('user'));
const token = user?.result?.token

const API_URL = process.env.REACT_APP_API_URL; 

export const createPowerPlantUnit = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/powerPlantUnit`, payload);
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const fetchPowerPlantUnitList = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/powerPlantUnit/list`, payload);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const fetchPowerPlantUnitById = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/powerPlantUnit/${id}`);
        // console.log(response,'by-id');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const updatePowerPlantUnitById = async (id, payload) => {
    try {
        const response = await axios.put(`${API_URL}/powerPlantUnit/${id}`, payload);
        // console.log(response,'update-by-id');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const deletePowerPlantUnitById = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/powerPlantUnit/${id}`);
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}