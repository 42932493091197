import axios from "axios";

const user = JSON.parse(localStorage.getItem('user'));
const token = user?.result?.token

const apiUrl = process.env.REACT_APP_API_URL;

export const createUser = async (formData) => {
    try {
        const response = await axios.post(`${apiUrl}/user`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
        })
        return response?.data;
    } catch (error) {
        console.error('Error creating content:', error);
        return error?.response?.data;
        // throw error;
    }
}

export const getUserList = async (payload) => {
    try {
        const response = await axios.post(`${apiUrl}/user/list`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        return response?.data;
    } catch (error) {
        console.error('Error fetching list:', error);
        return [];
    }
}

export const getUserById = async (id) => {
    try {
        const response = await axios.get(`${apiUrl}/user/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        return response?.data;
    } catch (error) {
        console.error('Error getting user :', error);
        return false;
    }
}

export const deleteUserById = async (id) => {
    try {
        const response = await axios.delete(`${apiUrl}/user/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        return response?.data;
    } catch (error) {
      console.error('Error deleting user:', error);
      return false;
      // throw error;
    }
}

export const updateUserById = async (id, formData) => {

    try {
        const response = await axios.put(`${apiUrl}/user/${id}`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        return response?.data;
    } catch (error) {
        console.error('Error updating user:', error);
        return false;
    }
}