import React, { useState, useRef, useEffect } from "react";
import {
  addArithmaticFormula,
  editArithmaticFormula,
  getAllGlobalVariableList,
  getGlobalVariableList,
  gloabalAutoComplete,
} from "../../services/apis/formulaList";
import { useNavigate, useParams, data } from "react-router-dom";
// import "./NestedFormulaAutoComplete.css";
const EditArithmaticFormula = ({ data }) => {
  const [Suggestions, setSuggestions] = useState([
    { "+": "+" },
    { "-": "-" },
    { "*": "*" },
    { "/": "/" },
    { "sum()": "sum()" },
    { "min()": "min()" },
    { "max()": "max()" },
    { "average()": "average()" },
  ]);

  const [updatedSuggestions, setupdatedSuggestions] = useState([]);

  const [usedGlobalVariables, setUsedGlobalVariables] = useState([]);
  const [showErrors, setShowErrors] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [lastChar, setLastChar] = useState("");
  const [cursorPosition, setCursorPosition] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [formulaName, setFormulaName] = useState("");
const [allGlobalVariableList, setAllGlobalVariableList] = useState([])
  const [variableList, setVariableList] = useState([]);

  const navigate = useNavigate();

  const { id } = useParams();

  const [errors, setErrors] = useState({
    formulaName: { error: "", show: true },
    inputValue: { error: "", show: true },
  });

  const fetchDataAndSetState = async () => {
    console.log({ data });
    console.log({ globalRecVariableId: data?.globalRecVariableId?._id });
    const globalVariableList = await getAllGlobalVariableList();
    console.log({ globalVariableList });
    const globalVariableArray = globalVariableList?.data?.data?.results;
    console.log({globalVariableArray})
    setAllGlobalVariableList(globalVariableArray);
    setVariableList((prev) => {
      const newData = [...prev, data?.globalRecVariableId] || [];
      const uniqueData = Array.from(
        new Set(newData.map((item) => item._id))
      ).map((id) => {
        return newData.find((item) => item._id === id);
      });
      console.log({ newData });
      console.log({ uniqueData });

      return [...uniqueData];
    });

    const updatedString = updateStringToName(globalVariableArray,data?.content[0]?.formula)

    setFormulaName(data?.globalRecVariableId?._id);
    setInputValue(updatedString);
    setTimeout(() => {
      setShowSuggestions(false);
    }, 50);
  };

  useEffect(() => {
    fetchDataAndSetState();
  }, [data]);


  function updateStringToName(globalVariableInfoArray, inputString) {
    // Iterate through each global variable in the array
    globalVariableInfoArray.forEach(variable => {
        // Get the unique name and corresponding name
        let uniqueName = variable.uniqueName;
        let correspondingName = variable.name;

        // Create a regular expression to find the unique name in the input string
        let regex = new RegExp(`\\b${uniqueName}\\b`, 'g');

        // Replace the unique name with the corresponding name
        inputString = inputString.replace(regex, correspondingName);
    });

    return inputString;
}


function updateStringToUniqueName(globalVariableInfoArray, inputString) {
    // console.log({ globalVariableInfoArray, inputString });

    // Sort the global variables array by length of the name, longest first
    // This helps in cases where one name is a substring of another
    globalVariableInfoArray.sort((a, b) => b.name.length - a.name.length);

    // Iterate through each global variable in the array
    globalVariableInfoArray.forEach(variable => {
        // Get the name and corresponding unique name
        let name = variable.name;
        let uniqueName = variable.uniqueName;

        // Create a regular expression to find the exact match of the name in the input string
        // The lookbehind (?<!\w) and lookahead (?!\w) ensure the match is not part of a longer word
        let regex = new RegExp(`(?<!\\w)${name}(?!\\w)`, 'gi');

        // Replace the name with the unique name
        inputString = inputString.replace(regex, uniqueName);
    });

    return inputString;
}
  const handleKeyDown = (e) => {
    if (e.key.length === 1) {
      setLastChar(e.key);
    }
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      const length = inputRef.current.value.length;
      inputRef.current.focus();
      inputRef.current.setSelectionRange(length, length);
    }
  }, []);
  const handleChange = (e) => {
    const userInput = e.target.value;
    console.log({ userInput });
    setCursorPosition(e.target.selectionStart);
    console.log({ charPos: userInput.charAt(e.target.selectionStart - 2) });
    const charPos = userInput.charAt(e.target.selectionStart - 1);
    setInputValue(userInput);
    console.log({ cursorPosition: e.target.selectionStart });
    console.log({ input: e.target.value });
    console.log({ lastChar });
    console.log("adding keyword");
    const keywordString = keyword;
    console.log({ keywordString });
    const getkeyword = getLastWord(e.target.value, e.target.selectionStart);
    setKeyword(getkeyword);

    // }
  };

  const updateSuggestions = (keyword) => {
    console.log({ Suggestedkeyword: keyword });
    console.log({ updatedSuggestions });
    const filtered = updatedSuggestions
      .filter((suggestion) => {
        const key = Object.keys(suggestion)[0].toLowerCase();
        return key.includes(keyword.toLowerCase());
      })
      .map((suggestion) => Object.keys(suggestion)[0]);
    console.log({ filtered });
    setFilteredSuggestions(filtered);
    setShowSuggestions(true);
  };

  const handleClick = (suggestion) => {
    console.log({ inputValue });
    console.log({ cursorPosition });
    // const newInputSuggestion = replaceKeywordAtPosition(inputValue,cursorPosition,keyword,suggestion);
    const newInputSuggestion = replaceLastWordBeforeCursor(
      inputValue,
      cursorPosition,
      suggestion,
      keyword
    );

    const foundObject = Suggestions.find((obj) =>
      obj.hasOwnProperty(suggestion)
    );

    if (foundObject === undefined) {
      console.log({ suggestion });
      const objInUpdatedSugegstions = updatedSuggestions.find((obj) =>
        obj.hasOwnProperty(suggestion)
      );
      console.log({ objInUpdatedSugegstions });
      setUsedGlobalVariables((prev) => [...prev, objInUpdatedSugegstions]);
    }
    console.log({ newInputSuggestion });
    setInputValue(newInputSuggestion);

    setFilteredSuggestions([]);
    setShowSuggestions(false);
  };

  function replaceSubstring(str, arr) {
    if (typeof str !== "string" || !Array.isArray(arr)) {
      return "";
    }
    arr.forEach((obj) => {
      Object.keys(obj).forEach((key) => {
        if (str.includes(key)) {
          str = str.replace(key, obj[key]);
        }
      });
    });
    return str;
  }

  useEffect(() => {
    console.log({ keyword });
    if (keyword.length > 0) {
      updateSuggestions(keyword);
    }
  }, [keyword]);

  useEffect(() => {
    console.log({ cursorPosition });
  }, [cursorPosition]);

  useEffect(() => {
    console.log({ variableList });
  }, [variableList]);

  useEffect(() => {
    console.log({ updatedSuggestions });

    updateSuggestions(keyword);
  }, [updatedSuggestions]);

  useEffect(() => {
    console.log({ usedGlobalVariables });
  }, [usedGlobalVariables]);

  function replaceLastWordBeforeCursor(
    text,
    cursorPosition,
    replacement,
    word
  ) {
    console.log({ text, cursorPosition, replacement, word });
    console.log(text.slice(0, cursorPosition)); // Extract the part before the replacement
    let start = text.slice(0, cursorPosition - word.length);
    // Extract the part after the replacement
    let end = text.slice(cursorPosition);
    // Concatenate the start, replaceString, and end
    const finalword = start + " " + replacement + " " + end;
    console.log({ finalword });
    return finalword;
  }

  //   getting global variables
  const getGlobalVariables = async () => {
    try {
      const response = await getGlobalVariableList();
      console.log({ response });
      const data = response?.data?.data?.results;
      console.log({ data });

      setVariableList((prev) => {
        const newData = [...prev, ...data] || [];
        console.log({ newData });
        const uniqueData = Array.from(
          new Set(newData.map((item) => item._id))
        ).map((id) => {
          return newData.find((item) => item._id === id);
        });
        console.log({ uniqueData });
        return [...uniqueData];
      });

      return data;
    } catch (e) {
      console.log(e);
    }
  };

  //   useEffect(() => {
  //     const result = getGlobalVariables();
  //   }, []);

  //   getting global variables
  const getAutocompleteGlobalVariableList = async (keyword) => {
    try {
      console.log("autocomplete api call");
      const response = await gloabalAutoComplete(keyword);
      console.log({ response });
      const data = response?.data?.data;
      console.log({ data });
      if (data && data.length > 0) {
        const resultArray = data.map((item) => {
          return {
            [item.name]: item.uniqueName,
          };
        });
        console.log({ resultArray });
        console.log({ resultArray: [...Suggestions, ...resultArray] });
        setupdatedSuggestions([...Suggestions, ...resultArray]);
      } else {
        setupdatedSuggestions([...Suggestions]);
      }

      //   setVariableList(data);
      //   return data;
    } catch (e) {
      console.log(e);
    }
  };

  function getLastWord(text, cursorPosition) {
    console.log("getting last word");
    const substring = text.slice(0, cursorPosition);
    const reversedSubstring = substring.split("").reverse().join("");
    const match = reversedSubstring.match(/\b[\w$]+\b/);
    if (match) {
      const lastWordReversed = match[0];
      const lastWord = lastWordReversed.split("").reverse().join("");
      console.log({ lastWord });
      return lastWord;
    } else {
      return "";
    }
  }

  useEffect(() => {
    console.log({ keyword });
    if (keyword.length >= 3) {
      const result = getAutocompleteGlobalVariableList(keyword);
    }
  }, [keyword]);

  const editFormula = async () => {
    setShowErrors(true);
    if (formulaName === "") {
      setShowErrors(true);
      errors.formulaName.show = true;
      errors.formulaName.error = "Formula Name Required";
      setErrors({ ...errors });
    }

    if (inputValue === "") {
      setShowErrors(true);
      errors.inputValue.show = true;
      errors.inputValue.error = "Formula is required";
      setErrors({ ...errors });
    }

    if (!errors.formulaName.show && !errors.inputValue.show) {

        const updatedString = updateStringToUniqueName(allGlobalVariableList,inputValue);
console.log({inputValue})
        const formattedstring = formatTextArea(updatedString);
        console.log({formattedstring})
      const apiResponse = await editArithmaticFormula(id, {
        type: "arithmetic",
        financialYear:data.financialYear,
        stateCode:data.stateCode,
        content: [
          {
            formula:formattedstring,
          },
        ],
        globalRecVariableId: formulaName,
      });
      console.log({ apiResponse });
      if (apiResponse.data.statusCode === 200) {
        console.log("formula updated successfully");
        navigate("/recommendations/formula/list");
      } else {
        console.log("Something Went Wrong...");
      }
    }
  };

  const handleFormulaNameValidations = () => {
    if (formulaName === "") {
      errors.formulaName.show = true;
      errors.formulaName.error = "Formula Name  Required";
      setErrors({ ...errors });
    } else {
      errors.formulaName.show = false;
      errors.formulaName.error = "";
      setErrors({ ...errors });
    }
  };

  useEffect(() => {
    handleFormulaNameValidations();
  }, [formulaName]);

  const handleInputValueValidations = () => {
    if (inputValue === "") {
      errors.inputValue.show = true;
      errors.inputValue.error = "Formula  Required";
      setErrors({ ...errors });
    } else {
      errors.inputValue.show = false;
      errors.inputValue.error = "";
      setErrors({ ...errors });
    }
  };

  function formatTextArea(inputText) {
    // Remove line breaks and excess spaces
    let formattedText = inputText
        .replace(/\s+/g, ' ') 
    
    
    return formattedText;
}

  useEffect(() => {
    handleInputValueValidations();
  }, [inputValue]);

  return (
    <div className="flex flex-col py-4 w-full">
      {" "}
      <div className="my-2  w-[400px] ">
        <label className="text-sm font-semibold">Formula Name</label>
        <select
          className="min-h-[40px] px-2 my-2 border border-gray-600 rounded-lg w-full"
          value={formulaName}
          aria-readonly
          //   onChange={handleFormulaName}
        >
          <option value="">Select Formula Name</option>
          {variableList.map((variable) => (
            <option value={variable._id}>{variable.name}</option>
          ))}

          {/* Add more options as needed */}
        </select>
        <p
          className={`${
            errors.formulaName.show && showErrors === true ? "" : "hidden"
          }  py-1 text-[red]`}
        >
          {errors.formulaName.error}
        </p>
      </div>
      <div className="my-2  w-[400px] ">
        <label className="text-sm font-semibold">Formula</label>
        <textarea
          className="min-h-[240px] px-2 mt-2   py-2 text-md font-semibold  border border-gray-600 rounded-lg w-full"
          type="text"
          value={inputValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder="Enter Formula"
        />
        {showSuggestions && inputValue && (
          <ul className="rounded-lg border border-gray-400">
            {filteredSuggestions.length ? (
              filteredSuggestions.map((suggestion, index) => (
                <li
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-100" : "bg-gray-200"
                  }   px-2  py-1   ${index === 0 ? "rounded-t-lg" : ""}  ${
                    index === filteredSuggestions.length - 1
                      ? "rounded-b-lg"
                      : ""
                  }  cursor-pointer `}
                  onClick={() => handleClick(suggestion)}
                >
                  {suggestion}
                </li>
              ))
            ) : (
              <li></li>
            )}
          </ul>
        )}
        <p
          className={`${
            errors.inputValue.show === true && showErrors ? "" : "hidden"
          }  py-1 text-[red]`}
        >
          {errors.inputValue.error}
        </p>
      </div>
      <div className="mt-5">
        <button
          onClick={editFormula}
          className="bg-green-800 text-white  p-2 rounded-lg"
        >
          Save Formula
        </button>
      </div>
    </div>
  );
};

export default EditArithmaticFormula;
