import React, { useEffect, useState } from 'react';
import PageTitleCard from '../../../components/pageTitleCard/PageTitleCard';
import RecordsPerPageDropdown from '../../../components/recordsPerPageDropdown/RecordsPerPageDropdown';
import config from '../../../config/config';
import Sidebar from '../../../components/sidebar/Sidebar';
import ResetButton from '../../../components/resetButton/ResetButton';
import GlobalVariablesTable from '../globalVariables/GlobalVariablesTable';
import { ToastContainer, toast } from 'react-toastify';
import SearchBar from '../../../components/searchBar/SearchBar';
import FilterDropdown from '../../../components/filterDropdown/FilterDropdown';
import AddBillingUnit from './AddBillingUnit';
import EditBillingUnit from './EditBillingUnit';
import { deleteBillingUnitById, fetchBillingUnitsList } from '../../../services/apis/billingUnit';
import BillingUnitTable from './BillingUnitTable';
import SearchById from '../../../components/searchById/SearchById';
import DeleteConfirmation from '../../../components/deleteConfirmation/DeleteConfirmation';
import RecommendationsTable from '../recommendationsTable.js/RecommendationsTable';

export default function BillingUnitListing() {

    const capitalizeWords = (str) => {
        return str
            .replace(/([a-z])([A-Z])/g, '$1 $2') 
            .split(' ') 
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' '); 
    };

    const { billingUnitListHeaders } = config;
    const statuses = ["active", "inactive"]; 

    const [isFormOpen, setIsFormOpen] = useState(false);
    const [editFormOpen, setEditFormOpen] = useState(false);
    const [updateCount, setUpdateCount] = useState(1);
    const [editId, setEditId] = useState("");
    const [tableData, setTableData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: "_id", direction: 'asc' });
    const [searchQuery, setSearchQuery] = useState('');
    const [searchById, setSearchById] = useState("");
    const [searchSymbolQuery, setSearchSymbolQuery] = useState('');
    const [statusFilter, setStatusFilter] = useState('');

    const [limit, setLimit] = useState(() => {
        const storedLimit = parseInt(localStorage.getItem('recordsPerPage'), 10);
        return storedLimit || 50;
    });
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [deleteModalOpen, setDeleteModalOpen] = useState(false); 
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [deleteItemName, setDeleteItemName] = useState("");
    const [loader, setLoader] = useState(false)

    const getBillingUnitList = async () => {
        setLoader(true)
        const payload = {
            page: currentPage,
            pageSize: limit,
            id: searchById,
            status: statusFilter,
            name: searchQuery,
            symbol: searchSymbolQuery,
            sortBy: sortConfig.key,
            sortOrder: sortConfig.direction,
        };
        const response = await fetchBillingUnitsList(payload);
        const data = response?.data?.data?.results;
        setTableData(data);
        setTotalCount(response?.data?.data?.total);
        setLoader(false)
    };

    useEffect(() => {
        getBillingUnitList();
    }, [currentPage, statusFilter, sortConfig, searchQuery, searchSymbolQuery, searchById, updateCount, limit]);

    const handleAddButtonClick = () => {
        setIsFormOpen(true);
    };

    const handleCloseForm = () => {
        setIsFormOpen(false);
    };

    const handleEditCloseForm = () => {
        setEditFormOpen(false);
    };

    const handleRecordsPerPageChange = (newLimit) => {
        setLimit(newLimit);
        setCurrentPage(1); 
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        setCurrentPage(1);
    };
    
    const handleResetFilters = () => {
        setSearchById("");
        setSearchQuery("");
        setSearchSymbolQuery("");
        setStatusFilter("");
    };

    const handleEdit = (id) => {
        setEditFormOpen(true);
        setEditId(id);
    };

    const handleDelete = (id, name) => {
        setDeleteModalOpen(true);
        setDeleteItemId(id);
        setDeleteItemName(name);
    };

    const confirmDelete = async () => {
        const response = await deleteBillingUnitById(deleteItemId);

        if (response?.data?.data?.length === 0) {
            toast.error('Failed to delete the Billing Unit. Please try again later.', {
                position: "top-center",
                autoClose: 1500,
            });
        } else {
            toast.success('Billing Unit deleted successfully!', {
                position: "top-center",
                autoClose: 1500,
            });
            setUpdateCount((prevCount) => prevCount + 1);
        }
        setDeleteModalOpen(false);
    };

    const handleSearch = (event) =>{
        const query = event.target.value;
        setSearchQuery(query);
        setCurrentPage(1);
    }

    const handleSearchBySymbol = (event) => {
        const query = event.target.value;
        setSearchSymbolQuery(query);
        setCurrentPage(1);
    };

    const handleSearchById = (event) => {
        const id = event.target.value;
        setSearchById(id);
        setCurrentPage(1);
    };

    const handleFilterChange = (selectedStatus) => {
        if(selectedStatus){
            setStatusFilter(selectedStatus);
        }
        setCurrentPage(1);
    };

    return (
        <div className="flex">
            <Sidebar />
            <div className="flex-grow p-4">
                <div className="max-w-[1920px]">
                    <PageTitleCard title="Billing Unit List" onAddButtonClick={handleAddButtonClick} addLinkText="Add New Billing Unit"/>
                    <div className=''>
                        <div className="overflow-hidden rounded-xl mt-2 ">
                            <div className="flex">
                                <div className="p-3 flex flex-wrap items-center justify-start gap-4 flex-row">
                                    <SearchBar searchQuery={searchQuery} handleSearch={handleSearch} title="Unit Name"/>
                                    <SearchBar searchQuery={searchSymbolQuery} handleSearch={handleSearchBySymbol} title="Symbol"/>
                                    <SearchById filterById={searchById} handleFilterById={handleSearchById}/>
                                    <FilterDropdown
                                        label="Status"
                                        options={statuses}
                                        // options={statuses?.map((status) => {
                                        //     return { label: capitalizeWords(status), value: status };
                                        // })}
                                        selectedValue={statusFilter}
                                        onChange={(value) => handleFilterChange(value)}
                                    />
                                    <RecordsPerPageDropdown onRecordsPerPageChange={handleRecordsPerPageChange} />
                                </div>
                                <div className="p-3 mt-[60px] mr-2 reset-button-container">
                                    <ResetButton onClick={handleResetFilters} />
                                </div>
                            </div>
                            <div className='bg-white p-2 rounded-xl min-h-[350px]'>
                                <RecommendationsTable
                                    loader={loader}
                                    headings={billingUnitListHeaders}
                                    data={tableData}
                                    handleEdit={(id, type) => handleEdit(id, type)}
                                    handleDelete={(id, name) => handleDelete(id, name)}
                                    handleSort={handleSort}
                                    sortConfig={sortConfig}
                                    title="Edit"
                                />
                            </div>
                        </div>
                        {totalCount > limit && (
                            <div className="pagination-controls bg-white rounded-b-xl border-[#abaaaa] mb-2 pb-2 flex flex-row items-center justify-end pt-8 -mt-2">
                                <button className={`bg-[#e1e0e0] ${currentPage === 1 ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl`} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                <span className="mx-4">{Math.min(currentPage * limit, totalCount) ? Math.min(currentPage * limit, totalCount) : '0'} off {totalCount ? totalCount : '0'}</span>
                                <button className={`bg-[#e1e0e0] ${currentPage * limit >= totalCount ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl mr-2`} disabled={currentPage * limit >= totalCount} onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
                            </div>
                        )}
                    </div>
                </div>
                {isFormOpen && <AddBillingUnit onClose={handleCloseForm} setIsFormOpen={setIsFormOpen} setUpdateCount={setUpdateCount} />}
                {editFormOpen && <EditBillingUnit onClose={handleEditCloseForm} editId={editId} setEditFormOpen={setEditFormOpen} setUpdateCount={setUpdateCount} />}
                <ToastContainer position="top-center" autoClose={3000} />
                <DeleteConfirmation
                    isOpen={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    onConfirm={confirmDelete}
                    itemName={deleteItemName}
                />
            </div>
        </div>
    )
}
