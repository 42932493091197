import React, { useEffect, useState } from 'react'
import { createConstantVariable } from '../../../services/apis/constantVariable';
import config from '../../../config/config';
import InputBox from '../../../components/inputBox/InputBox';
import { toast } from 'react-toastify';
import { fetchGlobalVariablesList } from '../../../services/apis/globalRecommendationVariable';
import { makeFinancialYear, makeYearFormate } from './getFY';


export default function AddConstantVariable({ onClose, setIsFormOpen, setUpdateCount }) {
    const financialYearData=makeFinancialYear()

    const [formData, setFormData] = useState({
        type: 'constant-edit',
        financialYear:"2024",
        value:"",
        globalRecVariableId: "",
        status: 'active',
    });
    const [errors, setErrors] = useState({});
    const [globalVariables, setGlobalVariables] = useState([]);

    useEffect(() => {
        const getGlobalVariablesList = async () => {
            const payload = {
                status: 'active',
                // isMap: false,
                type: 'constant'
            }
            const response = await fetchGlobalVariablesList(payload);
            const data = response?.data?.data?.results;
            setGlobalVariables(data);
            // console.log(data,'data')
        };
        getGlobalVariablesList();
    },[])

    const renderFormFields = () => {
        const fields = config.constantVariablesSettings.fields;

        return Object.keys(fields)?.map((fieldName, index) => {
            const field = fields[fieldName];
            const { label, type, required, options } = field;

            switch (type) {
                case 'text':
                    return (
                        <div key={index} className="mb-6">
                            <div className="flex items-center">
                                <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                                    {label}{required && <span className="text-red-500">*</span>} :
                                </label>
                                <InputBox
                                    width="w-3/4"
                                    placeholder={`Enter ${label}`}
                                    value={fieldName === 'content' ? formData.value : formData[fieldName] || ''}
                                    onChange={(e) => handleChange(fieldName, e.target.value)}
                                    className="ml-4"
                                />
                            </div>
                            {errors[fieldName] && (
                                <p className="absolute text-red-500 text-sm ml-44">{errors[fieldName]}</p>
                            )}
                        </div>
                    );
                case 'select':
                    return (
                        <div key={index} className="mb-6">
                            <div className="flex items-center">
                                <label htmlFor={fieldName} className="w-1/4 text-gray-700">
                                    {label}{required && <span className="text-red-500">*</span>} :
                                </label>
                                <select
                                    id={fieldName}
                                    value={ fieldName === "financialYear" ? makeYearFormate(formData[fieldName]): formData[fieldName] || ''}
                                    onChange={(e) => handleChange(fieldName, fieldName === "financialYear" ? e.target.value.split("-")[0]:e.target.value)}
                                    className={`w-3/4 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 ${
                                        errors[fieldName] ? 'border-red-500' : ''
                                    }`}
                                >
                                    <option value="">Select {label}</option>
                                    {fieldName === "globalRecVariableId" ? globalVariables?.map((globalVariable, index) => (
                                        <option key={index} value={globalVariable._id}>{globalVariable.name}</option>
                                    )): financialYearData?.map((opt,index)=>(
                                        <option key={index} value={opt}>{opt}</option>
                                    ))}
                                </select>
                            </div>
                            {errors[fieldName] && (
                                <p className="absolute text-red-500 text-sm ml-44">{errors[fieldName]}</p>
                            )}
                        </div>
                    );
                default:
                    return null;
            }
        });
    };

    const handleChange = (fieldName, value) => {
        if (fieldName === 'content') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                content: [{ value }]
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [fieldName]: value,
            }));
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: ''
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const validationErrors = {};
        if (!formData.globalRecVariableId) {
            validationErrors.globalRecVariableId = "Global Variable is required";
        }
        if (!formData.financialYear) {
            validationErrors.content = "Financial Year is required";
        }
        if (!formData.value) {
            validationErrors.content = "Content value is required";
        }
        
        if (Object.keys(validationErrors).length > 0) {
            console.log("apple11111")
            setErrors(validationErrors);
            return;
        }
        
        let {globalRecVariableId,...payload}= formData

        try {
            const response = await createConstantVariable(globalRecVariableId,payload);
            
            if (response?.data?.statusCode === 202){
                toast.error(`${response?.data?.message}`, {
                    position: "top-center",
                });
            } else {
                toast.success(`${response?.data?.message}`, {
                    position: "top-center",
                    autoClose: 3000
                });
                setUpdateCount(prevCount => prevCount + 1);
                setIsFormOpen(false);
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("Failed to add global variable. Please try again.", {
                position: "top-center",
            })
        } 
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
            <div className="bg-white p-4 shadow-lg w-full max-w-3xl max-h-[95vh] overflow-y-auto rounded-lg">
                <h2 className="text-lg font-semibold">Add a Constant Variable</h2>
                <form>
                    <div className="flex-grow overflow-auto">
                        <div className="max-w-8xl">
                            <div className="bg-white shadow-md overflow-hidden p-4">
                                {renderFormFields()}
                            </div>
                        </div>
                    </div>
                </form>
                <div className="sticky bottom-0 bg-white p-4 flex justify-center gap-8">
                    <button
                        type="button"
                        onClick={onClose}
                        className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4"
                    >
                        Save
                    </button>
                </div>
                {/* <ToastContainer position="top-center" autoClose={3000} /> */}
            </div>
        </div>
    );
}
