const LegalUpdatesList = [
    {
        _id: 1,
        title: "EcoPower Insights",
        description: "Lorem Ipsum Montreal School",
        shortDescription: "",
        document: "",
        type: "legalUpdates",
        status: "publish",
        slug: "ecopower-insights",
        metaTitle: "",
        metaDataDescription: "",
        metaKeyword: ""
    },
    {
        _id: 2,
        title: "Renewable Rhythms",
        description: "Lorem Ipsum Montreal Rhythms",
        shortDescription: "",
        document: "",
        type: "legalUpdates",
        status: "draft",
        slug: "renewable-rhythms",
        metaTitle: "",
        metaDataDescription: "",
        metaKeyword: ""
    }
]

export default LegalUpdatesList;