import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faPlus, faLongArrowAltUp, faLongArrowAltDown , faSearch, faEye, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { getPagesListData } from '../../services/apis/pagesList';
import { Link } from 'react-router-dom';
import '../common.css';

export default function PagesList() {

    const [totalPagesListData, setTotalPagesListData] = useState([]);
    const [pagesListData, setPagesListData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const data = await getPagesListData();
            setTotalPagesListData(data);
            setPagesListData(data);
        }
        fetchData();

        handleSort('_id', 'asc');
    },[]);

    // Function to handle sorting
    const handleSort = (key, direction) => {
        setPagesListData([...pagesListData].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        }));
        setSortConfig({ key, direction });
    };

    // Function to get sort icon based on sort configuration
    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'asc' ? <FontAwesomeIcon icon={faLongArrowAltUp} className="text-gray-500 ml-1" /> : <FontAwesomeIcon icon={faLongArrowAltDown } className="text-gray-500 ml-1" />;
        }
        return null;
    };

    // Function to handle search by name
    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
        if (!query || query.trim() === '') {
            setPagesListData(totalPagesListData); 
        } else {
            const filteredData = pagesListData.filter((data) => {
                return data && data.title && data.title.toLowerCase().includes(query.toLowerCase());
            });
            setPagesListData(filteredData);
    
            if (filteredData.length === 0) {
                setPagesListData([{ emptyResult: true }]); 
            }
        }
    };

  return (
    <>
        <div className="flex">
            <Sidebar />
            <div className="flex-grow p-4">
                <div className="max-w-8xl">
                    {/* Pages List Title Card */}
                    <div className="bg-white shadow-md overflow-hidden">
                        <div className="p-4 bg-white flex justify-between items-center">
                            <h2 className="text-lg font-semibold">Pages List</h2>
                            <Link to={`/add-page`}><span className="text-gray"><FontAwesomeIcon icon={faPlus} className="text-gray" /> Add Page</span></Link>
                        </div>
                    </div>

                    {/* Search Input */}
                    <div className="bg-white shadow-md overflow-hidden mt-4">
                        <div className="p-4">
                            <div className="mt-4 mb-4 max-w-xl relative">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <FontAwesomeIcon icon={faSearch} className="text-gray-400" />
                                </div>
                                <input
                                    type="text"
                                    placeholder="Search by Name..."
                                    className="w-full pl-10 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400"
                                    value={searchQuery}
                                    onChange={handleSearch}
                                />
                            </div>

                            {/* Pages List Table */}
                            <div className="mt-6">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead>
                                        <tr>
                                            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('_id', sortConfig.direction === 'asc' ? 'desc' : 'asc')}>Page ID {getSortIcon('_id')}</th>
                                            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('title', sortConfig.direction === 'asc' ? 'desc' : 'asc')}>Page Name {getSortIcon('title')}</th>
                                            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" >Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {pagesListData.length > 0 ? (
                                            pagesListData?.map((data, index) => {
                                                if (data.emptyResult) {
                                                    return (
                                                        <tr key={index}>
                                                            <td colSpan="5" className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 text-center">No Records Available</td>
                                                        </tr>
                                                    );
                                                }
                                                return(
                                                        <tr key={index}>
                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">{data._id}</td>
                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">{data.title}</td>
                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                                                <FontAwesomeIcon icon={faEye} className="text-gray-500 mr-4 cursor-pointer hover:text-gray-600" />
                                                                <Link to={`/edit-page/${data._id}`} className="text-gray-500 mr-1 hover:text-gray-600"><FontAwesomeIcon icon={faEdit} className="text-gray-500 mr-4 cursor-pointer hover:text-gray-600" /></Link>
                                                                <FontAwesomeIcon icon={faTrash} className="text-gray-500 cursor-pointer hover:text-gray-600" />
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan="5" className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 text-center">No Records Available</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
