import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import EditActionButton from '../../../components/actionButtons/EditActionButton';
import ActionButtons from '../../../components/actionButtons/ActionButtons';
import Loading from '../../../components/loader/Loader';

const getDisplayValue = (key, row) => {
    switch (key) {
        case 'variableName':
            return row.globalRecVariableId?.name || '-';
        case 'uniqueName':
            return row.globalRecVariableId?.uniqueName || '-';
        case 'state':
            if (row.objectType === 'state') {
                return row.objectId?.name || '-';
            } else if (row.objectType === 'discom') {
                return row.stateId?.name || '-';
            }
            return '-';
        case 'discom':
            if (row.objectType === 'discom') {
                return row.objectId?.name || '-';
            }
            return '-';
        case 'status':
            return row.globalRecVariableId?.status || '-';
        default:
            return row[key] || '-';
    }
};

export default function ChargesTable({loader=false, headings, data, handleSort, sortConfig, handleEdit, handleDelete, title }) {
    
    return (
        <div className="bg-white ">
            <div className="overflow-x-auto overflow-y-hidden">
                <table className="w-full px-2">
                    <thead>
                        <tr>
                            {Object.entries(headings)?.map(([key, heading], index, array) => (
                                <th
                                    key={heading}
                                    className={`px-3 py-3 bg-gray-100 text-left text-xs leading-4 font-medium text-black uppercase tracking-wider ${heading !== 'Actions' && heading !== 'Status' && heading !== 'State' && heading !== 'Discom' ? 'cursor-pointer' : ''}`}
                                    onClick={() => heading !== 'Actions' && heading !== 'Status' && heading !== 'State' && heading !== 'Discom' && handleSort(key)}
                                    style={{
                                        borderBottomLeftRadius: index === 0 ? '0.5rem' : '0',
                                        borderBottomRightRadius: index === array.length - 1 ? '0.5rem' : '0',
                                        borderTopLeftRadius: index === 0 ? '0.5rem' : '0',
                                        borderTopRightRadius: index === array.length - 1 ? '0.5rem' : '0',
                                    }}
                                >
                                    {heading} {sortConfig.key === key && (
                                        sortConfig.direction === 'asc' ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />
                                    )}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-300">
                        {data?.length && !loader > 0 ? (
                            data?.map((row, index) => (
                                <tr key={index}>
                                    {Object?.entries(headings)?.map(([key, value]) => (
                                        <td key={key} className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black">
                                            {key === 'roles' ? (
                                                row[key]?.map((role, index) => (
                                                    <span key={role?._id}>
                                                        {role?.name}
                                                        {index < row[key]?.length - 1 && ', '}
                                                    </span>
                                                ))
                                            ) : (
                                                key === 'actions' ? (
                                                    <ActionButtons handleEdit={() => handleEdit(row?._id, row?.objectType)} handleDelete={() => handleDelete(row?._id, row?.globalRecVariableId?.name)} title={title} />
                                                ) : (
                                                    key === 'status' ? (
                                                        <span className="p-1 px-2 inline-flex leading-5">
                                                            {row.globalRecVariableId?.status.charAt(0).toUpperCase() + row.globalRecVariableId?.status.slice(1)}
                                                        </span>
                                                    ) : (
                                                        key === 'objectType' ? (
                                                            <span className="p-1 px-2 inline-flex leading-5">
                                                                {row.objectType.charAt(0).toUpperCase() + row.objectType.slice(1)}
                                                            </span>
                                                        ) : (
                                                            key==="financialYear"?`${row?.financialYear}-${parseInt(row?.financialYear)+1}` :
                                                        getDisplayValue(key, row)
                                                )
                                            )))}
                                        </td>
                                    ))}
                                </tr>
                            ))
                        ) : loader ? 
                        (
                            <tr className="">
                                <td colSpan={Object.keys(headings).length} className="">
                                    <Loading/>
                                </td>
                            </tr>  
                        ):(
                            <tr>
                                <td colSpan={Object.keys(headings).length} className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black text-center">
                                    No Records Available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
